import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import {
  RadioGroup,
  Typography,
  FormControlLabel,
  TextField,
  Radio,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import { DirectionsBoat, Flight, DirectionsRailway } from "@material-ui/icons";

export default function TransportMode(props) {
  const { handleMultiToggle, data } = props;

  const [transportMode, setTransportMode] = useState([
    { id: 1, name: "Land", icon: <DirectionsRailway /> },
    { id: 2, name: "Ocean", icon: <DirectionsBoat /> },
    { id: 3, name: "Air", icon: <Flight /> },
  ]);

  return (
    <div>
      <ToggleButtonGroup
        value={data?.freightMode}
        className="customToggle"
        onChange={(event, value, name) =>
          handleMultiToggle(event, value, "freightMode")
        }
      >
        <ToggleButton value="ocean" data-testid="oceanToggle">
          <DirectionsBoat /> Ocean Transport
        </ToggleButton>
        <ToggleButton value="land" data-testid="landToggle">
          <DirectionsRailway /> Land Transport
        </ToggleButton>
      </ToggleButtonGroup>

      {/* {transportMode.map(e => {
        return (
          <Grid item xs={4}>
            <article className="box-checkbox-container">
              <input
                type="checkbox"
                id={e.id}
                className="box-checkbox-container_input"
              />
              <div>
                <span>{e.icon}</span>
                <span>{e.name}</span>
              </div>
            </article>
          </Grid>
        );
      })} */}
    </div>
  );
}
