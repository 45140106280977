import React from "react";
import { Paper, Button, Box } from "@material-ui/core";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import { FiXCircle, FiClock } from "react-icons/fi";
import { Skeleton } from "@material-ui/lab";
import APIkit from "../../../include/APIKit";
import moment from "moment";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";

const style = {
  title: {
    fontSize: "22px",
    fontWeight: "500",
  },
  date: {
    fontSize: "14px",
    color: "#838b92",
  },
  seen: {
    fontSize: "14px",
    color: "#838b92",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};

const status = currentStatus => {
  const style = {
    fontSize: "14px",
    color:
      currentStatus === 1
        ? "#ffa312"
        : currentStatus === 2
        ? "#00b437"
        : "#ef3c3b",
    display: "flex",
    alignItems: "center",
  };
  switch (currentStatus) {
    case 1: // pending
      return (
        <div style={style}>
          <FiClock />
          &nbsp; Pending
        </div>
      );
    case 2: // accepted
      return (
        <div style={style}>
          <CheckRoundedIcon />
          &nbsp; Accepted
        </div>
      );
    case 3: // rejected
      return (
        <div style={style}>
          <FiXCircle />
          &nbsp; Rejected
        </div>
      );
    default:
      return;
  }
};

export default function Actions({ offer, loading, contractId, requestId }) {
  const [isSeen] = React.useState(offer?.isSeen);
  const [disabled, setDisabled] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  const [severity, setSeverity] = React.useState("");
  const [message, setMessage] = React.useState("");

  const withdrawOffer = offerId => {
    setDisabled(true);

    APIkit.put(`offers/withdraw/${offerId}`)
      .then(res => {
        setMessage("Offer withdrawn successfully");
        setSeverity("success");
        setOpenAlert(true);

        setTimeout(() => {
          setOpenAlert(false);
        }, 3000);
      })
      .catch(e => {
        setMessage("Something went wrong, please try again");
        setSeverity("error");
        setOpenAlert(true);
        console.log(e);
      });
  };

  return (
    <Paper className="white-card submit-offer-card">
      <div className="card-body">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={4}
        >
          <div>
            {loading ? (
              <Skeleton variant="text" width="50px" />
            ) : (
              <div style={style.title}>
                {offer?.currency?.symbol + " " + offer?.finalPrice}
              </div>
            )}
            <div style={style.date}>
              Valid until:
              {loading ? (
                <Skeleton variant="text" width="100px" />
              ) : (
                moment(offer?.offerValidty).format("DD MM, YYYY")
              )}
            </div>
          </div>
          <div>
            {loading ? (
              <Skeleton variant="text" width="50px" />
            ) : (
              <div style={style.seen}>{isSeen ? "Seen" : "Unseen"}</div>
            )}

            {loading ? (
              <Skeleton variant="text" width="70px" />
            ) : (
              <div>{status(offer?.status)}</div>
            )}
          </div>
        </Box>

        <Collapse in={openAlert}>
          <Alert severity={severity}>{message}</Alert>
        </Collapse>

        <br />

        {offer?.status === 2 && (
          <Button
            className="submit-offer-btn"
            color="primary"
            variant="contained"
            disableElevation
            fullWidth
            href={"/contracts/" + contractId + "/overview"}
            target="_blank"
          >
            View Contract
          </Button>
        )}

        {offer?.requestReference?.status == 1 &&
        <Button
          className="submit-offer-btn"
          color="primary"
          variant="outlined"
          disableElevation
          fullWidth
          href={"/create-offer/" + requestId}
          target="_blank"
        >
          Submit another offer
        </Button>}

        <Button
          className={
            offer?.status === 4 || disabled
              ? "withdraw-offer-disabled-btn"
              : "withdraw-offer-btn"
          }
          disableElevation
          fullWidth
          disabled={offer?.status === 4 || disabled ? true : false}
          onClick={e => withdrawOffer(offer?.id)}
        >
          {offer?.status === 4 || disabled ? "Withdrawn" : "Withdraw Offer"}
        </Button>
      </div>
    </Paper>
  );
}
