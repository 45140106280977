import React, { useEffect } from "react";
import APIKit from "../include/APIKit";
import { Link } from "react-router-dom";
import { 
	LinearProgress
  } from '@material-ui/core';

function StripeRefresh() {

  useEffect(() => {
    APIKit.get("payments/new-account" ).then(result => {
		if (result.status === 200) {
		  if(result.data.isSuccess) {
			window.location.href = result.data.record;
		  }
		}
	}).catch(e => {});
  }, []);

  return (
    <div className="auth-container" style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, background: "#f5f6f8", zIndex: 9999}}>
      <div className="left-side">
        <div className="left-content">
          <Link to="/"><img src="/img/lgsm-logo.svg" className="auth-logo" /></Link>
          <div className="content-holder">
            <LinearProgress className="card-loader" />
            	<h2 className="page-h2">Refreshing your stripe onboarding link...</h2>          
			</div>
		</div>
      </div>
    </div>
  );
}

export default StripeRefresh;