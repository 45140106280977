import React, { Component } from 'react';

import APIKit from '../include/APIKit';
import moment from 'moment';

import MapComponent from './Map';

import {
	FormControlLabel,
	Checkbox,
  Button,
  IconButton,
  Drawer,
  Grid,
  Chip,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
  TableHead,
  CircularProgress
} from '@material-ui/core';

import {
  Alert,
  AlertTitle
} from '@material-ui/lab';


// IMPORT MATERIAL ICONS
import { 
  ChevronLeft,
  DirectionsBoat, 
  DirectionsRailway,
  CallReceived as CallReceivedIcon,
  CallMade as CallMadeIcon,  
  Place as PlaceIcon,
  Widgets as WidgetsIcon,
  EnhancedEncryption as EnhancedEncryptionIcon,
  CenterFocusStrong as CenterFocusStrongIcon,
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon,  
  MonetizationOn as MonetizationOnIcon
} from '@material-ui/icons';
import ClientInfo from "./request-drawer/ClientInfo";
import containerTypes from "../include/containerTypes.json";

export default class OfferConfirmationDialog extends Component {
  state = {
    open: false,
    requestId: null,
    loadingRequest: true,

    client: {
      id: "",
      averageRating: 0,
      currencyCode: "",
      fullName: "",
      isShipper: false,
      isSupplier: false,
      logoAbbreviation: "",
      logoUrl: "",
      numberOfBookings: 0,

      // missing from api
      amountToSpend: 198732,
      paymentVerified: "Verified",
      location: "Lebanon",
    },
  };

  componentDidMount = () => {};

  getRequestDetails = id => {
    APIKit.get("requests/" + id)
      .then(result => {
        if (result.status === 200) {
          this.setState({ client: result.data.record.organization });

          this.setState({ request: result.data.record }, () => {
            const requestServices = result.data.record.services;
            let services = [];
            Object.keys(requestServices).forEach(function (key) {
              services.push(requestServices[key].id);
            });
            this.setState({ requestServices: services });
          });
        }
        this.setState({ loadingRequest: false });
      })
      .catch(e => {
        this.setState({ loadingRequest: false });
      });
  };

  toggleDrawer = id => {
    window.history.pushState({}, null, "/requests/" + id);
    this.setState(
      { open: !this.state.open, requestId: id, loadingRequest: true },
      () => {
        this.getRequestDetails(id);
      }
    );
  };
  closeDrawer = () => {
    window.history.pushState({}, null, "/requests");
    this.setState({ open: false, loadingRequest: false });
  };

  favoriteRequest = () => {
    this.setState({
      request: {
        ...this.state.request,
        isFavorite: !this.state.request.isFavorite,
      },
    });
    APIKit.post("requests/favorite/" + this.state.request.id, null)
      .then(result => {})
      .catch(e => {});
  };

  render() {
    const transportModes = ["", "Ocean Freight", "Land Freight"];
    const shipmentTypes = [
      "",
      "RoRo",
      "Less Than Container Load",
      "Full Container Load",
      "Bulk or Break Bulk",
    ];
    const dateOptions = ["", "On", "Before", "After"];
    const packagingTypes = ["","Pallet","Crate","Box","Bag","Bale","Bundle","Can"]

    const request = this.state.request;
    const loadingRequest = this.state.loadingRequest;

    return (
      <Drawer
        anchor="right"
        open={this.state.open}
        onClose={this.closeDrawer}
        className="drawer-container"
      >
        <div className="drawer-actions">
          <IconButton
            aria-label="back"
            onClick={this.closeDrawer}
            color="primary"
          >
            <ChevronLeft fontSize="large" />
          </IconButton>
          {!loadingRequest && request && (
            <div className="actions-header">
              <p className="text-label">{request.reference}</p>
              <p className="text-content">
                Listed {moment(request.createdDate).format("ll")} | Expires in{" "}
                {request.expiryCountdown.remaining}
              </p>
            </div>
          )}
        </div>

        {!loadingRequest && request ? (
          <div className="drawer-content">
            
            
            <div className="shipment-body">
              <Grid container spacing={3}>
                <Grid item sm={8}>
                  <div className="shipment-header-container">
                    <div className="shipment-header">
                      <Grid container spacing={3} alignItems="center">
                        <React.Fragment>
                          <Grid item sm={3}>
                            <p className="text-label">
                              {request.routeOrigin.isPort
                                ? request.routeOrigin.description
                                : request.routeOrigin.city +
                                  ", " +
                                  request.routeOrigin.country.isoCode}
                            </p>
                            <p className="text-content">
                              {dateOptions[request.pickupDateOption]}{" "}
                              {moment(request.pickupDate).format("ll")}
                            </p>
                            <br />
                            <Chip
                              size="small"
                              icon={<CallMadeIcon />}
                              label="Pickup from Door"
                              className="pickup-required-chip required"
                            />
                          </Grid>
                          <Grid item sm={6}>
                            <div className="place-container">
                              <div className="place">
                                <span className="place-name">
                                  <img
                                    src={request.routeOrigin.country.flag}
                                    className="country-flag"
                                    alt=""
                                  />
                                  {/* {request.routeOrigin.city + ', ' + request.routeOrigin.country.isoCode} */}
                                </span>
                              </div>
                              <div className="mode">
                                <span className="route"></span>
                                {request.freightType === 1 && (
                                  <DirectionsBoat className="mode-icon" />
                                )}
                                {request.freightType === 2 && (
                                  <DirectionsRailway className="mode-icon" />
                                )}
                              </div>
                              <div className="place">
                                <span className="place-name">
                                  <img
                                    src={request.routeDestination.country.flag}
                                    className="country-flag"
                                    alt=""
                                  />
                                  {/* {request.routeDestination.city + ', ' + request.routeDestination.country.isoCode} */}
                                </span>
                              </div>
                            </div>
                          </Grid>
                          <Grid item sm={3}>
                            <p className="text-label">
                              {request.routeDestination.isPort
                                ? request.routeDestination.description
                                : request.routeDestination.city +
                                  ", " +
                                  request.routeDestination.country.isoCode}
                            </p>
                            <p className="text-content">
                              {dateOptions[request.deliveryDateOption]}{" "}
                              {moment(request.deliveryDate).format("ll")}
                            </p>
                            <br />
                            <Chip
                              size="small"
                              icon={<CallReceivedIcon />}
                              label="Delivery to Door"
                              className="pickup-required-chip"
                              disabled
                            />
                          </Grid>
                        </React.Fragment>
                      </Grid>
                    </div>
                    <MapComponent
                      markers={
                        !loadingRequest && this.state.open
                          ? [
                              {
                                lat: request.routeOrigin.latitude,
                                lng: request.routeOrigin.longtiude,
                                id: 1,
                              },
                              {
                                lat: request.routeDestination.latitude,
                                lng: request.routeDestination.longtiude,
                                id: 2,
                              },
                            ]
                          : []
                      }
                    />
                    <div className="shipment-header-information">
                      <div className="staticon">
                        <PlaceIcon />
                        <span className="big">3452</span>
                        <span className="small">
                          Total
                          <br />
                          KM
                        </span>
                      </div>
                      <div className="staticon">
                        <WidgetsIcon />
                        <span className="big">{request.itemsCount}</span>
                        <span className="small">
                          Total
                          <br />
                          Items
                        </span>
                      </div>
                      <div className="staticon">
                        <CenterFocusStrongIcon />
                        <span className="big">{request.volume}</span>
                        <span className="small">
                          Total
                          <br />
                          CBM
                        </span>
                      </div>
                      <div className="staticon">
                        <EnhancedEncryptionIcon />
                        <span className="big">{request.grossWeight}</span>
                        <span className="small">
                          Total
                          <br />
                          KG
                        </span>
                      </div>
                    </div>
                  </div>

                  <Paper className={"white-card "}>
                    <div className="card-header">Shipment Details </div>
                    <div className="card-body">
                      <Grid container spacing={3}>
                        <Grid item sm={4}>
                          <p className="review-body-label">Transport Mode:</p>
                          {transportModes[request.freightType]} <br />
                          <p className="review-body-label">Shipment Type:</p>
                          {shipmentTypes[request.shipmentType]}
                        </Grid>
                        <Grid item sm={4}>
                          <p className="review-body-label">Origin:</p>
                          {request.routeOrigin.city +
                            ", " +
                            request.routeOrigin.country.name}
                          <br />
                          <p className="review-body-label">
                            Pickup {dateOptions[request.pickupDateOption]}:
                          </p>
                          {moment(request.pickupDate).format("L")}
                        </Grid>
                        <Grid item sm={4}>
                          <p className="review-body-label">Destination:</p>
                          {request.routeDestination.city +
                            ", " +
                            request.routeDestination.country.name}
                          <br />
                          <p className="review-body-label">
                            Deliver {dateOptions[request.deliveryDateOption]}:
                          </p>
                          {moment(request.deliveryDate).format("L")}
                        </Grid>
                      </Grid>
                      <div className="spacer" />

                      <Grid container spacing={3}>
                        <Grid item sm={12}>
                          <p className="review-body-label">Cargo Details:</p>
                          <TableContainer>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  {request.shipmentType !== 4 && (
                                    <TableCell style={{ width: "20px" }}>
                                      Qty
                                    </TableCell>
                                  )}
                                  {request.shipmentType === 2 && (
                                    <TableCell>Package</TableCell>
                                  )}
                                  {request.shipmentType === 3 && (
                                    <TableCell>Container</TableCell>
                                  )}
                                  {request.shipmentType !== 3 && (
                                    <TableCell>Description</TableCell>
                                  )}
                                  {request.shipmentType !== 1 && (
                                    <TableCell>Category</TableCell>
                                  )}
                                  {!(
                                    request.shipmentType === 3 ||
                                    request.shipmentType === 4
                                  ) && <TableCell style={{minWidth: 140}}>L,W,H (cm)</TableCell>}
                                  
                                  <TableCell>Weight</TableCell>
                                  {request.shipmentType === 4 && (
                                    <TableCell>Volume</TableCell>
                                  )}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {request.items.map((item, index) => (
                                  <TableRow key={index}>
                                    {request.shipmentType !== 4 && (
                                      <TableCell>{item.quantity}</TableCell>
                                    )}
                                    {request.shipmentType === 2 && (
                                      <TableCell>
                                        {packagingTypes[item.packagingType]}
                                      </TableCell>
                                    )}
                                    {request.shipmentType === 3 && (
                                      <TableCell>
                                        {containerTypes.filter(c => c.Id == item.containerId)[0].Name}
                                      </TableCell>
                                    )}
                                    {request.shipmentType !== 3 && (
                                      <TableCell>
                                        {item.description || ""}
                                      </TableCell>
                                    )}
                                    {request.shipmentType !== 1 && (
                                      <TableCell>
                                        {item.category.name || ""}
                                      </TableCell>
                                    )}
                                    {!(
                                      request.shipmentType === 3 ||
                                      request.shipmentType === 4
                                    ) && (
                                      <TableCell>
                                        {item.length} x {item.width} x {item.height} 
                                      </TableCell>
                                    )}
                                    <TableCell>
                                      {item.grossWeight + "kg" || ""}
                                    </TableCell>
                                    {request.shipmentType === 4 && (
                                      <TableCell>
                                        {item.volume + "cbm" || ""}
                                      </TableCell>
                                    )}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <br />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        {request.description && (
                          <Grid item sm={12}>
                            <p className="review-body-label">Notes:</p>
                            {request.description}
                          </Grid>
                        )}
                        {request.services.length > 0 && (
                          <Grid item sm={12}>
                            <p className="review-body-label">
                              Additional Services Requested:
                            </p>
                            {request.services.length > 0 &&
                              request.services.map(service => (
                                <div className="services-box" key={service.id}>
                                  
                                  <div className="checkbox-label">
                                    <span className="checbox-title">
                                      {service.name}
                                    </span>
                                    <span className="checbox-info">
                                      {service.fields && service.fields.map((field, fieldIndex) => (
                                        <span>{field.name}: {field.value}</span>
                                      ))}
                                    </span>
                                  </div>
                                    
                                  
                                </div>
                              ))}
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </Paper>
                </Grid>

                <Grid item sm={4}>
                <Paper className="white-card submit-offer-card">
                  <div className="card-body">
                    {request.offersCount === 0 && (
                      <Alert severity="info" className="be-first">
                        <AlertTitle>Be The First!</AlertTitle>
                        This request hasn't received any offers yet! Be the
                        first one to submit an offer.
                      </Alert>
                    )}
                    <Button
                      className="submit-offer-btn"
                      color="primary"
                      variant="contained"
                      size="large"
                      fullWidth
                      disableElevation
                      href={"/create-offer/" + request.id}
                      target="_blank"
                    >
                      Submit an Offer
                    </Button>
                    {request.isFavorite ? (
                      <Button
                        className="favorite-request-btn"
                        color="primary"
                        variant="outlined"
                        size="large"
                        fullWidth
                        disableElevation
                        startIcon={<FavoriteIcon />}
                        onClick={this.favoriteRequest}
                      >
                        Favorited
                      </Button>
                    ) : (
                      <Button
                        className="favorite-request-btn"
                        color="primary"
                        variant="outlined"
                        size="large"
                        fullWidth
                        disableElevation
                        startIcon={<FavoriteBorderIcon />}
                        onClick={this.favoriteRequest}
                      >
                        Favorite
                      </Button>
                    )}

                    {request.lowestOffer > 0 && (
                      <div className="lowest-offer">
                        <span>Lowest Offer</span>
                        <span>${request.lowestOffer}</span>
                      </div>
                    )}

                    <div className="request-stats">
                      <div className="stat">
                        <span className="name">Favorited</span>
                        <span className="count">
                          <FavoriteIcon /> {request.favorites}
                        </span>
                      </div>
                      <div className="stat">
                        <span className="name">Offers</span>
                        <span className="count">
                          <MonetizationOnIcon /> {request.offersCount}
                        </span>
                      </div>
                    </div>
                  </div>
                </Paper>

                  <ClientInfo client={this.state.client} />
                </Grid>
              </Grid>
            </div>
          </div>
        ) : (
          <div className="loading-container">
            <CircularProgress />
          </div>
        )}
      </Drawer>
    );
  }
}
