import React, { useState, useEffect, useRef } from "react";

import {
  Badge ,
  makeStyles,
  Toolbar,
  IconButton,
  Button,
  AppBar,
  InputBase,
  MenuItem,
  Menu,
  Avatar,
  Drawer,
  Tooltip
} from '@material-ui/core';

import {
  Add,
  ChevronLeft,
  Search as SearchIcon
} from '@material-ui/icons';

import { useHistory } from "react-router-dom";
import { RiNotification4Line, RiNotification4Fill } from "react-icons/ri";
import NotificationsList from "./notifications/NotificationsList";
import { useStore } from "../context/StoreContext";
import { FiCheckCircle } from "react-icons/fi";


function Header(props) {
  const history = useHistory();
  const {userData} = useStore();
  const notificationsRef = useRef();

  const { container, title, subTitle, actions, canGoBack, backAction, subHeader, customTitle, searchEnabled, searchAction, searchPlaceholder, midContent, canCreateRequest } = props;
  const classes = useStyles();

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => {
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return;
    // }
    setDrawerOpen(open);
  };

  const HeaderActions = () => {
		return (
			<>
        {actions}
				{canCreateRequest && <Button 
					onClick={() => history.push('/requests/create')} 
					variant="contained" 
					color="primary" 
					disableElevation
					startIcon={<Add />}
          className="header-create-btn"
				>
					Create Request
				</Button>}
			</>
		)
	}

  return (

    <>
      <AppBar position="fixed" className="page-header" color="default" elevation={0}>
        <Toolbar>  
          {canGoBack && 
            <IconButton onClick={backAction} className="back-btn">
              <ChevronLeft size={19} />
            </IconButton>
          }

          {customTitle ? title : (
            <h1 className="page-title">
              <span>{title}</span>
              <span>{subTitle}</span>
            </h1>
          )}

          {searchEnabled && 
            <form className={classes.search} onSubmit={searchAction}>
              <div className={classes.searchIcon}>
                <SearchIcon style={{fontSize: 19}} />
              </div>
              <InputBase
                name="search"
                placeholder={searchPlaceholder || "Search"}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                //onSubmit={() => alert("ss")}
                //onClick={testNotification}
              />
            </form>
          }
          {midContent !== false && (
            midContent
          )}
  
          <div className="header-actions">
            {actions !== false && <HeaderActions />}
            <IconButton color="inherit" className="header-icon notifications-icon" onClick={() => toggleDrawer(true)}>
              <Badge badgeContent={userData?.unreadNotificationsCount} color="primary" variant="standard">
                <RiNotification4Fill size={19} />
              </Badge>
            </IconButton>
          </div>          
        </Toolbar>
        {subHeader}
      </AppBar>
      <Drawer anchor="right" open={isDrawerOpen} onClose={() => toggleDrawer(false)} >
        <div className="drawer-actions" style={{zIndex: 9999, position: "fixed", right: 0, top: 0, width: 500, maxWidth: '100%'}}>
          <IconButton
            aria-label="back"
            onClick={() => toggleDrawer(false)}
            className="back-btn"
          >
            <ChevronLeft size={19} />
          </IconButton>
            <div className="actions-header flex-header">
              <p className="drawer-title">Notifications</p>
              <Tooltip title="Mark all as read">
                <IconButton
                  edge="end"
                  aria-label="mark as read"
                  aria-haspopup="true"
                  onClick={() => notificationsRef.current.readAll()}
                >
                  <FiCheckCircle />
                </IconButton>
              </Tooltip>
            </div>
        </div>
        <NotificationsList ref={notificationsRef}  close={() => toggleDrawer(false)} />
      </Drawer>
    </>

  );
}

export default Header;




const drawerWidth = 250;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    //borderBottom: '1px solid #eeeeee',
    background: 'transparent',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: '#fff'
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    //borderRadius: theme.shape.borderRadius+4,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '350px !important',
    //boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.13)',
    //border: '1px solid #dbdee5',
    background: "#fff",
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
      width: 'auto',
    },
  },
  sectionDesktop: {
    width: '40%',
    textAlign: 'right'
  },
  searchIcon: {
    width: '45px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#c5c8d1',
    zIndex: 9
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
    //padding:'2px 0px',
    paddingRight: '18px',
    height: '36px',
    background: '#f5f6f8',
    borderRadius: 8
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 5),
    transition: theme.transitions.create('width'),
    width: '100%',
    color: '#80878f',
    // [theme.breakpoints.down('md')]: {
    //   width: 200,
    // },
  },
  list: {
    padding: '0px',
    margin: '18px 30px'
  }
}));