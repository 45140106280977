import React, { useState } from "react";
import {
	ListItemSecondaryAction, 
	ListItemText,
	ListItem,
	List,
	makeStyles,
	ListItemAvatar,
	Avatar,
	Typography,
	Divider
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

export default function Conversations({conversations, onChange, active}) {
	const classes = useStyles();
	// const [active, setActive] = useState(null);

	const handleChange = (id, index) => {
		// setActive(index);
		onChange(id);
	}

	const getTime = (date) => {
		var now = moment(new Date()); //todays date
		var end = moment(date); // another date
		var duration = moment.duration(now.diff(end));
		var hours = duration.asHours();
		if(hours > 48) {
			return moment(date).format("L");
		}
		return moment(date).fromNow();
	}

	return (
		<div className="conversations-container">
			<List className={classes.root}>
				{conversations && conversations.map((conv, index) => (
					
					<ListItem 
						alignItems="flex-start" 
						className={`conversation-item ${(active === conv.offerId) && "active"}`} 
						button
						onClick={() => handleChange(conv, index)}
					>
						<ListItemAvatar>
							<Avatar alt="Remy Sharp" src={conv.destinationProfileUrl} style={{border: '1px solid #f5f6f8'}} />
						</ListItemAvatar>
						<ListItemText
							primary={<>
								{conv.destinationName} 
								<span className="time">{getTime(conv.lastModifiedDate)}</span>
								
							</>}
							secondary={
								<>
									<div className="ref">
										Offer #{conv.reference?.offerNumber}
										{conv.unreadMessagesNumber > 0 && <span className={`chip-label unread`}>{conv.unreadMessagesNumber}</span>}
									</div>
									{conv.isLastMessageAttachment ? <><AttachFileIcon style={styles.icon} /> {`${conv.textContent ? conv.textContent : 'Attachment'}`} </> : conv.textContent}
								</>
							}
						/>
						
					</ListItem>
					
				
				))}
			</List>
		</div>
	)
}

const styles = {
	icon: {
		fontSize: 14,
		position: 'relative',
		bottom: '-2px'
	}
}