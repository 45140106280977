import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { StoreProvider } from './context/StoreContext';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { SnackbarProvider } from 'notistack';
import ErrorBoundary from './containers/ErrorBoundary';

const stripePromise = loadStripe(process.env.NODE_ENV === 'production' ? 
	"pk_live_51H0mdVE0kvb76JTWteF9lcu1WUpJjZdnsMZPAegc87tgFQ1yYawrtPhV07QtRiL3MqFUpTPXHk4AVhVUm561RHFU00Ky7r6zGk" :
	'pk_test_51H0mdVE0kvb76JTW3S3MPE7tToAkULEDEH6Z36YL4GRCyjZhd7kp3NLpJ5DmVDSo7bWTxPIWSCsZtcTfeqIGNenQ00eyv2IYI5'
);

ReactDOM.render(
	<StoreProvider>
		<Elements stripe={stripePromise}>
			<SnackbarProvider >
				<ErrorBoundary>
					<App />
				</ErrorBoundary>
			</SnackbarProvider>
		</Elements>
	</StoreProvider>
, document.getElementById('root'));
serviceWorker.unregister();
