import React from 'react';
import PropTypes from 'prop-types'
import { Button, IconButton, Paper, Tooltip } from '@material-ui/core';
import { Edit, Error, PersonAdd } from '@material-ui/icons';
import { Alert as MUAlert, AlertTitle, Skeleton } from '@material-ui/lab';
import { useStore } from '../../context/StoreContext';

export default function Notices(props) {
	const {
		setPartiesDialogOpen,
		isConsigneeMissing,
		// isCarrierMissing,
		isExporterMissing,
		isNotifyMissing,
		isShipmentMissingDetails,
		accountType,
		shipmentType,
		setActiveTab
	} = props;

	const MissingItem = ({description, title, action}) => {
		return (
			<MUAlert severity="warning"
				style={{marginBottom: 10}}
				action={action ? action : undefined}
			>	
				{/* <AlertTitle>{title}</AlertTitle> */}
				<div className="missing-item">
					{description}
				</div>
			</MUAlert>
		)
	}

	const handlePartyAction = (type) => {
		setActiveTab("overview");
		setPartiesDialogOpen(type);
	}



	return (
	
		<div className="alerts" style={{marginBottom: 20}}>
			
					
					{((isShipmentMissingDetails && shipmentType != 1 && accountType == 1) || (isShipmentMissingDetails && shipmentType == 1 && accountType == 2)) && 
						<MissingItem
							title="Missing Shipment Information"
							description="Shipment Cargo information is incomplete! Please open the shipment tab to fill required details."
							action={
								<Button color="inherit" size="small" onClick={() => setActiveTab("shipment")}>
									Open
								</Button>
							}
						/>
					}
					{isExporterMissing && accountType == 2 && 
						<MissingItem
							title="Assign an Exporter"
							description="Exporter is not assigned yet. Please assign an exporter"
							action={
								<Button color="inherit" size="small" onClick={() => handlePartyAction("exporter")}>
									Assign
								</Button>
							}
						/>
					}
					{isConsigneeMissing && accountType == 2 && 
						<MissingItem
							title="Assign a Consignee"
							description="Consignee is not assigned yet. Please assign a consignee"
							action={
								<Button color="inherit" size="small" onClick={() => handlePartyAction("consignee")}>
									Assign
								</Button>
							}
						/>
					}
					{isNotifyMissing && accountType == 2 && 
						<MissingItem
							title="Assign a Notify"
							description="Notify is not assigned yet. Please assign a notify"
							action={
								<Button color="inherit" size="small" onClick={() => handlePartyAction("notify")}>
									Assign
								</Button>
							}
						/>
					}
		
		</div>
	)
}
