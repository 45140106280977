import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Figure from "./Figure";
import APIKit from "../../include/APIKit";
import { BiDollar } from "react-icons/bi";
import { FiFileText, FiEdit } from "react-icons/fi";
import { FaWeightHanging } from "react-icons/fa";

export default function Figures(props) {
  const { requestNumber, contracts, figureTitle1, figureTitle2 } = props;
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [currency, setCurrency] = useState("$");

  const handleFigures = () => {
    APIKit.get("figures")
      .then(result => {
        setTotalWeight(result.totalWeight);
        setTotalAmount(result.totalAmount);
      })
      .catch(e => {});
  };

  useEffect(() => {
    handleFigures();
  }, []);

  return (
    <>
      <Grid container spacing={2} style={{ height: "100%" }}>
        <Grid item container spacing={2}>
          <Grid item xs={6} key={1} style={{ height: "100%" }}>
            <Figure
              icon={<BiDollar />}
              title={figureTitle1}
              value={currency + totalAmount.toFixed(2)}
              style={{ height: "100%" }}
            />
          </Grid>
          <Grid item xs={6} key={2} style={{ height: "100%" }}>
            <Figure
              icon={<FiFileText />}
              title={figureTitle2}
              value={requestNumber}
              style={{ height: "100%" }}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={6} key={3} style={{ height: "100%" }}>
            <Figure
              icon={<FiEdit />}
              title={"Contracts"}
              value={contracts?.totalItems || 0}
              style={{ height: "100%" }}
            />
          </Grid>
          <Grid item xs={6} key={4} style={{ height: "100%" }}>
            <Figure
              icon={<FaWeightHanging />}
              title={"Total Weight"}
              value={totalWeight}
              style={{ height: "100%" }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
