import React from "react";
import { Box } from "@material-ui/core";

export default function SupplierSettingsList({
  companyType,
  shipmentTypes,
  transportModes,
}) {
  const style = {
    div: {
      marginBottom: "20px",
      fontSize: "15px",
    },
    title: {
      fontWeight: "500",
      color: "rgb(0 0 0 / 50%)",
    },
  };

  return (
    <Box>
      <div style={style.div}>
        <div style={style.title}>Company Type</div>
        <div>{companyType}</div>
      </div>
      <div style={style.div}>
        <div style={style.title}>Transport Modes</div>
        <div>{transportModes && transportModes.map(e => e)}</div>
      </div>
      <div style={style.div}>
        <div style={style.title}>Main Shipment Types</div>
        <div>{shipmentTypes && shipmentTypes.map(e => e)}</div>
      </div>
    </Box>
  );
}
