import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

import CircularProgress from '@material-ui/core/CircularProgress';


import APIKit from '../include/APIKit';



function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const detailService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function MakemodelAutocomplete(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const loaded = React.useRef(false);

  const fetch = React.useMemo(
    () =>
      throttle((query, active) => {
      //console.log(query)
      setLoading(true);
        APIKit.get("makemodels?isCompact=false&query="+query+"&size=10" ).then(result => {
			if (result.status === 200) {
			  //console.log(result.data);
			  if (active) {
				let newOptions = [];
		
				if (value) {
				  newOptions = [value];
				}
		
				if (result.data) {
				  newOptions = [...newOptions, ...result.data.record];
				}
		
				setOptions(newOptions);
				
			  }
			} setLoading(false);
		  }).catch(e => {setLoading(false);})
      }, 200),
    [],
  );


 

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    let query = inputValue;

    fetch(query, active);

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  
  return (
    <Autocomplete
      style={{ width: '100%' }}
      size="small"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={props.value || value}
      loading={loading}
      noOptionsText={'Search make and models'}
      onChange={(event, newValue) => {
        props.selection(newValue);
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} InputLabelProps={{ shrink: true, required: true }} 
        
        InputProps={{
          ...params.InputProps,
          autoComplete: 'new-password',
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={20} className="autocompleteSpinner" /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
        
        label={props.label ? props.label : null} variant="outlined" fullWidth />
      )}
      renderOption={(option) => {
		  //console.log(option)
        return (
<div>
                <span key={option.id} style={{ fontWeight: option.description.toLowerCase() == inputValue.toLowerCase() ? 600 : 400 }}>
                  {option.description}
                </span>
              </div>
        );
      }}
    />
  );
}
