import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import Card from "../Card";

export default function SupplierOffers(props) {
  return (
    <div>
      <Card
        variant="white"
        bodyPadding={false}
        headerTitle="Offers"
        headerSubtitle=""
        headerAction={
          <Link to="/offers" style={{ textDecoration: "none" }}>
            <Button color="primary" disableElevation>
              ALL offers
            </Button>
          </Link>
        }
      ></Card>
    </div>
  );
}
