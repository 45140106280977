import React from "react";
import { TextField, Grid } from "@material-ui/core";

const style = {
  input: {
    width: "100%",
  },
};

export default function CompanyInfoForm(props) {
  const { companyInfo, setCompanyInfo } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          id="outlined-basic"
          label="Name"
          variant="outlined"
          style={style.input}
          type="text"
          onChange={e =>
            setCompanyInfo({ ...companyInfo, name: e.target.value })
          }
          defaultValue={companyInfo.name}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="outlined-basic"
          label="URL"
          variant="outlined"
          style={style.input}
          type="url"
          onChange={e =>
            setCompanyInfo({ ...companyInfo, url: e.target.value })
          }
          defaultValue={companyInfo.url}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="outlined-basic"
          label="Phone"
          variant="outlined"
          style={style.input}
          type="tel"
          onChange={e =>
            setCompanyInfo({ ...companyInfo, phone: e.target.value })
          }
          defaultValue={companyInfo.phone}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="outlined-basic"
          label="Address"
          variant="outlined"
          style={style.input}
          type="text"
          onChange={e =>
            setCompanyInfo({ ...companyInfo, address: e.target.value })
          }
          defaultValue={companyInfo.address}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="outlined-basic"
          label="VAT ID"
          variant="outlined"
          style={style.input}
          type="text"
          onChange={e =>
            setCompanyInfo({ ...companyInfo, vatid: e.target.value })
          }
          defaultValue={companyInfo.vatid}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="outlined-basic"
          label="Timezone"
          variant="outlined"
          style={style.input}
          onChange={e =>
            setCompanyInfo({ ...companyInfo, timezone: e.target.value })
          }
          defaultValue={companyInfo.timezone}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="outlined-basic"
          label="Description"
          variant="outlined"
          style={style.input}
          type="text"
          onChange={e =>
            setCompanyInfo({ ...companyInfo, description: e.target.value })
          }
          defaultValue={companyInfo.description}
        />
      </Grid>
    </Grid>
  );
}
