import React, { createContext, useState, useEffect } from "react";
import APIKit from "../include/APIKit";
import jwt_decode from 'jwt-decode';
import { HubConnectionBuilder } from '@microsoft/signalr';

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("UDATA")) || null);
  const [authToken, setAuthToken] = useState(localStorage.getItem("TOKEN") || false);
  const [accountType, setAccountType] = useState(localStorage.getItem("ACCT") || false);
  const [ connection, setConnection ] = useState(null);

  const setToken = (token) => {
    let decodedToken = jwt_decode(token);
    localStorage.setItem("TOKEN", token);
    localStorage.setItem("ACCT", decodedToken.UserType);
    setAuthToken(token);
    setAccountType(decodedToken.UserType);
  }

  useEffect(() => {
    if(authToken) {
      getUserDetails()
    }
    const newConnection = new HubConnectionBuilder()
    .withUrl("https://marketplace.intralogisoft.com/realtimehub", { accessTokenFactory: () => authToken })
    .withAutomaticReconnect()
    .build();

    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if(authToken) {
      getUserDetails()
    }
  }, [authToken]);

  useEffect(() => {
    if (connection) {
      connection.start().then(result => {
        console.log('Connected!');
      }).catch(e => console.log('Connection failed: ', e));

      connection.on("Notification", not => {
        getUserDetails();
      });
    }
  }, [connection]);


  const getUserDetails = () => {
    APIKit.get("authentication/Details" ).then(result => {
      if (result.status === 200) {
        localStorage.setItem("UDATA", JSON.stringify(result.data.record));
        setUserData(result.data.record);
      }
    }).catch(e => {});
  }




  return (
    <StoreContext.Provider
      value={{
        userData,
        setUserData,
        authToken,
        setToken,
        accountType,
        setAccountType,
        connection,
        getUserDetails
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export function useStore() {
  return React.useContext(StoreContext);
}