import React from "react";

export default function Figure(props) {
  const { title, value, icon } = props;
  const styles = {
    card: {
      background: "#fff",
      borderRadius: 4,
      border: "1px solid #dedede",
      padding: "20px",
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    flex: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    headerTitle: {
      fontSize: 14,
      fontWeight: "400",
      color: "rgb(0 0 0 / 50%)",
    },
    headerValue: {
      fontSize: 30,
      fontWeight: "500",
      marginBottom: "5px",
    },
    icon: {
      height: "50px",
      width: "50px",
      padding: "10px",
      background: "#e5ebfc",
      color: " #1c4ef5 ",
      borderRadius: "50%",
      fontSize: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "10px",
    },
  };
  return (
    <div className="card" style={styles.card}>
      {/* background : #e5ebfc
      color: #1c4ef5 */}
      <div style={styles.icon}>{icon}</div>

      <div style={styles.flex}>
        <div className="card-header" style={styles.headerTitle}>
          {title}
        </div>
        <div className="card-header" style={styles.headerValue}>
          {value}
        </div>
      </div>
    </div>
  );
}
