import React from "react";
import { TextField, Grid } from "@material-ui/core";
import { handleConfirmPass } from "./FormHandling";

const style = {
  input: {
    width: "100%",
  },
};
export default function PersonalInfoForm(props) {
  const { personalInfo, setPersonalInfo } = props;
  const [error, setError] = React.useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          id="firstname"
          label="First Name"
          variant="outlined"
          style={style.input}
          type="text"
          onChange={e =>
            setPersonalInfo({ ...personalInfo, firstname: e.target.value })
          }
          defaultValue={personalInfo.firstname}
          value={personalInfo.firstname}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="lastname"
          label="Last Name"
          variant="outlined"
          style={style.input}
          type="text"
          onChange={e =>
            setPersonalInfo({ ...personalInfo, lastname: e.target.value })
          }
          defaultValue={personalInfo.lastname}
          value={personalInfo.lastname}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="phone"
          label="Phone number"
          variant="outlined"
          style={style.input}
          type="tel"
          onChange={e =>
            setPersonalInfo({ ...personalInfo, phone: e.target.value })
          }
          defaultValue={personalInfo.phone}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="email"
          label="Email"
          variant="outlined"
          style={style.input}
          type="email"
          onChange={e =>
            setPersonalInfo({ ...personalInfo, email: e.target.value })
          }
          defaultValue={personalInfo.email}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="password"
          label="Password"
          variant="outlined"
          style={style.input}
          type="password"
          onChange={e =>
            setPersonalInfo({ ...personalInfo, password: e.target.value })
          }
          defaultValue={personalInfo.password}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="confirmPassword"
          label="Confirm Password"
          variant="outlined"
          style={style.input}
          error={error}
          type="password"
          onChange={e =>
            setPersonalInfo({ ...personalInfo, confirmPass: e.target.value })
          }
          onBlur={e =>
            handleConfirmPass(personalInfo.password, personalInfo.confirmPass)
              ? setError(false)
              : setError(true)
          }
          defaultValue={personalInfo.confirmPass}
        />
      </Grid>
    </Grid>
  );
}
