import React, { useEffect, useState } from "react";
import { 
	Button,
	FormControl,
	Grid,
	MenuItem,
	Select,
	TextField,
	IconButton,
	InputLabel,
	FormControlLabel,
	Checkbox,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody
} from "@material-ui/core";
import { 
	Delete as DeleteIcon,
	Add as AddIcon
} from "@material-ui/icons";
import Card from "../Card";

import containerTypes from "../../include/containerTypes.json";

export default function ReviewTab (props) {
	const { 
		request,
		services
	} = props;

    const transportModes = ['', 'Ocean', 'Land', 'Air'];
    const shipmentTypes = ['', 'RoRo', 'Less Than Container Load', 'Full Container Load', 'Bulk or Break Bulk'];
    const dateOptions = ['', 'On', 'Before', 'After'];
    const packagingTypes = ["","Pallet","Crate","Box","Bag","Bale","Bundle","Can"]



	return (
		<Card 
			headerTitle="Notes & Additional Services"
		>
			<Grid container spacing={3}>
				<Grid item sm={4}>
				<p className="review-body-label">Transport Mode:</p>
				{transportModes[request.freightType]} <br/>
				<p className="review-body-label">Shipment Type:</p>
				{shipmentTypes[request.shipmentType]}
				</Grid>
				<Grid item sm={4}>
				<p className="review-body-label">Origin:</p>
				{request.routeOrigin?.description}<br/>
				<p className="review-body-label">Pickup {dateOptions[request.pickupDateOption]}:</p>
				{request.pickupDate}
				</Grid>
				<Grid item sm={4}>
				<p className="review-body-label">Destination:</p>
				{request.routeDestination?.description}<br/>
				<p className="review-body-label">Deliver {dateOptions[request.deliveryDateOption]}:</p>
				{request.deliveryDate}
				</Grid>
			</Grid>
			<div className="spacer" />


			<Grid container spacing={3}>
				<Grid item sm={12}>
				<p className="review-body-label">Cargo Details:</p>
				<TableContainer>
					<Table size="small">
					<TableHead>
						<TableRow>
						{request.shipmentType != 4 &&
							<TableCell>Qty</TableCell>
						}
						{request.shipmentType == 2 && 
							<TableCell>Packaging</TableCell>
						}
						{request.shipmentType == 3 && 
							<TableCell>Container Type</TableCell>
						}
						{request.shipmentType != 3 && 
							<TableCell>Shipment Type</TableCell>
						}
						{!(request.shipmentType == 3 || request.shipmentType == 4) && 
							<TableCell>Length</TableCell>
						}
						{!(request.shipmentType == 3 || request.shipmentType == 4) && 
							<TableCell>Width</TableCell>
						}
						{!(request.shipmentType == 3 || request.shipmentType == 4) && 
							<TableCell>Height</TableCell>
						}
						<TableCell>Gross Weight</TableCell>
						{request.shipmentType == 4 && 
							<TableCell>Volume</TableCell>
						}
						</TableRow>
					</TableHead>
					<TableBody>
						{request.items.map((item, index) => (
						<TableRow key={index}>
							{request.shipmentType != 4 &&
							<TableCell>{item.quantity}</TableCell>
							}
							{request.shipmentType == 2 && 
							<TableCell>{packagingTypes[item.packagingType]}</TableCell>
							}
							{request.shipmentType == 3 && 
							<TableCell>{containerTypes.filter(c => c.Id == item.containerId)[0].Name}</TableCell>
							}
							{request.shipmentType != 3 && 
							<TableCell>{item.description || ''}</TableCell>
							}
							{!(request.shipmentType == 3 || request.shipmentType == 4) && 
							<TableCell>{item.length || ''}</TableCell>
							}
							{!(request.shipmentType == 3 || request.shipmentType == 4) && 
							<TableCell>{item.width || ''}</TableCell>
							}
							{!(request.shipmentType == 3 || request.shipmentType == 4) && 
							<TableCell>{item.height || ''}</TableCell>
							}
							<TableCell>{item.grossWeight || ''}</TableCell>
							{request.shipmentType == 4 && 
							<TableCell>{item.volume || ''}</TableCell>
							}
						</TableRow>
						))}
					</TableBody>
					</Table>
				</TableContainer>
				<br/>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				{request.description && 
				<Grid item sm={12}>
					<p className="review-body-label">Notes:</p>
					{request.description}
				</Grid>
				}
				<Grid item sm={12}>
				<p className="review-body-label">Additional Services:</p>
				{services && services.map((service) => (
					request.services.filter(c => c.id === service.id)[0] && 							
					<div className="services-box" key={service.id}>
                                  
						<div className="checkbox-label">
						<span className="checbox-title">
							{service.name}
						</span>
						<span className="checbox-info">
							{service.fields && service.fields.map((field, fieldIndex) => (
							<span>{field.name}: {field.value}</span>
							))}
						</span>
						</div>
					  
					
				  	</div>
				))}
				</Grid>
			</Grid>
		</Card>
	)
}