import React from "react";
import { Grid, Paper } from "@material-ui/core";
import moment from "moment";

export default function OfferDetails(props) {
  const { offer, request } = props;

  React.useEffect(() => {
    console.log(request);
  }, [request]);

  const dateOptions = ["", "On", "Before", "After"];
  const transportModes = ["Ocean freight", "Land Freight", "Air Freight"];
  const shipmentTypes = [
    "",
    "RoRo",
    "Less Than Container Load",
    "Full Container Load",
    "Bulk or Break Bulk",
  ];

  return (
    <Paper className={"white-card "}>
      <div className="card-header">
        <div>Offer Details</div>
      </div>
      <div className="card-body">
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <p className="review-body-label">Transport Mode:</p>
            {transportModes[offer?.freightType]}
          </Grid>
          <Grid item sm={6}>
            <p className="review-body-label">Shipment Type:</p>
            {shipmentTypes[offer?.shipmentType]}
          </Grid>

          <Grid item sm={6}>
            <p className="review-body-label">Origin:</p>
            {request?.routeOrigin?.description}
            <br />
            <p className="review-body-label">
              Pickup &nbsp;
              {dateOptions[request?.pickupDateOption]}:
            </p>
            {moment(request?.pickupDate).format("L")}
            <br />
            {offer?.freightType === 0 && (
              <>
                <p className="review-body-label">
                  Loading date:
                  {/* {dateOptions[request?.pickupDateOption]}: */}
                </p>
                {moment(offer?.loadingDate).format("L")}
              </>
            )}
          </Grid>
          <Grid item sm={6}>
            <p className="review-body-label">Destination:</p>
            {request?.routeDestination?.description}
            <br />
            <p className="review-body-label">
              Deliver &nbsp;
              {dateOptions[request?.deliveryDateOption]}:
            </p>
            {moment(offer?.deliveryDate).format("L")}
            <br />
            {offer?.freightType === 0 && (
              <>
                <p className="review-body-label">
                  Discharging date:
                  {/* {dateOptions[request?.deliveryDateOption]}: */}
                </p>
                {moment(offer?.dischargingDate).format("L")}
              </>
            )}
          </Grid>
        </Grid>

        {offer?.note ? (
          <>
            <div className="spacer"></div>
            <Grid container spacing={3}>
              <Grid item sm={12}>
                <p className="review-body-label">Notes:</p>
                {offer?.note}
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </div>
    </Paper>
  );
}
