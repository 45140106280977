import React from "react";
import Avatar from "./Avatar";
import CreateIcon from "@material-ui/icons/Create";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export default function AvatarNameContainer(props) {
  const { name, defaultUrl, loading, imageUrl, setImageUrl } = props;
  const style = {
    box: {
      paddingBottom: "20px",
      borderBottom: "1px solid rgb(241, 241, 241)",
      width: "50%",
    },
    name: {
      fontSize: 18,
      fontWeight: "500",
      color: "rgb(0 0 0)",
    },
  };
  return (
    <Box display="flex" alignItems="center" style={style.box}>
      <Avatar
        defaultUrl={defaultUrl}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
      />
      <div style={{ paddingLeft: "15px" }}>
        <div style={style.name}>
          {loading ? <Skeleton variant="text" width="100px" /> : name}
        </div>
      </div>
    </Box>
  );
}
