import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import APIKit from "../../../include/APIKit";
import AlertComponent from "../generalSettings/Alert";

export default function InviteMemberDialog({ open, setOpen }) {
  const [alert, setAlert] = useState({
    show: false,
    alertMessage: "",
    severity: "",
  });

  const handleClose = () => {
    setOpen(false);
  };
  const handleInvite = () => {
    APIKit.get("fake")
      .then(result => {
        if (result.status === 200) {
          setAlert({
            ...alert,
            show: true,
            alertMessage: "Invitation sent successfully",
            severity: "success",
          });
          setTimeout(() => {
            setAlert({ ...alert, show: false });
          }, 5000);
        }
      })
      .catch(e => {
        setAlert({
          ...alert,
          show: true,
          alertMessage: "Sorry Something went wrong",
          severity: "error",
        });

        setTimeout(() => {
          setAlert({ ...alert, show: false });
        }, 5000);
      });
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Invite member</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the email address of the member you would like to
            invite.
          </DialogContentText>
          <TextField
            variant="outlined"
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
          />
          <AlertComponent
            severity={alert.severity}
            open={alert.show}
            message={alert.alertMessage}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ maxWidth: 100 }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            style={{ marginRight: 15 }}
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleInvite}
          >
            Invite
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
