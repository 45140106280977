import React from "react";
import { TextField, Button, FormControl } from "@material-ui/core";
import APIKit from "../../include/APIKit";

export default function InviteCollab() {
  const style = {
    background: "#fff",
    borderRadius: 4,
    border: "1px solid #dedede",
    position: "relative",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space between",
    "& >*": {
      height: "33%",
    },
  };
  const handleSubmit = () => {
    APIKit.post("api")
      .then(result => {})
      .catch(e => {});
  };
  return (
    <div className="card" style={style}>
      <div
        style={{
          fontSize: "20px",
          fontWeight: 500,
          marginBottom: "10px",
        }}
      >
        Invite a collaborator
      </div>
      <FormControl onSubmit={handleSubmit}>
        <TextField
          label="Email address"
          variant="outlined"
          type="email"
          size="small"
          style={{ marginBottom: "10px" }}
        />
        <Button
          type="submit"
          style={{ maxWidth: 100 }}
          variant="contained"
          color="primary"
        >
          Invite
        </Button>
      </FormControl>
    </div>
  );
}
