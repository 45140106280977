import React from "react";
import { Box } from "@material-ui/core";
import DefaultCurrency from "../generalSettings/DefaultCurrency";
import TimeZoneSelector from "../generalSettings/TimeZoneSelector";
import DateTimeFormat from "../generalSettings/DateTimeFormat";

const style = {
  input: {
    width: "60%",
    marginBottom: "20px",
  },
  box: {
    padding: "20px 30px",
  },
  div: {
    marginBottom: "15px",
  },
  title: {
    fontSize: 16,
    fontWeight: "500",
    color: "rgb(0 0 0 / 50%)",
    paddingBottom: "10px",
  },
};

export default function LocationInfoForm() {
  return (
    <Box style={style.box} display="flex" flexDirection="column">
      <div style={style.div}>
        <div style={style.title}>Timezone</div>
        <TimeZoneSelector />
      </div>
      <div style={style.div}>
        <div style={style.title}>Currency</div>
        <DefaultCurrency />
      </div>
      <div style={style.div}>
        <div style={style.title}>Preffered date format</div>
        <DateTimeFormat />
      </div>
    </Box>
  );
}
