import React from 'react';
import {useStripe, useElements, IbanElement, CardElement} from '@stripe/react-stripe-js';

import APIKit from '../../include/APIKit';

export default function CardSetupForm() {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const accountholderName = event.target['accountholder-name'];
    const email = event.target.email;


    APIKit.get("PaymentMethods/new-setupintent/card").then(async res => {
      const result = await stripe.confirmCardSetup(res.data.record, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: 'Jenny Rosen',
          },
        }
      });
  
      if (result.error) {
        // Show error to your customer.
        console.log(result.error.message);
      } else {
        // Show a confirmation message to your customer.
        // The SetupIntent is in the 'succeeded' state.
      }
    }).catch(e => {});

    
    
  };

  return (
	<div className="auth-container overlay createProfile">
		<div className="auth-body">
	  		<div className="content-holder" data-testid="content" >
          <form onSubmit={handleSubmit}>
            <CardElement />
            <button disabled={!stripe}>Save Card</button>
          </form>
				
			</div>
		</div>
	</div>
  );
}