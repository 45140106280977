/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import AccountCard from "./account/AccountCard";
import LocationCard from "./account/LocationCard";
import AlertComponent from "./generalSettings/Alert";

export default function Account() {
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");

  return (
    <div className="tab-content">
      <AlertComponent open={open} severity={severity} message={alertMessage} />
      <br />
      <AccountCard
        setOpen={setOpen}
        setSeverity={setSeverity}
        setAlertMessage={setAlertMessage}
      />
      <br />
      <LocationCard
        setOpen={setOpen}
        setSeverity={setSeverity}
        setAlertMessage={setAlertMessage}
      />
    </div>
  );
}
