import React, { useEffect, useState } from "react";
import APIKit from "../../include/APIKit";
import Page from "../../containers/Page";
import { useHistory, useParams } from "react-router";
import { Backdrop, Button, CircularProgress, Grid, TextField } from "@material-ui/core";
import RequestInfo from "../../components/CreateOffer/RequestInfo";
import TransportFields from "../../components/CreateOffer/TransportFields";
import PricingFields from "../../components/CreateOffer/PricingFields";
import RequestServices from "../../components/CreateOffer/RequestServices";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import { AlertTitle, Alert } from "@material-ui/lab";
import { ChevronRight } from "@material-ui/icons";

export default function CreateOffer() {
	const history = useHistory();
	const params = useParams();
	const [isBusy, setIsBusy] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [successMessage, setSuccessMessage] = useState(false);
	const [canSubmit, setCanSubmit] = useState(false);
	const [loadingRequest, setLoadingRequest] = useState(true);
	const [request, setRequest] = useState(null);
	const [services, setServices] = useState(null);
	const [offer, setOffer] = useState({
		requestReference: {
		  id: null
		}, 
		note: null, 
		price: '',
		totalPrice: 0,
		offerItems: [
		  {
			isRoute: true,
			routeOrigin: {
			  port: null,
			},
			routeDestination: {
			  port: null,
			},
			freightType: 1,
			isOptional: false,
			price: ''
		  }
		],
		offerValidty: null,
		pickupDate: null,
		deliveryDate: null,
		loadingDate: null,
		dischargingDate: null
	});

	useEffect(() => {
		fetchServices();
		getRequestDetails();
	}, [])

	useEffect(() => {
		validate();
	}, [offer])

	const getRequestDetails = () => {
		APIKit.get("requests/"+params.id ).then(result => {
			if (result.status === 200) {
				let tempOffer = {...offer, requestReference: { id: result.data.record.id }};
				if(result.data.record.routeOrigin.isPort) {
					tempOffer.offerItems[0].routeOrigin.port = result.data.record.routeOrigin.port;
				}
				if(result.data.record.routeDestination.isPort) {
					tempOffer.offerItems[0].routeDestination.port = result.data.record.routeDestination.port;
				}
				tempOffer.offerItems[0].freightType = result.data.record.freightType;
				for(var i in result.data.record.services) {
					const item = {
						logisticService: result.data.record.services[i],
						price: ''
					};
					tempOffer.offerItems.push(item);
				}
				setOffer(tempOffer);
				setRequest(result.data.record);
			}
			setLoadingRequest(false);
		}).catch(e => {
			setLoadingRequest(false);
		})
	}

	const fetchServices = () => {
		APIKit.get("LogisticServices" ).then(result => {
			if (result.status === 200) {
				setServices(result.data.record);
			}
		}).catch(e => {})
	}

	const handleDateChange = (value, name) => {
		try {
			value = value.toISOString();
		}
		catch (e) {}
		setOffer({...offer, [name]: value});
	}

	

	const submitOffer = () => {
		setIsBusy(true);
		let offerPayload = {...offer};
		offerPayload.price = parseFloat(offerPayload.price);
		APIKit.post("offers", offerPayload ).then(result => {
			if (result.status === 200) { 
				console.log(result.data);
				setIsBusy(false);
				setErrorMessage(false);
				setSuccessMessage("Your offer has been successfully submitted!");
				setCanSubmit(false);
			}
			else {
				setIsBusy(false);
				setErrorMessage('Oops! Soemthing wen\'t wrong. Please try again. ')
			}
		}).catch(e => {
			setIsBusy(false);
			setErrorMessage('Oops! Soemthing wen\'t wrong. Please try again. ')
		})
	}


	const validate = () => {
		if(
			offer.offerValidty != null && 
			offer.loadingDate != null && 
			offer.dischargingDate != null 
		) {
			console.log(true)
		  setCanSubmit(true);
		}
		else {
			console.log(false)
			setCanSubmit(false);
		}
	  }

		

	return (
		<Page title="Submit an Offer" loading={!request} >
			<Grid container spacing={5} >
				<Grid item sm={7}>
					<TransportFields 
						offer={offer} 
						request={request} 
						setOffer={setOffer}
					/>
				</Grid>
				<Grid item sm={5}>
					<RequestInfo request={request} />
				</Grid>
			</Grid>

			<Grid container spacing={5} >
				<Grid item sm={7}>
					<PricingFields 
						offer={offer} 
						request={request} 
						setOffer={setOffer}
						services={services}
					/>	
				</Grid>
				<Grid item sm={5}>
					{request && <RequestServices services={request.services} />}
				</Grid>
			</Grid>

			<Grid container spacing={5} >
				<Grid item sm={7}>
					<div className="form-box">
						<p className="card-body-label">Notes</p>
						<TextField 
							label="Optionally leave a note for the customer" 
							variant="outlined" 
							name="note" 
							onChange={(event) => setOffer({ ...offer, note: event.target.value })} 
							fullWidth 
							multiline 
							rows={3} 
						/>
					</div>    

					<br/>
					<KeyboardDatePicker
						autoOk
						variant="inline"
						size="small"
						inputVariant="outlined"
						label="Valid Until"
						value={offer.offerValidty}
						format="MM/dd/yyyy"
						InputAdornmentProps={{ position: "end" }}
						onChange={(value, name) => handleDateChange(value, "offerValidty")}
						maxDate={request?.expiryDate}
						//error={offer.pickupDate == null && validationStep == 0 ? true : false}
						disablePast
					/>
					<br />

					<Alert severity="info" className="response-message">
						<AlertTitle>Before You Submit!</AlertTitle>
						By submitting this offer you're giving the customer the permission to accept and book your offer at the price you defined. 
						You can modify the offer only before being accepted by the customer.
					</Alert>
					

					<div className="submit-container">
						{ errorMessage &&  <Alert severity="error" className="response-message">{errorMessage}</Alert> }
						{ successMessage &&  <Alert severity="success" className="response-message">{successMessage}</Alert> }
						<Grid container spacing={3}>
							<Grid item sm={6}>
							
							</Grid>
							<Grid item sm={6}>
								<Button variant="contained" size="large" color="primary" onClick={submitOffer} disableElevation endIcon={<ChevronRight />}   className="submit-button" disabled={!canSubmit || isBusy} >
								Submit Offer
								</Button> 
								<br/><br/><br/>
							</Grid>
						</Grid>
						
					</div>   
					<Backdrop open={isBusy} className="backDrop-1" onClick={null}>
						<CircularProgress />
					</Backdrop> 
				</Grid>
				<Grid item sm={5}>
					
				</Grid>
			</Grid>
			
		</Page>
	)
}