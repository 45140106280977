import React from "react";
import { Link } from "react-router-dom";

import MaterialTable from "material-table";
import Box from "@material-ui/core/Box";

import APIKit from "../../../include/APIKit";
import RolesSelect from "./RolesSelect";
import AlertDialog from "./AlertDialog";
import Avatar from "./Avatar";
import ActionsMenu from "../../ActionsMenu";

export default function MembersList({ data, setData, name }) {
  const style = {
    name: {
      padding: "0px 10px",
    },
    icon: {
      fontSize: "20px",
      cursor: "pointer",
    },
  };

  const [role, setRole] = React.useState("");
  const [alertDialogOpen, setAlertDialogOpen] = React.useState(false);

  const handleRoleChange = (e, value) => {
    setRole(value);
  };

  const editRole = (newData, oldData) => {
    // new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     const dataUpdate = [...data];
    //     const index = oldData.data.id;
    //     dataUpdate[index] = newData;
    //     setData([...dataUpdate]);
    //     resolve();
    //   }, 1000);
    // });
    APIKit.put("")
      .then(result => {
        if (result.status === 200) {
          alert("woho removed");
          setAlertDialogOpen(false);
        }
      })
      .catch(e => {
        alert("not removed yet:( ");
        setAlertDialogOpen(false);
      });
  };

  const removeMember = oldData => {
    // new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     const dataDelete = [...data];
    //     const index = oldData.data.id;
    //     dataDelete.splice(index, 1);
    //     setData([...dataDelete]);

    //     resolve();
    //   }, 1000);
    // });
    setAlertDialogOpen(false);
    APIKit.put("")
      .then(result => {
        if (result.status === 200) {
          alert("woho removed");
          setAlertDialogOpen(false);
        }
      })
      .catch(e => {
        alert("not removed yet:( ");
        setAlertDialogOpen(false);
      });
  };
  const memberList = [
    {
      title: "Member name",
      with: "30%",
      field: "name",
      sorting: true,
      editable: "never",
      headerStyle: {
        textAlign: "left",
        paddingLeft: "30px",
      },
      cellStyle: {
        paddingLeft: "30px",
      },
      render: rowData => (
        <Box display="flex" alignItems="center">
          <Avatar
            role={rowData.role}
            src={rowData.src}
            name={rowData.name}
            badge={true}
          />
          <div style={style.name}>{rowData.name}</div>
        </Box>
      ),
    },
    {
      title: "Role",
      field: "role",
      sorting: false,
      render: rowData => <div>{rowData.role}</div>,
      editComponent: props => <RolesSelect />,
    },
    {
      title: "Email",
      field: "email",
      editable: "never",
      sorting: false,
      render: rowData => <div>{rowData.email}</div>,
    },
    {
      title: "",
      field: "",
      width: "25px",
      cellStyle: {
        textAlign: "right",
      },
      sorting: false,
      render: rowData => (
        <Box pt={2} pb={2}>
          <ActionsMenu
            id={rowData.id}
            status={rowData.requestStatus}
            // refresh={this.refreshTable}
            contractId={rowData.contract?.id}
          />
        </Box>
      ),
    },
  ];
  return (
    <div>
      <AlertDialog
        open={alertDialogOpen}
        setOpen={setAlertDialogOpen}
        name={name}
        removeMember={removeMember}
      />

      <MaterialTable
        title=""
        columns={memberList}
        data={data}
        localization={{
          header: {
            actions: "",
          },
        }}
        options={{
          search: true,
          selection: false,
          toolbar: false,
          pageSizeOptions: [],
          draggable: false,
          actionsColumnIndex: -1,
          actionsCellStyle: {
            paddingRight: "30px",
          },
        }}
        style={{ boxShadow: "none" }}
      />
    </div>
  );
}
