import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from "react-router-dom";


// UI Imports
import { 
  Button, 
  TextField, 
  Grid, 
  CircularProgress, 
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  LinearProgress
} from '@material-ui/core';

import { 
	ToggleButton, 
	ToggleButtonGroup,
	Alert,
  AlertTitle,
	Autocomplete,
} from '@material-ui/lab';

import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import {
	Close as CloseIcon,
	Person as PersonIcon,
	Business as OfficeIcon,
  Error as ErrorIcon
} from '@material-ui/icons';

import { useStore } from "../context/StoreContext";

import Carousel from 'react-material-ui-carousel'
import APIKit from "../include/APIKit";


function ConfirmEmail(props) {

  const { userid, token } = useParams();


  const [isBusy, setIsBusy] = useState(true);
  const [success, setSuccess] = useState(false);
  const { setToken, authToken } = useStore();

  document.title = "Confirming email address - Logisoft Marketplace";

  useEffect(() => {
    
    if(userid && token) {
      confirm();
    }
  }, []);

  function confirm() {
    APIKit.get("authentication/confirmEmail?uid="+userid+"&token="+token ).then(result => {
      if (result.status === 200) {
        if(result.data.isSuccess) {
          setIsBusy(false);
          setSuccess(true);
          setInterval(() => {
            setToken(result.data.record.accessToken);
            //setRedirecting(true);
            if(result.data.record.userDetails.accountType == 1) {
              window.location.href = "/create-profile";
            } 
            else {
              window.location.href = "/";
            }
          }, 1000);
        }
      }
    }).catch(e => {});
  }


  function login(Email, Password) {
    const body = {
      Email,
      Password
    }

    APIKit.post("authentication/login", body ).then(result => {
      if (result.status === 200) {
        if(result.data.isSuccess) {
          setToken(result.data.record.accessToken);
          setInterval(() => {
            setRedirecting(true);
          }, 3000);
        }
      }
      setIsBusy(false);
    }).catch(e => {});
  
  }

  const [redirecting, setRedirecting] = useState(false);

  if(redirecting) {
    return <Redirect to="/login" />;
  }

  





  return (
    <div className="auth-container" style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, background: "#f5f6f8", zIndex: 9999}}>
      <div className="auth-body">
        <Link to="/">
          <img src="/img/lgsm-logo.svg" className="auth-logo" />
        </Link>
        <div className="content-holder">
  
            <LinearProgress className="card-loader" />
            {/* <img src="/img/mail2.svg" className="auth-icon" /> */}
            <h2 className="page-h2">{success ? "Congratulations! Your account is verified. Redirecting..." : "Verifying your email address"}</h2>
          
		    </div>
      </div>
    </div>
  );
}

export default ConfirmEmail;
