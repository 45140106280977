import React, { useState, useEffect } from "react";
import { TextField, Box } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function DateTimeFormat() {
  const [id, setId] = useState(0);
  const [example, setExample] = useState("02/17/2009");

  const handleChange = (e, value) => {
    setId(value.id);
  };

  useEffect(() => {
    dateFormat.filter(df => df.id === id && setExample(df.example));
  }, [id]);

  return (
    <Box display="flex" alignItems="center">
      <Autocomplete
        autoComplete
        autoHighlight
        id="combo-box-demo"
        options={dateFormat}
        getOptionLabel={option => option.format}
        style={{ width: 300 }}
        renderInput={params => (
          <TextField {...params} label="Date Format" variant="outlined" />
        )}
        defaultValue={dateFormat.find(v => v.format[0])}
        onChange={handleChange}
      />

      <span
        style={{
          color: "rgb(0 0 0 / 50%)",
          fontSize: 14,
          paddingLeft: "10px",
        }}
      >
        Preview: {example}
      </span>
    </Box>
  );
}

const dateFormat = [
  {
    id: 0,
    format: "MM/DD/YY",
    example: "02/17/2009",
  },
  {
    id: 1,
    format: "DD/MM/YY",
    example: "17/02/2009",
  },
  {
    id: 2,
    format: "YY/MM/DD",
    example: "2009/02/17",
  },
  {
    id: 3,
    format: "Month D, Yr",
    example: "February 17, 2009",
  },
  {
    id: 4,
    format: "D Month, Yr",
    example: "17 February, 2009",
  },
  {
    id: 5,
    format: "Yr, Month D",
    example: "2009, February 17",
  },
  {
    id: 6,
    format: "MonDDYY",
    example: "Feb172009",
  },
  {
    id: 7,
    format: "DDMonYY",
    example: "17Feb2009",
  },
  {
    id: 8,
    format: "YYMonDD",
    example: "2009Feb17",
  },
];
