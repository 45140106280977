import React, { Component } from "react";

import ActionsMenu from "../../components/ActionsMenu.js";
import { withSnackbar } from "notistack";
import { Link, Redirect } from "react-router-dom";

import moment from "moment";

import {
  Paper,
  Tabs,
  Tab,
  Chip,
  Avatar,
  Grid,
  Container,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanelActions,
  Button,
  Divider,
  InputLabel,
  Menu,
  MenuItem,
  FormControl,
  Select,
  TextField,
  IconButton,
  InputBase,
  Tooltip,
  Card,
  CardHeader,
} from "@material-ui/core";

// IMPORT MATERIAL ICONS
import {
  ChevronLeft,
  LocalShipping,
  ChevronRight,
  Flight,
  DirectionsBoat,
  DirectionsRailway,
  DirectionsCar,
  Landscape,
  VerticalAlignBottom,
  VerticalAlignTop,
  MoreVertRounded as MoreVertRoundedIcon,
  LocalShipping as LocalShippingIcon,
  ExpandMore as ExpandMoreIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Directions as DirectionsIcon,
  Search as SearchIcon,
  Menu as MenuIcon,
  Edit as EditIcon,
  EditAttributes as EditAttributesIcon,
  Cancel as CancelIcon,
  MoreVert as MoreVertIcon,
  PersonAdd as PersonAddIcon,
  Business,
  Person,
} from "@material-ui/icons";

import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";

import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";

import APIKit from "../../include/APIKit";
import PartyFormDialog from "../../components/PartyFormDialog.js";
import NotificationBar from "../../components/NotificationBar.js";
import Page from "../../containers/Page.js";
import NoResults from "../../components/NoResults.jsx";

let timeout = 0;
let selection = [];

function ActionMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setAnchorEl(null);
    props.edit(true, props.partyId);
  };

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        //anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        //transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleClose}>Delete</MenuItem>
      </Menu>
    </div>
  );
}

class Parties extends Component {
  tableRef = React.createRef();
  partyDialog = React.createRef();

  state = {
    parties: [],
    party: null,
    activeTab: 1,
    pageSize: 10,
    filters: {
      //countryCode: "BE",
      query: "",
      pageSize: 100,
    },
    filterPaneOpen: false,
    countries: null,
    name: "",
    typing: false,
    typingTimeout: 0,
    menuOpen: false,
    anchorEl: null,
    selection: [],
    isPartyDialogOpen: false,
    loading: true
  };

  UNSAFE_componentWillMount = () => {
    document.title = "Parties";
  };
  componentDidMount() {
    this.getCountries();
    this.search();
  }

  handleChange = (event, activeTab) => {
    this.setState({ activeTab }, () => {
      this.tableRef.current.onChangePage(null, 0);
    });
  };

  handleRowClick = (event, id) => {
    this.props.history.push("/contract/" + id);
  };
  handleSelection = data => {
    selection = data;
  };

  handleToggle = (event, value, name) => {
    this.setState({ filters: { ...this.state.filters, [name]: value } }, () => {
      this.tableRef.current.onChangePage(null, 0);
    });
  };

  handleInputChange = event => {
    let name = event.target.name;
    let value = event.target.value;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      this.setState(
        { filters: { ...this.state.filters, [name]: value } },
        () => {
          this.tableRef.current.onChangePage(null, 0);
        }
      );
    }, 1000);
  };

  refreshTable = () => {
    this.tableRef.current.onChangePage(null, 0);
  };

  handleSearchInputChange = event => {
    event.preventDefault();
    this.setState(
      {
        filters: {
          ...this.state.filters,
          query: event.target[0].value,
        },
      },
      () => {
        this.search();
      }
    );
  };

  handleFilters = (value, name) => {
    const filters = this.state.filters;
    filters[name] = value != null ? value : null;
    this.setState({ filters }, () => {
      this.search();
    });
  };
  handleDateChange = value => {
    console.log(1);
  };

  renderLabel = date => {
    console.log(date);
    if (date) {
      return date;
    } else {
      return null;
    }
  };

  search = () => {
    APIKit.post("parties/search", this.state.filters)
      .then(result => {
        if (result.status === 200) {
          this.setState({ 
            parties: result.data.record.items, 
            loading: false 
          });
          // console.log("result 200");
        }
        
      })
      .catch(e => {
        console.log("result 500");
      });
  };

  getCountries = () => {
    APIKit.get("countries")
      .then(result => {
        if (result.status === 200) {
          this.setState({ countries: result.data.record });
        }
      })
      .catch(e => {});
  };

  openMenu = (event, id) => {
    console.log(event.currentTarget);
    this.setState({ anchorEl: event.currentTarget, menuOpen: id });
  };

  closeMenu = event => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  bulkUnlist = (event, data) => {
    let ids = [];
    for (var i in data) {
      if (data[i].requestStatus == 2 || data[i].requestStatus == 3) {
        this.showAlert(
          "warning",
          "You cannot modify assigned or completed requests!"
        );
        return null;
      }
      if (data[i].requestStatus == 4) {
        this.showAlert(
          "warning",
          "You cannot unlist already cancelled requests!"
        );
        return null;
      }
      ids.push(data[i].id);
    }
    APIKit.post("requests/UpdateListing", ids)
      .then(result => {
        if (result.status === 200) {
          this.showAlert("success", "Successfully Updated!");
          this.refreshTable();
        }
      })
      .catch(e => {});
  };

  bulkDelete = (event, data) => {
    let ids = [];
    for (var i in data) {
      if (data[i].requestStatus == 2 || data[i].requestStatus == 3) {
        this.showAlert(
          "warning",
          "You cannot delete assigned or completed requests!"
        );
        return null;
      }
      ids.push(data[i].id);
    }
    APIKit.post("requests/delete", ids)
      .then(result => {
        if (result.status === 200) {
          this.refreshTable();
        }
      })
      .catch(e => {});
  };

  showAlert = (variant, message) => {
    this.props.enqueueSnackbar(message, {
      variant: variant,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  dialogToggler = (open, partyId, action) => {
    if (partyId) {
      const party = this.state.parties.filter(function (o) {
        return o.id == partyId;
      });
      this.setState({ isPartyDialogOpen: open, party }, () => this.search());
    } else {
      this.setState({ isPartyDialogOpen: open, party: null });
    }
  };

  render() {
    return (
      <Page
        title="Parties"
        navigation={true}
        forceAction={true}
        searchPlaceholder="Search parties"
        searchAction={this.handleSearchInputChange}
        actions={
          <Button
            variant="text"
            startIcon={<PersonAddIcon />}
            size="medium"
            disableElevation
            className="header-actions-btn blue"
            color="primary"
            onClick={open => this.dialogToggler(true)}
          >
            Add a Party
          </Button>
        }
        loading={this.state.loading}
      >
        {this.state.isPartyDialogOpen && (
          <PartyFormDialog
            open={this.state.isPartyDialogOpen}
            dialogToggler={(open, action) => this.dialogToggler(open, action)}
            party={this.state.party ? this.state.party[0] : null}
          />
        )}

        <div className="pageBody">
          <div>
            <Grid container spacing={3}>
              {this.state.parties.length > 0 ?
                this.state.parties.map(party => (
                  <Grid item xs={12} sm={4}>
                    <Card className={"contact-card"}>
                      <CardHeader
                        avatar={
                          <Avatar>
                            {party.isOrganization ? <Business /> : <Person />}
                          </Avatar>
                        }
                        action={
                          <ActionMenu
                            partyId={party.id}
                            edit={(open, partyId) =>
                              this.dialogToggler(open, partyId)
                            }
                          />
                        }
                        title={party.name}
                        subheader={party.addresses[0].country.name}
                      />
                    </Card>
                  </Grid>
                )) :(
                  <NoResults
                    title="You don't have parties yet!"
                    subtitle="It seems that you do not have any parties added or matched with the current filters."
                    buttonLabel={"Create a Party"}
                    buttonAction={() => this.dialogToggler(true)}
                  />
                )
              }
              
            </Grid>
          </div>
        </div>
      </Page>
    );
  }
}

export default withSnackbar(Parties);
