import React from "react";
import { Link, useHistory } from "react-router-dom";

import { Button } from "@material-ui/core";
import { BiPaperPlane } from "react-icons/bi";

const style = {
  text: {
    padding: "20px 0",
    color: "#546e7a",
    fontSize: "14px",
  },
  icon: {
    color: "#1d4ef5",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default function VerificationEmailSent() {
  document.title = "Verification sent";
  const history = useHistory();

  const redirectToLogin = e => {
    e.preventDefault();
    history.push("/login");
  };
  return (
    <div className="auth-container">
      <div className="auth-body">
        <Link to="/">
          <img src="/img/lgsm-logo.svg" className="auth-logo" />
        </Link>
        <div className="content-holder">
          <div>
            <h2 className="page-h2" style={style.title}>
              Check your email &nbsp;
              <BiPaperPlane style={style.icon} />
            </h2>
            <div style={style.text}>
              We have sent you a Verification email to your email address.
              Please follow the steps provided in the email to update your
              password.
              <br />
              If you don't want to change your password in the meantime, select
              <b> Log In </b>.
            </div>
            <br />
            <form onSubmit={redirectToLogin}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disableElevation
                fullWidth
              >
                Login
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
