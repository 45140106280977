import React, { useEffect } from "react";
import NotificationsList from "../components/notifications/NotificationsList";
import Page from "../containers/Page";
import FilterNotifications from "../components/notifications/FilterNotifications";
import APIKit from "../include/APIKit";

export default function Notifications() {
  const [loading, setLoading] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);
  const [filteredNotifications, setFilteredNotifications] = React.useState([]);
  // const [filterNotifications, setFilterNotifications] = React.useState(1);
  const [activeTab, setActiveTab] = React.useState(1);

  const [count, setCount] = React.useState({
    unread: 0,
    read: 0,
    all: 0,
    archived: 0,
  });

  const list = [
    {
      id: 1,
      title: "All",
      selected: true,
      value: count.all,
    },
    {
      id: 2,
      title: "Read",
      selected: false,
      value: count.read,
    },
    {
      id: 3,
      title: "Unread",
      selected: false,
      value: count.unread,
    },
    {
      id: 4,
      title: "Archived",
      selected: false,
      value: count.archived,
    },
  ];

  const getNotifications = () => {
    APIKit.get("/notifications")
      .then(response => {
        if (response.status === 200) {
          console.log("ok");
          setNotifications(response.data.record.items);
          setFilteredNotifications(response.data.record.items);
        }
      })
      .catch(e => {});
  };

  const filterNotifications = activeTab => {
    switch (activeTab) {
      case 1: {
        //all
        setFilteredNotifications(notifications);
        // setCount({
        //   ...count,
        //   all: notifications.length,
        // });
        break;
      }
      case 2: {
        //seen
        setFilteredNotifications(
          notifications.filter(notification => notification.isSeen)
        );
        // setCount({
        //   ...count,
        //   read: filteredNotifications.length,
        // });
        break;
      }

      case 3: {
        //unseen
        setFilteredNotifications(
          notifications.filter(notification => notification.isSeen === false)
        );
        // setCount({
        //   ...count,
        //   unread: filteredNotifications.length,
        // });
        break;
      }

      case 4: {
        //archived
        setFilteredNotifications(
          notifications.filter(notification => notification.severity === 2)
        );
        // setCount({
        //   ...count,
        //   archived: filteredNotifications.length,
        // });
        break;
      }

      default:
        setFilteredNotifications(notifications);
        break;
    }
  };
  useEffect(() => {
    if (navigator.onLine) {
      getNotifications();
    } else {
      alert("No Internet Connection");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    filterNotifications(activeTab);
    console.log(filteredNotifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <Page
      containerMaxWidth="none"
      containerClass="no-paddings"
      title="Notifications"
      //   actions={<Actions />}
    >
      <FilterNotifications
        loading={loading}
        setLoading={setLoading}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        list={list}
      />

      <NotificationsList />
      {/* <NotificationsList notifications={filteredNotifications} /> */}
    </Page>
  );
}
