import React, { useEffect, useState } from "react";
import APIKit from "../../include/APIKit";
import { Doughnut, Pie } from "react-chartjs-2";
import Card from "../Card";

export default function ShipmentFIgures() {
  const [figuresValue, setFiguresValue] = useState({
    roro: "3313",
    lcl: "2313",
    fcl: "2313",
    bulk: "2513",
  });
  const handleFiguresValue = () => {
    APIKit.get("figures")
      .then(result => {
        setFiguresValue(result.roro, result.lcl, result.fcl, result.bulk);
      })
      .catch(e => {});
  };

  useEffect(() => {
    handleFiguresValue();
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        // fullSize: true,
        // maxWidth: "100%",
        position: "right",
        // align: "start",
        labels: {
          boxWidth: 30,
          // boxHeight: 15,
          // padding: 15,

          font: {
            size: 14,
          },
          generateLabels: function (chart) {
            let data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.labels.map(function (label, i) {
                let meta = chart.getDatasetMeta(0);
                let ds = data.datasets[0];
                let fill = ds.backgroundColor[i];
                let value = ds.data[i];
                return {
                  text: label + " : " + value,
                  fillStyle: fill,
                  borderWidth: 0,
                  hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                  index: i,
                };
              });
            }
            return [];
          },
        },
      },
    },
    maintainAspectRatio: false,
  };
  const data = {
    labels: ["RORO", "Bulk", "LCL", "FCL"],
    datasets: [
      {
        label: "Shipment Figures",
        fill: true,
        data: [
          figuresValue.roro,
          figuresValue.bulk,
          figuresValue.lcl,
          figuresValue.fcl,
        ],
        backgroundColor: ["#249CFF", "#1578CF", "#0A579E", "#003870"],
        borderWidth: 0,
      },
    ],
  };
  return (
    <Card
      letiant="white"
      bodyPadding={true}
      headerTitle="Shipment figures"
      headerSubtitle=""
    >
      <Pie data={data} options={options} width={270} height={150} />
    </Card>
  );
}
