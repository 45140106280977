import React from "react";
import AvatarComponent from "../settings/membersPermissions/Avatar";
import { Box, Button, Collapse, IconButton, Tooltip } from "@material-ui/core";
import moment from "moment";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router";
import { FiCheckCircle } from "react-icons/fi";
import {replaceQuotes} from "../../include/helpers";

export default function Notification({notification, updateNotification, close}) {
  const history = useHistory();
  const [displayActions, setDisplayActions] = React.useState(false);

  const style = {
    text: {
      padding: "0 10px",
    },
    title: {
      paddingBottom: "2px",
    },
    description: {
      color: "rgb(0 0 0/50%)",
    },
    box: {
      padding: "15px 30px",
      background: notification.isSeen ? "#fbfbfc" : "#fff",
      borderBottom: "1px solid #eee",
      position: 'relative'
    },
    time: {
      color: "rgb(0 0 0/50%)",
      fontSize: "12px",
      display: (!displayActions) ? "block" : "none",
      position: "absolute",
      top: 23,
      //right: 0
    },
    action: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },

    actionBox: {
      display: (!notification.isSeen && displayActions) ? "flex" : "none",
      transition: "all 5s ease-in-out",
      animation: displayActions && `show-notification-actions 1s linear`,
    },
    // iconContainer: {
    //   padding: "7px",
    //   minWidth: "40px",
    // },
    icon: {
      fontSize: "20px",
      color: "rgb(0 0 0/50%)",
    },
  };

  const handleDisplay = () => {
    if(!notification.isSeen)
      setDisplayActions(true);
  };

  const handleDisplayOff = () => {
    if(!notification.isSeen)
      setDisplayActions(false);
  };
  

  return (
    <>
      <Box
        id={notification.id}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={style.box}
        onMouseOver={handleDisplay}
        onMouseLeave={handleDisplayOff}
        onClick={() => {
          updateNotification([notification.id]);
          history.push(notification.target);
          close();
        }}
      >
        <Alert
          className={`app-notification compact ${notification.customClass} ${notification.isSeen && "noti-read"}`}
          icon={notification.icon}
        >
          <div className="notitificationTitle" style={{color: notification.isSeen ? "#80878f" : "#262626"}}>{notification.title}</div>
          <div className="noti-description" dangerouslySetInnerHTML={{ __html: replaceQuotes(notification.description) || "" }} />
        </Alert>

        <div style={style.action}>
          <div style={style.time}>{moment(notification.notificationDate).fromNow()}</div>
          
        </div>
        {!notification.isSeen &&
            <Box style={style.actionBox}>
              <Tooltip title={"Mark as read"}>
                <IconButton
                  edge="end"
                  aria-label="mark as read"
                  aria-haspopup="true"
                  onClick={(e) => { e.stopPropagation(); updateNotification([notification.id]); }}
                  style={style.iconContainer}
                >
                  <FiCheckCircle style={style.icon} />
                </IconButton>
              </Tooltip>
            </Box>
          }
      </Box>
    </>
  );
}
