import React from "react";
import { Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

export default function ShipmentDetails({ offer }) {
  return (
    <Paper className="white-card submit-offer-card">
      <div
        className="card-header"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>Request {offer?.requestReference?.number}</div>
        <div>
          <Link
            to={`/requests/${offer?.requestReference?.id}`}
            style={{
              textTransform: "none",
              color: "#1d4ef5",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            View Request &nbsp;
            <FiArrowRight />
          </Link>
        </div>
      </div>
    </Paper>
  );
}
