import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import MessagesTab from "../components/Contract/MessagesTab";
import Conversations from "../components/message/Conversations";
import Page from "../containers/Page";
import APIKit from "../include/APIKit";

export default function Messages() {
	const {id} = useParams();
	const history = useHistory();
	const [conversations, setConversations] = useState([]);
	const [conversation, setConversation] = useState(null);

	useEffect(() => {
		getConversations();
	}, [])

	const getConversations = () => {
		APIKit.get("messages/conversations" ).then(result => {
			if (result.status === 200) {
				let convs = result.data.record.items;
        		setConversations(convs);
				if(convs) {
					setConversation(convs.filter(c => c.offerId == id)[0]);
				}
				
			}
		}).catch(e => {})
	}

	const onConversationChange = (conv) => {
		setConversation(conv);
		history.push("/messages/"+conv.offerId)
		console.log(conv)
	}

	const updateParent = (message, offerId) => {
		setConversations( conversations.map(c =>
			c.offerId === offerId
			  ? { ...c, ...message}
			  : c
		))
	}

	const markAsRead = (offerId) => {
		setConversations( conversations.map(c =>
			c.offerId === offerId
			  ? { ...c, unreadMessagesNumber: 0}
			  : c
		))
	}

	const searchConversations = (event) => {
		event.preventDefault();
		alert("hi")
	}

	return (
		<Page 
			containerMaxWidth="none" 
			containerClass="no-paddings" 
			mainClass="mainClass"
			title="Messages" 
			searchPlaceholder="Search messages"
			searchAction={searchConversations}
		>
			<div className="messages-container">
				<Conversations conversations={conversations} onChange={onConversationChange} active={conversation?.offerId} />
				{conversation && <MessagesTab 
                  offerId={conversation.offerId} 
				  supplier={{fullName: conversation.destinationName, logoUrl: conversation.destinationProfileUrl}}
				  variant="conversation"
				  reference={conversation.reference}
				  updateParent={updateParent}
				  markAsRead={markAsRead}
                />}
			</div>
		</Page>
	)
}