import React from "react";
import { Chip, Grid, Paper } from "@material-ui/core";

export default function(props) {
	const { services } = props;
	return (
		<Paper className={"white-card stripped"} >
            <p className="card-header">Requested Services</p>
            <div className="card-body">

              <Grid container spacing={3} alignItems="center" >
                <Grid item>
                  <div className="card-body-label-secondary">
                    {services && services.map((service) => (
                      <Chip size="small" className="chip-blue" label={service.name} key={service.id} />
                    ))}
                  </div>
                </Grid>
              </Grid>
            </div>
        </Paper>
	)
}