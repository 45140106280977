import React from 'react';
import { Avatar } from '@material-ui/core';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';

const FileIcon = props => {
  return (
    <img
      src={"/img/filetypes/" + props.type + ".svg"}
      className="message-filetype-icon"
    />
  );
};

export default function Message({message, name, avatar, loading, downloadAttachment}) {
	return !loading ? (
		<div className={message.isSent ? "message-item sent" : "message-item"}>
        <Avatar
          className="message-party-avatar"
          src={avatar}
          style={message.isSent === false ? {color: '#1c4ef5', backgroundColor: '#d2dbfe', fontSize: 14} : {fontSize: 14}}
        >
          {name.match(/\b(\w)/g).join("").toUpperCase()}
        </Avatar>
        <div className="message-body">
          <div className="message-info">
            <span className="message-party-name">{name}</span>
            <span className="message-time">{moment(message.messageDate).fromNow()}</span>
          </div>
          <div className="message-content">
            {message.content}
            {message.isAttachment && <div style={{marginTop: 25}}>
              {message.attacments && message.attacments.map((att, key) => (
                <div className="file link" key={key} onClick={(id, name) => downloadAttachment(att.id, att.name)}>
                  <FileIcon type={att.extension || att.name.substring(att.name.lastIndexOf(".") + 1)} /> {att.name}
                </div>
              ))}
            </div>}
          </div>
        </div>
    </div>
	) : (
    <div className="message-item">
        <Skeleton variant="rect" width={35} height={35} style={{borderRadius: 35, marginRight: 15}} />
        <div className="message-body">
          <div className="message-info" style={{marginBottom: 5}}>
            <Skeleton variant="text" width="100px" />
          </div>
         
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="50%" />
          
        </div>
    </div>
  )
}