import React, {useState, useEffect} from "react";
import OfferConfirmationDialog from "../../components/OfferConfirmationDialog.js";
import OfferFilters from "../../components/Shipper-OffersFilter.js";
import InfiniteScroll from "react-infinite-scroll-component";
import _get from "lodash/get";

import moment from "moment";

import { Button, Chip, Grid, Paper, Step, Stepper } from "@material-ui/core";

import { Alert, AlertTitle, Skeleton } from "@material-ui/lab";

// IMPORT MATERIAL ICONS
import {
  Settings as SettingsIcon,
  GroupAdd as GroupAddIcon,
  VideoLabel as VideoLabelIcon,
  Home as HomeIcon,
  LocalShipping as LocalShippingIcon,
  CallMade as CallMadeIcon,
  CallReceived as CallReceivedIcon,
  DirectionsCar,
  Landscape,
  DirectionsBoat,
  DirectionsRailway,
} from "@material-ui/icons";

import APIKit from "../../include/APIKit";
import Page from "../../containers/Page.js";
import PartyBox from "../../components/Contract/PartyBox.jsx";
import PartiesDialog from "../../components/PartiesDialog.jsx";
import PartyFormDialog from "../../components/PartyFormDialog.js";
import PartyDialog from "../../components/parties/PartyDialog.jsx";
import MapComponent from "../../components/Map";
import OverviewStepper from "../../components/Contract/OverviewStepper.js";
import ShipmentsTab from "../../components/Contract/ShipmentsTab.js";
import MessagesTab from "../../components/Contract/MessagesTab.js";
import SupplierDetails from "../../components/Contract/SupplierDetails.js";
import PaymentDetails from "../../components/Contract/PaymentDetails.js";
import DocumentsTab from "../../components/Contract/DocumentsTab.js";
import Tabs from "../../components/Contract/Tabs.js";
import PageHeader from "../../components/layout/PageHeader.jsx";
import RatingBox from "../../components/Contract/RatingBox.jsx";
import CancelContract from "../../components/Contract/CancelContract.jsx";
import ContractStatus from "../../components/Contract/ContractStatus.jsx";
import { useHistory, useParams } from "react-router";
import TrackingUpdateDialog from "../../components/Contract/TrackingUpdateDialog.jsx";
import { useStore } from "../../context/StoreContext.js";
import CarrierBox from "../../components/Contract/CarrierBox.jsx";
import CarriersDialog from "../../components/CarriersDialog.jsx";
import ShipperDetails from "../../components/Contract/ShipperDetails.js";
import ContractSummary from "../../components/Contract/ContractSummary.jsx";
import RatingEmojis from "../../components/Contract/RatingEmojis.jsx";
import RatingDialog from "../../components/Contract/RatingDialog.jsx";
import RatingPreview from "../../components/Contract/RatingPreview.jsx";
import Notices from "../../components/Contract/Notices.jsx";
import usePrevious from "../../include/usePrevious.js";


export default function Contract() {
  const history = useHistory();
  const params = useParams();
  const { accountType } = useStore();

  const [activeTab, setActiveTab] = useState("overview");
  const [contract, setContract] = useState(null);
  const [loading, setLoading] = useState(true);
  const [partiesDialogOpen, setPartiesDialogOpen] = useState(false);
  const [loadingParty, setloadingParty] = useState({
    loadingConsignee: false
  });
  const [party, setParty] = useState(null);
  const [isPartyDialogOpen, setIsPartyDialogOpen] = useState(false);
  const [activeMilestone, setActiveMilestone] = useState(false);
  const [carriersDialogOpen, setCarriersDialogOpen] = useState(false);
  const [loadingMsUpdate, setloadingMsUpdate] = useState(false);
  const [msError, setMsError] = useState(false);
  const [ratingMode, setRatingMode] = useState(true);


  const prevParams = usePrevious({params});

  // useEffect(() => {
  //     if(prevParams.id !== params.id) {

  //       // process here
  //     }
  //     // if(prevParams.sendAmount !== sendAmount) {

  //     //   // process here
  //     // }
  // }, [params])


  useEffect(() => {
    document.title = "Contract";
    setActiveTab(params.tab || "overview");
    console.log(params);
    if(prevParams?.params?.id !== params.id) {
      setContract(null)
      setLoading(true)
    }
    getContract();
  }, [params]);

  const getContract = () => {
    APIKit.get(`contracts/${params.id}?isSeen=true`)
      .then(result => {
        if(result.status === 200) {
          if(result.data.isSuccess) {
            setContract(result.data.record)
          }
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
      });
  };


  const handleTabChange = (event, activeTab) => {
    history.push(
      "/contract/" + params.id + "/" + activeTab
    );
    setActiveTab(activeTab)
  };

  const handlePartyChange = (type, party) => {
    const object = {
      contractId: params.id,
      entityId: party?.sameAsOwner ? null : party?.id,
      sameAsOwner: party?.sameAsOwner ? true : false
    };
    let isMissing;
    let loadingParty;
    switch (type) {
      case "exporter":
        isMissing = "isExporterMissing";
        loadingParty = "loadingExporter";
        break;
      case "consignee":
        isMissing = "isConsigneeMissing";
        loadingParty = "loadingConsignee";
        break;
      case "notify":
        isMissing = "isNotifyMissing";
        loadingParty = "loadingNotify";
        break;
      case "carrier":
        isMissing = "isCarrierMissing";
        loadingParty = "loadingCarrier";
        break;
    }

    setContract({
      ...contract,
      [type]: party,
      [isMissing]: false,
    });
    setloadingParty({...loadingParty, [loadingParty]: true});
    setPartiesDialogOpen(false);
    setCarriersDialogOpen(false);
    // if(party.initialAddress)
    //   delete Object.assign(party, {["address"]: party["initialAddress"] })["initialAddress"];
    APIKit.put(`contracts/${type}`, object)
      .then(result => {
        if (result.status === 200) {
          if (result.data.isSuccess) {
            setContract({
              ...contract,
              [type]: party,
              [isMissing]: false,
            });
          }
        }
        setLoading(false);
        setloadingParty({...loadingParty, [loadingParty]: false});
      })
      .catch(e => {
        setLoading(false);
        setloadingParty({...loadingParty, [loadingParty]: false});
      });
    console.log(party);
  };

  const dialogToggler = (open, party, type) => {
    setIsPartyDialogOpen(open);
    setloadingParty(party || null)
    console.log(party);
  };

  const partyDialogCallback = (party, type) => {
    setPartiesDialogOpen(false);
    setIsPartyDialogOpen(false);
    setContract({...contract, [type]: party  })
    handlePartyChange(type, party);
    console.log(party, type);
  };

  const updateRating = (review) => {
    let reviews = {...contract.reviews};
    let key = accountType == 1 ? "supplier" : "shipper";
    reviews[key] = review;
    setContract({...contract, reviews});
    setRatingMode(false);
  }

  const confirmMilestone = (ms) => {
    if(accountType == 1) {
      setActiveMilestone(ms);
    }
    else {
      //action for client
      setActiveMilestone(ms);
    }
  }

  const updateMilestone = (ms) => {

    const msIndex = contract.trackingDetails.milestones.findIndex((x, index) => x.id === ms.id);
    const milestones = contract.trackingDetails.milestones;
    milestones[msIndex].confirmationStatus = ms.isConfirmed ? (accountType == 1 ? 1 : 5) : 6;
    milestones[msIndex].completionDate = ms.completionDate;

    if(ms.nextMilestoneEstimatedDate && milestones[msIndex+1]) {
      milestones[msIndex+1].estimatedDate = ms.nextMilestoneEstimatedDate;
    }

    
    let status = contract.status;

    setMsError(false)
    setloadingMsUpdate(true);
    const body = {
      ...ms,
      contractId: params.id,      
    }
    APIKit.put("tracking/confirm", body).then(result => {
      if (result.status === 200) {
        if (result.data.isSuccess) {
          if(msIndex == milestones.length-1) {
            status = accountType == 1 ? 4 : 5;
          }
          setContract({...contract, 
            status, 
            trackingDetails: {
              ...contract.trackingDetails, 
              activeIndex: msIndex+1,
              milestones
            }
          });
          setActiveMilestone(false);
        }
      } 
      setloadingMsUpdate(false);
    })
    .catch(e => {
      setloadingMsUpdate(false);
      if(e.response.status === 500) {
        setMsError("Something went wrong!")
      }
      else if(e.response.status === 400) {
        setMsError(e.response?.data?.Errors ? e.response?.data?.Errors[0] : "Couldn't update!")
      }
      
    });
  }

  const trackingLabel = (status) => {
    if(status == 4) {
      return "Delivered";
    }
    else if(status == 5) {
      return "Delivered";
    }
    else {
      return "In Progress";
    }
  }

  const typeIcons = [
    "",
    <DirectionsCar className="shipment-icon-big" />,
    <svg
      className="MuiSvgIcon-root shipment-icon-big customIcon"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M21.5,3.5h-19c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h19c1.1,0,2-0.9,2-2v-13C23.5,4.4,22.6,3.5,21.5,3.5z M21.5,18.5h-19v-13h19V18.5zM3.5, 12.5 h17 v5 h-17z"></path>
    </svg>,
    <svg
      className="MuiSvgIcon-root shipment-icon-big customIcon"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M21.5,3.5h-19c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h19c1.1,0,2-0.9,2-2v-13C23.5,4.4,22.6,3.5,21.5,3.5z M21.5,18.5h-19v-13h19V18.5zM3.5, 6.5 h17 v11 h-17z"></path>
    </svg>,
    <Landscape className="shipment-icon-big" />,
  ];

  return (
    <Page 
      customTitle={true}
      searchEnabled={true}
      title={contract && (
        <div className="icon-grid" style={{marginRight: 30}}>
          <div className="iconComplex">
            {typeIcons[contract.shipmentType]}
            {contract.freightType == 1 && (
              <DirectionsBoat className="shipment-icon-small" />
            )}
            {contract.freightType == 2 && (
              <DirectionsRailway className="shipment-icon-small land" />
            )}
          </div>
          <div>
            <div className="item-title item-ref">
              {contract.number || "12009"}
            </div>
            <div className="item-date">
              {moment(contract.startDate).format('L LT')} {contract.endDate && " - " +moment(contract.endDate).format('L LT')}
            </div>
          </div>
          {/* <Chip
            size="small"
            label={ShipmentStatus[request.status]}
            className={"shipment-status " + ShipmentStatus[request.status]}
            style={{ marginLeft: 25 }}
          /> */}
        </div>
      )}
      
      subHeader={
        <Tabs
          activeTab={activeTab}
          onChange={handleTabChange}
          unreadMessagesCount={contract?.unreadMessagesCount}
          isShipmentMissingDetails={((contract?.isShipmentMissingDetails && contract?.shipmentType != 1 && accountType == 1) || (contract?.isShipmentMissingDetails && contract?.shipmentType == 1 && accountType == 2))}
        />
      }
      canGoBack={true}
      backAction={() => history.push('/contracts')}
      mainClass={'request-page-container'}
    >
      {contract && <ContractStatus 
        status={contract?.status} 
        accountType={accountType} 
        deliveryDate={contract?.deliveryDate} 
        onConfirm={() => setActiveMilestone(contract?.trackingDetails?.milestones[contract?.trackingDetails?.milestones.length-1])}
        openRating={() => setRatingMode(true)}
        rated={_get(contract.reviews, accountType == 1 ? "supplier" : "shipper")}
      />}

      {(ratingMode && contract?.status == 5) && (
        // <RatingBox isRated={contract?.isRated} rateValue={contract?.rate} />
        <RatingDialog
          open={contract.status == 5 && !_get(contract.reviews, accountType == 1 ? "supplier" : "shipper")} 
          setOpen={() => setRatingMode(false)}
          contractId={contract.id}
          updateRating={updateRating}
        />
        
      )}

      <TrackingUpdateDialog
        milestone={activeMilestone} 
        onClose={() => setActiveMilestone(false)}
        onUpdate={updateMilestone}
        carrierId={contract?.carrier?.id}
        loading={loadingMsUpdate}
        error={msError}
        accountType={accountType}
      />
      {contract && 
        <Notices
          accountType={accountType}
          shipmentType={contract?.shipmentType}
          isCarrierMissing={contract?.isCarrierMissing}
          isConsigneeMissing={contract?.isConsigneeMissing}
          isExporterMissing={contract?.isExporterMissing}
          isNotifyMissing={contract?.isNotifyMissing}
          isShipmentMissingDetails={contract?.isShipmentMissingDetails}
          setPartiesDialogOpen={setPartiesDialogOpen}
          setActiveTab={setActiveTab}
        />
      }

      {/* <ConfirmDialog 
        open={confirmation} 
        onClose={() => setConfirmation(false)} 
        onConfirm={onConfirm}
        title="Mark contract as complete"
        content="By marking this contract as complete you're confirming that the shipment has been delivered to the client."
      /> */}

      <Grid container spacing={4}>
        <Grid item sm={9}>
       
          
          <div className="">
            {activeTab === "overview" && (
              <div className="page-content tab">
               {!!contract && <ContractSummary contract={contract} />}

                <Grid container spacing={3}>
                <Grid item sm={5}>
                    
                    
                    <PartyBox
                      type="exporter"
                      title="Exporter"
                      party={contract?.exporter}
                      missing={contract?.isExporterMissing}
                      openDialog={value =>
                        setPartiesDialogOpen("exporter")
                      }
                      edit={() => {setIsPartyDialogOpen("exporter"); setParty(contract?.exporter)}}
                      loading={loading || loadingParty?.loadingExporter}
                      status={contract?.status}
                      setSameAsOwner={handlePartyChange}
                    />

                    <PartyBox
                      type="consignee"
                      title="Consignee"
                      party={contract?.consignee}
                      missing={contract?.isConsigneeMissing}
                      openDialog={value =>
                        setPartiesDialogOpen("consignee")
                      }
                      edit={() => {setIsPartyDialogOpen("consignee"); setParty(contract?.consignee)}}
                      loading={loading || loadingParty?.loadingConsignee}
                      status={contract?.status}
                      setSameAsOwner={handlePartyChange}
                    />

                    <PartyBox
                      type="notify"
                      title="Notify"
                      party={contract?.notify}
                      missing={contract?.isNotifyMissing}
                      openDialog={value =>
                        setPartiesDialogOpen("notify")
                      }
                      edit={() => {setIsPartyDialogOpen("notify"); setParty(contract?.notify)}}
                      loading={loading || loadingParty?.loadingNotify}
                      status={contract?.status}
                      setSameAsOwner={handlePartyChange}
                    />

                    
                    <CarriersDialog
                      open={carriersDialogOpen}
                      close={() =>
                        setCarriersDialogOpen(false)
                      }
                      onChange={(type, party) =>
                        handlePartyChange(type, party)
                      }
                    />

                    {partiesDialogOpen && <PartiesDialog
                      open={partiesDialogOpen}
                      close={() =>
                        setPartiesDialogOpen(false)
                      }
                      onChange={(type, party) =>
                        handlePartyChange(type, party)
                      }
                      addAction={open =>
                        dialogToggler(partiesDialogOpen)
                      }
                    />}

                    {!!isPartyDialogOpen && (
                      <PartyDialog
                        open={isPartyDialogOpen}
                        close={() =>
                          setIsPartyDialogOpen(false)
                        }
                        onSuccess={(party, type) =>
                          partyDialogCallback(party, type)
                        }
                        party={party}
                      />
                    )}
                  </Grid>
                  <Grid item sm={7}>
                    
                    <Paper className={"white-card "}>
                      <div className="card-header">
                        Tracking
                        <div className="actions-panel">
                          <Chip
                            size="small"
                            label={trackingLabel(contract?.status)}
                            className={`shipment-status ${contract?.status == 5 ? "Assigned" : "Completed"}`}
                          />
                        </div>
                      </div>

                      {!!contract ? 
                        <MapComponent
                          markers={[
                            {
                              lat: contract?.routeOrigin?.latitude,
                              lng: contract?.routeOrigin?.longtiude,
                              id: 1,
                            },
                            {
                              lat: contract?.routeDestination?.latitude,
                              lng: contract?.routeDestination?.longtiude,
                              id: 2,
                            },
                          ]}
                          fullscreenControl={true}
                          fullscreenControlPosition="RIGHT_BOTTOM"
                          height="100px"
                        />
                      :
                        <Skeleton variant="rect" width="100%" height="250px" />
                      }

                      {!!contract && <OverviewStepper
                        trackingDetails={contract?.trackingDetails}
                        confirmMilestone={confirmMilestone}
                        contractStatus={contract?.status}
                      />}
                    </Paper>
                  </Grid>
                
                </Grid>
              </div>
            )}

            {activeTab === "shipment" && (
              <ShipmentsTab
                items={contract?.items}
                shipmentType={contract?.shipmentType}
                contract={contract}
              />
            )}

            {activeTab === "messages" && contract && (
              <MessagesTab
                contractId={params.id}
                offerId={contract?.offer?.id}
                supplier={contract?.supplier}
              />
            )}

            {activeTab === "documents" && (
              <DocumentsTab
                contractId={params.id}
                files={contract?.attachments}
              />
            )}
          </div>
        </Grid>

        <Grid item sm={3}>
          <SupplierDetails
            loading={!contract}
            party={accountType == 2 ? contract?.supplier : contract?.shipper}
            type={accountType}
          />

          <CarrierBox
            type="carrier"
            title="Carrier"
            party={contract?.carrier}
            missing={contract?.isCarrierMissing}
            openDialog={value =>
              setCarriersDialogOpen("carrier")
            }
            loading={loading || loadingParty?.loadingCarrier}
            status={contract?.status}
          />

          <PaymentDetails
            loading={!contract}
            // offer={contract?.offer}
            paymentStatus={contract?.payment?.status}
            payment={contract?.payment}
            paidAmount={50}
            // contractId={params.id}
            accountType={accountType}
            contractStatus={contract?.status}
          />

          {(!!contract && _get(contract, 'reviews') ) && <RatingPreview reviews={contract?.reviews} accountType={accountType} />}
        </Grid>
      </Grid>

      
    </Page>
  );
  
}

