import React from "react";
import { Chip, Grid, Paper } from "@material-ui/core";
import { CallMade, CallReceived, CenterFocusStrong, DirectionsBoat, DirectionsRailway, EnhancedEncryption, Place, Widgets } from "@material-ui/icons";
import MapComponent from "../Map";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";

export default function ContractSummary({contract}) {
	const dateOptions = ["", "On", "Before", "After"];

	return (
		<div className="shipment-header-container contract">
			<div className="shipment-header">
			<Grid container spacing={3} alignItems="center">
				<React.Fragment>
				<Grid item sm={3}>
					<p className="text-label">
					{contract.routeOrigin.isPort
						? contract.routeOrigin.description
						: contract.routeOrigin.city +
						", " +
						contract.routeOrigin.country.isoCode}
					</p>
					<p className="text-content" style={{marginBottom: 30}}>
					{dateOptions[contract.pickupDateOption]}{" "}
					{moment(contract.pickupDate).format("ll")}
					</p>
					
					<Chip
					size="small"
					icon={<CallMade />}
					label={contract.routeOrigin.isPort ? "Port" : "Pickup from Door"}
					className={`pickup-required-chip ${contract.routeOrigin.isPort ? "" : "required"}`}
					disabled={contract.routeOrigin.isPort}
					/>
				</Grid>
				<Grid item sm={6}>
					<div className="place-container">
					<div className="place">
						<span className="place-name">
						<img
							src={contract.routeOrigin.country.flag}
							className="country-flag"
							alt=""
						/>
						{/* {contract.routeOrigin.city + ', ' + contract.routeOrigin.country.isoCode} */}
						</span>
					</div>
					<div className="mode">
						<span className="route"></span>
						{contract.freightType === 1 && (
						<DirectionsBoat className="mode-icon" />
						)}
						{contract.freightType === 2 && (
						<DirectionsRailway className="mode-icon" />
						)}
					</div>
					<div className="place">
						<span className="place-name">
						<img
							src={contract.routeDestination.country.flag}
							className="country-flag"
							alt=""
						/>
						{/* {contract.routeDestination.city + ', ' + contract.routeDestination.country.isoCode} */}
						</span>
					</div>
					</div>
				</Grid>
				<Grid item sm={3}>
					<p className="text-label" style={{textAlign: 'right'}}>
					{contract.routeDestination.isPort
						? contract.routeDestination.description
						: contract.routeDestination.city +
						", " +
						contract.routeDestination.country.isoCode}
					</p>
					<p className="text-content" style={{textAlign: 'right', marginBottom: 30}}>
					{dateOptions[contract.deliveryDateOption]}{" "}
					{moment(contract.deliveryDate).format("ll")}
					</p>
					
					<Chip
					size="small"
					icon={<CallReceived />}
					label={contract.routeDestination.isPort ? "Delivery to Port" : "Delivery to Door"}
					className={`pickup-required-chip ${contract.routeDestination.isPort ? "" : "required"}`}
					disabled={contract.routeDestination.isPort}
					style={{float: 'right'}}
					/>
				</Grid>
				</React.Fragment>
			</Grid>
			</div>
			{/* <MapComponent
				markers={[
				{
					lat: contract?.routeOrigin?.latitude,
					lng: contract?.routeOrigin?.longtiude,
					id: 1,
				},
				{
					lat: contract?.routeDestination?.latitude,
					lng: contract?.routeDestination?.longtiude,
					id: 2,
				},
				]}
				fullscreenControl={true}
				fullscreenControlPosition="RIGHT_BOTTOM"
				height="100px"
			/>
			<div className="shipment-header-information">
			<div className="staticon">
				<Place />
				<span className="big">3452</span>
				<span className="small">
				Total
				<br />
				KM
				</span>
			</div>
			<div className="staticon">
				<Widgets />
				<span className="big">{contract.itemsCount}</span>
				<span className="small">
				Total
				<br />
				Items
				</span>
			</div>
			<div className="staticon">
				<CenterFocusStrong />
				<span className="big">{contract.volume}</span>
				<span className="small">
				Total
				<br />
				CBM
				</span>
			</div>
			<div className="staticon">
				<EnhancedEncryption />
				<span className="big">{contract.grossWeight}</span>
				<span className="small">
				Total
				<br />
				KG
				</span>
			</div>
			</div> */}
		</div>
	)
}



	// return (
	// 	<Paper className={"white-card "} >
	// 		{/* <div className="card-header wbtn">
	// 			{loading ? <Skeleton variant="text" width="78px" /> : title}
	// 		</div> */}
	// 		<div className="card-body">
	// 			<ul className="route-list">
	// 				<li className="route-item">
	// 					{/* <span className="route-dot"></span> */}
	// 					<img
	// 						src={contract.routeOrigin.country.flag}
	// 						className="country-flag"
	// 						alt=""
	// 					/>
	// 					<div className="route-info">
	// 						<div className="route-title">
	// 							{contract.routeOrigin.isPort
	// 							? contract.routeOrigin.description
	// 							: contract.routeOrigin.city +
	// 							", " +
	// 							contract.routeOrigin.country.isoCode}
	// 						</div>
	// 						<span className="route-subtitle">
	// 							{dateOptions[contract.pickupDateOption]}{" "}
	// 							{moment(contract.pickupDate).format("ll")}
	// 						</span>
	// 					</div>
	// 				</li>
	// 				<div className="mode">
	// 					<span className="route"></span>
	// 					{contract.freightType === 1 && (
	// 					<DirectionsBoat className="mode-icon" />
	// 					)}
	// 					{contract.freightType === 2 && (
	// 					<DirectionsRailway className="mode-icon" />
	// 					)}
	// 				</div>
	// 				<li className="route-item">
	// 					{/* <span className="route-dot"></span> */}
	// 					<img
	// 						src={contract.routeDestination.country.flag}
	// 						className="country-flag"
	// 						alt=""
	// 					/>
	// 					<div className="route-info">
	// 						<div className="route-title">
	// 							{contract.routeDestination.isPort
	// 							? contract.routeDestination.description
	// 							: contract.routeDestination.city +
	// 							", " +
	// 							contract.routeDestination.country.isoCode}
	// 						</div>
	// 						<span className="route-subtitle">
	// 							{dateOptions[contract.deliveryDateOption]}{" "}
	// 							{moment(contract.deliveryDate).format("ll")}
	// 						</span>
	// 					</div>
	// 				</li>
	// 			</ul>
	// 		</div>
	// 	</Paper>
		
	// )