import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

const StyledToggleButtonGroup = withStyles(theme => ({
  grouped: {
    margin: 0,
    border: "none",
  },
}))(ToggleButtonGroup);

export default function FilterNotifications(props) {
  const { loading, setLoading, activeTab, setActiveTab, list } = props;

  const useStyles = makeStyles(theme => ({
    root: {
      padding: "10px 27px",
      justfiyContent: "space-between",
      borderRadius: 0,
      "&.MuiButtonBase-root:not(.MuiIconButton-root)": {
        borderRadius: "0px",
      },
    },

    label: {
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },

    vertical: {
      width: "100%",
    },
  }));

  const style = {
    title: {
      fontSize: "1.5em",
      color: "rgb(0 0 0/50%)",
      fontWeight: "500",
      padding: "27px",
    },
    buttonTitle: {
      fontSize: "16px",
    },
  };

  // const [activeTab, setActiveTab] = React.useState(1);

  const handleChange = (event, newValue) => {
    setLoading(true);
    if (newValue !== null) {
      setActiveTab(newValue);
    }
  };
  const classes = useStyles();

  return (
    <div className="filter-notification">
      <div style={style.title}>Filter notifications</div>
      <div>
        <StyledToggleButtonGroup
          value={activeTab}
          exclusive
          onChange={handleChange}
          aria-label="text alignment"
          orientation="vertical"
          classes={{ vertical: classes.vertical }}
        >
          {list.map(item => {
            return (
              <ToggleButton
                value={item.id}
                disableRipple
                fullWidth={true}
                classes={{ label: classes.label, root: classes.root }}
              >
                <div style={style.buttonTitle}>{item.title}</div>
                <div>{item.value}</div>
              </ToggleButton>
            );
          })}
        </StyledToggleButtonGroup>
      </div>
    </div>
  );
}
