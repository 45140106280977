import React from "react";
import ContainerIcon from "../../include/custom/ContainerIcon";
import { countries, continents } from "../../include/countries";
import { RadioGroup, Typography, FormControlLabel, TextField, Radio } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Error as ErrorIcon, DirectionsRailway, DirectionsBoat, DirectionsCar, Landscape } from '@material-ui/icons';

const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: option => option.name,
});



const CoverageStep = (props) => {
	const data = props.data;

	const handleInlineRadio = (event) => {
		props.setState({...data, [event.target.name]: [event.target.value][0]});
	}

	return (
		<div className="currentStep">
		{data.freightMode.includes("ocean") && <React.Fragment>
		<Typography variant="h6" gutterBottom className="fieldset-title">Ocean Transport Coverage</Typography>
		<Typography variant="subtitle2" gutterBottom className="flextext">Origin Coverage
			<RadioGroup className="inlineRadio" name="oceanCoverageFromType" value={data.oceanCoverageFromType} onChange={handleInlineRadio} >
			<FormControlLabel value="countries" control={<Radio size="small" />}  label="Countries" />
			<FormControlLabel value="continents" control={<Radio size="small" />} label="Continents" />
			</RadioGroup>
		</Typography>
		{data.oceanCoverageFromType == 'countries' ?
			<Autocomplete
			multiple
			value={data.oceanFromCountries}
			disableCloseOnSelect
			style={{ width: '100%' }}
			options={countries}
			filterOptions={filterOptions}
			autoComplete="new-password"
			getOptionLabel={(option) => option.name}
			onChange={(event, option) => props.setState({...data, oceanFromCountries: option })}
			renderInput={(params) => (
			<TextField
				{...params}
				label="Select countries"
				name="countries"
				variant="outlined"
				autoComplete="new-password"
				inputProps={{
				...params.inputProps,
				autoComplete: 'new-password',
				}}
			/>
			)}
			/>
		: 
			<Autocomplete
			multiple
			value={data.oceanFromContinents}
			disableCloseOnSelect
			style={{ width: '100%' }}
			options={continents}
			getOptionLabel={(option) => option.name}
			onChange={(event, option) => props.setState({...data, oceanFromContinents: option })}
			renderInput={(params) => (
			<TextField
				{...params}
				label="Select continets"
				name="continents"
				variant="outlined"
				autoComplete="new-password"
				inputProps={{
				...params.inputProps,
				autoComplete: 'new-password',
				}}
			/>
			)}
			/>
		}
		<Typography variant="subtitle2" gutterBottom className="flextext">Destination Coverage
			<RadioGroup className="inlineRadio" name="oceanCoverageToType" value={data.oceanCoverageToType} onChange={handleInlineRadio} >
			<FormControlLabel value="countries" control={<Radio size="small" />}  label="Countries" />
			<FormControlLabel value="continents" control={<Radio size="small" />} label="Continents" />
			</RadioGroup>
		</Typography>
		{data.oceanCoverageToType == 'countries' ?
			<Autocomplete
			multiple
			value={data.oceanToCountries}
			disableCloseOnSelect
			style={{ width: '100%' }}
			options={countries}
			filterOptions={filterOptions}
			autoComplete="new-password"
			getOptionLabel={(option) => option.name}
			onChange={(event, option) => props.setState({...data, oceanToCountries: option })}
			renderInput={(params) => (
			<TextField
				{...params}
				label="Select countries"
				name="countries"
				variant="outlined"
				autoComplete="new-password"
				inputProps={{
				...params.inputProps,
				autoComplete: 'new-password',
				}}
			/>
			)}
			/>
		: 
			<Autocomplete
			multiple
			value={data.oceanToContinents}
			disableCloseOnSelect
			style={{ width: '100%' }}
			options={continents}
			getOptionLabel={(option) => option.name}
			onChange={(event, option) => props.setState({...data, oceanToContinents: option })}
			renderInput={(params) => (
			<TextField
				{...params}
				label="Select continets"
				name="continents"
				variant="outlined"
				autoComplete="new-password"
				inputProps={{
				...params.inputProps,
				autoComplete: 'new-password',
				}}
			/>
			)}
			/>
		}
		</React.Fragment>}
		{data.freightMode.includes("land") && <React.Fragment>
		<Typography variant="h6" gutterBottom className="fieldset-title">Land Transport Coverage</Typography>
		<Typography variant="subtitle2" gutterBottom className="flextext">Origin Coverage
			<RadioGroup className="inlineRadio" name="landCoverageFromType" value={data.landCoverageFromType} onChange={handleInlineRadio} >
			<FormControlLabel value="countries" control={<Radio size="small" />}  label="Countries" />
			<FormControlLabel value="continents" control={<Radio size="small" />} label="Continents" />
			</RadioGroup>
		</Typography>
		{data.landCoverageFromType == 'countries' ?
			<Autocomplete
			multiple
			value={data.landFromCountries}
			disableCloseOnSelect
			style={{ width: '100%' }}
			options={countries}
			filterOptions={filterOptions}
			autoComplete="new-password"
			getOptionLabel={(option) => option.name}
			onChange={(event, option) => props.setState({...data, landFromCountries: option })}
			renderInput={(params) => (
			<TextField
				{...params}
				label="Select countries"
				name="countries"
				variant="outlined"
				autoComplete="new-password"
				inputProps={{
				...params.inputProps,
				autoComplete: 'new-password',
				}}
			/>
			)}
			/>
		: 
			<Autocomplete
			multiple
			value={data.landFromContinents}
			disableCloseOnSelect
			style={{ width: '100%' }}
			options={continents}
			getOptionLabel={(option) => option.name}
			onChange={(event, option) => props.setState({...data, landFromContinents: option })}
			renderInput={(params) => (
			<TextField
				{...params}
				label="Select continets"
				name="continents"
				variant="outlined"
				autoComplete="new-password"
				inputProps={{
				...params.inputProps,
				autoComplete: 'new-password',
				}}
			/>
			)}
			/>
		}
		<Typography variant="subtitle2" gutterBottom className="flextext">Destination Coverage
			<RadioGroup className="inlineRadio" name="landCoverageToType" value={data.landCoverageToType} onChange={handleInlineRadio} >
			<FormControlLabel value="countries" control={<Radio size="small" />}  label="Countries" />
			<FormControlLabel value="continents" control={<Radio size="small" />} label="Continents" />
			</RadioGroup>
		</Typography>
		{data.landCoverageToType == 'countries' ?
			<Autocomplete
			multiple
			value={data.landToCountries}
			disableCloseOnSelect
			style={{ width: '100%' }}
			options={countries}
			filterOptions={filterOptions}
			autoComplete="new-password"
			getOptionLabel={(option) => option.name}
			onChange={(event, option) => props.setState({...data, landToCountries: option })}
			renderInput={(params) => (
			<TextField
				{...params}
				label="Select countries"
				name="countries"
				variant="outlined"
				autoComplete="new-password"
				inputProps={{
				...params.inputProps,
				autoComplete: 'new-password',
				}}
			/>
			)}
			/>
		: 
			<Autocomplete
			multiple
			value={data.landToContinents}
			disableCloseOnSelect
			style={{ width: '100%' }}
			options={continents}
			getOptionLabel={(option) => option.name}
			onChange={(event, option) => props.setState({...data, landToContinents: option })}
			renderInput={(params) => (
			<TextField
				{...params}
				label="Select continets"
				name="continents"
				variant="outlined"
				autoComplete="new-password"
				inputProps={{
				...params.inputProps,
				autoComplete: 'new-password',
				}}
			/>
			)}
			/>
		}
		</React.Fragment>}
	</div>
	)
}

export default CoverageStep;