/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import OfferDrawer from "../../components/offers/Drawer";
import FilterPane from "../../components/offers/FilterPane";
import OffersList from "../../components/offers/OffersList";
import Tabs from "../../components/offers/Filter/Tabs";
import Page from "../../containers/Page";
import APIKit from "../../include/APIKit";
import { useParams } from "react-router-dom";

export default function Offers() {
  const [offer, setOffer] = useState();
  const [request, setRequest] = useState({
    // deliveryDate: "2021-11-24T08:32:00Z",
    // deliveryDateOption: 1,
    // favorites: 0,
    // freightType: 1,
    // grossWeight: 322,
    // id: 80,
    // pickupDate: "2021-11-12T08:32:00Z",
    // pickupDateOption: 1,
    // routeDestination: { description: "Barcelona, Spain" },
    // routeOrigin: { description: "Berlin, Germany" },
    // shipmentType: 3,
  });

  const [requestId, setRequestId] = useState();

  const { offerId } = useParams();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = React.useState({
    // shipmentTypes: [],
    // freightTypes: [1, 2],
  });
  const [openDrawer, setOpenDrawer] = useState(false);

  const tableRef = React.createRef();

  const handleLocationFilter = () => {
    console.log("location filter");
  };

  const handleToggle = (event, value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const refreshTable = () => {
    tableRef.current.onChangePage(null, 0);
  };

  const getOffers = query => {
    return new Promise((resolve, reject) => {
      let body = {
        pageSize: query.pageSize,
        pageNumber: query.page + 1,
        status: activeTab === 0 ? null : activeTab,
        ...filters,
      };
      APIKit.post("offers/supplier", body)
        .then(result => {
          if (result.status === 200) {
            if (result.data.record.totalItems < query.pageSize) {
              setPageSize(result.data.record.totalItems);
            }

            resolve({
              data: result.data.record.items,
              page: query.page,
              totalCount: result.data.record.totalItems,
            });
          }
        })
        .catch(e => {
          console.log(e);
        });
    });
  };

  const getSingleOffer = id => {
    setLoading(true);
    APIKit.get(`Offers/` + id)
      .then(res => {
        if (res.status === 200) {
          setOffer(res.data);
          setRequestId(res.data.requestReference.id);
          setLoading(false);
        }
      })
      .catch(e => console.log(e));
  };

  const getRequest = id => {
    APIKit.get(`requests/` + id)
      .then(res => {
        res.status === 200 && setRequest(res.data.record);
      })
      .catch(e => console.log(e));
  };

  useEffect(() => {
    refreshTable();
  }, [filters, activeTab]);

  useEffect(() => {
    console.log(requestId);
    requestId && getRequest(requestId);
  }, [requestId]);

  return (
    <Page
      searchEnabled={true}
      title="Offers"
      mainClass={"request-page-container"}
      subHeader={<Tabs activeTab={activeTab} setActiveTab={setActiveTab} />}
    >
      <OfferDrawer
        open={openDrawer}
        setOpen={setOpenDrawer}
        offer={offer}
        offerItems={offer?.offerItems}
        loading={loading}
        request={request}
      />

      {/* <FilterPane
        handleLocationFilter={handleLocationFilter}
        handleToggle={handleToggle}
      /> */}
      <OffersList
        data={query => getOffers(query)}
        tableRef={tableRef}
        // openDrawer={openDrawer}
        pageSize={pageSize}
        setOpenDrawer={setOpenDrawer}
        getSingleOffer={getSingleOffer}
        offerId={offerId}
      />
    </Page>
  );
}
