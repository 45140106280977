import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import moment from "moment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Notification from "./Notification";
import APIKit from "../../include/APIKit";
import { 
  RiMoneyDollarCircleFill,
	RiChat3Fill,
	RiCheckboxCircleFill,
  RiShieldCheckFill,
  RiShieldStarFill,
  RiNavigationFill,
  RiUser3Fill,
  RiTodoFill,
  RiNotification4Fill,
  RiFileUploadFill
} from "react-icons/ri";
import { Skeleton } from "@material-ui/lab";
import NoResults from "../NoResults";
import { LinearProgress } from "@material-ui/core";
import { useStore } from "../../context/StoreContext";

//export default function NotificationsList(props) {
const NotificationsList = forwardRef((props, ref) => {
  const { containerStyle, close } = props;
  const {userData, setUserData} = useStore();

  const [notifications, setNotifications] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setUserData({...userData, unreadNotificationsCount: 0})
    getNotifications();
  }, [])

  const getNotifications = (more) => {
    setFetchingMore(more ? true : false);
    APIKit.get(`/notifications?markAsReceived=true&pageSize=50&page=${page}`).then(response => {
        if (response.status === 200) {
          setNotifications(more ? [...notifications, ...response.data.record.items] : response.data.record.items);
          setPage(response.data.record.pageIndex+1);
        }
        setLoading(false);
        setFetchingMore(false);
    }) .catch(e => {
      setLoading(false);
      setFetchingMore(false);
    });
  };

  useImperativeHandle(ref, () => ({
    readAll() {
      updateNotification([], true);
    }
  }));

  const updateNotification = (ids, updateAll = false) => {
    let nArray = [...notifications];
    ids.forEach(id => {
      let index = notifications.findIndex(n => n.id === id);
      nArray[index].isSeen = true;
    })
    if(updateAll) {
      nArray.filter(n => n.isSeen = true);
    }
    setNotifications(nArray)
    APIKit.put("/notifications/seen", {ids, updateAll});
  }

  const onScroll = (e) => {
    if (Math.abs(e.target.scrollTop + e.target.clientHeight) === e.target.scrollHeight) {
      console.log('✅ ~ Reached bottom');
      getNotifications(true)
    }
  }

  const notification = not => {
    switch(not.type) {
      case 1: return {
        title: "New Offer Received", 
        description: not.description, 
        target: `/request/${not.metaData?.RequestId}`, 
        icon: <RiMoneyDollarCircleFill />
      }
      case 8: return {
        title: "Offer Accepted", 
        description: not.description, 
        target: `/contract/${not.objectId}`, 
        icon: <RiCheckboxCircleFill />
      }
      case 10: return {
        title: "New Message Received", 
        description: not.description, 
        target: `/messages/${not.objectId}`, 
        icon: <RiChat3Fill />
      }
      case 11: return {
        title: "Shipment Details Updated", 
        description: not.description, 
        target: `/contract/${not.objectId}/shipment`, 
        icon: <RiTodoFill />
      }
      case 12: return {
        title: not?.title, 
        description: not?.description, 
        target: `/contract/${not?.objectId}`, 
        icon: <RiNotification4Fill />
      }
      case 14: return {
        title: "Shipment Tracking Updated", 
        description: not.description, 
        target: `/contract/${not.objectId}`, 
        icon: <RiNavigationFill />
      }
      case 16: return {
        title: not.title, 
        description: not.description, 
        target: `/contract/${not.objectId}/documents`, 
        icon: <RiFileUploadFill />
      }
      case 17: return {
        title: "Shipment Delivered 🎉", 
        description: not.description, 
        target: `/contract/${not.objectId}`, 
        icon: <RiCheckboxCircleFill />
      }
      case 18: return {
        title: "Contract Completed", 
        description: not.description, 
        target: `/contract/${not.objectId}`, 
        icon: <RiShieldCheckFill />, 
        customClass: "noti-green"
      }
      case 19: return {
        title: "Contract Reviewed", 
        description: not.description, 
        target:  `/contract/${not.objectId}`, 
        icon:  <RiShieldStarFill />
      }
      case 20: return {
        title: not.title, 
        description: not.description, 
        target: `/contract/${not.objectId}`, 
        icon: <RiUser3Fill />
      }
      case 21: return {
        title: not.title, 
        description: not.description, 
        target: `/contract/${not.objectId}`, 
        icon: <RiNotification4Fill />,
        customClass: "noti-orange"
      }
      case 22: return {
        title: not.title, 
        description: not.description, 
        target: `/contract/${not.objectId}`, 
        icon: <RiNotification4Fill />,
        customClass: "noti-orange"
      }
      default: return {
        title: not.title, 
        description: not.description, 
        target: `/`, 
        icon: <RiNotification4Fill />
      }
    }
  }

  const fakeRows = new Array(Math.trunc(window.innerHeight/108.17));

  return (
    <div className="notifications-list-container scrollable" style={{...containerStyle}} onScroll={onScroll} >
      {loading ?
        fakeRows.fill().map((message, key) => <NotificationSkeleton />) : (
          <>
            {notifications && notifications.length > 0 ? (
              notifications.map(not => (
                <Notification
                  key={not.id}
                  notification={{
                    ...notification(not),
                    id: not.id, 
                    isSeen: not.isSeen,
                    isReceived: not.isReceived,
                    notificationDate: not.notificationDate
                  }}
                  updateNotification={updateNotification}
                  close={close}
                />
              ))
            ) : (
              <div className="no-notifications">
                <NoResults
                  title="No Notifications!"
                  subtitle="You don't have any notifications at this moment"
                  //buttonLabel={accountType == 1 ? "Find Shipments" : "Create a Request"}
                  //buttonAction={() => history.push(accountType == 1 ? '/find-shipments' : '/requests/create')}
                />
              </div>
            )}
            <NotificationSkeleton style={{visibility: fetchingMore ? "visible" : "hidden"}} />
          </>
        )
      }
      
    </div>
  );
})

const NotificationSkeleton = ({style}) => {
  return (
    <div style={{height: 108.17, borderBottom: '1px solid #eee', padding: '30px', display: 'flex', alignItems: 'center', flexDirection: 'row', ...style}}>
      <Skeleton
        variant="rect"
        width={38}
        height={38}
        style={{ borderRadius: 35, marginRight: 12 }}
      />
      <div style={{width: 'calc(100% - 50px)'}}>
        <Skeleton variant="text" width="40%" />
        <Skeleton variant="text" width="80%" />
      </div>
    </div>
  )
}

export default NotificationsList;
