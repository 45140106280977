import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {
	Button,
	IconButton,
	Typography,
	Dialog,
	CircularProgress
} from '@material-ui/core';

import { 
	ToggleButton, 
	ToggleButtonGroup,
	Alert,
} from '@material-ui/lab';

import {
	Close as CloseIcon,
	CreditCard as CreditCardIcon,
	AccountBalance as AccountBalanceIcon,
	Euro as EuroIcon
} from '@material-ui/icons';


import APIKit from '../../../include/APIKit';
import {useStripe, useElements, IbanElement, CardElement} from '@stripe/react-stripe-js';
import SepaSetupForm from '../../Stripe/SepaSetupForm';
import ACHSetupForm from '../../Stripe/ACHSetupForm';










export default function AddPaymentMethod(props) {
	const stripe = useStripe();
	const elements = useElements();

	const [methodType, setMethodType] = useState('card');
	const [error, setError] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	

	const handleSubmit = async (event) => {
		event.persist();
		event.preventDefault();
		setError(false);
		setLoading(true);
		if (!stripe || !elements) {
			return;
		}

		if(methodType === 'bank_account') {
			let result;
			const bankAccount = {
				country: "US",
				currency: "USD",
				account_number: event.target['account_number'].value,
				account_holder_name: event.target['account_holder_name'].value,
				account_holder_type: event.target['account_holder_type'].value,
				routing_number: event.target['routing_number'].value,
			}

			result = await stripe.createToken('bank_account', bankAccount);
			if (result.error) {
				setError(result.error.message);
			} else {
				APIKit.put(`PaymentMethods/attach-bankaccount?token=${result.token.id}`).then(async res => {
					if(props.callback) {
						props.callback(result.token.bank_account.id);
						return;
					}
					props.refresh();
					
				});
				
			}
			setLoading(false);
		}
		else {
			APIKit.get(`PaymentMethods/new-setupintent/${methodType}`).then(async res => {
				let result;

				if(methodType === 'card') {
					result = await stripe.confirmCardSetup(res.data.record, {
						payment_method: { card : elements.getElement(CardElement) }
					});
				}
				else if(methodType === 'sepa') {
					const name = event.target['account_holder_name'].value;
					const email = event.target['account_holder_email'].value;
					result = await stripe.confirmSepaDebitSetup(res.data.record, {
						payment_method: {
							sepa_debit: elements.getElement(IbanElement),
							billing_details: {
								name,
								email
							},
						}
					});
				}
				
			
				if (result.error) {
					setError(result.error.message);
				} else {
					if(props.callback) {
						props.callback(result.setupIntent.payment_method);
						return;
					}
					props.refresh();
				}
				setLoading(false);
			}).catch(e => {});
		}
	};

	const renderForm = () => {
		switch(methodType) {
			case 'card':
				return <CardElement />;
			case 'bank_account':
				return <ACHSetupForm />;
			case 'sepa':
				return <SepaSetupForm />;
			default: 
				return <CardElement />;
		}
	}




	return (
		<div>
		<Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title" maxWidth="sm"  className="add-payment-dialog">
			<DialogTitle id="form-dialog-title" onClose={props.onClose} >Add Payment Method</DialogTitle>
			{error !== false ? <Alert style={{borderRadius: 0}} severity="error">{error}</Alert> : ""}
			<form onSubmit={handleSubmit}>
			<DialogContent dividers style={{borderTop: 0}}>
			
				<ToggleButtonGroup value={methodType} className="payment-options-toggle" exclusive onChange={(event, value) => setMethodType(value)} >
					<ToggleButton value="card">
						<CreditCardIcon /> Card
					</ToggleButton>
					<ToggleButton value="bank_account">
						<AccountBalanceIcon style={{fontSize: 20}} /> ACH Debit
					</ToggleButton>
					<ToggleButton value="sepa">
						<EuroIcon style={{fontSize: 20}} /> SEPA Debit
					</ToggleButton>
				</ToggleButtonGroup>
				<br />
				
				
					{renderForm()}
				
	
				

				
			
			</DialogContent>
			<DialogActions>
			<Button onClick={props.onClose} color="primary">
				Cancel
			</Button>
		

			<Button type="submit" style={{minWidth: 68}} disabled={!stripe || loading} variant="contained" color="primary">
				{loading ? <CircularProgress size={20} style={{padding: '2px 0px'}} color="inherit" /> : "Save"}
			</Button>

			</DialogActions>
			</form>	
		</Dialog>
		</div>
	);
}







const styles = (theme) => ({
	root: {
		margin: 0,
		padding: '16px 24px',
		borderBottom: '1px solid #eee'
	},
	DialogContent: {
		margin: 0
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});


const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: '24px',
		borderColor: '#eee'
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: '12px 24px',
	},
}))(MuiDialogActions);
