import React, { useEffect } from "react";

import moment from "moment";

import APIKit from "../../include/APIKit";
import Page from "../../containers/Page.js";
import PaymentCard from "../../components/contracts/PaymentCard.jsx";
import ContractsTable from "../../components/contracts/ContractsTable.jsx";
import TabsComponent from "../../components/contracts/Tabs";
import FilterPane from "../../components/contracts/FilterPane";
import NoResults from "../../components/NoResults";
import { useStore } from "../../context/StoreContext";
import { useHistory } from "react-router";

export default function Contracts() {
  const tableRef = React.createRef();


  const [activeTab, setActiveTab] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const [filters, setFilters] = React.useState({
    shipmentTypes: [1,2,3,4],
    freightTypes: [1, 2],
  });
  const [paymentDetailsOpen, setPaymentDetailsOpen] = React.useState(false);

  // useEffect(() => {
  //   tableRef.current.onChangePage(null, 0);
  // }, [filters])

  const handleTabChange = (event, activeTab) => {
    setActiveTab(activeTab);
  };

  const handleToggle = (event, value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleInputChange = event => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const handleFilters = (value, name) => {
    setFilters({ ...filters, [name]: value });

    // if (name === "createdFrom" && (value !== null || value !== undefined)) {
    //   value = moment(value).format("YYYY-MM-DD");
    // }
    // const localFilters = filters;
    // localFilters[name] = value != null ? value : null;
    // setFilters(localFilters);
    // console.log(localFilters)
  };

  const handleFilterOnTabChange = (data, activeTab) => {
    return data.filter(item => item.status === activeTab);
  };

  const search = query => {
    //setLoading(true)
    return new Promise((resolve, reject) => {
      let body = {
        pageSize: 10,
        pageNumber: query.page + 1,
        status: activeTab === 0 ? null : activeTab,
        ...filters,
      };

      APIKit.post("contracts", body)
        .then(result => {
          if (result.status === 200) {
            setTotal(result.data.record.contracts.totalItems)
            
            if (result.data.record.contracts.totalItems < query.pageSize) {
              setPageSize(result.data.record.contracts.totalItems);
            } else {
              setPageSize(query.pageSize);
            }

            // filter on activeTab
            //data = handleFilterOnTabChange(data, activeTab);

            resolve({
              data: result.data.record.contracts.items,
              page: query.page,
              totalCount: result.data.record.contracts.totalItems,
            });
          }
          setLoading(false)
        })
        .catch(e => {
          setLoading(false)
        });
    });
  };

  const shipmentTypes = [
    "",
    "RoRo",
    "Less Than Container Load",
    "Full Container Load",
    "Bulk or Break Bulk",
  ];
  const freightTypes = ["", "Ocean", "Land", "RoRo", "Bulk or Break Bulk"];

  const refreshTable = () => {
    if(tableRef.current) {
      tableRef.current.onChangePage(null, 0);
    }
  };

  // useEffect(() => {
  //   search({});
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  useEffect(() => {
    refreshTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, activeTab]);

  return (
    <Page 
      title="Contracts"
      subHeader={<TabsComponent activeTab={activeTab} handleTabChange={handleTabChange} />}
      mainClass={"request-page-container"}
      //loading={!!loading}
    >
      <PaymentCard open={paymentDetailsOpen} setOpen={setPaymentDetailsOpen} />


      <FilterPane
        handleInputChange={handleInputChange}
        handleToggle={handleToggle}
        freightTypes={filters.freightTypes}
        shipmentTypes={filters.shipmentTypes}
        handleFilters={handleFilters}
      />

      <ContractsTable
        search={search}
        pageSize={pageSize}
        tableRef={tableRef}
        refreshTable={refreshTable}
        setOpenDialog={setPaymentDetailsOpen}
      />
        

        {/* {(!loading && total === 0) && (
          <NoResults
            title="Oh Lord!"
            subtitle="You don't have any contracts at this moment. What's stopping you?"
            buttonLabel={accountType == 1 ? "Find Shipments" : "Create a Request"}
            buttonAction={() => history.push(accountType == 1 ? '/find-shipments' : '/requests/create')}
          />
        )} */}

        
    </Page>
  );
}
