import React, { useEffect, useState } from "react";
import APIKit from "../../include/APIKit";
import Page from "../../containers/Page";
import TransportTab from "../../components/CreateRequest/TransportTab";
import ProgressBar from "../../components/CreateRequest/ProgressBar";
import { useHistory, useParams } from "react-router";
import { Alert } from "@material-ui/lab";
import CargoTab from "../../components/CreateRequest/CargoTab";
import ServicesTab from "../../components/CreateRequest/ServicesTab";
import ReviewTab from "../../components/CreateRequest/ReviewTab";

export default function CreateRequest() {
	const history = useHistory();
	const params = useParams();
	const [loading, setLoading] = useState(null);
	const [pageLoading, setPageLoading] = useState(true);
	const [message, setMessage] = useState(null);
	const [selectedServices, setSelectedServices] = useState([]);
	const [services, setServices] = useState([]);
	const [validationStep, setValidationStep] = useState(null);
	const [progress, setProgress] = useState(1);
	const [request, setRequest] = useState({
		title: "",
		freightType: 1,
		shipmentType: 3,
		shipperRole: 1,
		validty: 1,
		deliveryDate: null,
		deliveryDateOption: 1,
		pickupDate: null,
		pickupDateOption: 1,
		// routeOrigin: {
		//   longtiude: 0,
		//   latitude: 0,
		//   name: "",
		//   description: "",
		//   city: "",
		//   country: {
		// 	isoCode: null
		//   },
		//   zipCode: ""
		// },
		// routeDestination: {
		//   longtiude: 0,
		//   latitude: 0,
		//   name: "",
		//   description: "",
		//   city: "",
		//   country: {
		// 	isoCode: null
		//   },
		//   zipCode: ""
		// },
		grossWeight: 0.00,
		volume: 0.00,
		items: [
		  {
			quantity: 1,
			isDangerousItem: false,
			isRealMeasures: false,
			grossWeight: null,
			// volume: null,
			containerId: 13,
			category: {id: null},
			packagingType: 1,
			mobility: 0
		  }
		],
		services: [],
		isAdvancedRequest: false,
		currency: {
			id: 1
		}
	});

	useEffect(() => {
		fetchServices();
		if(params.id) {
			getRequestDetails(params.id)
		}
		else {
			setPageLoading(false)
		}
	}, [])
	

	useEffect(() => {
		//if(name == 'shipmentType' || (name == 'freightType' && value == 3))
		//if(request.shipmentType)
		clearItems();
	}, [request.shipmentType])

	useEffect(() => {
		setRequest({...request, services: selectedServices});
	}, [selectedServices])

	const clearItems = () => {
		handleItemAdd(true);
	}


	const getRequestDetails = (id) => {
		APIKit.get(`requests/${id}`)
			.then(result => {
				if(result.status === 200) {
					if(result.data.isSuccess) {
						setRequest(result.data.record)
					}
				}
				setPageLoading(false);
			})
			.catch(e => {
				//setLoading(false);
			});
	};
	
	

	const handleItemAdd = (clear) => {
		let newItem;
		if(request.shipmentType == 1) {
			newItem = {
				description: null,
				quantity: 1,
				isDangerousItem: false,
				isRealMeasures: false,
				width: null,
				height: null,
				length: null,
				grossWeight: null,
				mobility: 0
			};
		}
		else if (request.shipmentType == 2) {
			newItem = {
				description: null,
				quantity: 1,
				isDangerousItem: false,
				isRealMeasures: false,
				width: null,
				height: null,
				length: null,
				grossWeight: null,
				category: { id: null },
				packagingType: 1
			};
		}
		else if (request.shipmentType == 3) {
			newItem = {
				quantity: 1,
				isDangerousItem: false,
				grossWeight: null,
				containerId: 13,
				category: { id: null }
			};
		}
		else if (request.shipmentType == 4) {
			newItem = {
				description: null,
				isDangerousItem: false,
				grossWeight: null,
				volume: null,
				category: { id: null }
			};
		}
		setRequest({...request, items: clear === true ? [newItem] : [...request.items, newItem ]})
	}

	const checkProperties = (obj) => {
		for (var key in obj) {
		if (obj[key] == null)
			return true;
		}
		return false;
	}

	const fetchServices = () => {
		APIKit.get("LogisticServices" ).then(result => {
			if (result.status === 200) {
				setServices(result.data.record);
			}
		}).catch(e => {})
	}

	const validate = (prog) => {
		setValidationStep(prog);
		if(prog == 0) {
			if(request.routeOrigin.country.isoCode != null && request.routeDestination.country.isoCode != null && request.pickupDate != null && request.deliveryDate != null ) {
				return true;
			}
		}
		else if(prog == 33.33) {
			const items = request.items;
			let canProceed = [];
			for(var i in items) {
				canProceed.push(checkProperties(items[i]));
			}
			return !canProceed.includes(true);
		
			//return canProceed;
			//console.log("in out: "+ true);
		}
		else {
			return true;
		}
	}

	const navigate = (dir) => {
		if(dir == 'next') {
			// remove this line when u finish coding
			setProgress(progress+1)
			// end here

			if(validate(progress)) {
				setProgress(progress+1);
			}
			else {
				setProgress(progress);
			}
		}
		if(dir == 'back') {
			setProgress(progress-1);
		}
		//console.log(this.state)
	}

	const createRequest = (data) => {
		setLoading(true)
		APIKit[params.id ? "put" : "post"]("requests", data ).then(result => {
			if (result.status === 200) { 
				console.log(result.data);
				setMessage({success: true, content: "Your request has been successfully submitted! Reirecting you to your shipments page..."});
				history.push('/request/'+result.data.record?.id);
			}
			else {
				setMessage({success: false, content: 'Oops! Soemthing wen\'t wrong. Please try again. '});
			}
			setLoading(false)
		}).catch(e => {
			setLoading(false)
			setMessage({success: false, content: 'Oops! Soemthing wen\'t wrong. Please try again. '});
		})
	}

	const renderTab = () => {
		switch(progress) {
			case 1:
				return (
					<TransportTab 
						request={request}
						setRequest={setRequest}
						validationStep={validationStep}
					/>
				);
			case 2:
				return (
					<CargoTab
						request={request}
						setRequest={setRequest}
						handleItemAdd={handleItemAdd}
					/>
				);
			case 3:
				return (
					<ServicesTab
						request={request}
						setRequest={setRequest}
						selectedServices={selectedServices}
						setSelectedServices={setSelectedServices}
						services={services}
					/>
				);
			case 4:
				return (
					<ReviewTab
						request={request}
						services={services}
					/>
				);
		}
	}

	

	

	return (
		<Page 
			title="Create Request"
			loading={pageLoading}
		>
			<div className="request-form">
				{renderTab()}

				<br/>
				{message !== null &&  <Alert severity={message.success ? "success" : "error"}>{message.content}</Alert>}
				<br/>

				<ProgressBar 
					value={progress} 
					next={(dir)=> navigate('next')} 
					back={(dir)=> navigate('back')} 
					submit={(dir)=> createRequest(request)} 
					loading={loading}
				/>
			</div>
		</Page>
	)
}