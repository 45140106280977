import React from "react";
import { Tabs, Tab } from "@material-ui/core";

export default function TabsComponent({ activeTab, handleTabChange }) {
  return (
    <Tabs
      value={activeTab}
      onChange={handleTabChange}
      indicatorColor="primary"
      textColor="primary"
      className="headerTabs"
    >
      <Tab label="All" value={0} />
      <Tab label="Active" value={1} />
      <Tab label="Cancelled" value={2} />
      {/* <Tab label="Expired" value={3} /> */}
      <Tab label="Delivered" value={4} />
      <Tab label="Completed" value={5} />
      {/* <Tab label="Conflict" value={6} /> */}
    </Tabs>
  );
}
