import React from "react";
import { Paper, Avatar } from "@material-ui/core";
import { Room as RoomIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { Link } from "react-router-dom";

export default function SupplierDetails(props) {
  const { party, loading, type } =
    props;
  return (
    <div>
      <Paper className={"white-card "}>
        <div className="card-header center-align1">
          {loading ? (
            <Skeleton
              variant="text"
              width="90px"
              // style={{ marginLeft: "auto", marginRight: "auto" }}
            />
          ) : (
            type == 2 ? "Supplier" : "Client"
          )}
        </div>
        <Link to="" style={{ textDecoration: "none", color: "inherit" }}>
          <div className="card-body">
            <div className="supplier-box" style={{ width: "100%" }}>
              <div className="supplier-logo">
                {loading ? (
                  <Skeleton
                    variant="rect"
                    width={45}
                    height={45}
                    style={{ borderRadius: 35 }}
                  />
                ) : (
                  <Avatar className="supplier-pic" src={party?.logoUrl}>
                    {party?.logoAbbreviation}
                  </Avatar>
                )}
              </div>
              <div className="supplier-content" style={{ width: "100%" }}>
                <div className="supplier-name">
                  {loading ? (
                    <Skeleton variant="text" width="40%" />
                  ) : (
                    party?.fullName
                  )}
                </div>
                <div className="supplier-location">
                  {loading ? (
                    <Skeleton variant="text" width="80%" />
                  ) : (
                    <React.Fragment>
                      <RoomIcon
                        style={{ fontSize: 12, marginBottom: "-1px" }}
                      />{" "}
                      {party?.country?.name || "N/A"}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </Paper>
    </div>
  );
}
