import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { FiUserPlus } from "react-icons/fi";
import Card from "../Card";
import MembersList from "./membersPermissions/MembersList";
import InviteMemberDialog from "./membersPermissions/InviteMemberDialog";
import APIKit from "../../include/APIKit";

export default function MembersPermissions() {
  const [inviteOpen, setInviteOpen] = React.useState(false);
  const [data, setData] = useState([
    {
      name: "Joe",
      email: "joe@company.com",
      // phone: "+9766151832",
      role: "Owner",
      src: "https://cdn.shopify.com/s/files/1/0045/5104/9304/t/27/assets/AC_ECOM_SITE_2020_REFRESH_1_INDEX_M2_THUMBS-V2-1.jpg?v=8913815134086573859",
    },
    {
      name: "Malek",
      email: "joe@company.com",
      // phone: "+9766151832",
      role: "Owner",
    },
    {
      name: "Maria",
      email: "joe@company.com",
      // phone: "+9766151832",
      role: "Staff",
    },
    {
      name: "Ahmad",
      email: "joe@company.com",
      // phone: "+9766151832",
      role: "Admin",
    },
    {
      name: "Louis Chunk",
      email: "joe@company.com",
      // phone: "+9766151832",
      role: "Staff",
    },
    {
      name: "Mira",
      email: "joe@company.com",
      // phone: "+9766151832",
      role: "Staff",
    },
    {
      name: "Mark",
      email: "joe@company.com",
      // phone: "+9766151832",
      role: "Admin",
    },
  ]);
  const getData = () => {
    APIKit.get("data").then(result => {
      if (result.status === 200) {
        setData(data => [
          ...data,
          {
            name: result.name,
            email: result.email,
            role: result.role,
            src: result.src,
          },
        ]);
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Card
        variant="white"
        bodyPadding={false}
        headerTitle="Members and Permissions"
        headerSubtitle="Here you can manage all company members"
        headerAction={
          <Button
            color="primary"
            disableElevation
            startIcon={<FiUserPlus style={{ fontSize: 18 }} />}
            onClick={() => setInviteOpen(true)}
          >
            Invite member
          </Button>
        }
      >
        <InviteMemberDialog open={inviteOpen} setOpen={setInviteOpen} />
        <MembersList data={data} setData={setData} />
      </Card>
    </div>
  );
}
