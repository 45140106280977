import React from "react";
import { Paper } from "@material-ui/core";
import CardBody from "./client-info/CardBody";

export default function ClientInfo(props) {
  const { client } = props;

  /* {logoUrl, fullName, amountToSpend, paymentVerified, averageRating, location, isShipper, currencyCode, numberOfBookings} */

  return (
    <Paper className={"white-card "}>
      <CardBody
        url={client.logoUrl}
        name={client.fullName}
        isShipper={client.isShipper}
        averageRating={client.averageRating}
        currencyCode={client.currencyCode}
        nbOfBooking={client.numberOfBookings}
        amountToSpend={client.amountToSpend}
        paymentVerified={client.paymentVerified}
        location={client.location}
      />
    </Paper>
  );
}
