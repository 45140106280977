import React from "react";
import { 
	Button,
	FormControl,
	Grid,
	MenuItem,
	Select,
	TextField,
	IconButton,
	InputLabel,
	FormControlLabel,
	Checkbox
} from "@material-ui/core";
import { 
	Delete as DeleteIcon,
	Add as AddIcon
} from "@material-ui/icons";
import Card from "../Card";
import MakemodelAutocomplete from "../MakemodelAutocomplete";
import CategoriesAutocomplete from "../CategoriesAutocomplete";
import containerTypes from "../../include/containerTypes.json";

export default function CargoTab (props) {
	const { 
		request,
		setRequest,
		handleItemAdd
	} = props;

	const handleItemInputChange = (event, index) => {
		let value = 0;
		const items = [...request.items];
		if(event.target.name == 'length' || event.target.name == 'width' || event.target.name == 'height' || event.target.name == 'grossWeight' || event.target.name == 'volume' || event.target.name == 'quantity' ) {
			value = parseFloat(event.target.value);
		}
		else {
			value = event.target.value;
		}
		items[index][event.target.name] = value;
		let total = 0;
		for(var i in items) {
			total += (items[i].quantity || 1) * (items[i].grossWeight);
		}
		setRequest({...request, items,  grossWeight: total});
	}

	const handleItemSelectChange = (event, index) => {
		const items = [...request.items];
		items[index][event.target.name] = event.target.value;
		setRequest({...request, items});
	}

	const handleItemCategoryChange = (value, index) => {
		const items = [...request.items];
		items[index].category.id = value;
		setRequest({...request, items});
	}

	const handleItemMakemodelChange = (value, index) => {
		console.log(value)
		const items = [...request.items];
		items[index].description = value ? value.description : "";
		items[index].length = value ? value.length : "";
		items[index].width = value ? value.width : "";
		items[index].height = value ? value.height : "";
		items[index].grossWeight = value ? value.weight : "";
		setRequest({...request, items});
	}

	const handleItemCheckboxChange = (event, index) => {
		const items = [...request.items];
		items[index][event.target.name] = event.target.checked;
		setRequest({...request, items});
	}

	const handleItemDelete = (index) => {
		const items = [...request.items];
		items.splice(index, 1);
		setRequest({...request, items});
	}

	


	return (
		<Card 
			headerTitle="Cargo Details"
			footer={
				<Grid container spacing={1} alignItems="center">
                  <Grid item sm={6}>
                    <Button onClick={handleItemAdd} color="primary" startIcon={<AddIcon />}>
                      Add More Cargo
                    </Button>
                  </Grid>
                  {request.shipmentType != 3 && <Grid item sm={6} className="text-align-right">
                    {/* <span className="footer-label"><span className="label-span">Total Volume: </span>{this.state.request.volume} cbm</span> */}
                    <span className="footer-label"><span className="label-span">Total Weight: </span>{request.grossWeight} kg</span>
                  </Grid>}
                </Grid>
			}
		>
			{request.items.map((item, index) => ( <div  className="request-item" key={index}>
				<Grid container spacing={1} alignItems="center" >
					{request.shipmentType != 4 && 
						<Grid item sm={1} >
							<TextField label="Qty" name="quantity" value={item.quantity || ''} onChange={(event, ind) => handleItemInputChange(event, index)} variant="outlined" type="number" size="small" fullWidth InputLabelProps={{ shrink: true, required: true }} />
						</Grid>
					}
					{request.shipmentType === 2 &&
						<Grid item sm={1} >
							<FormControl variant="outlined" fullWidth size="small" >
								<InputLabel id="pt-label">Packaging</InputLabel>
								<Select
									onChange={(event, ind) => handleItemSelectChange(event, index)}
									name="packagingType"
									value={item.packagingType}
									labelId="pt-label"
									label="Packaging"
									defaultValue={1}
								>
									<MenuItem value={1}>Pallet</MenuItem>
									<MenuItem value={2}>Crate</MenuItem>
									<MenuItem value={3}>Box</MenuItem>
									<MenuItem value={4}>Bag</MenuItem>
									<MenuItem value={5}>Bale</MenuItem>
									<MenuItem value={6}>Bundle</MenuItem>
									<MenuItem value={7}>Can</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					}
					{request.shipmentType == 3 && 
						<Grid item sm={3} >
							<FormControl variant="outlined" fullWidth size="small">
								<InputLabel id="ct-label">Container Type</InputLabel>
								<Select labelId="ct-label" label="Container Type" onChange={(event, ind) => handleItemSelectChange(event, index)} name="containerId"  value={item.containerId} required >
									{containerTypes.map(cont => (
										<MenuItem value={cont.Id}>{cont.Name}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					}
					{request.shipmentType != 1 && <Grid item sm={request.shipmentType == 4 ? 4 : 3} >
						<CategoriesAutocomplete selection={(data, ind) => handleItemCategoryChange(data ? parseFloat(data.id) : null, index)} label="Category" shipmentType={request.shipmentType} />
					</Grid>}
					{request.shipmentType === 1 && <Grid item sm={4} >
						<MakemodelAutocomplete selection={(data, ind) => handleItemMakemodelChange(data ? data : null, index)} label="Make/Model" value={item.description || ''} />
					</Grid>}
					{(request.shipmentType !== 1) && <Grid item sm={request.shipmentType == 1 ? 6 : 3} >
						<TextField label="Description" name="description" value={item.description || ''} onChange={(event, ind) => handleItemInputChange(event, index)} InputLabelProps={{ shrink: true, required: true }} variant="outlined" size="small" fullWidth />
					</Grid>}
					{!(request.shipmentType == 3 || request.shipmentType == 4) && <Grid item sm={1} >
						<TextField label="L" name="length" value={item.length || ''} onChange={(event, ind) => handleItemInputChange(event, index)} variant="outlined" type="number" size="small" fullWidth InputLabelProps={{ shrink: true, required: true }} placeholder="cm" />
					</Grid>}
					{!(request.shipmentType == 3 || request.shipmentType == 4) && <Grid item sm={1} >
						<TextField label="W" name="width" value={item.width || ''} onChange={(event, ind) => handleItemInputChange(event, index)} variant="outlined" type="number" size="small" fullWidth InputLabelProps={{ shrink: true, required: true }} placeholder="cm" />
					</Grid>}
					{!(request.shipmentType == 3 || request.shipmentType == 4) && <Grid item sm={1} >
						<TextField label="H" name="height" value={item.height || ''} onChange={(event, ind) => handleItemInputChange(event, index)} variant="outlined" type="number" size="small" fullWidth InputLabelProps={{ shrink: true, required: true }} placeholder="cm" />
					</Grid>}
					<Grid item sm={request.shipmentType == 4 ? 2 : 1} >
						<TextField label="Weight" name="grossWeight" value={item.grossWeight || ''} onChange={(event, ind) => handleItemInputChange(event, index)} variant="outlined" type="number" size="small" fullWidth InputLabelProps={{ shrink: true, required: true }} placeholder="kg" />
					</Grid>
					{request.shipmentType == 4 && <Grid item sm={2} >
						<TextField label="Volume" name="volume" value={item.volume || ''} onChange={(event, ind) => handleItemInputChange(event, index)} variant="outlined" type="number" size="small" fullWidth InputLabelProps={{ shrink: true, required: true }} placeholder="cbm" />
					</Grid>}
					{request.shipmentType === 1 &&
						<Grid item sm={2} >
							<FormControl variant="outlined" fullWidth size="small" >
								<InputLabel id="pt-label">Mobility</InputLabel>
								<Select
									onChange={(event, ind) => handleItemSelectChange(event, index)}
									name="mobility"
									value={item.mobility}
									labelId="pt-label"
									label="Mobility"
									defaultValue={0}
								>
									<MenuItem value={0}>Regular</MenuItem>
									<MenuItem value={1}>NonRunner</MenuItem>
									<MenuItem value={2}>ForkLifted</MenuItem>
									<MenuItem value={3}>Towed</MenuItem>
									<MenuItem value={4}>Tracked</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					}
					<Grid item sm={1} className="text-align-center" >
						<IconButton aria-label="delete" size="small" onClick={(ind) => handleItemDelete(index)} disabled={index == 0 ? true : false}>
						<DeleteIcon />
						</IconButton>
					</Grid>
					
					</Grid>
					<Grid container spacing={1} >
					<Grid item sm={6} className="small-checkboxes">
						{!(request.shipmentType == 3 || request.shipmentType == 4) &&
							<FormControlLabel control={  <Checkbox checked={item.isRealMeasures} size="small" onChange={(event, ind) => handleItemCheckboxChange(event, index)} name="isRealMeasures" color="primary" /> } label="Exact Measurments" />
						}
						{(request.shipmentType == 3 || request.shipmentType == 2) &&
							<FormControlLabel control={  <Checkbox checked={item.isDangerousItem} size="small" onChange={(event, ind) => handleItemCheckboxChange(event, index)} name="isDangerousItem" color="primary" /> } label="Dangerous Goods" />
						}
						{(item.isDangerousItem) &&
							<FormControl variant="outlined" fullWidth size="small" >
								<InputLabel id="pt-label">IMO Class</InputLabel>
								<Select
									onChange={(event, ind) => handleItemSelectChange(event, index)}
									name="imoClass"
									value={item.imoClass}
									labelId="pt-label"
									label="IMO Class"
								>
									<MenuItem value={1}>Explosives 1.1</MenuItem>
									<MenuItem value={2}>Explosives 1.2</MenuItem>
									<MenuItem value={3}>Explosives 1.3</MenuItem>
									<MenuItem value={4}>Explosives 1.4</MenuItem>
									<MenuItem value={5}>Explosives 1.5</MenuItem>
									<MenuItem value={6}>Explosives 1.6</MenuItem>
									<MenuItem value={7}>Flamable Gas 2.1</MenuItem>
									<MenuItem value={8}>Non-flamable Gas 2.2</MenuItem>
									<MenuItem value={9}>Oxygen Gas 2.3</MenuItem>
									<MenuItem value={10}>Poison Gas 2.4</MenuItem>
									<MenuItem value={11}>Flammable Liquid 3</MenuItem>
									<MenuItem value={12}>Flammable Solids Or Substances 4.1</MenuItem>
									<MenuItem value={13}>Flammable Solids 4.2</MenuItem>
									<MenuItem value={14}>Substances With Water Emit Flammable Gases 4.3</MenuItem>
									<MenuItem value={15}>Oxydizing Substances 5.1</MenuItem>
									<MenuItem value={16}>Organic Peroxides 5.2</MenuItem>
									<MenuItem value={17}>Toxic Substances 6.1</MenuItem>
									<MenuItem value={18}>Infactious Substances 6.2</MenuItem>
									<MenuItem value={19}>Radioactive Substances 7</MenuItem>
									<MenuItem value={20}>Corrosives 8</MenuItem>
									<MenuItem value={21}>Miscellaneous 9</MenuItem>
								</Select>
							</FormControl>
						}
					</Grid>
				</Grid>
			</div> ))}	
		</Card>
	)
}