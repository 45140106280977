import React from "react";
import { 
	FormControl,
	Grid,
	MenuItem,
	Select
} from "@material-ui/core";
import { 
	ToggleButton, 
	ToggleButtonGroup,
} from '@material-ui/lab';
import { 
	DirectionsBoat,
	Flight,
	DirectionsRailway,
	DirectionsCar,
	Landscape
} from "@material-ui/icons";
import Card from "../Card";
import GoogleMaps from "../GoogleMapsAutocomplete";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { delay, get } from "lodash";
import PortsAutocomplete from "../PortsAutocomplete";

const locationLabels = ['', 'Address or Postal Code', 'Address or Postal Code', 'Address or Airport'];

export default function TransportTab(props) {
	const { 
		request,
		setRequest,
		validationStep
	} = props;
	console.log(request)

	const handleToggle = (event, value, name) => {
		if(value !== null) {
			setRequest({...request, [name]: value})
		}
	}

	const handleSelectChange = (event) => {
		setRequest({...request, [event.target.name]: event.target.value})
	}

	const handleDateChange = (value, name) => {
		if (value) {
			value = value.toISOString()
		}
		setRequest({...request, [name]: value})
	}
	

	const handleLocation = (data, name) => {
	
		const location = {
			longtiude: 0,
			latitude: 0,
			name: "",
			description: "",
			city: "",
			country: {
				isoCode: null
			},
			zipCode: ""
		};
	
		if(data != null) {
		  for (var i = 0; i < data.address_components.length; i += 1) {
			var addressObj = data.address_components[i];
			for (var j = 0; j < addressObj.types.length; j += 1) {
			  if (addressObj.types[j] === 'country') { /*outputs result if it is country*/
				location.country.isoCode = addressObj.short_name;
			  }
			  else if (addressObj.types[j] === 'administrative_area_level_1') {
				location.state = addressObj.long_name;
			  }
			  else if (addressObj.types[j] === 'locality') {
				location.city = addressObj.long_name;
			  }
			  else if (addressObj.types[j] === 'postal_code') {
				location.zipCode = addressObj.short_name;
			  }
			}
		  }
		  location.name = data.name;
		  location.description = data.formatted_address;
		  location.longtiude = data.geometry.location.lng();
		  location.latitude = data.geometry.location.lat();
		  setRequest({...request, [name] : location})
		}
		else {
		  location.country.isoCode = null;
		  setRequest({...request, [name] : null})
		}
		
	}

	const handleAddressType = (event) => {
		setRequest({...request, [event.target.name]: {...request[event.target.name], isPort: event.target.value}})
	}

	const handlePortChange = (data, name) => {
		console.log(request[name])
		setRequest({...request, [name]: {
			...request[name],
			portId: data ? data.id : null,
			port: data ? data : null,
			description: data ? `${data.city} (${data.code})` : null
		}})
	}

	return (
		<Card headerTitle="Transport and Route Information">

			<p className="card-body-label">Transport Mode</p>
			<ToggleButtonGroup 
				value={request.freightType} 
				exclusive 
				onChange={(event, value, name) => handleToggle(event, value, 'freightType')} 
				aria-label="Freight Type" 
				className="customToggle" 
			>
				<ToggleButton value={1} aria-label="left aligned">
					<DirectionsBoat/> Ocean
				</ToggleButton>
				
				<ToggleButton value={2} aria-label="centered">
					<DirectionsRailway/> Land
				</ToggleButton>
				<ToggleButton value={3} aria-label="right aligned" disabled>
					<Flight/> Air
				</ToggleButton>
			</ToggleButtonGroup>

			<p className="card-body-label">Shipment Type</p>
			<ToggleButtonGroup 
				value={request.shipmentType} 
				exclusive 
				onChange={(event, value, name) => handleToggle(event, value, 'shipmentType')} 
				aria-label="Shipment Type" 
				className="customToggle" 
			>
				<ToggleButton value={3} aria-label="centered" disabled={request.freightType == 3 ? true : false} >
					<svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M21.5,3.5h-19c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h19c1.1,0,2-0.9,2-2v-13C23.5,4.4,22.6,3.5,21.5,3.5z M21.5,18.5h-19v-13h19V18.5zM3.5, 6.5 h17 v11 h-17z"></path></svg> FCL
				</ToggleButton>
				<ToggleButton value={2} aria-label="right aligned" disabled={request.freightType == 3 ? true : false}>
					<svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M21.5,3.5h-19c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h19c1.1,0,2-0.9,2-2v-13C23.5,4.4,22.6,3.5,21.5,3.5z M21.5,18.5h-19v-13h19V18.5zM3.5, 12.5 h17 v5 h-17z"></path></svg> LCL
				</ToggleButton>
				<ToggleButton value={1} aria-label="RoRo" disabled={request.freightType == 3 ? true : false}>
					<DirectionsCar/> RoRo
				</ToggleButton>
				<ToggleButton value={4} aria-label="centered" disabled={request.freightType == 3 ? true : false}>
					<Landscape/> Bulk
				</ToggleButton>
			</ToggleButtonGroup>

			<Grid container spacing={3} alignItems="center" >
				<Grid item sm={6}>
					<p className="label-title">Origin</p>
					<Grid container  spacing={1} alignItems="center">
						<Grid item sm={3} >
							<FormControl variant="outlined" fullWidth disabled={request.freightType === 2}>
								<Select
									onChange={handleAddressType}
									name="routeOrigin"
									value={get(request, 'routeOrigin.isPort') ? true : false}
								>
									<MenuItem value={false}>Address</MenuItem>
									<MenuItem value={true}>Port</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item sm={9} >
							{get(request, 'routeOrigin.isPort') ? (
								<PortsAutocomplete 
									selection={(data, name) => handlePortChange(data, 'routeOrigin')} 
									label="Port of Origin" 
									value={request.freightType === 2 ? false : get(request, 'routeOrigin.port')} 
									//type="1"
								/>
							) : (
								<GoogleMaps 
									selection={data => {}} 
									details={(data, name) => handleLocation(data, 'routeOrigin') } 
									label={locationLabels[request.freightType]} 
									error={!request.routeOrigin && validationStep == 0 ? true : false} 
									value={request.routeOrigin}
								/>
							)}
							
						</Grid>
					</Grid>
					
					<br/>
					<Grid container  spacing={1} alignItems="center">
						<Grid item sm={5} >
							<FormControl variant="outlined" fullWidth size="small">
								<Select
								labelId="pickup-option-label"
								onChange={handleSelectChange}
								name="pickupDateOption"
								value={request.pickupDateOption}
								>
									<MenuItem value={1}>Pickup On</MenuItem>
									<MenuItem value={2}>Pickup Before</MenuItem>
									<MenuItem value={3}>Pickup After</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item sm={7} >
						<KeyboardDatePicker
							autoOk
							variant="inline"
							size="small"
							inputVariant="outlined"
							fullWidth
							label="Date"
							value={request.pickupDate}
							format="MM/dd/yyyy"
							InputAdornmentProps={{ position: "end" }}
							onChange={(value, name) => handleDateChange(value, "pickupDate")}
							error={request.pickupDate == null && validationStep == 0 ? true : false}
							disablePast
						/>
						</Grid>
					</Grid>
					</Grid>
					<Grid item sm={6}>
						<p className="label-title">Destination</p> 
						<Grid container  spacing={1} alignItems="center">
							<Grid item sm={3} >
								<FormControl variant="outlined" fullWidth disabled={request.freightType === 2}>
									<Select
										onChange={handleAddressType}
										name="routeDestination"
										value={request.freightType === 2 ? false : get(request, 'routeDestination.isPort') ? true : false}
									>
										<MenuItem value={false}>Address</MenuItem>
										<MenuItem value={true}>Port</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item sm={9} >
								{get(request, 'routeDestination.isPort') ? (
									<PortsAutocomplete 
										selection={(data, name) => handlePortChange(data, 'routeDestination')} 
										label="Port of Destination" 
										value={get(request, 'routeDestination.port')} 
										//type="1"
									/>
								) : (
									<GoogleMaps 
										selection={data => {}} 
										details={(data, name) => handleLocation(data, 'routeDestination') } 
										label={locationLabels[request.freightType]} 
										error={!request.routeDestination && validationStep == 0 ? true : false} 
										value={request.routeDestination}
									/>
								)}
								
							</Grid>
						</Grid>
					
					<br/>
					<Grid container spacing={1} alignItems="center" >
						<Grid item sm={5} >
							<FormControl variant="outlined" fullWidth size="small">
								<Select
									labelId="delivery-option-label"
									onChange={handleSelectChange}
									name="deliveryDateOption"
									value={request.deliveryDateOption}
								>
									<MenuItem value={1}>Deliver On</MenuItem>
									<MenuItem value={2}>Deliver Before</MenuItem>
									<MenuItem value={3}>Deliver After</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item sm={7} >
						<KeyboardDatePicker
							autoOk
							variant="inline"
							size="small"
							inputVariant="outlined"
							fullWidth
							label="Date"
							value={request.deliveryDate}
							format="MM/dd/yyyy"
							InputAdornmentProps={{ position: "end" }}
							onChange={(value, name) => handleDateChange(value, "deliveryDate")}
							error={request.deliveryDate == null && validationStep == 0 ? true : false}
							disablePast
							minDate={request.pickupDate}
						/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			
		</Card>
	)
}