import React from "react";

import { Grid, Button } from "@material-ui/core";

export default function SortingPanel({
  totalOffersCount,
  requestFilters,
  sortingType,
  sortBy,
}) {
  return (
    <div className="sorting-panel">
      <Grid container spacing={3}>
        <Grid item sm={3}></Grid>
        <Grid item sm={9}>
          {requestFilters && (
            <div className="sorting-holder">
              <span className="results-count">{totalOffersCount} Results</span>
              <span className="results-sort-text">Sort by: </span>
              <Button
                className={sortingType === 3 ? "active-btn" : ""}
                onClick={int => sortBy(3)}
              >
                Best value
              </Button>
              <Button
                className={sortingType === 1 ? "active-btn" : ""}
                onClick={int => sortBy(1)}
              >
                Cheapest
              </Button>
              <Button
                className={sortingType === 2 ? "active-btn" : ""}
                onClick={int => sortBy(2)}
              >
                Quickest
              </Button>
              <Button
                className={sortingType === 4 ? "active-btn" : ""}
                onClick={int => sortBy(4)}
              >
                Latest
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
