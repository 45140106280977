import React from "react";
import { Button, IconButton } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";

export default function DrawerHeader(props) {
  const { closeDrawer, offer, loading } = props;

  return (
    <div
      className="drawer-actions"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconButton aria-label="back" onClick={closeDrawer} color="primary">
          <ChevronLeft fontSize="large" />
        </IconButton>
        <div className="actions-header">
          <span style={{ fontSize: "24px", color: "initial", fontWeight: 500 }}>
            {loading ? (
              <Skeleton variant="text" width="100px" />
            ) : (
              "#" + offer?.id
            )}
          </span>

          <p className="text-content">
            {loading ? (
              <Skeleton variant="text" width="200px" />
            ) : (
              "Submitted on " + moment(offer?.createdDate).format("ll")
            )}
          </p>
        </div>
      </div>

      {/* <Button
        color="primary"
        variant="contained"
        disableElevation
        //   href={"/create-offer/" + request.id}
        href={"/create-offer/"}
        target="_blank"
        style={{
          textTransform: "none",
          padding: "7px 25px",
          borderRadius: "8px",
        }}
      >
        Submit another offer
      </Button> */}
    </div>
  );
}
