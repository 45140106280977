import React, { useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import APIKit from "../../include/APIKit";

export default function AutocompleteComponent(props) {
  const { label, onChange } = props;

  const [countries, setCountries] = React.useState([]);

  const getCountries = () => {
    APIKit.get("countries")
      .then(result => {
        if (result.status === 200) {
          setCountries(result.data.record);
        }
      })
      .catch(e => {});
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <div>
      <Autocomplete
        options={countries ? countries : []}
        onChange={onChange}
        getOptionLabel={option => option.name}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            size="small"
          />
        )}
        autoSelect
        disabled={countries && countries.length === 0 ? true : false}
        openOnFocus={false}
      />
    </div>
  );
}
