import React from "react";
import { Box } from "@material-ui/core";
import CompanyInfoForm from "./CompanyInfoForm";
import AvatarNameContainer from "./AvatarNameContainer";

export default function CompanyInfo(props) {
  const { companyInfo, setCompanyInfo, loading, imageUrl, setImageUrl } = props;

  const style = {
    box: {
      padding: "20px 30px",
      borderBottom: "1px solid rgb(241, 241, 241)",
    },
    title: {
      fontSize: 18,
      fontWeight: "500",
      color: "rgba(0, 0, 0, 50%)",
      paddingBottom: "15px",
    },
  };
  return (
    <Box style={style.box}>
      <div style={style.title}>Company Information</div>
      <AvatarNameContainer
        defaultUrl={companyInfo?.logoUrl}
        name={companyInfo?.companyName}
        loading={loading}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
      />
      <CompanyInfoForm
        companyInfo={companyInfo}
        setCompanyInfo={setCompanyInfo}
      />
    </Box>
  );
}
