import React from 'react';
import PropTypes from 'prop-types'
import Header from '../components/Header';
import { useStore } from "../context/StoreContext";
import { CircularProgress, Container } from '@material-ui/core';
import Sidebar from '../components/Sidebar';

export default function Page({
	navigation = true, 
	containerClass, 
	containerMaxWidth = "lg", 
	children, 
	header = true, 
	title, 
	subTitle, 
	actions, 
	searchPlaceholder, 
	searchAction, 
	mainClass, 
	canGoBack, 
	backAction, 
	subHeader, 
	customTitle = false, 
	searchEnabled = true, 
	midContent = false, 
	canCreateRequest = true, 
	loading = false
}) {
	const {userData} = useStore();	
	return (
		<div className="PageContainer">
			{header && 
				<Header 
					title={title} 
					subTitle={subTitle} 
					actions={actions} 
					searchEnabled={searchEnabled}
					searchAction={searchAction} 
					searchPlaceholder={searchPlaceholder} 
					canGoBack={canGoBack}
					backAction={backAction}
					subHeader={subHeader}
					customTitle={customTitle}
					midContent={midContent}
					canCreateRequest={canCreateRequest}
				/>
			}
			{(navigation && userData) &&  <Sidebar  mobileOpen={false} userDetails={userData} />}
			{(navigation && !userData) && <div className="preloader"><CircularProgress  /></div>}
			<main className={`root-container ${mainClass}`}>
				{navigation ? 
					<div className="main-container">
						<Container maxWidth={containerMaxWidth} className={containerClass}>
							{loading ? <PageLoader /> : children}
						</Container>
					</div> 
				: children}
			</main>
		</div>
	)
}

const PageLoader = () => {
	return (
		<div className="page-loader"><CircularProgress  /></div>
	)
}

Page.propTypes = {
    navigation: PropTypes.bool,
    header: PropTypes.bool,
	containerClass: PropTypes.string,
	containerMaxWidth: PropTypes.string
}