import React, { useState } from "react";
import MaterialTable from "material-table";

import { Paper, Chip, Box, IconButton, Tooltip, CircularProgress } from "@material-ui/core";
import moment from "moment";
import ActionsMenu from "./ActionsMenu";
import { useHistory } from "react-router";
import APIKit from "../../include/APIKit";
import { FiList } from "react-icons/fi";
import { useParams } from "react-router-dom";
import NoResults from "../NoResults";
import { DirectionsBoat, DirectionsCar, DirectionsRailway, Landscape } from "@material-ui/icons";

export default function OffersList({
  pageSize,
  tableRef,
  refreshTable,
  data,
  setOpenDrawer,
  getSingleOffer,
  offerId,
}) {
  const history = useHistory();
  // const [openDrawer, setOpenDrawer] = useState(false);

  const handleRowClick = (event, id) => {
    history.push("/offers/" + id);
    setOpenDrawer(true);
    getSingleOffer(id);
  };

  const offerStatuses = ["", "Pending", "Accepted", "Rejected", "Withdrawn"];
  const dateOptions = ["", "On", "Before", "After"];

  const typeIcons = [
    "",
    <DirectionsCar className="shipment-icon-big" />,
    <svg
      className="MuiSvgIcon-root shipment-icon-big customIcon"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M21.5,3.5h-19c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h19c1.1,0,2-0.9,2-2v-13C23.5,4.4,22.6,3.5,21.5,3.5z M21.5,18.5h-19v-13h19V18.5zM3.5, 12.5 h17 v5 h-17z"></path>
    </svg>,
    <svg
      className="MuiSvgIcon-root shipment-icon-big customIcon"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M21.5,3.5h-19c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h19c1.1,0,2-0.9,2-2v-13C23.5,4.4,22.6,3.5,21.5,3.5z M21.5,18.5h-19v-13h19V18.5zM3.5, 6.5 h17 v11 h-17z"></path>
    </svg>,
    <Landscape className="shipment-icon-big" />,
  ];

  const offersColumns = [
    {
      title: "Offer",
      width: "20%",
      sorting: false,
      render: rowData => (
        <div className="icon-grid">
          <div style={{marginRight: 10}}>
              <div className="iconComplex">
                {typeIcons[rowData.shipmentType]}
                {rowData.freightType == 1 && (
                  <DirectionsBoat className="shipment-icon-small" />
                )}
                {rowData.freightType == 2 && (
                  <DirectionsRailway className="shipment-icon-small land" />
                )}
              </div>
          </div>
          <div>
            <div className="item-title item-ref">
              {rowData?.number}
            </div>
            <div className="item-date">
              Sent on: &nbsp;
              {moment(rowData?.creationDate).format("DD MM, YYYY")}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Origin",
      field: "offerItems",
      width: "1/",
      sorting: false,
      render: rowData => (
        <div>
          <div className="item-title item-ref">
            {rowData?.originAddress.isPort
              ? rowData?.originAddress.port.code
              : rowData?.originAddress.city}
          </div>
          <div className="item-date">
            {moment(rowData?.originAddress.pickupDate).format("DD MM, YYYY")}
          </div>
        </div>
      ),
    },
    {
      title: "Destination",
      field: "offerItems",
      width: `1/6`,
      sorting: false,
      render: rowData => (
        <div>
          <div className="item-title item-ref">
            {rowData?.destinationAddress.isPort
              ? rowData?.destinationAddress.port.code
              : rowData?.destinationAddress.city}
          </div>
          <div className="item-date">
            {moment(rowData?.dischargingDate).format("DD MM, YYYY")}
          </div>
        </div>
      ),
    },
    {
      title: "Price",
      field: "finalPrice",
      width: "1/",
      sorting: false,
      render: rowData => (
        <div className="">
          <div className="item-title item-ref">
            {rowData?.currency.symbol}
            {rowData?.totalPrice}
          </div>
          <div className="item-date">
            Valid until: &nbsp;
            {moment(rowData?.deliveryDate).format("DD MM, YYYY")}
          </div>
        </div>
      ),
    },
    {
      title: "Status",
      field: "status",
      width: "1/",
      sorting: false,
      render: rowData => (
        <>
          <Chip
            size="small"
            label={offerStatuses[rowData?.status]}
            className={"offer-status " + offerStatuses[rowData?.status]}
          />
        </>
      ),
    },
    // {
    //   title: "",
    //   field: "",
    //   width: "25px",
    //   sorting: false,
    //   cellStyle: {
    //     marginLeft: "auto",
    //     textAlign: "right",
    //   },
    //   render: rowData => (
    //     <ActionsMenu
    //       id={rowData?.id}
    //       status={rowData?.status}
    //       refresh={refreshTable}
    //       requestId={rowData?.request.id}
    //       contractId={rowData?.contract?.id}
    //     />
    //   ),
    // },
  ];

  return (
    <MaterialTable
      title=""
      tableRef={tableRef}
      options={{
        search: false,
        selection: false,
        toolbar: false,
        pageSize: pageSize,
        pageSizeOptions: [10, 20, 50, 100],
        cellStyle: {
          paddingLeft: "24px",
        },
        headerStyle: {
          paddingLeft: "24px",
        },
      }}
      onRowClick={(event, rowData) => handleRowClick(event, rowData?.id)}
      columns={offersColumns}
      data={data}
      components={{
        Container: props => (
          <Paper {...props} elevation={1} className="custom-table-container" />
        ),
        OverlayLoading: () => <div className="table-loader"><CircularProgress  /></div>
      }}
      style={{ boxShadow: "none", border: "1px solid #eee" }}
      localization={{
        body: {
          emptyDataSourceMessage: <NoResults
          title="No offers found!"
          subtitle="You don't have any offers at this moment. What's stopping you?"
          buttonLabel="Find Shipments"
          buttonAction={() => history.push('/find-shipments')}
        />
        }
      }}
    />
  );
}
