import React from "react";
import { Tabs, Tab, Chip } from "@material-ui/core";
import { Warning } from "@material-ui/icons";

export default function TabsComponent(props) {
  const { activeTab, onChange, unreadMessagesCount, isShipmentMissingDetails } = props;
  return (
    <Tabs
      value={activeTab}
      onChange={onChange}
      indicatorColor="primary"
      textColor="primary"
      className="headerTabs"
    >
      <Tab label="Overview" value="overview" />
      <Tab label={<>Shipment {isShipmentMissingDetails && <Warning className="tab-warning-icon" />}</>} value="shipment" className="chip-tab" />
      <Tab label={<>Messages {unreadMessagesCount > 0 && <span class="chip-label tab-chip">{unreadMessagesCount}</span>}</>} value="messages" className="chip-tab" />
      <Tab label="Documents" value="documents" />
    </Tabs>
  );
}
