import React, { useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Button, InputBase } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import InfiniteScroll from "react-infinite-scroll-component";

import APIKit from "../include/APIKit";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { Business, Person, Search as SearchIcon } from "@material-ui/icons";
import usePartiesSearch from "./parties/usePartiesSearch";

export default function PartiesDialog(props) {
  const { open, close, addAction } = props;

  const [addOpen, setAddOpen] = React.useState(false);
  const [methods, setMethods] = React.useState(null);
  const [defaultMethod, setDefaultMethod] = React.useState(null);
  const [selectedValue, setSelectedValue] = React.useState(null);

  const classes = useStyles();

  const handleAddClick = () => {
    setAddOpen(true);
  };

  const handleSelection = party => {
    props.onChange(open, party);
  };

  // start of infinite scroll

  const [query, setQuery] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(1);

  const { loading, parties, hasMore, error } = usePartiesSearch(
    query,
    pageNumber
  );

  const observer = useRef();
  let lastPartyRef = useCallback(
    node => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber(prevPageNumber => prevPageNumber + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  const handleSearchInputChange = event => {
    setQuery(event.target.value);
    setPageNumber(1);
  };

  // end of infinite scroll

  return (
    <React.Fragment>
      <Dialog
        onClose={close}
        open={!!open}
        maxWidth={"xs"}
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          Assign a party
          <div>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon style={{ fontSize: 20 }} />
              </div>
              <InputBase
                placeholder="Search parties"
                // value={filters}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent style={{ padding: 0, height: 400, position: 'relative' }} dividers>
          <List style={{ padding: 0 }}>
            {parties &&
              parties.map((party, index) =>
                parties.length === index + 1 ? (
                  <ListItem
                    button
                    onClick={() => handleSelection(party)}
                    key={index}
                    ref={lastPartyRef}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        {party.isOrganization ? <Business /> : <Person />}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={party.name}
                      secondary={party.initialAddress?.country?.name}
                    />
                  </ListItem>
                ) : (
                  <ListItem
                    button
                    onClick={() => handleSelection(party)}
                    key={index}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        {party.isOrganization ? <Business /> : <Person />}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={party.name}
                      secondary={party.initialAddress?.country?.name}
                    />
                  </ListItem>
                )
              )}
            
            
          </List>
          {error && <div style={{ paddingLeft: "16px" }}>Error</div>}
          {loading && <div className="full-loader"><CircularProgress  /></div>}
        </DialogContent>
        <DialogActions style={{ padding: 0, display: "block" }}>
          <ListItem autoFocus button onClick={addAction} style={{ height: 70 }}>
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Add new party" />
          </ListItem>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    marginTop: 15,
    width: "100% !important",
    //boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.13)',
    border: "1px solid #e0e0e0",
    background: "#fff",
    [theme.breakpoints.up("sm")]: {
      marginLeft: 0,
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(4),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#80878f",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
    padding: "2px 0px",
    paddingRight: "18px",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 4),
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "#80878f",
    // [theme.breakpoints.down('md')]: {
    //   width: 200,
    // },
  },
  list: {
    padding: "0px",
    margin: "18px 30px",
  },
}));
