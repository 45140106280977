import React from "react";
import { 
	Chip,
	Grid,
	Paper,

} from "@material-ui/core";
import { FCL, LCL } from "../../include/custom/CustomIcons";
import { DirectionsBoat, DirectionsCar, DirectionsRailway, Flight, Landscape } from "@material-ui/icons";
import moment from "moment";


export default function RequestInfo(props) {
	const { request } = props;
    const transportModes = ['', 'Ocean Freight', 'Land Freight', 'Air Freight'];
    const chipColor = ['', 'chip-blue', 'chip-brown'];
    const shipmentTypes = ['', 'RoRo', 'Less Than Container Load', 'Full Container Load', 'Bulk or Break Bulk'];
    const dateOptions = ['', 'On', 'Before', 'After'];

    const transportModeIcons = ['', <DirectionsBoat/>, <DirectionsRailway/>, <Flight/> ];
    const shipmentTypeIcons = ['', <DirectionsCar/>, <FCL />, <LCL />, <Landscape/> ]

	return (
		<Paper className={"white-card stripped coffer-rinfo"} >
			<p className="card-header">Request Information</p>
			<div className="card-body">

			<Grid container spacing={3}  >
				<Grid item>
				<div className="card-body-label-secondary">
					<Chip size="small" className={chipColor[request.freightType]} icon={transportModeIcons[request.freightType]} label={transportModes[request.freightType]} />
					<Chip size="small" className="chip-default" icon={shipmentTypeIcons[request.shipmentType]} label={shipmentTypes[request.shipmentType]} />
				</div>
				</Grid>
			</Grid>

			<Grid container spacing={3} >
				<Grid item >
				<p className="card-body-label" style={{marginTop: '20px'}}>Pickup {dateOptions[request.pickupDateOption]}: {moment(request.pickupDate).format('ll')}</p>
				<div className="countries-grid" style={{marginBottom: 20}}>
					<img src={request.routeOrigin.country.flag} className="grid-country-flag" />
					<div>
						<div className="item-title">
							{request.routeOrigin.description}
						</div>
					</div>
				</div>

				
				
				
				
				<p className="card-body-label">Deliver {dateOptions[request.deliveryDateOption]}: {moment(request.deliveryDate).format('ll')}</p>
				<div className="countries-grid">
					<img src={request.routeDestination.country.flag} className="grid-country-flag" />
					<div>
						<div className="item-title">
							{request.routeDestination.description}
						</div>
					</div>
				</div>
				</Grid>
			</Grid>
			</div>
		</Paper>
	)
}