import React, { useRef, useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Button, InputBase } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import InfiniteScroll from "react-infinite-scroll-component";

import APIKit from "../include/APIKit";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { Business, Person, Search as SearchIcon } from "@material-ui/icons";

export default function CarriersDialog(props) {
  const { open, close, addAction } = props;
  const [carriers, setCarriers] = useState(null)
  const classes = useStyles();

  useEffect(() => {
	getCarriers();
  }, [])

  const handleSelection = party => {
    props.onChange(open, party);
  };

  const getCarriers = () => {
	APIKit.get(`carriers`).then(result => {
	  if (result.status === 200) {
		if (result.data.isSuccess) {
		  setCarriers(result.data.record);
		}
	  }
	})
  }


//   const handleSearchInputChange = event => {
//     setQuery(event.target.value);
//     setPageNumber(1);
//   };


  return (
    <React.Fragment>
      <Dialog
        onClose={close}
        open={!!open}
        maxWidth={"xs"}
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          Assign a Carrier
          {/* <div>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon style={{ fontSize: 20 }} />
              </div>
              <InputBase
                placeholder="Search carriers"
                // value={filters}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={handleSearchInputChange}
              />
            </div>
          </div> */}
        </DialogTitle>
        <DialogContent style={{ padding: 0, height: 400 }} dividers>
          <List style={{ padding: 0 }}>
            {carriers ? 
              carriers.map((party, index) => (
                  <ListItem
                    button
                    onClick={() => handleSelection(party)}
                    key={index}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Business />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={party.name}
                      secondary={party.description}
                    />
                  </ListItem>
                )
              ) : (
				  <CircularProgress />
			  )}
          </List>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    marginTop: 15,
    width: "100% !important",
    //boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.13)',
    border: "1px solid #e0e0e0",
    background: "#fff",
    [theme.breakpoints.up("sm")]: {
      marginLeft: 0,
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(4),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#80878f",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
    padding: "2px 0px",
    paddingRight: "18px",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 4),
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "#80878f",
    // [theme.breakpoints.down('md')]: {
    //   width: 200,
    // },
  },
  list: {
    padding: "0px",
    margin: "18px 30px",
  },
}));
