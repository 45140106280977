import React, { createContext, useState } from "react";
import APIKit from "../include/APIKit";
import jwt_decode from 'jwt-decode';
import ActionDialog from "../components/ActionDialog";
import VerifyBankDialog from "../components/settings/payments/VerifyBankDialog";

export const UtilityContext = createContext();

export const UtilityProvider = ({ children }) => {
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [actionDialogProps, setActionDialogProps] = useState({
    title: "Are you sure?",
    content: "Please confirm the action you are about to take.",
    cancelText: "Cancel",
    confirmText: "Confirm"
  });

  const [verifyBankOpen, setVerifyBankOpen] = useState(false);
  const [verifyBankProps, setVerifyBankProps] = useState({
    id: null
  });

  const openActionDialog = (object) => {
    setActionDialogProps({...actionDialogProps, ...object});
    setActionDialogOpen(true);
  }

  const verifyBank = (id, callback) => {
    setVerifyBankProps({...verifyBankProps, id, callback})
    setVerifyBankOpen(true);
  }



  return (
    <UtilityContext.Provider
      value={{
        openActionDialog,
        verifyBank
      }}
    >
      {children}
      <ActionDialog
        open={actionDialogOpen}
        title={actionDialogProps.title}
        content={actionDialogProps.content}
        close={() => setActionDialogOpen(false)}
        confirmAction={() => {actionDialogProps.confirmAction(); setActionDialogOpen(false)}}
        action={actionDialogProps.action}
      />
      <VerifyBankDialog open={verifyBankOpen} id={verifyBankProps.id} callback={verifyBankProps.callback}  close={() => setVerifyBankOpen(false)} />
    </UtilityContext.Provider>
  );
};

export function useUtils() {
  return React.useContext(UtilityContext);
}