import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";

export default function AvatarComponent(props) {
  const { name, src, role, badge } = props;

  const avatarbgColor = role => {
    switch (role) {
      case "Owner":
        return "#00b437";
      case "Admin":
        return "#1d4ef5";
      case "Staff":
        return "#ffa312";
      default:
        return "#1d4ef5";
    }
  };
  return (
    <Badge color="secondary" variant="dot" invisible={badge} overlap="circular">
      <Avatar style={{ background: avatarbgColor(role) }} src={src}>
        {name &&
          name
            .match(/\b(\w)/g)
            .join("")
            .toUpperCase()}
      </Avatar>
    </Badge>
  );
}
