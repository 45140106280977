import React, { useState } from "react";
import Card from "../Card";
import SupplierSettingsForm from "./generalSettings/SupplierSettingsForm";
import SupplierSettingsList from "./generalSettings/SupplierSettingsList";

import { Button, Box, CircularProgress } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { HiPencil } from "react-icons/hi";

import APIKit from "../../include/APIKit";
import AlertComponent from "./generalSettings/Alert";

const style = {
  box: {
    padding: "22px 30px",
  },
};

export default function GeneralSettingsTab() {
  const [data, setData] = useState({
    businessType: "",
    shipmentTypes: [],
    freightMode: [],
    oceanCoverageFromType: "countries",
    oceanCoverageToType: "countries",
    oceanFromContinents: [],
    oceanFromCountries: [],
    landCoverageFromType: "countries",
    landCoverageToType: "countries",
    landFromContinents: [],
    landFromCountries: [],
  });

  const [alert, setAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = React.useState(false);

  const handleMultiToggle = (event, value, name) => {
    // setState({ ...state, [name]: value });
    setData({ ...data, [name]: value });
  };

  const edit = () => {
    setEditMode(true);
  };

  const save = () => {
    // APIKit.get("countries");
    setLoading(true);

    APIKit.get("fake")
      .then(res => {
        if (res.status === 200) {
          setLoading(false);
          setEditMode(false);
          setAlert({
            ...alert,
            open: true,
            severity: "success",
            message: "Updated successfully",
          });

          setTimeout(() => {
            setAlert({
              ...alert,
              open: false,
            });
          }, 5000);
        }
      })
      .catch(e => {
        setEditMode(false);
        setLoading(false);
        setAlert({
          ...alert,
          open: true,
          severity: "error",
          message: e.message,
        });

        setTimeout(() => {
          setAlert({
            ...alert,
            open: false,
          });
        }, 5000);
      });
  };

  const getDetails = () => {
    APIKit.get("Organizations/onboarding-details")
      .then(res => {
        if (res.status === 200) {
          setData(res.data.record);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    getDetails();
  }, []);

  return (
    <div className="tab-content">
      <AlertComponent
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
      />

      <br />
      <Card
        variant="white"
        bodyPadding={false}
        headerTitle="General settings"
        headerSubtitle="Change your default settings"
        headerAction={
          editMode ? (
            <Button
              color="primary"
              disableElevation
              startIcon={
                loading ? (
                  <CircularProgress style={{ width: 20, height: 20 }} />
                ) : (
                  <SaveIcon style={{ fontSize: 20 }} />
                )
              }
              onClick={() => save()}
            >
              {loading ? "Updating changes..." : "Update"}
            </Button>
          ) : (
            <Button
              color="primary"
              disableElevation
              startIcon={<HiPencil style={{ fontSize: 20 }} />}
              onClick={() => edit()}
            >
              Edit
            </Button>
          )
        }
      >
        <Box style={style.box}>
          {editMode ? (
            <SupplierSettingsForm
              handleMultiToggle={handleMultiToggle}
              data={data}
              setData={setData}
            />
          ) : (
            <SupplierSettingsList
              companyType={data.businessType}
              shipmentTypes={""}
              transportModes={""}
            />
          )}
        </Box>
      </Card>
    </div>
  );
}
