import React from "react";
import MaterialTable from "material-table";

import { Paper, Chip, Avatar, Tooltip, CircularProgress } from "@material-ui/core";
import moment from "moment";
import ActionsMenu from "./ActionsMenu";
import Rating from "@material-ui/lab/Rating";
import { useHistory } from "react-router";
import APIKit from "../../include/APIKit";
import { useStore } from "../../context/StoreContext";
import { DirectionsBoat, DirectionsCar, DirectionsRailway, Landscape } from "@material-ui/icons";
import NoResults from "../NoResults";

export default function ContractsTable({
  data,
  freightTypes,
  shipmentTypes,
  pageSize,
  tableRef,
  refreshTable,
  search,
  openDialog,
  setOpenDialog,
}) {
  const history = useHistory();
  const {accountType} = useStore();

  const handleOpenPaymentDetails = () => {
    console.log("open");
    setOpenDialog(true);
  };

  const showAlert = (variant, message) => {
    this.props.enqueueSnackbar(message, {
      variant: variant,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  const bulkUnlist = (event, data) => {
    let ids = [];
    for (var i in data) {
      if (data[i].requestStatus === 2 || data[i].requestStatus === 3) {
        showAlert(
          "warning",
          "You cannot modify assigned or completed requests!"
        );
        return null;
      }
      if (data[i].requestStatus === 4) {
        showAlert("warning", "You cannot unlist already cancelled requests!");
        return null;
      }
      ids.push(data[i].id);
    }
    APIKit.post("requests/UpdateListing", ids)
      .then(result => {
        if (result.status === 200) {
          showAlert("success", "Successfully Updated!");
          refreshTable();
        }
      })
      .catch(e => {});
  };

  const bulkDelete = (event, data) => {
    let ids = [];
    for (var i in data) {
      if (data[i].requestStatus === 2 || data[i].requestStatus === 3) {
        this.showAlert(
          "warning",
          "You cannot delete assigned or completed requests!"
        );
        return null;
      }
      ids.push(data[i].id);
    }
    APIKit.post("requests/delete", ids)
      .then(result => {
        if (result.status === 200) {
          this.refreshTable();
        }
      })
      .catch(e => {});
  };

  const handleRowClick = (event, id) => {
    history.push("/contract/" + id + "/overview");
  };

  const contractStatus = [
    "",
    "Active",
    "Cancelled",
    "Expired",
    "Delivered",
    "Completed",
    "Conflict",
  ];

  const nameInitials = (name) => {
    return name.match(/\b(\w)/g)
    .join("")
    .toUpperCase()
  }

  const typeIcons = [
    "",
    <DirectionsCar className="shipment-icon-big" />,
    <svg
      className="MuiSvgIcon-root shipment-icon-big customIcon"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M21.5,3.5h-19c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h19c1.1,0,2-0.9,2-2v-13C23.5,4.4,22.6,3.5,21.5,3.5z M21.5,18.5h-19v-13h19V18.5zM3.5, 12.5 h17 v5 h-17z"></path>
    </svg>,
    <svg
      className="MuiSvgIcon-root shipment-icon-big customIcon"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M21.5,3.5h-19c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h19c1.1,0,2-0.9,2-2v-13C23.5,4.4,22.6,3.5,21.5,3.5z M21.5,18.5h-19v-13h19V18.5zM3.5, 6.5 h17 v11 h-17z"></path>
    </svg>,
    <Landscape className="shipment-icon-big" />,
  ];

  const shipmentColumns = [
    {
      title: "Contract",
      width: "20%",
      sorting: false,
      render: rowData => (
        <div className="icon-grid">
          <div style={{marginRight: 10}}>
            {/* <Avatar className="supplier-pic" src={accountType == 1 ? rowData.client.logoUrl : rowData.supplier.logoUrl}>
              {nameInitials(accountType == 1 ? rowData.client.fullName : rowData.supplier.fullName)}
            </Avatar> */}
              <div className="iconComplex">
                {typeIcons[rowData.shipmentType]}
                {rowData.freightType == 1 && (
                  <DirectionsBoat className="shipment-icon-small" />
                )}
                {rowData.freightType == 2 && (
                  <DirectionsRailway className="shipment-icon-small land" />
                )}
              </div>
          </div>
          <div>
            <div className="item-title item-ref">
              {rowData.number}
            </div>
            <div className="item-date">
              {moment(rowData.startDate).format("LL")}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Origin",
      sorting: false,
      width: "25%",
      render: rowData => (
        <div className="countries-grid">
          <img src={rowData.originAddress.country.flag} className="grid-country-flag" />
          <div>
            <div className="item-title">
              {rowData.originAddress.description || rowData.originAddress.country?.name}
            </div>
            <div className="item-date">
              {rowData.originAddress.isPort ? "Port" : "Address"}
            </div>
          </div>
        </div>
      ),
    },

    {
      title: "Destination",
      sorting: false,
      width: "25%",
      render: rowData => (
        <div className="countries-grid">
          <img src={rowData.destinationAddress.country.flag} className="grid-country-flag" />
          <div>
            <div className="item-title">
              {rowData.destinationAddress.description || rowData.destinationAddress.country?.name}
            </div>
            <div className="item-date">
              {rowData.destinationAddress.isPort ? "Port" : "Address"}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Offer",
      width: "20%",
      sorting: false,
      render: rowData => (
        <div className="">
          <div className="item-title item-ref item-price">${rowData.totalAmount}</div>
          <div className="item-date">
            {rowData.client.averageRating ? (
              <Rating name="read-only" value={3} readOnly size="small" />
            ) : (
              "Not rated"
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Status",
      width: "10%",
      sorting: false,
      render: rowData => (
        <Chip
          size="small"
          label={contractStatus[rowData.status]}
          className={"contract-status " + contractStatus[rowData.status]}
        />
      ),
    },
    // {
    //   title: "",
    //   field: "",
    //   width: "25px",
    //   sorting: false,
    //   cellStyle: {
    //     textAlign: "right",
    //   },
    //   render: rowData => (
    //     <ActionsMenu
    //       id={rowData.id}
    //       status={rowData.status}
    //       refresh={refreshTable}
    //     />
    //   ),
    // },
  ];

  return (
    <div>
      <MaterialTable
        title="My Contracts"
        tableRef={tableRef}
        options={{
          search: false,
          selection: false,
          toolbar: false,
          pageSize: pageSize,
          pageSizeOptions: [10, 20, 50, 100],
          cellStyle: {
            paddingLeft: "24px",
          },
          headerStyle: {
            paddingLeft: "24px",
          },
          rowStyle: rowData => ({
            backgroundColor: rowData.isSeen ? "#fff" : "#f1f6fd"
          })
        }}
        onRowClick={(event, rowData) => handleRowClick(event, rowData.id)}
        columns={shipmentColumns}
        data={search}
        components={{
          Container: props => (
            <Paper
              {...props}
              elevation={1}
              className="custom-table-container"
            />
          ),
          OverlayLoading: () => <div className="table-loader"><CircularProgress  /></div>
        }}
        style={{ boxShadow: "none", border: "1px solid #eee" }}
        actions={[
          {
            tooltip: "Show payment details",
            icon: "payment",
            position: "toolbar",
            onClick: handleOpenPaymentDetails,
          },
          //   {
          //     tooltip: "Unlist",
          //     icon: "cancel",
          //     position: "toolbarOnSelect",
          //     onClick: (event, data) => bulkUnlist(event, data),
          //   },
          //   {
          //     tooltip: "Delete",
          //     icon: "delete",
          //     position: "toolbarOnSelect",
          //     onClick: (event, data) => bulkDelete(event, data),
          //   },
        ]}
        
        localization={{
          body: {
            emptyDataSourceMessage: <NoResults
            title="Oh Lord!"
            subtitle="You don't have any contracts at this moment. What's stopping you?"
            buttonLabel={accountType == 1 ? "Find Shipments" : "Create a Request"}
            buttonAction={() => history.push(accountType == 1 ? '/find-shipments' : '/requests/create')}
          />
          }
        }}
      />
    </div>
  );
}
