import React from 'react';
import PropTypes from 'prop-types'
import { Button, IconButton, Paper, Tooltip } from '@material-ui/core';
import { Edit, Error, PanTool, PersonAdd } from '@material-ui/icons';
import { Alert, AlertTitle, Skeleton } from '@material-ui/lab';
import { useStore } from '../../context/StoreContext';

export default function PartyBox(props) {
	const {type, title, party, missing, openDialog, loading, edit, status, sameAsOwner, setSameAsOwner} = props;
	const { accountType } = useStore();

	const canUpdate = (accountType == 2 && status != 5) ? true : false;

	return (
		<Paper className={"white-card "} >
			<div className="card-header wbtn">
				{loading ? <Skeleton variant="text" width="78px" /> : title}
				{canUpdate && 
					<div className="actions-panel">
						{!party?.sameAsOwner && party && <Tooltip title="Edit Party"><IconButton onClick={edit} ><Edit fontSize="small" color="primary" /></IconButton></Tooltip>}
						{!party?.sameAsOwner &&
							<Tooltip title={`I am the ${title}`} >
								<IconButton aria-label="delete" onClick={() => setSameAsOwner(title.toLowerCase(), {sameAsOwner: true})} >
									<PanTool style={{fontSize: 17}} color={"primary"} />
								</IconButton>
							</Tooltip>
						}

						<Tooltip title="Assign Party"><IconButton aria-label="delete" onClick={() => openDialog(true)} ><PersonAdd fontSize="small" color="primary" /></IconButton></Tooltip>
					</div>
				}
			</div>
			<div className="card-body">
				{missing ? (
					<div>
						<Alert severity="warning"
							action={
								canUpdate && 
								<Button color="inherit" size="small" onClick={() => openDialog(true)}>
									Assign
								</Button>
							}
						>
							Not assigned yet!
						</Alert>
                        
					</div>
				) : (
					<div className="party-item">
						{party?.sameAsOwner && accountType == 2 ? (
							<div>You're the {title.toLowerCase()} of this shipment.</div>
						) : (
							<>
								<div className="party-name">
									{loading ? <Skeleton variant="text" width="40%" /> : party?.name}
								</div>
								<div className="party-address">
									{loading ? 
										<Skeleton variant="text" width="80%" />
										: 
										<React.Fragment>
											{party?.initialAddress?.addressLine1} 
											<br/>
											{party?.initialAddress?.city} {party?.initialAddress?.country?.name}
										</React.Fragment>
									}
								</div>
								<div className="party-contact">{loading ? <Skeleton variant="text" width="50%" /> :""}</div>
							</>
						)}
						
					</div>
				)}
			</div>
		</Paper>
	)
}

PartyBox.propTypes = {
  title: PropTypes.string,
  party: PropTypes.object,
  type: PropTypes.string,
  missing: PropTypes.bool,
};
