import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import APIKit from "../../include/APIKit";

import Page from "../../containers/Page";
import Figures from "../../components/Dashboard/Figures";
import SupplierOffers from "../../components/Dashboard/SupplierOffers";
import InviteCollab from "../../components/Dashboard/InviteCollab";
import ShipmentFigures from "../../components/Dashboard/ShipmentFigures";
import ActiveContracts from "../../components/Dashboard/ActiveContracts";
import Actions from "../../components/Dashboard/Actions";

export default function Dashboard() {
  const [contracts, setContracts] = useState([]);
  const [requests, setRequests] = useState([]);
  const [activeTab, setActiveTab] = useState(1);

  const [requestsNumber, setRequestsNumber] = useState(0);
  const [pageSize, setPageSize] = useState(42);

  // let pendingRequests, ActiveRequests;
  const [pendingRequests, setPendingRequests] = useState([]);
  const [ActiveRequests, setActiveRequests] = useState([]);

  const search = query => {
    APIKit.post("contracts")
      .then(result => {
        if (result.status === 200) {
          // result.data.record.contracts.items.filter(contract => {
          //   setContracts(() => contract.status === 1);
          // });
          setContracts(() => result.data.record.contracts);
        }
      })
      .catch(err => {});
    let body = {
      pageSize: pageSize,
      pageNumber: 1,
    };
    APIKit.post("requests/search", body)
      .then(result => {
        if (result.status === 200) {
          setRequestsNumber(result.data.record.totalItems);
          setPendingRequests(
            result.data.record.items.filter(request => {
              return request.requestStatus === 1;
            })
          );
          setActiveRequests(
            result.data.record.items.filter(request => {
              return request.requestStatus === 2;
            })
          );
          console.log("activeTab in parent is " + activeTab);
        }
      })
      .catch(err => {});
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    activeTab === 1
      ? setRequests(() => pendingRequests)
      : setRequests(() => ActiveRequests);
  }, [ActiveRequests, activeTab, pendingRequests]);

  return (
    <Page navigation={true}>
      <Grid container spacing={3}>
        <Grid item xs={9} container direction="column" spacing={2}>
          <Grid item>
            <Figures
              requestNumber={requestsNumber}
              requests={requests}
              contracts={contracts}
              figureTitle1="Total Revenues"
              figureTitle2="Total Shipped units"
            />
          </Grid>
          <Grid item>
            <SupplierOffers contracts={contracts} />
          </Grid>
          <Grid item>
            <ActiveContracts contracts={contracts} />
          </Grid>
        </Grid>
        <Grid item xs={3} container direction="column" spacing={2}>
          <Grid item>
            <Actions value={4} />
          </Grid>
          <Grid item>
            <ShipmentFigures />
          </Grid>
          <Grid item>
            <InviteCollab />
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
}
