import React from "react";
import PaymentMethodsTab from "./PaymentMethodsTab";
import GeneralSettingsTab from "./GeneralSettingsTab";
import ProfileCompanyInfo from "./ProfileCompanyInfo";
import MembersPermissions from "./MembersPermissions";
import PasswordSecurity from "./PasswordSecurity";
import Account from "./Account";

export default function TabContent(props) {
  const renderTabContent = () => {
    switch (props.tab) {
      case "payment-methods":
        return <PaymentMethodsTab />;
      case "general-settings":
        return <GeneralSettingsTab />;
      case "profile-company-info":
        return <ProfileCompanyInfo />;
      case "members-permissions":
        return <MembersPermissions />;
      case "password-and-security":
        return <PasswordSecurity />;
      case "account":
        return <Account />;
      default:
        return <div>No content</div>;
    }
  };
  return <div className="tab-content">{renderTabContent()}</div>;
}
