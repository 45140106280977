import React from "react";
import Button from '@material-ui/core/Button';
import Page from "../containers/Page";



function NotFound(props) {
  

  return (
    <Page navigation={true}>
      <div className="empty-state">
        <div className="img-holder">
          <img src="../img/empty.svg" alt="notfound" />
        </div>
        <div className="title darker">Page doesn't exist</div>
        <div className="info">Sorry, but the page your trying to access is either not found or you dont have permission to access it.</div>
        <br/> 
        <Button variant="contained" disableElevation color="primary">Go Home</Button>
      </div>
    </Page>
  );
}

export default NotFound;
