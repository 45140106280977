import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import {
  Person as PersonIcon,
  Business as OfficeIcon,
  //   SettingsIcon,
} from "@material-ui/icons";
import SettingsIcon from "@material-ui/icons/Settings";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

export default function Tabs({ activeTab, setTab }) {
  const handleToggle = value => {
    if (value) {
      setTab(value);
    }
  };

  return (
    <div className="tabs-container">
      <ToggleButtonGroup
        value={activeTab}
        className="tabToggler"
        exclusive
        onChange={(event, value) => handleToggle(value)}
      >
        <ToggleButton value="payment-methods">
          <OfficeIcon /> Payment Methods
        </ToggleButton>
        <ToggleButton value="general-settings">
          <SettingsIcon /> General Settings
        </ToggleButton>
        <ToggleButton value="profile-company-info">
          <SupervisorAccountIcon /> Profile and Company Info
        </ToggleButton>
        <ToggleButton value="password-and-security">
          <VpnKeyIcon /> Password and Security
        </ToggleButton>
        <ToggleButton value="members-permissions">
          <LockRoundedIcon /> Members and Permissions
        </ToggleButton>
        <ToggleButton value="account">
          <PersonIcon /> Account
        </ToggleButton>
        <ToggleButton value="notifications">
          <PersonIcon /> Notifications
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
