import React, { useEffect } from "react";
import { 
	TextField,
	FormControlLabel,
	Checkbox,
	Select,
	FormControl,
	MenuItem
} from "@material-ui/core";
import Card from "../Card";

export default function ServicesTab (props) {
	const { 
		request,
		setRequest,
		selectedServices,
		setSelectedServices,
		services
	} = props;

	const handleServicesCheckbox = (event, service) => {
		
		setSelectedServices(
			selectedServices.filter(c => c.id === service.id)[0]
			? selectedServices.filter(c => c.id !== service.id)
			: [...selectedServices, service]
		)

		// setState(state => ({
		// selectedServices: state.selectedServices.includes(id)
		// 	? state.selectedServices.filter(c => c !== id)
		// 	: [...state.selectedServices, id]
		// }), () => {
		// this.setState({request: { ...this.state.request, serviceIds: this.state.selectedServices }}, ()=>{ console.log(this.state)});
		// });
		
	}

	const onServiceFieldChange = (value, fieldIndex, serviceId) => {
		let tempServices = [...selectedServices];
		const ssIndex = tempServices.findIndex(ss => ss.id === serviceId);
		tempServices[ssIndex].fields[fieldIndex].value = value;
		setSelectedServices(tempServices);
	}



	return (
		<Card 
			headerTitle="Notes & Additional Services"
		>
			<div className="form-box">
				<p className="card-body-label">Notes</p>
				<TextField label="Notes or Special Requirements" variant="outlined" name="description" value={request.description || ''} onChange={event => setRequest({...request, description: event.target.value})} fullWidth multiline rows={4} />
			</div>
			<div className="form-box">
				<p className="card-body-label" style={{marginBottom: 3}}>Currency</p>
				<span className="sub-label">You'll receive offers and pay in the selected currency</span>
				<FormControl variant="outlined" size="small">
					<Select
						onChange={event => setRequest({...request, currency: {...request.currency, id: event.target.value}})}
						name="currency"
						value={request.currency?.id}
					>
						<MenuItem value={1}>USD</MenuItem>
						<MenuItem value={2}>EUR</MenuItem>
					</Select>
				</FormControl>
			</div><br/>
			<p className="card-body-label">Additional Services</p>
			{services && services.map((service, serviceIndex) => (
				
				<div className="services-box" key={service.id}>
						<FormControlLabel control={  <Checkbox checked={request.services ? request.services.filter(c => c.id === service.id)[0] : false} onChange={(event, ind) => handleServicesCheckbox(event, service)} name="services" color="primary" /> } label={
						<div className="checkbox-label">
							<span className="checbox-title">{service.name}</span>
							<span className="checbox-info">{service.description}</span>
						</div>
					}  />
					{request.services.filter(c => c.id === service.id)[0] && (
						service.fields && <div className="service-fields">
							
							{service.fields.map((field, fieldIndex) => (
								<TextField 
									label={field.name} 
									value={field.value} 
									onChange={(event, _index, _serviceId) => onServiceFieldChange(event.target.value, fieldIndex, service.id)} 
									variant="outlined" 
									type={field.type} 
									size="small"  
									InputLabelProps={{ shrink: true, required: true }} 
									placeholder={field.placeholder} 
									required={field.isRequired}
									className="service-field"
								/>
							))}
						</div>
					)}
				</div>
				
			))}	
		</Card>
	)
}
