import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";

export default function paymentCard({ open, setOpen }) {
  const style = {
    title: {
      fontSize: "16px",
      fontWeight: "500",
      color: "#80878f",
    },
    stat: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "10px 0",
    },
    numbers: {
      fontSize: "1.5rem",
      fontWeight: "500",
    },
  };

  const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="simple-dialog-title" onClose={handleClose}>
        Payment details
      </DialogTitle>

      <DialogContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <div style={style.title}>Paid</div>
          <div>1673827$</div>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <div style={style.title}>Not Paid</div>
          <div>1673827$</div>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
