import React, { Component } from "react";
import { compose, withProps, lifecycle } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import fetch from "isomorphic-fetch";
import { Skeleton } from "@material-ui/lab";
// import pickupMarker from "../img/pickupMarker.svg";

// import vector from '../img/deliverMarker.png';

const MapWithAMarkers = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCBF4_ctmHoMx5RRA9R9I61zRO7bNwC8BM",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `250px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  lifecycle({
    componentDidMount() {
      console.log(this.props.markers);

      this.setState({
        zoomToMarkers: map => {
          console.log("Zoom to markers");
          console.log(this.props.markers);
          const bounds = new window.google.maps.LatLngBounds();
          map &&
            map.props.children.forEach(child => {
              if (child.type === Marker) {
                bounds.extend(
                  new window.google.maps.LatLng(
                    child.props.position.lat,
                    child.props.position.lng
                  )
                );
              }
            });
          map && map.fitBounds(bounds);
        },
      });
    },
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    ref={props.zoomToMarkers}
    defaultZoom={5}
    defaultCenter={{ lat: 25.0391667, lng: 121.525 }}
    options={{
      styles: exampleMapStyles,
      streetViewControl: false,
      zoomControl: true,
      disableDefaultUI: true,
      fullscreenControl: props.fullscreenControl,
      fullscreenControlOptions: {
        position:
          window.google.maps.ControlPosition[props.fullscreenControlPosition],
      },
    }}
  >
    {props.markers.map(marker => (
      <Marker
        key={marker.id}
        position={{ lat: marker.lat, lng: marker.lng }}
        icon={{
          url:
            marker.id == 1
              ? "/img/pickupMarker.svg"
              : "/img/deliveryMarker.svg",
          scaledSize: new window.google.maps.Size(42, 42),
          anchor: new window.google.maps.Point(20, 20),
        }}
      />
    ))}
  </GoogleMap>
));

class MapComponent extends Component {
  render() {
    return this.props.markers.length > 0 ? (
      <MapWithAMarkers
        markers={this.props.markers}
        fullscreenControl={this.props.fullscreenControl}
        fullscreenControlPosition={this.props.fullscreenControlPosition}
      />
    ) : null;
    //<div style={{ height: `300px`, backgroundColor: `#e5e3de` }} />
  }
}

export default MapComponent;

let $main_color = "#19527d",
  $saturation = -20,
  $brightness = 5;
const exampleMapStyles = [
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#e9e9e9",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#dedede",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ffffff",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        saturation: 36,
      },
      {
        color: "#333333",
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#f2f2f2",
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#fefefe",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#fefefe",
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
];
