import React from 'react';
import { List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';


export default function PaymentMethodsListSkeleton(props) {
	let arr = new Array(5).fill(null);
	return (
		<List style={{padding: 0}} className="payment-methods-list">

			{arr.map((ar, index) => (
				<ListItem key={index}   style={{paddingLeft: 30, paddingRight: 18, borderBottom: '1px solid #f1f1f1', opacity: (100-(index*20))/100}} >
					<ListItemAvatar  >
						<Skeleton variant="rect" width={35} height={35} style={{borderRadius: 3}} />
					</ListItemAvatar>
					<ListItemText 
						primary={<Skeleton variant="text" width="40%" />} 
						secondary={<Skeleton variant="text" width="20%" />} 
					/>
				</ListItem>
			))}
			
		</List>
	)
}