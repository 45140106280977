import React, { useEffect, useState } from "react";
import APIKit from "../include/APIKit";
import SupplierDashboard from "../pages/Supplier/Dashboard";
import ShipperDashboard from "../pages/Client/Dashboard";
import { useStore } from "../context/StoreContext";

export default function Dashboard() {
  const {accountType} = useStore();

  return accountType === 1 ? <SupplierDashboard /> : <ShipperDashboard />;
}
