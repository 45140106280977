import React from "react";
import EditableRow from "./EdiatbleRow";
import ShipmentItems from "./ShipmentItems";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export default function ShipmentsTab(props) {
  const { items, shipmentType, contract } = props;

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <div className="page-content tab">
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          Something went wrong please try again
        </Alert>
      </Snackbar>

      {items && contract && (
        <ShipmentItems
          items={items}
          shipmentType={shipmentType}
          contract={contract}
          setOpenSnackbar={setOpenSnackbar}
          status={contract?.status}
        />
      )}
    </div>
  );
}
