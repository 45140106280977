import React, { useEffect, useState } from "react";

import { Button, CircularProgress } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { HiPencil } from "react-icons/hi";

import ChangePassForm from "./passwordSettings/ChangePassForm";
import APIKit from "../../include/APIKit";
import Card from "../Card";
import PassInfo from "./passwordSettings/PassInfo";

export default function PasswordSecurity() {
  const [loading, setLoading] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);

  const [password, setPassword] = useState({
    currentPassword: {
      value: "",
      error: null,
      showPass: false,
    },
    newPassword: {
      value: "",
      error: null,
      showPass: false,
    },
    confirmPassword: {
      value: "",
      error: null,
      showPass: false,
    },
  });

  const edit = () => {
    setEditMode(true);
  };

  const save = () => {
    setLoading(true);
    APIKit.put("data")
      .then(result => {
        if (result.status === 200) {
          setLoading(false);
          setEditMode(false);
          setPassword({
            ...password,
            newPassword: {
              ...password.newPassword,
              value: result.newPassword,
            },
          });
        }
      })
      .catch(err => {
        setLoading(false);
        setEditMode(false);
        alert("Something went wrong");
      });
  };
  useEffect(() => {
    console.log(password);
  }, [password]);

  return (
    <div className="tab-content">
      <Card
        variant="white"
        bodyPadding={false}
        headerTitle="Password and Security settings"
        headerSubtitle="Change or reset your password"
        headerAction={
          editMode ? (
            <Button
              color="primary"
              disableElevation
              startIcon={
                loading ? (
                  <CircularProgress style={{ width: 20, height: 20 }} />
                ) : (
                  <SaveIcon style={{ fontSize: 20 }} />
                )
              }
              onClick={() => save()}
            >
              {loading ? "Saving changes..." : "Save changes"}
            </Button>
          ) : (
            <Button
              color="primary"
              disableElevation
              startIcon={<HiPencil style={{ fontSize: 20 }} />}
              onClick={() => edit()}
            >
              Change password
            </Button>
          )
        }
      >
        {editMode ? (
          <ChangePassForm password={password} setPassword={setPassword} />
        ) : (
          <PassInfo />
        )}
      </Card>
    </div>
  );
}
