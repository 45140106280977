import React, { useEffect, useState } from "react";
import ConfirmDialog from "../ConfirmDialog";
import { Alert } from "@material-ui/lab";
import { Button, Paper, TextField } from "@material-ui/core";
import APIKit from "../../include/APIKit";
import _get from "lodash/get";
import RatingStars from "./RatingStars";

export default function RatingPreview({reviews, accountType}) {
	return (
		<Paper className={"white-card "}>
			<div
				className="card-header"
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
			Rating & Reviews
			</div>
			<div className="card-body" style={{paddingTop: 10}}>

				<ReviewItem title={`${accountType == 2 ? "Supplier's " : "Client's "} Review`} review={_get(reviews, accountType == 2 ? 'supplier' : 'shipper')} />
				<hr className="hr" />
				<ReviewItem title="Your Review" review={_get(reviews, accountType == 1 ? 'supplier' : 'shipper')} />

				
			</div>
		</Paper>
	)
}

const ReviewItem = ({review, title}) => {
	return (
		<>
			<div style={{
				display:'flex',
				flexDirection: 'row',
				alignItems: 'center'
			}}>
				<span 
					style={{
						flexGrow: 1,
						width: '50%',
						fontSize: 12,
						textTransform: 'uppercase',
						fontWeight: 500,
						color: '#7e878f',
						paddingTop: 3
					}}
				>{title}</span>
				<RatingStars rating={review?.rate} />
			</div>
			<p style={{margin: 0}}>{review?.description}</p>
		</>
	)
}