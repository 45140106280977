/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Tooltip,
} from "@material-ui/core";
import { Add, Check, Close, Delete as DeleteIcon, Edit } from "@material-ui/icons";
import CategoriesAutocomplete from "../CategoriesAutocomplete";
import EditableCel from "../EditableCell";
import { Forklift, ForkliftFilled } from "../../include/custom/CustomIcons";
import APIKit from "../../include/APIKit";
import { FiCheck, FiX } from "react-icons/fi";
import { useStore } from "../../context/StoreContext";

export default function ShipmentItems(props) {
  const { shipmentType, grossWeight, volume, contract, setOpenSnackbar, status } =
    props;

  const [items, setItems] = useState(props.items);
  const { accountType } = useStore();
  // const [canEdit, setCanEdit] = useState(accountType == 1 ? false : true);

  const canEdit = (accountType == 1 && status != 5 && shipmentType != 1) || (accountType == 2 && status != 5 && shipmentType == 1)  ? true : false;

  const [activeRow, setActiveRow] = useState(0);

  const [countries, setCountries] = useState([]);
  
  const packagingTypes = ["","Pallet","Crate","Box","Bag","Bale","Bundle","Can"]


  React.useEffect(() => {
    if(shipmentType == 1) {
      getCountries();
    }
  }, []);

  React.useEffect(() => {}, [accountType]);

  const getCountries = () => {
    APIKit.get("countries")
      .then(result => {
        if (result.status === 200) {
         setCountries(result.data.record);
        }
      })
      .catch(e => {});
  }

  const saveItem = newItem => {
    newItem.error = {};
    // newItem.loading = name;

    APIKit.put("/contracts/item", newItem)
      .then(res => {
        newItem.loading = false;
      })
      .catch(e => {
        // open a snackbar
        setOpenSnackbar(true);
        newItem.state = "error";
        newItem.loading = false;
        // newItem.error[name] = true;
        newItem.state = "error";
        setItems(items.map(i => (i.id !== newItem.id ? i : newItem)));
        console.log(e);
      });
  };

  const onChange = (newItem, name) => {
    // change the value of each field depending on his id and place it in the items array
    setItems(items.map(i => (i.id !== newItem.id ? i : newItem)));
  };

  const ShipmentItem = props => {
    const { index, item } = props;

    const [tempItem, setTempItem] = useState(item);
    const [mobilityCheck, setMobilityCheck] = useState(tempItem?.forklifted);

    const handleMobilityCheck = () => {
      activeRow && setMobilityCheck(prev => !prev);
    };
    const handleOnChange = (name, value) => {
      console.log(name, value)
      setTempItem({ ...tempItem, [name]: value });
    };

    const rowId = item.id;

    const onChange = (value, name) => {
      const newItem = item;
      newItem[name] = value;
      props.onChange(newItem, name);
    };

    // let cells = [];

    // shipmentType === 1
    //   ? (cells = [
    //       { name: "description", required: true },
    //       { name: "vin", required: true },
    //       { name: "loadingDate", required: true },
    //       { name: "value", required: true },
    //       {
    //         name: "country",
    //         required: true,
    //         value: contract?.routeOrigin.country.name,
    //       },
    //       { name: "length", required: true },
    //       { name: "width", required: true },
    //       { name: "height", required: true },
    //       { name: "grossWeight", required: true },
    //     ])
    //   : (cells = [
    //       { name: "description", required: true },
    //       { name: "referenceNumber", required: true },
    //     ]);
    
    const fields = () => { switch(shipmentType) {
      case 1: //roro
        return [
          { name: "description", required: true, editable: false, width: '25%' },
          { name: "reference", required: true, width: '25%' },
          { name: "registrationYear", required: true, type: "number", minValue: 1900, maxValue: 2030, width: '15%' },
          { name: "vehicleValue", required: true, placeholder: contract?.payment?.currency?.symbol, width: '15%' },
          {
            name: "countryOfOrigin",
            required: true,
            options: countries,
            type: "country",
            width: '20%'
          },
          // { name: "length", required: true, editable: false },
          // { name: "width", required: true, editable: false },
          // { name: "height", required: true, editable: false },
          // { name: "grossWeight", required: true, editable: false },
        ]
      case 2: //lcl
        return [
          { name: "category", sub: "name", required: false, editable: false },
          { name: "packagingType", enum: packagingTypes, required: false, editable: false },
          { name: "description", required: true },
          { name: "reference", required: true },
        ]
      case 3: //fcl
        return [
          { name: "container", sub: "name", required: false, editable: false },
          { name: "category", sub: "name", required: false, editable: false },
          { name: "grossWeight", required: false, editable: false },
          { name: "description", required: true},
          { name: "reference", required: true },
        ]
      case 4: // bulk
        return [
          { name: "category", sub: "name", required: false, editable: false },
          { name: "description", required: true },
          { name: "volume", editable: false},
          { name: "grossWeight", editable: false},
          { name: "reference", required: true },
        ]
      default:
        return []
    }}

    const cells = fields();

    return (
      <TableRow key={index}>
        {cells.map(cell => (
          <EditableCel
            key={index}
            value={cell.value ? cell.value : (
              cell.sub ? (
                cell.enum ? cell.enum[tempItem[cell.name]?.[cell.sub]] : tempItem[cell.name]?.[cell.sub]
              ) : cell.enum ? cell.enum[tempItem[cell.name]] : tempItem[cell.name]
            )}
            name={cell.name}
            width={cell.width}
            loading={tempItem.loading}
            error={tempItem.error}
            state={tempItem.state}
            required={cell.required}
            onChange={(value, name) => onChange(value, name)}
            editMode={rowId === activeRow}
            setActiveRow={setActiveRow}
            handleOnChange={handleOnChange}
            setItems={setItems}
            items={items}
            tempItem={tempItem}
            saveItem={saveItem}
            editable={cell.editable === false ? false : true}
            canEdit={canEdit}
            type={cell.type}
            minValue={cell.minValue}
            maxValue={cell.maxValue}
            options={cell.options}
            placeholder={cell.placeholder}
          />
        ))}

        {/* <TableCell width="50px" className="editable-cell">
          <Tooltip title={mobilityCheck ? "Forklifted" : "Not forklifted"}>
            <Checkbox
              color="primary"
              icon={<Forklift />}
              checkedIcon={<ForkliftFilled color="#1c4ef5" />}
              checked={mobilityCheck}
              onChange={handleMobilityCheck}
            />
          </Tooltip>
        </TableCell> */}

        {canEdit && (
          <>
            <TableCell width="10%">
              {rowId !== activeRow ? (
                <IconButton
                  onClick={e => setActiveRow(rowId)}
                  className="editable-cell-button"
                  disabled={tempItem.loading}
                >
                  {tempItem.loading ? (
                    <CircularProgress size={19} />
                  ) : (
                    <Edit style={{ fontSize: 18 }} />
                  )}
                </IconButton>
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <IconButton
                    aria-label="saveRowItems"
                    onClick={() => {
                      setActiveRow(null);
                      setItems(
                        items.map(i => (i.id !== tempItem.id ? i : tempItem))
                      );
                      saveItem(tempItem);
                    }}
                  >
                    <Check style={{ fontSize: 18 }} />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setActiveRow(null);
                      setTempItem(item);
                    }}
                  >
                    <Close style={{ fontSize: 18 }} />
                  </IconButton>
                </Box>
              )}
            </TableCell>
          </>
        )}
      </TableRow>
    );
  };

  const getTableHeader = shipmentType => {
    const headerCells = [
      [
        "Description",
        "VIN",
        "Year",
        "Value ("+contract?.payment?.currency?.code+")",
        "Origin Country",
        // contract.routeOrigin?.country.isoCode === "US" && "State",
        // contract.routeOrigin?.country.isoCode === "US" && "Title number",
        // "Length",
        // "Width",
        // "Height",
        // "Weight",
        "",
      ],
      ["Category", "Packaging", "Description", "Reference Number", ""],
      ["Container Type", "Category", "Weight (Kg)", "Description", "Container Number", ""],
      ["Category", "Description", "Volume (cbm)", "Weight (kg)", "Reference Number", ""],
    ];
    return (
      <TableHead
        style={{ borderBottom: "1px solid #eee", backgroundColor: "none" }}
      >
        <TableRow>
          {headerCells[shipmentType - 1].map(cell => (
            <TableCell
              key={cell}
              style={{ borderBottom: "1px solid #eee", background: "none" }}
            >
              {cell}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  return (
    <Paper className={"white-card "}>
      <p className="card-header">Shipment Cargo</p>

      <TableContainer className="card-table" style={{ position: "relative" }}>
        <Table className="contract-shipment-table">
          {getTableHeader(shipmentType)}

          <TableBody>
            {items.map((item, index) => (
              <ShipmentItem
                key={index}
                item={item}
                index={index}
                onChange={onChange}
              />
            ))}
          </TableBody>

        </Table>
      </TableContainer>

      {/* <div className="paper-footer">
        <Grid container spacing={1} alignItems="center">
          <Grid item sm={6}></Grid>
          {shipmentType != 3 && (
            <Grid item sm={6} className="text-align-right">
              <span className="footer-label">
                <span className="label-span">Total Volume: </span>
                {volume} cbm
              </span>
              <span className="footer-label">
                <span className="label-span">Total Weight: </span>
                {grossWeight} kg
              </span>
            </Grid>
          )}
        </Grid>
      </div> */}
    </Paper>
  );
}
