import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormControl, InputLabel, Input, InputAdornment, OutlinedInput, TextField } from '@material-ui/core';
import APIKit from '../../../include/APIKit';
import { Alert } from '@material-ui/lab';


export default function VerifyBankDialog(props) {
	const [deposits, setDeposits] = useState({
		firstAmount: '',
		secondAmount: ''
	});
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);

	const verify = () => {
		setError(false);
		setLoading(true);

		const data = {
			bankAccountId: props.id,
			...deposits
		}
		APIKit.put("PaymentMethods/verify-bankaccount", data ).then(result => {
			setLoading(false);
			if(result.status === 200) {
				if(result.data.isSuccess) {
					setSuccess("Your bank account has been successfully verified.");
					try {
						props.callback();
					}
					catch(e) {
						console.log("props.callback is not defined here")
					}
					setTimeout(() => {
						props.close();
					}, 1000);
					
				}
			}
		}).catch(e => {
			setLoading(false);
			setError("Unable to verify bank account");
		});
	}
	return (
		<Dialog open={props.open} className="verify-bank-dialog" >
			<DialogTitle style={{borderBottom: '1px solid #eee'}}>
				Verify bank account
				<span className="dialog-subtitle">Our payment provider Stripe sent two small deposits to this bank account. To verify this account, please confirm the amounts of these deposits.</span>
			</DialogTitle>
			{error != false ? <Alert style={{borderRadius: 0}} severity="error">{error}</Alert> : ""}
			{success != false ? <Alert style={{borderRadius: 0}} severity="success">{success}</Alert> : ""}
			<DialogContent dividers style={{background: '#f5f6f8', borderTop: 0}}>
				<form className="verify-bank-form ">
				<span className="form-label">First deposit</span>
				<TextField
					className="money-field"
					//label="First deposit"
					autoFocus
					//className={clsx(classes.margin, classes.textField)}
					inputProps={{
						maxLength: 2,
					}}
					InputProps={{
						startAdornment: <InputAdornment position="start">$0.</InputAdornment>,
					}}
					variant="outlined"
					size="small"
					fullWidth
					placeholder="00"
					value={deposits.firstAmount}
					onChange={(event) => setDeposits({...deposits, firstAmount: event.target.value})}
				/>
				<br /><br />
				<span className="form-label">Second deposit</span>
				<TextField
					className="money-field"
					//label="Second deposit"
					//className={clsx(classes.margin, classes.textField)}
					inputProps={{
						maxLength: 2,
					}}
					InputProps={{
						startAdornment: <InputAdornment position="start">$0.</InputAdornment>,
					}}
					variant="outlined"
					size="small"
					fullWidth
					placeholder="00"
					value={deposits.secondAmount}
					onChange={(event) => setDeposits({...deposits, secondAmount: event.target.value})}
				/>
				
					
				</form>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.close} >Cancel</Button>
				<Button onClick={verify} disabled={loading} color="primary" variant="contained" disableElevation >Verify</Button>
			</DialogActions>
		</Dialog>
	)
}