import React from "react";
import PropTypes from "prop-types";

export default function Card({
  children,
  variant,
  header,
  headerTitle,
  headerSubtitle,
  headerAction,
  bodyPadding,
  footer
}) {
  const styles = {
    card: {
      background: "#fff",
      borderRadius: 4,
      border: "1px solid #eee",
      position: "relative",
    },
    header: {
      padding: "22px 30px",
      borderBottom: "1px solid #eee",
      display: "flex",
      alignItems: "center",
    },
    headerContent: {
      width: headerAction && "calc(100% - 200px)",
    },
    headerTitle: {
      fontSize: 20,
      fontWeight: "500",
      marginBottom: "5px",
    },
    headerSubtitle: {
      fontSize: 14,
      fontWeight: "400",
      color: "rgb(0 0 0 / 50%)",
    },
    headerAction: {
      width: "200px",
      textAlign: "right",
    },
    body: {
      padding: bodyPadding ? "30px" : "0px",
      position: "relative",
    },
  };
  return (
    <div className="card" style={styles.card}>
      {header && (
        <div className="card-header" style={styles.header}>
          <div style={styles.headerContent}>
            {headerTitle && <div style={styles.headerTitle}>{headerTitle}</div>}
            {headerSubtitle && (
              <div style={styles.headerSubtitle}>{headerSubtitle}</div>
            )}
          </div>
          {headerAction && (
            <div style={styles.headerAction}>{headerAction}</div>
          )}
        </div>
      )}
      <div style={styles.body}>{children}</div>
      {footer && <div className="paper-footer">{footer}</div>}
    </div>
  );
}

Card.propTypes = {
  variant: PropTypes.oneOf(["white", "bordered"]),
  elevated: PropTypes.bool,
  bordered: PropTypes.bool,
  header: PropTypes.bool,
  headerTitle: PropTypes.string,
  headerSubtitle: PropTypes.string,
  headerAction: PropTypes.any,
  bodyPadding: PropTypes.bool,
};
Card.defaultProps = {
  variant: "white",
  elevated: false,
  bordered: true,
  header: true,
  headerTitle: "",
  headerTitle: "",
  headerAction: "",
  bodyPadding: true,
};
