import React from "react";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import CreateIcon from "@material-ui/icons/Create";
import IconButton from "@material-ui/core/IconButton";

export default function AvatarComponent({
  defaultUrl,
  badge,
  width,
  height,
  // imageUrl,
  // setImageUrl,
}) {
  const style = {
    Avatar: {
      width: width || "60px",
      height: height || "60px",
    },
    iconContainer: {
      borderRadius: "5px",
      backgroundColor: "#fafafa",
      height: "25px",
      width: "25px",
      position: "relative",

      "&:hover": {
        cursor: "pointer",
      },
    },
    icon: {
      fontSize: 20,
      color: "#888",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    input: {
      display: "none",
    },
  };

  const [imageUrl, setImageUrl] = React.useState(null);
  const handleChange = e => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = () => {
      reader.readyState === 2 && setImageUrl(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      invisible={badge}
      badgeContent={
        <div>
          <input
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            style={style.input}
            onChange={e => handleChange(e)}
          />
          <label htmlFor="contained-button-file">
            <IconButton
              style={style.iconContainer}
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <CreateIcon style={style.icon} />
            </IconButton>
          </label>
        </div>
      }
    >
      <Avatar
        alt="Travis Howard"
        src={imageUrl ? imageUrl : defaultUrl}
        style={style.Avatar}
      />
    </Badge>
  );
}
