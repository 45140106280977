import { Button } from "@material-ui/core";
import React from "react";

export default function NoResults({title, subtitle, buttonLabel, buttonAction}) {
	return (
		<div className="empty-state small-state">
			<div className="bg img-holder">
				<img src="../img/no-data.svg" al="" />
			</div>
			<div className="title">{title}</div>
			<div className="info">
				{subtitle}
			</div>
			{buttonAction && <Button variant="contained" color="primary" disableElevation style={{marginTop: 30}} onClick={buttonAction}>{buttonLabel}</Button>}
		</div>
	)
}