import React from "react";
import {
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TextField,
  TextareaAutosize,
} from "@material-ui/core";
import { Edit, Error, TextFieldsOutlined, Warning } from "@material-ui/icons";
import { useStore } from "../context/StoreContext";
import {
  Autocomplete
} from "@material-ui/lab";
export default function EditableCel(props) {
  let {
    value,
    name,
    state,
    type,
    options,
    width,
    onChange,
    loading,
    required,
    error,
    editMode,
    setActiveRow,
    key,
    handleOnChange,
    setItems,
    items,
    tempItem,
    saveItem,
    editable,
    canEdit,
    minValue,
    maxValue,
    placeholder
  } = props;

  const { accountType } = useStore();
  // let canEdit = accountType == 1 ? false : true;

  const handleItemInputChange = event => {};
  const handleItemSelectChange = event => {
    setActiveRow(false);
  };

  const onKeyDown = e => {
    // on escape
    if (e.keyCode === 27) {
      setActiveRow(null);
    } else if (e.keyCode === 13) {
      // on enter -> save
      //if(!e.target.value && required) return;
      //if(e.target.value.length < 2) return;
      setActiveRow(null);
      setItems(items.map(i => (i.id !== tempItem.id ? i : tempItem)));
      saveItem(tempItem);
    }
  };

  const OriginCountry = (props) => {
    return (
      <Autocomplete
        options={props.options}
        onChange={props.onChange}
        getOptionLabel={option => option.name}
        renderInput={params => (
          <TextField
            {...params}
            // label={props.name}
            variant="outlined"
            size="small"
            name={props.name}
          />
        )}
        // autoSelect
        // autoHighlight
        value={props.value}
        openOnFocus={false}
      />
    )
  }

  const renderField = (type) => {
    switch(type) {
      case "select":
        return (
          <Select
            onChange={event => onChange(event.target.value)}
            name={name}
            value={value}
          >
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )
      case "country":
        return (
          <OriginCountry
            options={options}
            onChange={(event, option) => handleOnChange(name, option)}
            name={name}
            value={value}
          />
        )
      default:
        return (
          <TextField
            defaultValue={value}
            variant="outlined"
            type={type || "text"}
            size="small"
            fullWidth
            onKeyDown={onKeyDown}
            onChange={event => handleOnChange(name, event.target.value)}
            name={name}
            inputProps={type == "number" ? { min: minValue, max: maxValue } : {}}
            placeholder={placeholder}
          />
        )
    }
  }

  const renderValue = (type) => {
    switch(type) {
      case "select":
        return options.filter(x => x.value === value)[0].label
      case "country":
        return value?.name
      default:
        return value;
    }
  }

  return (
    <>
      {canEdit ? (
        <TableCell
          className={`editable-cell ${
            state === "error" && error[name] ? "error" : ""
          } ${required && !value && "required"}`}
          width={width}
        >
          {(editMode && editable) ? 
            renderField(type) 
          : (
            <div className="editable-value">
              {required && !value && (
                <span className="state-message">
                  <Warning className="state-icon" />
                </span>
              )}
              {renderValue(type)}
            </div>
          )}
        </TableCell>
      ) : (
        <TableCell>
          {!value ? <div></div> : <div>{renderValue(type)}</div>}
        </TableCell>
      )}
    </>
  );
}