export const replaceQuotes = function(source) {
    let string = source.split("\"").map(function (value, index) {
        if(index % 2 == 0) {
            return value
        } else {
            return "<b>" + value + "</b>"
        }
    }).join("");
    return string.split("*").map(function (value, index) {
        if(index % 2 == 0) {
            return value
        } else {
            return "<i>" + value + "</i>"
        }
    }).join("");
}



export default {
    replaceQuotes
};