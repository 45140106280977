import React from "react";
import { Grid } from "@material-ui/core";

import ShipmentDetails from "./ShipmentDetails";
import OfferDetails from "./OfferDetails";
import OffersItems from "./OffersItems";
import Actions from "./Actions";

export default function DrawerContent({ offer, offerItems, loading, request }) {
  return (
    <div className="drawer-content">
      <div className="shipment-body">
        <Grid container spacing={3}>
          <Grid item sm={8}>
            <OfferDetails offer={offer} request={request} />
            <OffersItems offerItems={offerItems} offer={offer} />
          </Grid>
          <Grid item sm={4}>
            <Actions
              offer={offer}
              loading={loading}
              requestId={request?.id}
              contractId={12}
            />
            <ShipmentDetails offer={offer} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
