import React from "react";
import { Alert } from "@material-ui/lab";
import { Collapse } from "@material-ui/core";

export default function AlertComponent({ severity, open, message }) {
  return (
    <Collapse in={open}>
      <Alert severity={severity}>{message}</Alert>
    </Collapse>
  );
}
