import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

import CircularProgress from '@material-ui/core/CircularProgress';


import APIKit from '../include/APIKit';


function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const detailService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function PortsAutocomplete(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const loaded = React.useRef(false);

  const fetch = React.useMemo(
    () =>
      throttle((query, active) => {
      //console.log(query)
      setLoading(true);
      let url = "ports/search?query="+query+"&size=10";
      if(props.type) url+= "&type="+props.type;
        APIKit.get(url).then(result => {
			if (result.status === 200) {
			  //console.log(result.data);
			  if (active) {
				let newOptions = [];
		
				if (value) {
				  newOptions = [value];
				}
		
				if (result.data.record) {
				  newOptions = [...newOptions, ...result.data.record];
				}
		
				setOptions(newOptions);
				
			  }
			} setLoading(false);
		  }).catch(e => {setLoading(false);})
      }, 200),
    [],
  );


 

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    let query = inputValue;

    fetch(query, active);

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  
  return (
    <Autocomplete
      disabled={props.disabled}
      style={{ width: '100%' }}
      //size="small"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.code)}
      getOptionSelected={(option, value) => option.id === value.id}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={props.value || value}
      loading={loading}
      noOptionsText={'Search city or code'}
      onChange={(event, newValue) => {
        props.selection(newValue);
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} 
        //InputLabelProps={{ shrink: true, required: true }} 
        
        InputProps={{
          ...params.InputProps,
          autoComplete: 'new-password',
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={20} className="autocompleteSpinner" /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
        
        label={props.label ? props.label : null} variant="outlined" fullWidth error={props.error} helperText={props.helperText || null} />
      )}
      renderOption={(option) => {
		  //console.log(option)
        return (
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <span key={option.id} style={{ fontWeight: (option.city.toLowerCase() == inputValue.toLowerCase() || option.code.toLowerCase() == inputValue.toLowerCase()) ? 700 : 400 }}>
                {option.code}
              </span>
            </Grid>
            <Grid item xs={9}>
              <span style={{fontWeight: 400, color: '#7f878e'}}>{option.city}</span>
            </Grid>
            
            {/* <Typography variant="body2" color="textSecondary">
              {option.city}
            </Typography> */}
          </Grid>
          // <div>
          //   <span key={option.id} style={{ fontWeight: (option.city.toLowerCase() == inputValue.toLowerCase() || option.code.toLowerCase() == inputValue.toLowerCase()) ? 700 : 400 }}>
          //     {option.code} <span style={{fontWeight: 400, color: '#7f878e'}}>{option.city}</span>
          //   </span>
          //   {/* <Typography variant="body2" color="textSecondary">
          //     {option.city}
          //   </Typography> */}
          // </div>
        );
      }}
    />
  );
}
