import React, { useEffect } from "react";
import { Tabs, Tab, Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import ActionsMenu from "../../components/ActionsMenu.js";
import moment from "moment";
import { Box, Chip } from "@material-ui/core";

const style = {
  title: {
    fontSize: "16px",
    fontWeight: "500",
  },
  subtitle: {
    fontSize: "14px",
    color: "#80878f",
    fontWeight: "400",
  },
  expire: {
    fontSize: "14px",
    color: "#80878f",
  },
};

export default function RequestsTabs(props) {
  const { tableRef, data, activeTab, setActiveTab } = props;
  // const [activeTab, setActiveTab] = useState(1);
  // const [pageSize, setPageSize] = useState(3);

  const history = useHistory();
  const handleRowClick = (event, id) => history.push("/request/" + id);
  const handleTabChange = () => setActiveTab(activeTab === 1 ? 2 : 1);
  // const handleTabChange = () => setActiveTab(activeTab);

  useEffect(() => {
    console.log("activeTab in child is " + activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const shipmentColumns = [
    {
      title: "Shipment",
      width: "20%",
      sorting: false,
      cellStyle: {
        padding: "0 30px",
      },
      render: rowData => (
        <Box pt={2} pb={2}>
          <div>
            <div className="item-title item-ref" style={style.title}>
              {rowData.reference}
            </div>
            <div className="item-title" style={style.subtitle}>
              {/* {rowData.currency}  */}${" "}
              {moment(rowData.createdDate).format("DD/MM/YYYY")}
            </div>
          </div>
        </Box>
      ),
    },
    {
      title: "Offers",
      field: "offers",
      sorting: false,
      render(rowData) {
        if (rowData.offersCount === 0) {
          return <div className="item-date">-</div>;
        } else {
          return (
            <Box pt={2} pb={2}>
              <div className="item-title item-offers">
                {rowData.offersCount} Offers
              </div>
            </Box>
          );
        }
      },
    },

    {
      title: "Lowest price",
      field: "",
      sorting: false,
      render(rowData) {
        if (rowData.offersCount === 0) {
          return (
            <Box pt={2} pb={2}>
              <div className="item-date">-</div>
            </Box>
          );
        } else {
          return (
            <Box pt={2} pb={2}>
              <div className="item-date">
                $<span className="offerPrice">{rowData.lowestOffer}</span>{" "}
                lowest offer
              </div>
            </Box>
          );
        }
      },
    },
    {
      title: "",
      field: "",
      width: "25px",
      cellStyle: {
        textAlign: "right",
      },
      sorting: false,
      render: rowData => (
        <Box pt={2} pb={2}>
          <ActionsMenu
            id={rowData.id}
            status={rowData.requestStatus}
            // refresh={this.refreshTable}
            contractId={rowData.contract?.id}
          />
        </Box>
      ),
    },
  ];
  return (
    <div>
      {/* <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        className="headerTabs"
      >
        <Tab label="Pending" value={1} style={{ padding: "0 30px" }} />
        <Tab label="Active" value={2} />
      </Tabs> */}
      <MaterialTable
        title=""
        tableRef={tableRef}
        options={{
          search: false,
          selection: false,
          toolbar: false,
          pageSize: 3,
          pageSizeOptions: [],
          headerStyle: {
            display: "none",
          },
        }}
        onRowClick={(event, rowData) => handleRowClick(event, rowData.id)}
        columns={shipmentColumns}
        data={data}
        components={{
          Container: props => (
            <Paper
              {...props}
              elevation={1}
              className="custom-table-container"
            />
          ),
        }}
        style={{ boxShadow: "none", border: "none" }}
      />
    </div>
  );
}
