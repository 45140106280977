import React from "react";
import ContainerIcon from "../../include/custom/ContainerIcon";
import { FormControl, Select, MenuItem, FormHelperText, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Error as ErrorIcon, DirectionsRailway, DirectionsBoat, DirectionsCar, Landscape } from '@material-ui/icons';

const CategoryStep = (props) => {
	const validate = props.validation;
	const data = props.data;
	return (
		<div className="currentStep">
			<Typography variant="subtitle2" gutterBottom>What is your company's main transport modes?</Typography>
			<ToggleButtonGroup value={data?.freightMode} className="customToggle" onChange={(event, value, name) => props.handleMultiToggle(event, value, 'freightMode')} >
				<ToggleButton value='ocean' data-testid="oceanToggle">
					<DirectionsBoat/> Ocean Transport
				</ToggleButton>
				<ToggleButton value='land' data-testid="landToggle">
					<DirectionsRailway/> Land Transport
				</ToggleButton>
			</ToggleButtonGroup>
			{validate.freightMode && <FormHelperText style={{marginTop: 10}} error><ErrorIcon className="errorIcon" /> Please select at least one mode</FormHelperText>}

			<Typography variant="subtitle2" gutterBottom className="mt-20">What do you mainly ship?</Typography>
			<ToggleButtonGroup value={data?.shipmentTypes} className="customToggle" onChange={(event, value, name) => props.handleMultiToggle(event, value, 'shipmentTypes')} >
				<ToggleButton value="containers" data-testid="containersToggle">
					<ContainerIcon/> Containers
				</ToggleButton>
				<ToggleButton value="roro" data-testid="roroToggle">
					<DirectionsCar/> RoRo
				</ToggleButton>
				<ToggleButton value="bulk" data-testid="bulkToggle">
					<Landscape/> Bulk
				</ToggleButton>
			</ToggleButtonGroup>

			{validate.shipmentTypes && <FormHelperText style={{marginTop: 10}} error><ErrorIcon className="errorIcon" /> Please select at least one shipment type</FormHelperText>}

			<Typography variant="subtitle2" gutterBottom className="mt-20">What is your company type?</Typography>
			<FormControl variant="outlined" fullWidth error={validate.businessType}  >
				<Select
				onChange={(event) => props.setState({...data, businessType: event.target.value})}
				name="freightType"
				value={data?.businessType}
				data-testid="businessType"
				>
				<MenuItem value={1} data-testid="option1">Carrier</MenuItem>
				<MenuItem value={2} data-testid="option2">Freight Forwarder</MenuItem>
				<MenuItem value={0} data-testid="option0">NVOCC</MenuItem>
				</Select>
				{validate.businessType && <FormHelperText error><ErrorIcon className="errorIcon" /> Please specify your business type</FormHelperText>}
			</FormControl>
		</div>
	)
}

export default CategoryStep;