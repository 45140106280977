import React from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
  TableHead,
} from "@material-ui/core";

export default function OffersItems({ offer, offerItems }) {
  const [rows, setRows] = React.useState();

  React.useEffect(() => {
    offerItems && setRows(offerItems);
  }, [offerItems]);

  const freightTypes = ["", "Ocean", "Land", "RoRo", "Bulk or Break Bulk"];

  return (
    <Paper className={"white-card "}>
      <div
        className="card-header"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>Offer Items</div>
      </div>

      <TableContainer>
        <Table aria-label="Offer items table">
          <TableHead>
            <TableRow>
              <TableCell style={{ paddingLeft: "30px" }}>Item</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>State</TableCell>
              {!offer?.isFlatPrice && <TableCell>Price</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map(row => (
                <TableRow>
                  <TableCell style={{ paddingLeft: "30px" }}>
                    {row.logisticService?.name
                      ? row.logisticService?.name
                      : freightTypes[row?.freightType] + " freight"}
                  </TableCell>
                  <TableCell>
                    {row.logisticService?.description
                      ? row.logisticService?.description
                      : "From " +
                        row?.routeOrigin?.country.name +
                        " To " +
                        row?.routeDestination?.country.name}
                  </TableCell>
                  <TableCell>
                    {row.isOptional ? "Optional" : "Required"}
                  </TableCell>
                  {!offer?.isFlatPrice && (
                    <TableCell>
                      {offer?.currency.symbol}
                      {row?.price}
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
