import React, {useState} from 'react';
import TabContent from '../../components/settings/TabContent';
import Tabs from '../../components/settings/Tabs';
import Page from '../../containers/Page';
import { Grid } from '@material-ui/core';

export default function Settings() {
	const [activeTab, setTab] = useState('payment-methods');

	return (
		<Page containerMaxWidth="none">
			<div className="flex-layout">
				<div className="flex-left">
					<Tabs activeTab={activeTab} setTab={setTab} />
				</div>
				<div className="flex-right">
					<TabContent tab={activeTab} />
				</div>
			</div>
		</Page>
	);
}