import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { DirectionsCar, Landscape } from "@material-ui/icons";
import ContainerIcon from "../../../../include/custom/ContainerIcon";

export default function SupplierShipmentTypes(props) {
  const { data, handleMultiToggle } = props;
  return (
    <div>
      <ToggleButtonGroup
        value={data?.shipmentTypes}
        className="customToggle"
        onChange={(event, value, name) =>
          handleMultiToggle(event, value, "shipmentTypes")
        }
      >
        <ToggleButton value="containers" data-testid="containersToggle">
          <ContainerIcon /> Containers
        </ToggleButton>
        <ToggleButton value="roro" data-testid="roroToggle">
          <DirectionsCar /> RoRo
        </ToggleButton>
        <ToggleButton value="bulk" data-testid="bulkToggle">
          <Landscape /> Bulk
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
