import React from "react";
import APIKit from "../include/APIKit";

import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { ArrowRight, ArrowRightAlt, ChevronRight } from "@material-ui/icons";


function ActionsMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar();

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const editRequest = event => {
    event.stopPropagation();
    history.push(`/edit-request/${props.id}`)
  };

  const listing = event => {
    event.stopPropagation();
    APIKit.post("requests/listing", [props.id])
      .then(result => {
        if (result.status === 200) {
          enqueueSnackbar("Successfully Updated!", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
          props.refresh();
        }
      })
      .catch(e => {});
    setAnchorEl(null);
  };
  const deleteListing = event => {
    event.stopPropagation();
    APIKit.post("requests/delete", [props.id])
      .then(result => {
        if (result.status === 200) {
          props.refresh();
        }
      })
      .catch(e => {});
    setAnchorEl(null);
  };

  const menuId = props.id;
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
      className="actions-menu"
      elevation={1}
    >
      {props.status === 1 && (
        <span>
          <MenuItem onClick={editRequest}>Edit Request</MenuItem>
          <MenuItem onClick={listing}>Unlist</MenuItem>
        </span>
      )}
      {props.status === 4 && (
        <span>
          <MenuItem onClick={editRequest}>Edit Request</MenuItem>
          <MenuItem onClick={listing}>Relist</MenuItem>
        </span>
      )}
      {!(props.status === 2 || props.status === 3) && (
        <MenuItem onClick={deleteListing} className="delete-menu-item">
          Delete
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <div>
      {!(props.status === 2 || props.status === 3) && (
        <Button
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          className="item-menu"
        >
          <MoreVertIcon />
        </Button>
      )}
      {props.status === 2 && (
          <Button
            edge="end"
            onClick={(event) => { event.stopPropagation(); history.push(`/contract/${props.contractId}/overview`); }}

            className="contract-button"
            variant="text"
            size="small"
            endIcon={<ChevronRight />}
          >
            View Contract
          </Button>
      )}
      {renderMenu}
    </div>
  );
}

export default ActionsMenu;
