import React, { useEffect } from "react";

import { Box, TextField, IconButton, InputAdornment } from "@material-ui/core";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

export default function ChangePassForm(props) {
  const { password, setPassword } = props;

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const style = {
    container: {
      display: "flex",
      flexDirection: "column",
      padding: "22px 30px",
    },
    input: {
      width: "50%",
      marginBottom: "20px",
    },
    message: {
      fontSize: "14px",
      color: "red",
      padding: "5px 10px",
    },
  };

  const handleOnChange = key => e => {
    setPassword({
      ...password,
      [key]: { ...password[key], value: e.target.value },
    });
  };

  const handleClickShowPassword = key => e => {
    setPassword({
      ...password,
      [key]: { ...password[key], showPassword: !password[key].showPassword },
    });
  };

  const handleOnBlur = key => e => {
    const regex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,100}$/;

    if (key === "newPassword") {
      // check with regex
      setPassword({
        ...password,
        [key]: {
          ...password[key],
          error: !regex.test(e.target.value)
            ? "Password must be 8 characters long, contain at least one small letter, one capital letter, one digit, and one special character."
            : null,
        },
      });
    }

    if (key === "confirmPassword") {
      setPassword({
        ...password,
        [key]: {
          ...password[key],
          error:
            password[key].value !== password["newPassword"].value
              ? "Passwords don't match"
              : null,
        },
      });
    }
  };

  useEffect(() => {
    console.log(password);
  }, [password]);

  return (
    <div style={style.container}>
      <Box display="flex" alignItems="flex-start">
        <TextField
          id="currentPass"
          label="Current password"
          variant="outlined"
          type={password.currentPassword.showPassword ? "text" : "password"}
          style={style.input}
          error={password.currentPassword.error}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword("currentPassword")}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {password.currentPassword.showPassword ? (
                    <AiFillEye />
                  ) : (
                    <AiFillEyeInvisible />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handleOnChange("currentPassword")}
          defaultValue={password.currentPassword.value}
        />

        <div style={style.message}>
          {password["currentPassword"].error ?? ""}
        </div>
      </Box>

      <Box display="flex" alignItems="flex-start">
        <TextField
          id="newPassword"
          label="New password"
          variant="outlined"
          type={password.newPassword.showPassword ? "text" : "password"}
          style={style.input}
          error={password.newPassword.error}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword("newPassword")}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {password.newPassword.showPassword ? (
                    <AiFillEye />
                  ) : (
                    <AiFillEyeInvisible />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handleOnChange("newPassword")}
          onBlur={handleOnBlur("newPassword")}
          defaultValue={password.newPassword.value}
        />

        <div style={style.message}>{password["newPassword"].error ?? ""}</div>
      </Box>

      <Box display="flex" alignItems="flex-start">
        <TextField
          id="confirmPass"
          label="Confirm new password"
          variant="outlined"
          type={password.confirmPassword.showPassword ? "text" : "password"}
          style={style.input}
          error={password.confirmPassword.error}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword("confirmPassword")}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {password.confirmPassword.showPassword ? (
                    <AiFillEye />
                  ) : (
                    <AiFillEyeInvisible />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handleOnChange("confirmPassword")}
          onBlur={handleOnBlur("confirmPassword")}
          defaultValue={password.confirmPassword.value}
        />

        <div style={style.message}>
          {password["confirmPassword"].error ?? ""}
        </div>
      </Box>
    </div>
  );
}
