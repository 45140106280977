/**
* Use the CSS tab above to style your Element's container.
*/
import React from 'react';
import {IbanElement} from '@stripe/react-stripe-js';
import './IbanFormStyles.css'
import { Alert } from '@material-ui/lab';
import { Select, MenuItem, FormControl } from '@material-ui/core';

// Custom styling can be passed as options when creating an Element.
const IBAN_STYLE = {
  base: {
    color: '#32325d',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    },
    ':-webkit-autofill': {
      color: '#32325d',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a',
    },
  }
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  placeholderCountry: 'DE',
  style: IBAN_STYLE,
};

export default function ACHSetupForm() {
  return (
    <React.Fragment>
      <div className="form-row inline stripe-form">
	  	<div className="col">
          <label>
            Account holder type
			<FormControl variant="outlined" size="small" fullWidth style={{marginTop: 10}}  >
            <Select
				defaultValue="company"
				variant="outlined"
				name="account_holder_type"
			>
				<MenuItem value="individual">Individual</MenuItem>
				<MenuItem value="company">Company</MenuItem>
			</Select>
			</FormControl>
          </label>
        </div>
		
	  	<div className="col">
          <label>
            Account holder name
            <input
              name="account_holder_name"
              placeholder="Jenny"
              required
            />
          </label>
        </div>

        <div className="col">
          <label>
            Routing number
            <input
              name="routing_number"
              placeholder="110000000"
              required
            />
          </label>
        </div>

		<div className="col">
          <label>
            Account number
            <input
              name="account_number"
              placeholder="000123456789"
              required
            />
          </label>
        </div>

		



      </div>

    </React.Fragment>
  );
};