import React, { Component } from "react";

import ActionsMenu from "../../components/ActionsMenu.js";
import { withSnackbar } from "notistack";
import { Link, Redirect } from "react-router-dom";

import moment from "moment";

import {
  Paper,
  Tabs,
  Tab,
  Chip,
  Avatar,
  Grid,
  Container,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanelActions,
  Button,
  Divider,
  InputLabel,
  Menu,
  MenuItem,
  FormControl,
  Select,
  TextField,
  IconButton,
  InputBase,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";

// IMPORT MATERIAL ICONS
import {
  ChevronLeft,
  LocalShipping,
  ChevronRight,
  Flight,
  DirectionsBoat,
  DirectionsRailway,
  DirectionsCar,
  Landscape,
  VerticalAlignBottom,
  VerticalAlignTop,
  MoreVertRounded as MoreVertRoundedIcon,
  LocalShipping as LocalShippingIcon,
  ExpandMore as ExpandMoreIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Directions as DirectionsIcon,
  Search as SearchIcon,
  Menu as MenuIcon,
  Edit as EditIcon,
  EditAttributes as EditAttributesIcon,
  Cancel as CancelIcon,
} from "@material-ui/icons";

import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";

import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";

import APIKit from "../../include/APIKit";
import Page from "../../containers/Page.js";
import NoResults from "../../components/NoResults.jsx";

let timeout = 0;
let selection = [];

class Requests extends Component {
  tableRef = React.createRef();

  state = {
    activeTab: 0,
    pageSize: 10,
    filters: {
      shipmentTypes: [1,2,3,4],
      freightTypes: [1, 2],
    },
    filterPaneOpen: false,
    countries: null,
    name: "",
    typing: false,
    typingTimeout: 0,
    menuOpen: false,
    anchorEl: null,
    selection: [],
  };

  UNSAFE_componentWillMount = () => {
    document.title = "Requests";
  };
  componentDidMount() {
    this.getCountries();
  }

  handleChange = (event, activeTab) => {
    this.setState({ activeTab }, () => {
      this.tableRef.current.onChangePage(null, 0);
    });
  };

  handleRowClick = (event, id) => {
    this.props.history.push("/request/" + id);
  };
  handleSelection = data => {
    selection = data;
  };

  handleToggle = (event, value, name) => {
    this.setState({ filters: { ...this.state.filters, [name]: value } }, () => {
      this.tableRef.current.onChangePage(null, 0);
    });
  };

  handleInputChange = event => {
    event.preventDefault();
    let value = event.target['search'].value;
    // if (timeout) clearTimeout(timeout);
    // timeout = setTimeout(() => {
      this.setState(
        { filters: { ...this.state.filters, query: value } },
        () => {
          this.tableRef.current.onChangePage(null, 0);
        }
      );
    // }, 1000);
  };

  refreshTable = () => {
    this.tableRef.current.onChangePage(null, 0);
  };

  // handleInputChange = (event) => {
  //   this.setState({filters: {...this.state.filters, [event.target.name]: event.target.value}}, ()=> {
  //     this.tableRef.current.onChangePage(null, 0);
  //   });
  // }

  handleFilters = (value, name) => {
    if (name == "createdFrom" && (value != null || value != undefined)) {
      value = moment(value).format("YYYY-MM-DD");
    }
    const filters = this.state.filters;
    filters[name] = value != null ? value : null;
    this.setState({ filters }, () => {
      this.tableRef.current.onChangePage(null, 0);
    });
  };
  handleDateChange = value => {
    console.log(1);
  };

  renderLabel = date => {
    console.log(date);
    if (date) {
      return date;
    } else {
      return null;
    }
  };

  search = query => {
    return new Promise((resolve, reject) => {
      let body = {
        pageSize: query.pageSize,
        pageNumber: query.page + 1,
        status: this.state.activeTab === 0 ? null : this.state.activeTab,
        ...this.state.filters,
      };
      APIKit.post("requests/search", body)
        .then(result => {
          if (result.status === 200) {
            this.setState({total: result.data.record.totalItems})
            if (result.data.record.totalItems < query.pageSize) {
              this.setState({ pageSize: result.data.record.totalItems });
            } else {
              this.setState({ pageSize: query.pageSize });
            }
            resolve({
              data: result.data.record.items,
              page: query.page,
              totalCount: result.data.record.totalItems,
            });
          }
        })
        .catch(e => {});
    });
  };

  getCountries = () => {
    APIKit.get("countries")
      .then(result => {
        if (result.status === 200) {
          this.setState({ countries: result.data.record });
        }
      })
      .catch(e => {});
  };

  openMenu = (event, id) => {
    console.log(event.currentTarget);
    this.setState({ anchorEl: event.currentTarget, menuOpen: id });
  };

  closeMenu = event => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  bulkUnlist = (event, data) => {
    let ids = [];
    for (var i in data) {
      if (data[i].requestStatus == 2 || data[i].requestStatus == 3) {
        this.showAlert(
          "warning",
          "You cannot modify assigned or completed requests!"
        );
        return null;
      }
      if (data[i].requestStatus == 4) {
        this.showAlert(
          "warning",
          "You cannot unlist already cancelled requests!"
        );
        return null;
      }
      ids.push(data[i].id);
    }
    APIKit.post("requests/UpdateListing", ids)
      .then(result => {
        if (result.status === 200) {
          this.showAlert("success", "Successfully Updated!");
          this.refreshTable();
        }
      })
      .catch(e => {});
  };

  bulkDelete = (event, data) => {
    let ids = [];
    for (var i in data) {
      if (data[i].requestStatus == 2 || data[i].requestStatus == 3) {
        this.showAlert(
          "warning",
          "You cannot delete assigned or completed requests!"
        );
        return null;
      }
      ids.push(data[i].id);
    }
    APIKit.post("requests/delete", ids)
      .then(result => {
        if (result.status === 200) {
          this.refreshTable();
        }
      })
      .catch(e => {});
  };

  showAlert = (variant, message) => {
    this.props.enqueueSnackbar(message, {
      variant: variant,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  render() {
    const ShipmentStatus = [
      "",
      "Pending",
      "Assigned",
      "Completed",
      "Cancelled",
    ];
    const shipmentTypeTitles = ["", "Unit", "Item", "Container", "Item"];

    const typeIcons = [
      "",
      <DirectionsCar className="shipment-icon-big" />,
      <svg
        className="MuiSvgIcon-root shipment-icon-big customIcon"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path d="M21.5,3.5h-19c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h19c1.1,0,2-0.9,2-2v-13C23.5,4.4,22.6,3.5,21.5,3.5z M21.5,18.5h-19v-13h19V18.5zM3.5, 12.5 h17 v5 h-17z"></path>
      </svg>,
      <svg
        className="MuiSvgIcon-root shipment-icon-big customIcon"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path d="M21.5,3.5h-19c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h19c1.1,0,2-0.9,2-2v-13C23.5,4.4,22.6,3.5,21.5,3.5z M21.5,18.5h-19v-13h19V18.5zM3.5, 6.5 h17 v11 h-17z"></path>
      </svg>,
      <Landscape className="shipment-icon-big" />,
    ];

    const shipmentColumns = [
      {
        title: "Request",
        sorting: true,
        width: "30%",
        render: rowData => (
          <div className="icon-grid">
            <Tooltip
              title={
                rowData.itemsCount > 1
                ? rowData.itemsCount +
                  " " +
                  shipmentTypeTitles[rowData.shipmentType] +
                  "s"
                : rowData.itemsCount +
                  " " +
                  shipmentTypeTitles[rowData.shipmentType]
              }
            >
            <div>
              <div className="iconComplex">
                {typeIcons[rowData.shipmentType]}
                {rowData.freightType == 1 && (
                  <DirectionsBoat className="shipment-icon-small" />
                )}
                {rowData.freightType == 2 && (
                  <DirectionsRailway className="shipment-icon-small land" />
                )}
                {/* <StarIcon className="shipment-icon-small"/> */}
              </div>
            </div>
            </Tooltip>
            <div>
              <div className="item-title item-ref">
                #{rowData.reference}
              </div>
              <div className="item-date">
              {moment(rowData.createdDate).format("L LT")}
              </div>
            </div>
          </div>
        ),
      },
      // {
      //   title: "Shipment",
      //   width: "20%",
      //   sorting: false,
      //   render: rowData => (
      //     // <div className="icon-grid">
      //     /* <div className="iconComplex">
      //         <DirectionsRailway className="shipment-icon-big"/>
      //         <DirectionsBoat className="shipment-icon-small"/>
      //       </div> */
      //     <div>
      //       <div className="item-title item-ref"> {rowData.reference}</div>
      //       <div className="item-date">
      //         {moment(rowData.createdDate).format("L LT")}
      //       </div>
      //     </div>
      //   ),
      // },
      {
        title: "Origin",
        sorting: false,
        render: rowData => (
          <div className="countries-grid">
            <img
              src={rowData.fromAddress.country.flag}
              className="grid-country-flag"
              alt=""
            />
            <div>
              <div className="item-title">
                {rowData.fromAddress.description}
              </div>
              <div className="item-date">
                {moment(rowData.pickupDate).format("L")}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "Destination",
        sorting: false,
        render: rowData => (
          <div className="countries-grid">
            <img
              src={rowData.toAddress.country.flag}
              className="grid-country-flag"
              alt=""
            />
            <div>
              <div className="item-title">{rowData.toAddress.description}</div>
              <div className="item-date">
                {moment(rowData.deliveryDate).format("L")}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "Status",
        field: "status",
        sorting: false,
        render: rowData => (
          <Chip
            size="small"
            label={ShipmentStatus[rowData.requestStatus]}
            className={
              "shipment-status " + ShipmentStatus[rowData.requestStatus]
            }
          />
        ),
      },
      {
        title: "Offers",
        field: "",
        sorting: false,
        render(rowData) {
          if (rowData.offersCount == 0) {
            return (
              <div>
                <div className="item-date">-</div>
              </div>
            );
          } else {
            return (
              <div>
                <div className="item-title item-offers">
                  {rowData.offersCount} Offers
                </div>
                <div className="item-date">
                  $<span className="offerPrice">{rowData.lowestOffer}</span>{" "}
                  lowest
                </div>
              </div>
            );
          }
        },
      },
      {
        title: "",
        field: "",
        width: "25px",
        sorting: false,
        render: rowData => (
          <ActionsMenu
            id={rowData.id}
            status={rowData.requestStatus}
            refresh={this.refreshTable}
            contractId={rowData.contract?.id}
          />
        ),
      },
    ];

    return (
      <Page
        navigation={true}
        // containerMaxWidth="none" 
        // containerClass="no-paddings" 
        title="My Requests" 
        // actions={<Actions />}
        searchPlaceholder="Search requests"
        searchAction={this.handleInputChange}
        mainClass={'request-page-container'}
        subHeader={
          <Tabs
            value={this.state.activeTab}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            className="headerTabs"
          >
            <Tab label="All Requests" value={0} />
            <Tab label="Pending" value={1} />
            <Tab label="Assigned" value={2} />
            <Tab label="Completed" value={3} />
            <Tab label="Cancelled" value={4} />
          </Tabs>
        }
      >         
        
        <div className="">
          <div className="filter-pane">
            {/* <ExpansionPanel elevation={1} className="expansion" expanded={this.state.filterPaneOpen}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon onClick={() => { this.setState({ filterPaneOpen: !this.state.filterPaneOpen }); }} />}
                  aria-label="Expand"
                  aria-controls="panel1c-content"
                  id="panel1c-header"
                > */}
            <Grid container spacing={3}>
              {/* <Grid item xs={12} sm={4}>
                <Paper className="grid-search">
                  <InputBase
                    className="search-input"
                    placeholder="Search"
                    inputProps={{ "aria-label": "Search" }}
                    onChange={this.handleInputChange}
                    name="query"
                  />
                  <IconButton className="search-iconBtn" aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Grid> */}
              <Grid item xs={12} sm={8}>
                <ToggleButtonGroup
                  value={this.state.filters.freightTypes}
                  onChange={(event, value, name) =>
                    this.handleToggle(event, value, "freightTypes")
                  }
                  aria-label="Freight Type"
                  size="small"
                >
                  <ToggleButton value={1} aria-label="left aligned">
                    <Tooltip title="Ocean" placement="bottom">
                      <DirectionsBoat />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value={2} aria-label="centered">
                    <Tooltip title="Land" placement="bottom">
                      <DirectionsRailway />
                    </Tooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
                <span className="padder"></span>
                <ToggleButtonGroup
                  value={this.state.filters.shipmentTypes}
                  onChange={(event, value, name) =>
                    this.handleToggle(event, value, "shipmentTypes")
                  }
                  aria-label="Shipment Type"
                  size="small"
                >
                  <ToggleButton value={3} aria-label="centered">
                    <Tooltip title="FCL" placement="bottom">
                      <svg
                        className="MuiSvgIcon-root"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path d="M21.5,3.5h-19c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h19c1.1,0,2-0.9,2-2v-13C23.5,4.4,22.6,3.5,21.5,3.5z M21.5,18.5h-19v-13h19V18.5zM3.5, 6.5 h17 v11 h-17z"></path>
                      </svg>
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value={2} aria-label="right aligned">
                    <Tooltip title="LCL" placement="bottom">
                      <svg
                        className="MuiSvgIcon-root"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path d="M21.5,3.5h-19c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h19c1.1,0,2-0.9,2-2v-13C23.5,4.4,22.6,3.5,21.5,3.5z M21.5,18.5h-19v-13h19V18.5zM3.5, 12.5 h17 v5 h-17z"></path>
                      </svg>
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value={1} aria-label="RoRo">
                    <Tooltip title="RoRo" placement="bottom">
                      <DirectionsCar />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value={4} aria-label="centered">
                    <Tooltip title="Bulk/Break Bulk" placement="bottom">
                      <Landscape />
                    </Tooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              {/* <Grid item xs={12} sm={0}></Grid> */}
              <Grid item xs={12} sm={2}>
                <Autocomplete
                  options={this.state.countries ? this.state.countries : []}
                  onChange={(event, option) => {
                    this.handleFilters(
                      option ? option.isoCode : null,
                      "originCountryCode"
                    );
                  }}
                  getOptionLabel={option => option.name}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Origin"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  autoSelect
                  disabled={this.state.countries == null ? true : false}
                  // autoHighlight
                  openOnFocus={false}
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Autocomplete
                  options={this.state.countries ? this.state.countries : []}
                  onChange={(event, option) => {
                    this.handleFilters(
                      option ? option.isoCode : null,
                      "destinationCountryCode"
                    );
                  }}
                  getOptionLabel={option => option.name}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Destination"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  // autoSelect
                  disabled={this.state.countries == null ? true : false}
                  // autoHighlight
                  openOnFocus={false}
                />
              </Grid>
            </Grid>
          </div>

          <MaterialTable
            title="My Requests"
            tableRef={this.tableRef}
            options={{
              search: false,
              selection: false,
              toolbar: false,
              pageSize: this.state.pageSize,
              pageSizeOptions: [10, 20, 50, 100],
            }}
            onRowClick={(event, rowData) =>
              this.handleRowClick(event, rowData.id)
            }
            // onSelectionChange={data=>{this.handleSelection(data)}}
            columns={shipmentColumns}
            data={query => this.search(query)}
            components={{
              Container: props => (
                <Paper
                  {...props}
                  elevation={1}
                  className="custom-table-container"
                />
              ),
              OverlayLoading: () => <div className="table-loader"><CircularProgress  /></div>
            }}
            //onChangePage={() => null}
            style={{ boxShadow: "none", border: "1px solid #eee" }}
            actions={[
              {
                tooltip: "Unlist",
                icon: "cancel",
                position: "toolbarOnSelect",
                onClick: (event, data) => this.bulkUnlist(event, data),
              },
              {
                tooltip: "Delete",
                icon: "delete",
                position: "toolbarOnSelect",
                onClick: (event, data) => this.bulkDelete(event, data),
              },
              // {
              //   icon: () =>  <EditAttributesIcon style={{ color: this.state.editBtnColor }} />,
              //   onClick: (event, rowData) => {
              //     this.setState({selectEnabled: !this.state.selectEnabled}, ()=> {
              //       if(this.state.selectEnabled) {
              //         this.setState({editBtnColor: '#1d4ef5'})
              //       }
              //       else {
              //         this.setState({editBtnColor: null})
              //       }
              //     });

              //   },
              //   isFreeAction: true,
              //   tooltip: 'Bulk Edit Mode',
              // }
            ]}
            // localization={{
            //   pagination: {
            //     labelDisplayedRows: '{from}-{to} of {count}',
            //     //labelRowsSelect: 'rows per page'
            //   }
            // }}
            localization={{
              body: {
                emptyDataSourceMessage: <NoResults
                title="No Requests Found!"
                subtitle="You don't have any matching requests at this moment."
                buttonLabel={"Create a Request"}
                buttonAction={() => this.props.history.push('/requests/create')}
              />
              }
            }}
          />
        </div>

      </Page>
    );
  }
}

export default withSnackbar(Requests);
