import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const detailService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function GoogleMaps(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  autocompleteService.current = new window.google.maps.places.AutocompleteService();
  detailService.current = new window.google.maps.places.PlacesService(document.createElement('div'));
  
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!document.querySelector('#google-maps')) {
        loadScript(
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyCBF4_ctmHoMx5RRA9R9I61zRO7bNwC8BM&libraries=places',
          document.querySelector('head'),
          'google-maps',
        );
      }
    }

  }, [])
  
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  const fetchDetails = throttle((placeId, callback) => {
        detailService.current.getDetails({
          placeId: placeId,
          fields: ['name', 'address_components','type','geometry.location', 'formatted_address']
        }, (results) => {
          props.details(results);
        });
      }, 200)
    



 

  React.useEffect(() => {
    let active = true;
    
    //if (!autocompleteService.current && window.google) {     
      console.log("😆")
      
    //}
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ 
      input: inputValue, 
      types: ['geocode'],
      //componentRestrictions: {country: 'lb'} 
    }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
        
      }
    });
  
    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  
  return (
    <Autocomplete
      style={{ width: '100%' }}
      size={props.size ? props.size : "normal"}
      className="white-autocomplete"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={props.value || value}
      noOptionsText={'No locations found'}
      onChange={(event, newValue) => {
        if(newValue) {
          fetchDetails(newValue.place_id);
        }
        else {
          props.details(null);
        }
        props.selection(newValue);
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password',
        }} label={props.label ? props.label : null} variant="outlined" fullWidth error={props.error} />
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        //console.log(option)
        //console.log(matches)
        //console.log(parts)

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 600 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
