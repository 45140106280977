import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default function ActionDialog(props) {
	return (
		<Dialog open={props.open} onClose={props.onClose} >
			<DialogTitle>{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{props.content}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.cancelAction || props.close} >{props.cancelText || "Cancel"}</Button>
				<Button onClick={props.confirmAction} color="primary" disableElevation >{props.confirmText || "Confirm"}</Button>
			</DialogActions>
		</Dialog>
	)
}