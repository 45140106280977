import React from "react";
import { Paper, Button, Chip, CircularProgress } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import ConfirmDialog from "../ConfirmDialog";
import APIKit from "../../include/APIKit";

export default function PaymentDetails(props) {
  const { offer, loading, paymentStatus, paidAmount, contractId, contractStatus, payment, accountType } = props;
  const [status, setStatus] = React.useState("");
  const [confirmation, setConfirmation] = React.useState(false);
  const [loadingc, setLoadingc] = React.useState(false);

  let serviceCharge = 40;

  // 0 - Paid . 1/2 pending

  const handlePayment = () => {
    switch (paymentStatus) {
      case 0:
        setStatus("Fully paid");
        break;
      case 1:
        setStatus("Partially paid");
        break;
      case 2:
        setStatus("Not paid");
        break;
      default:
        setStatus("");
    }
  };

  React.useEffect(() => {
    handlePayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatus]);

  React.useEffect(() => {
    console.log(paymentStatus);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatus]);

  const format = (value) =>
	new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: payment?.currency?.code
	}).format(value);

  const paymentStatuses = ["Paid", "Pending"];

  const onConfirm = () => {
    setConfirmation(false);
    setLoadingc(true);
    APIKit.put("contracts/complete/"+contractId ).then(result => {
			setLoadingc(false);
			if (result.status === 200) {
				if(result.data.isSuccess) {
          window.location.reload();
				}
			}
		}).catch(e => {
			setLoadingc(false);
		});
  }

  const renderActions = () =>{
    switch(contractStatus) {
      case 1:
        return (
          <Button
            variant="contained"
            disableElevation
            color="primary"
            fullWidth
            onClick={() => setConfirmation(true)}
            disabled={loadingc}
          >
            {loadingc ? <CircularProgress size={23} /> : "Mark as Complete"}
          </Button>
        )
      case 4:
        return (
          <div className="contractStatus completed">
            Completed
          </div>
        )
    }
  }

  return (
    <div>
      {payment && 
      <Paper className={"white-card "}>
        <div
          className="card-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {loading ? (
            <Skeleton
              variant="text"
              width="90px"
              style={{ marginLeft: "auto", marginRight: "auto" }}
            />
          ) : (
            "Payment"
          )}

          <Chip
            size="small"
            label={paymentStatuses[paymentStatus]}
            className={"contract-status " + paymentStatuses[paymentStatus]}
          />
        </div>
        <div className="card-body">
          <div className="offer-price-box">
            <div className="offer-item">
              <div className="item-label">
                {loading ? (
                  <Skeleton variant="text" width="105px" />
                ) : (
                  "Offer Amount"
                )}
              </div>
              <div className="item-price">
                {loading ? (
                  <Skeleton variant="text" width="80px" className="ml-auto" />
                ) : (
                  format(payment?.amount)
                )}
              </div>
            </div>
            
              <div className="offer-item">
                <div className="item-label">
                  {loading ? (
                    <Skeleton variant="text" width="116px" />
                  ) : (
                    "Service Charge"
                  )}
                </div>
                <div className="item-price">
                  {loading ? (
                    <Skeleton variant="text" width="50px" className="ml-auto" />
                  ) : (
                    format(accountType == 1 ? payment?.serviceCharge : 0.00)
                  )}
                </div>
              </div>
            

            <div className="offer-total">
              <div className="item-label">
                {loading ? (
                  <Skeleton
                    variant="text"
                    width="50px"
                    className="ml-auto mr-auto"
                  />
                ) : 
                  accountType == 1 ? "Revenue" : "Total Cost"
                }
              </div>
              <div className="item-price">
                {loading ? (
                  <Skeleton
                    variant="text"
                    width="100px"
                    height="28px"
                    className="ml-auto mr-auto"
                  />
                ) :
                  format(accountType == 1 ? payment?.netAmount : payment?.amount)
                }
              </div>
            </div>
          </div>
          {/* {loading ? (
            <Skeleton
              variant="rect"
              width="100%"
              height="36px"
              style={{ borderRadius: 4 }}
              className="ml-auto mr-auto"
            />
          ) : (
            renderActions()
          )} */}
        </div>
      </Paper>
      }

    </div>
  );
}
