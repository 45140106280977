import React from "react";
import { Box } from "@material-ui/core";
import DefaultCurrency from "./DefaultCurrency";
import TimeZoneSelector from "./TimeZoneSelector";
import DateTimeFormat from "./DateTimeFormat";
import TransportMode from "./supplier/TransportMode";
import ShipmentTypes from "./supplier/ShipmentTypes";
import CompanyType from "./supplier/CompanyType";

export default function SettingsList({ handleMultiToggle, data, setData }) {
  const style = {
    title: {
      fontSize: "16px",
      fontWeight: "500",
      color: "rgb(0 0 0 / 50%)",
      paddingBottom: "10px",
    },
  };
  return (
    <div>
      <Box mb={3}>
        <div style={style.title}>Company Type</div>
        <CompanyType data={data} setData={setData} />
        {/* <TransportMode handleMultiToggle={handleMultiToggle} data={data} /> */}
      </Box>

      <Box mb={3}>
        <div style={style.title}>Your services</div>
        <TransportMode handleMultiToggle={handleMultiToggle} data={data} />
      </Box>

      <Box mb={3}>
        <div style={style.title}>Main shipment types</div>
        <ShipmentTypes handleMultiToggle={handleMultiToggle} data={data} />
      </Box>

      {/* <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={style.box}
      >
        <div style={style.title}>Please choose your Time zone</div>
        <TimeZoneSelector />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={style.box}
      >
        <div style={style.title}>Please choose your Default currency</div>
        <DefaultCurrency />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={style.box}
      >
        <div style={style.title}>Please choose your Preferred Date format</div>
        <DateTimeFormat />
      </Box> */}
    </div>
  );
}
