import React from "react";
import { Chip, Box, Avatar } from "@material-ui/core";
import PinDropOutlinedIcon from "@material-ui/icons/PinDropOutlined";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import StarRateOutlinedIcon from "@material-ui/icons/StarRateOutlined";
import EuroIcon from "@material-ui/icons/Euro";
import CheckCircleSharpIcon from "@material-ui/icons/CheckCircleSharp";
import CheckCircleOutlineSharpIcon from "@material-ui/icons/CheckCircleOutlineSharp";
import { AiOutlineCheckCircle, AiFillCheckCircle } from "react-icons/ai";
import { PersonPin, VerifiedUser } from "@material-ui/icons";

export default function Badges(props) {
  const {
    location,
    rating,
    paymentVerified,
    amount,
    currency,
    currencySymbol,
    currencyCode,
  } = props;
  const style = {
    chip: {
      margin: "3px",
      color: "#1c4ef5",
      border: "none",
      paddingLeft: "4px",
      paddingRight: "4px",
    },
    icon: {
      fontSize: "5px",
      color: "inherit",
    }
  };
  return (
    <Box>
      {currency && (
        <Chip
          variant="outlined"
          size="small"
          avatar={<Avatar className="currencyAvatar">{currencySymbol}</Avatar>}
          // icon={
          //   currencySymbol === "$" ? (
          //     <AttachMoneyOutlinedIcon style={style.icon} />
          //   ) : (
          //     <EuroIcon style={style.icon} />
          //   )
          // }
          //label={currencyCode}
          style={style.chip}
          className="chip-color iconChip"
        />
      )}
      {location && (
        <Chip
          variant="outlined"
          size="small"
          // icon={<FiMapPin style={style.icon} />}
          icon={<PersonPin style={style.icon} />}
          label={location}
          style={style.chip}
          className="chip-color"
        />
      )}
      {rating && (
        <Chip
          variant="outlined"
          size="small"
          icon={<StarRateOutlinedIcon style={style.icon} />}
          label={rating + " stars"}
          style={style.chip}
          className="chip-color"
        />
      )}

      {paymentVerified && 
        <Chip
          variant="outlined"
          size="small"
          icon={<VerifiedUser style={style.icon} />}
          //label="Payment Method Verified"
          style={style.chip}
          className="chip-color green iconChip"
        />
      }
      {amount && (
        <Chip
          variant="outlined"
          size="small"
          icon={
            currencySymbol === "$" ? (
              <AttachMoneyOutlinedIcon style={style.icon} />
            ) : (
              <EuroIcon style={style.icon} />
            )
          }
          label={`Spent ${amount}`}
          style={style.chip}
          className="chip-color"
        />
      )}
    </Box>
  );
}
