import React from "react";
import { Grid } from "@material-ui/core";

import Search from "./Search";
import ToggleButtonsFilter from "./ToggleButtonsFilter";
import AutocompleteComponent from "./Autocomplete";

export default function FilterPane(props) {
  const {
    handleInputChange,
    handleToggle,
    shipmentTypes,
    freightTypes,
    handleFilters,
  } = props;

  return (
    <div className="filter-pane">
      <Grid container spacing={3}>
        {/* <Grid item xs={12} sm={4}>
          <Search handleInputChange={handleInputChange} />
        </Grid> */}
        <Grid item xs={12} sm={8}>
          <ToggleButtonsFilter
            handleToggle={handleToggle}
            shipmentTypes={shipmentTypes}
            freightTypes={freightTypes}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <AutocompleteComponent
            label="Origin"
            onChange={(event, option) => {
              handleFilters(
                option ? option.isoCode : null,
                "originCountryCode"
              );
            }}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <AutocompleteComponent 
            label="Destination"
            onChange={(event, option) => {
              handleFilters(
                option ? option.isoCode : null,
                "destinationCountryCode"
              );
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
