import React from "react";
import { Tabs, Tab } from "@material-ui/core";

export default function TabsComponent({ activeTab, setActiveTab }) {
  React.useEffect(() => {
    console.log(activeTab);
  }, [activeTab]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Tabs
      value={activeTab}
      onChange={handleTabChange}
      indicatorColor="primary"
      textColor="primary"
      className="headerTabs"
    >
      <Tab label="All Offers" value={0} />
      <Tab label="Pending" value={1} />
      <Tab label="Accepted" value={2} />
      {/* <Tab label="Rejected" value={3} /> */}
      <Tab label="Withdrawn" value={4} />
    </Tabs>
  );
}
