import React, { useEffect, useState } from "react";
import _get from "lodash/get";
import { Button } from "@material-ui/core";
import { ToggleButton } from "@material-ui/lab";
import { Close as CloseIcon, Add as PlusIcon } from "@material-ui/icons";
import Card from "../Card";
import APIKit from "../../include/APIKit";
import PaymentMethodsList from "./payments/PaymentMethodsList";
import PaymentMethodListSkeleton from "./payments/PaymentMethodListSkeleton";
import AddPaymentMethod from "./payments/AddPaymentMethod";

export default function PaymentMethodsTab(props) {
  const [methods, setMethods] = useState(null);
  const [addOpen, setAddOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getPaymentMethods();
  }, []);

  const getPaymentMethods = () => {
    setLoading(true);
    APIKit.get("PaymentMethods")
      .then(result => {
        if (result.status === 200) {
          if (result.data.isSuccess) {
            setMethods(result.data.record);
          }
        }
        setLoading(false);
      })
      .catch(e => {});
  };

  const refresh = () => {
    getPaymentMethods();
    setAddOpen(false);
  };

  return (
    <div className="tab-content">
      <Card
        variant="white"
        bodyPadding={false}
        headerTitle="Payment Methods"
        headerSubtitle="Manage your billing and payment methods"
        headerAction={
          <Button
            color="primary"
            disableElevation
            startIcon={<PlusIcon style={{ fontSize: 18 }} />}
            onClick={() => setAddOpen(true)}
          >
            Add Method
          </Button>
        }
      >
        {loading ? (
          <PaymentMethodListSkeleton />
        ) : (
          <PaymentMethodsList methods={methods} onDelete={() => refresh()} />
        )}
        {addOpen && (
          <AddPaymentMethod
            open={addOpen}
            onClose={() => setAddOpen(false)}
            refresh={() => refresh()}
          />
        )}
      </Card>
    </div>
  );
}
