import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ConfirmDialog from "../ConfirmDialog";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import VoyagesAutocomplete from "../VoyagesAutocomplete";
import { Alert } from "@material-ui/lab";

export default function TrackingUpdateDialog({milestone, onClose, onUpdate, carrierId, loading, error, accountType}) {

	const [milestoneData, setMilestoneData] = useState({});
	const [newVoyage, setNewVoyage] = useState(false);

	// useEffect(() => {
	// 	setMilestone(milestoneProps)
	// }, [milestoneProps])

	const handleInputChange = (event, value) => {
		setMilestoneData({ ...milestoneData, [event.target.name]: event.target.value });
	};

	const handleDateChange = (value, name) => {
		try {
			value = value.toISOString();
		}
		catch (e) {}
		setMilestoneData({...milestoneData, [name]: value});
	}

	const onConfirm = () => {
		onUpdate({...milestoneData, id: milestone.id, isConfirmed: true, description: ""});
	}
	const onDecline = () => {
		onUpdate({...milestoneData, id: milestone.id, isConfirmed: false, description: ""});
	}

	const CompletionDate = (props) => {
		return (
			<>
				<p className="form-label confirm-label">{currentMsLabel()}</p> 
				<KeyboardDatePicker
					autoOk
					variant="inline"
					size="small"
					inputVariant="outlined"
					label="Completion Date"
					value={milestoneData.completionDate}
					format="MM/dd/yyyy"
					InputAdornmentProps={{ position: "end" }}
					onChange={(value, name) => handleDateChange(value, "completionDate")}
					//error={offer.pickupDate == null && validationStep == 0 ? true : false}
					disableFuture
					fullWidth
				/>
			</>
		)
	}

	const NextDate = (props) => {
		return (
			<>
				<p className="form-label confirm-label">{nextMsLabel()}</p> 
				<KeyboardDatePicker
					autoOk
					variant="inline"
					size="small"
					inputVariant="outlined"
					label="Estimated Date"
					value={milestoneData.nextMilestoneEstimatedDate || null}
					format="MM/dd/yyyy"
					InputAdornmentProps={{ position: "end" }}
					onChange={(value, name) => handleDateChange(value, "nextMilestoneEstimatedDate")}
					//error={offer.pickupDate == null && validationStep == 0 ? true : false}
					disablePast
					fullWidth
				/>
			</>
		)
	}

	const currentMsLabel = () => {
		let location = milestone.address.description;
		switch(milestone.milestoneTypeDescription) {
			case "Pickup":
				return "When was the shipment picked up?"
			case "MainCarriageOriginIn":
				return `When did the shipment arrive at ${location}?`
			case "MainCarriageOriginOut":
				return  `When did the shipment depart at ${location}?`
			case "MainCarriageDestinationIn":
				return  `When did the shipment arrive at ${location}?`
			case "MainCarriageDestinationOut":
				return  `When did the shipment depart from ${location}?`
			case "Delivery":
				return "When was the shipment delivered?"
			 
		}
	}

	const nextMsLabel = () => {
		const nextMs = milestone.nextMs;
		let location = nextMs.address.description;
		switch(nextMs.milestoneTypeDescription) {
		  case "MainCarriageOriginIn":
			return `When will the shipment Arrive at ${location}?`
		  case "MainCarriageDestinationOut":
			return  `When will the shipment Depart from ${location}`
		}
	}

	const handleVoyageChange = (data) => {
		let voyage;
		let vesselImo = data?.vesselImo;

		setNewVoyage(data?.isNew);
		voyage = data ? data?.isNew ? data.inputValue : data.code : null;
		setMilestoneData({...milestoneData, voyage, vesselImo})
	}



	const renderFields = (type) => {
		// TODO: check on all steps: if the step is the last - do not ask for next estimation date.
		switch(type) {
		  case "Pickup":
			return (
			  <>
			  	<CompletionDate />
				<br /><br />
				<NextDate />
			  </>
			)
		  case "MainCarriageOriginIn":
			return (
			  <>
				<CompletionDate />
			  </>
			)
		  case "MainCarriageOriginOut":
			return (
			  <>
				{/* carrier has to be defined before updating this step */}
				{!carrierId && <Alert severity="warning" style={{marginBottom: 25}}>Carrier needs to be selected before selecting a voyage!</Alert>}
				<CompletionDate /><br /><br />
				<VoyagesAutocomplete 
					selection={(data, name) => handleVoyageChange(data)} 
					label="Voyage" 
					carrierId={carrierId}
					size="small"
					canAdd
				/>
				{newVoyage && <>
				<br />
				<TextField
					label="Vessel IMO"
					name="vesselImo"
					value={milestoneData.vesselImo || ""}
					onChange={handleInputChange}
					variant="outlined"
					type="text"
					size="small"
					fullWidth
					InputLabelProps={{ required: false }}
				/>
				</>}
				
				
			  </>
			)
		  case "MainCarriageDestinationIn":
			return (
			  <>
			  	<CompletionDate />
				{!!milestone.nextMs && <>
					<br /><br />
					<NextDate />
				</>}
				
			  </>
			)
		  case "MainCarriageDestinationOut":
			return (
			  <>
				<CompletionDate />
			  </>
			)
		  case "Delivery":
			return (
			  <>
				<CompletionDate />
			  </>
			)
		}
	}

	const renderContent = (type) => {
		switch(type) {
			case "MainCarriageOriginDropOff":
				return "Please confirm that you have delivered the shipment to the Port of loading";
			case "Pickup":
				return "Do you confirm that your shipment has been picked up by the supplier?";
			case "MainCarriageDestinationIn":
				return "Did the supplier deliver your shipment? Once you confirm the delivery you're stating that the supplier did fullfil their obligations and the contract will be completed"
			case "Delivery":
				return "Did the supplier deliver your shipment? Once you confirm the delivery you're stating that the supplier did fullfil their obligations and the contract will be completed";
		}
	}



	return (
		<ConfirmDialog
			open={milestone != false}
			title={accountType == 1 ? "Update Milestone" : "Confirm Milestone"}
			confirmText={accountType == 1 ? "Update" : "Confirm"}
			cancelText={accountType != 2 && "Cancel"}
			onClose={onClose}
			noElevation
			onConfirm={onConfirm}
			disabledAction={loading}
			content={accountType == 2 && renderContent(milestone.milestoneTypeDescription)}
			dangerText={accountType == 2 && "Decline"}
			onDanger={onDecline}
		>
			{(accountType == 1 && milestone) && renderFields(milestone.milestoneTypeDescription)}
			{!!error && <Alert severity="error" style={{marginTop: 25}}>{error}</Alert>}
		</ConfirmDialog>
	)
}