import React, { useState, useEffect } from "react";
import {
  Paper,
  Avatar,
  Grid,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  LinearProgress,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
  Delete as DeleteIcon,
  GetApp as DownloadIcon,
  Image as ImageIcon,
  Backup as BackupIcon,
  Error as ErrorIcon,
} from "@material-ui/icons";
import APIKit from "../../include/APIKit";
import moment from "moment";
import { saveAs } from "file-saver";
import { useStore } from "../../context/StoreContext";

const DocumentsTab = props => {
  const {accountType} = useStore();
  const senderKey = accountType == 1 ? "supplierAttachments" : "clientAttachments";
  const receiverKey = accountType == 1 ? "clientAttachments" : "supplierAttachments";

  const [fileError, setFileError] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const [files, setFiles] = useState(props.files);

  useEffect(() => {
    setFiles(props.files);
  }, [props.files]);

  useEffect(() => {
    if (selectedFiles != "") uploadFile();
  }, [selectedFiles]);

  const [documents, setDocuments] = useState([
    { name: "Dock Receipt", date: "22 May, 2021 18:00", type: 1 },
    { name: "Bill Of Lading", date: "24 May, 2021 23:04", type: 1 },
    { name: "Pikcup Order", date: "2 June, 2021 18:00", type: 1 },
    { name: "Document Of Random", date: "22 May, 2021 18:00", type: 1 },
    { name: "No-Clue File", date: "22 May, 2021 18:00", type: 2 },
    { name: "Shipping Invoice", date: "22 May, 2021 18:00", type: 2 },
  ]);

  const downloadAttachment = (id, name, key) => {
    console.log(key)
    let atts = [...files[key]];
    let objIndex = atts.findIndex(obj => obj.id == id);
    atts[objIndex].download = true;
    setFiles({ ...files, [key]: atts });
    APIKit.get(`attachments/token/${id}`)
      .then(result => {
        if (result.status === 200) {
          saveAs(result.data.record, name);
          atts[objIndex].download = false;
          setFiles({ ...files, [key]: atts });
        }
      })
      .catch(_e => {});
  };

  const downloadSAttachment = (id, name) => {
    let supplierAttachments = [...files.supplierAttachments];
    let objIndex = supplierAttachments.findIndex(obj => obj.id == id);
    supplierAttachments[objIndex].delete = true;
    setFiles({ ...files, supplierAttachments });
    APIKit.get(`attachments/token/${id}`)
      .then(result => {
        if (result.status === 200) {
          saveAs(result.data.record, name);
          supplierAttachments[objIndex].delete = false;
          setFiles({ ...files, supplierAttachments });
        }
      })
      .catch(_e => {});
  };

  const deleteAttachment = (id, _index) => {
    let atts = [...files[senderKey]];
    let objIndex = atts.findIndex(obj => obj.id == id);
    atts[objIndex].delete = true;
    setFiles({ ...files, [senderKey]: atts });
    APIKit.delete(`attachments/${id}`)
      .then(result => {
        if (result.status === 200) {
          setFiles({
            ...files,
            [senderKey]: files[senderKey].filter(
              obj => obj.id !== id
            ),
          });
        }
      })
      .catch(_e => {});
  };

  const handleFile = event => {
    const file = event.target.files[0];
    if (!file) return;
    setFileError(false);
    if (file.size >= 5242880) {
      setFileError("File size must be less than 5MB");
      return false;
    }
    const name = file.name;
    const lastDot = name.lastIndexOf(".");
    const fileName = name.substring(0, lastDot);
    const extension = name.substring(lastDot + 1);
    const newFile = {
      name,
      uploadDate: moment(),
      extension,
      size: file.size,
      loading: true,
    };
    setSelectedFiles(file);
    setFiles({
      ...files,
      [senderKey]: [newFile, ...files[senderKey]],
    });
    console.warn(file);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function uploadFile() {
    const data = new FormData();
    data.append("Name", selectedFiles.name);
    data.append("ContractId", props.contractId);
    data.append("Attachment", selectedFiles);
    data.append("DocumentTypeId", 2);

    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);
      },
    };

    APIKit.post("Attachments", data, config)
      .then(result => {
        if (result.status === 200) {
          if (result.data.isSuccess) {
            let atts = [...files[senderKey]];
            atts[0] = result.data.record;
            setFiles({ ...files, [senderKey]: atts });
          } else {
            setIsError(true);
            setErrorMessage(result.data.message);
          }
        } else {
          setIsError(true);
          setErrorMessage(result.data.message);
        }
        setSelectedFiles("");
      })
      .catch(_e => {});
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const FileIcon = props => {
    return (
      <img
        src={"/img/filetypes/" + props.type + ".svg"}
        className="filetype-icon"
      />
    );
  };

  return (
    <div className="page-content tab">
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <Paper className={"white-card "}>
            <div className="card-header wbtn">
              Sent Documents
              <div className="actions-panel">
                <form>
                  <Button
                    disabled={uploadProgress > 0 && uploadProgress < 100}
                    color="primary"
                    component="label"
                    disableElevation
                    startIcon={<BackupIcon />}
                  >
                    Upload
                    <input
                      type="file"
                      name="files"
                      hidden
                      onChange={event => handleFile(event)}
                    />
                  </Button>
                </form>
              </div>
            </div>
            {/* <div className="card-body" style={{paddingBottom: 5}}>
						<Alert severity="warning" style={{marginBottom: 25}}><AlertTitle>Missing Packing List</AlertTitle>
							The supplier requires you provide a packing list.
							<form>
								<Button color="primary" component="label" variant="contained" disableElevation style={{marginTop: 15}} size="small">
									Upload Now
									<input type="file" name="files" hidden onChange={(event) => handleFile(event)} />            
								</Button>
							</form>
							
						</Alert>
					</div> */}
            <div className="documents-container">
              {fileError != false && (
                <FormHelperText
                  style={{ marginTop: 10, paddingLeft: 25, paddingRight: 25 }}
                  error
                >
                  <ErrorIcon className="errorIcon" />
                  {fileError}
                </FormHelperText>
              )}

              <List style={{ padding: 0 }}>
                {(files && senderKey) && files[senderKey].map((file, index) => (
                  <ListItem
                    key={index}
                    button
                    onClick={(_id, _name, _key) =>
                      downloadAttachment(file.id, file.name, senderKey)
                    }
                    style={{ paddingLeft: 25 }}
                  >
                    <ListItemAvatar>
                      <FileIcon type={file.extension} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={file.name}
                      secondary={
                        file.loading == true
                          ? formatBytes(file.size) + " - Uploading..."
                          : formatBytes(file.size) +
                            " - " +
                            moment(file.uploadDate).format("DD MMM, YYYY HH:mm")
                      }
                    />
                    <ListItemSecondaryAction>
                      {file.delete || file.download || file.loading ? (
                        <CircularProgress
                          variant={
                            file.loading ? "determinate" : "indeterminate"
                          }
                          size={25}
                          value={uploadProgress}
                        />
                      ) : (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={(_id, index) =>
                            deleteAttachment(file.id, index)
                          }
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </div>
          </Paper>
        </Grid>
        <Grid item sm={6}>
          <Paper className={"white-card "}>
            <div className="card-header wbtn">
              Received Documents
              <div className="actions-panel">
                {/* <IconButton aria-label="delete" ><AddIcon fontSize="small" color="primary" /></IconButton> */}
              </div>
            </div>
            <div className="documents-container">
              <List style={{ padding: 0 }}>
                {(files && receiverKey) && files[receiverKey].map((file, index) => (
                  <ListItem
                    key={index}
                    button
                    onClick={(_id, _name, _key) =>
                      downloadAttachment(file.id, file.name, receiverKey)
                    }
                    style={{ paddingLeft: 25 }}
                  >
                    <ListItemAvatar>
                      <FileIcon type={file.extension} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={file.name}
                      secondary={
                        formatBytes(file.size) +
                        " - " +
                        moment(file.uploadDate).format("DD MMM, YYYY HH:mm")
                      }
                    />
                    <ListItemSecondaryAction>
                      {file.download && (
                        <CircularProgress variant="indeterminate" size={25} />
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default DocumentsTab;
