import React, { useEffect, useState } from "react";
import {
  Paper,
  Avatar,
  Button,
  IconButton,
  TextField,
  Badge,
  LinearProgress,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import APIKit from "../../include/APIKit";
import Message from "../message/Message";
import { useStore } from "../../context/StoreContext";
import moment from "moment";
import MessageFooter from "../message/MessageFooter";
import { saveAs } from "file-saver";


const StyledBadge = withStyles(theme => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);


export default function MessagesTab(props) {
  const { userData, connection } = useStore();
  const {contractId, supplier, variant, reference, offerId} = props;
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [page, setPage] = useState(1);

  useEffect(() => {
    setLoadingMessages(true);
    fetchMessages(props.offerId, 1);
    if(props.markAsRead) props.markAsRead(offerId);
    console.log(offerId)
  }, [offerId])


  const sendMessage = () => {
    if(!message) {
      //return false;
    }
    console.log(attachments)
    setMessages([{
      content: message,
      isSent: true,
      messageDate: moment(),
      isAttachment: attachments ? true : false,
      attacments: Array.from(attachments)
    }, ...messages]);

    if(props.updateParent) {
      props.updateParent({
        lastModifiedDate: moment(),
        isLastMessageAttachment: attachments ? true : false,
        textContent: message
      }, offerId)
    }

    setMessage("");

    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log(percentCompleted);
      },
    };

    const data = new FormData();
    data.append("content", message);
    data.append("offerId", offerId);

    Array.from(attachments).forEach(file => data.append('attachments', file));
  
    // data.append("attachments", attachments[0]);

    APIKit.post('messages', data, config).then(result => {
			if (result.status === 200) {
			  
			}
			//setLoading(false)
		}).catch(e => {})
  }

  useEffect(() => {
    if (connection) {
      connection.on('MessageReceived', msg => {
        console.log(msg);
        console.log(messages)
        setMessages([{
          content: msg.content,
          isSent: msg.isSent,
          messageDate: msg.messageDate,
          isAttachment: msg.isAttachment,
          attacments: msg.attacments
        }, ...messages]);
        console.log(messages)
      });
    }
  }, [connection, messages]);

  useEffect(() => {
    //if(!searchQuery) return;
    fetchMessages(offerId, page)
  }, [searchQuery]);


  const fetchMessages = (offerId, page, more) => {
    if(more) {
      setFetchingMore(true);
    }
    // else {
    //   setMessages([]);
    // }
    if(searchQuery) {
      //setMessages([]);
      setPage(1)
    }
    let query = `?offerId=${offerId}&pageSize=10&pageNumber=${page}&searchQuery=${searchQuery}`;
    APIKit.get('messages'+query).then(result => {
      if (result.status === 200) {
        if(result.data.record.totalPages >= page) {
          if(more) {
            setMessages([...messages, ...result.data.record.items]);
          }
          else {
            setMessages(result.data.record.items);
          }
          setPage(page+1)
        }
        
      }
      setFetchingMore(false);
      setLoadingMessages(false);
    }).catch(e => {});
  }


  const searchMessages = (searchQuery, pageSize = 100) => {
    setFetchingMore(true);
    let query = `?offerId=${offerId}&pageSize=${pageSize}&searchQuery=${searchQuery}`;
    APIKit.get('messages'+query).then(result => {
      if (result.status === 200) {
        setMessages(result.data.record.items);
      }
      setFetchingMore(false);
    }).catch(e => {});
  }

  const onScroll = (e) => {
    if(searchQuery) {
      return;
    }
    if (Math.abs(e.target.scrollTop - e.target.clientHeight) === e.target.scrollHeight) {
      console.log('✅ ~ Reached bottom');
      fetchMessages(offerId, page, true);
    }
  }

  const downloadAttachment = (id, name) => {
    setDownloading(true)
    // let clientAttachments = [...files.clientAttachments];
    // let objIndex = clientAttachments.findIndex(obj => obj.id == id);
    // clientAttachments[objIndex].delete = true;
    // setFiles({ ...files, clientAttachments });
    APIKit.get(`attachments/token/${id}`)
      .then(result => {
        if (result.status === 200) {
          saveAs(result.data.record, name);
          //clientAttachments[objIndex].delete = false;
          //setFiles({ ...files, clientAttachments });
        }
        setDownloading(false)
      })
      .catch(_e => { setDownloading(false)});
  };

  return (
    <div className={`page-content tab ${variant === 'conversation' && "conversation-container"}`}>
      <Paper className={"white-card messages-box"} style={{overflow: 'hidden'}}>
        <div className="card-header wbtn">
          <div className="supplier-box">
            <div className="supplier-logo">
              <StyledBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
              >
                <Avatar
                  className="supplier-pic"
                  src={supplier?.logoUrl}
                >
                  {supplier?.fullName.match(/\b(\w)/g).join("").toUpperCase()}
                </Avatar>
              </StyledBadge>
            </div>
            <div className="supplier-content">
              <div className="supplier-name">{supplier?.fullName}</div>
              <div className="supplier-time">
                {variant === "conversation" ? `Offer #${reference?.offerNumber}` : "Online | Local time June 2, 1:45 PM"}
              </div>
            </div>
          </div>
          <div className="actions-panel" style={{display: 'flex', alignItems: 'center'}}>
            {searchOpen && <TextField onChange={(event) => {setSearchQuery(event.target.value);}} autoFocus />}
            <IconButton
              aria-label="delete"
              style={{ marginTop: 10, marginRight: 5 }}
              onClick={()=> setSearchOpen(!searchOpen)}
            >
              <SearchIcon style={{ color: "#8c92a1" }} />
            </IconButton>

            

          </div>
          {(downloading || fetchingMore) && <LinearProgress className="bottomProgress" />}
        </div>

        <div className="messages-container" onScroll={onScroll}>
          {!loadingMessages ? messages.map((message, key) => (
            <Message 
              name={message.isSent ? userData?.fullName : supplier?.fullName} 
              avatar={message.isSent ? userData?.profilePictureUrl : supplier?.logoUrl} 
              message={message} 
              key={key} 
              downloadAttachment={downloadAttachment}
            />
          )) : (
            [1,2,3].map((message, key) => (
              <Message 
                loading={loadingMessages}
                key={key}
              />
            ))
          )}
       
        </div>

        <MessageFooter
          onInputChange={setMessage}
          setAttachments={setAttachments}
          onSubmit={sendMessage}
          message={message}
        />

      </Paper>
    </div>
  );
}
