import React, { useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton, InputBase } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import InfiniteScroll from "react-infinite-scroll-component";

import APIKit from "../../include/APIKit";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { Business, Close, Person, Search as SearchIcon } from "@material-ui/icons";

export default function MessageDialog(props) {
  const { open,offerId, close, addAction } = props;

  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const classes = useStyles();

  const sendMessage = () => {
		if(!message) return false;
		setLoading(true);
		const data = new FormData();
		data.append("content", message);
		data.append("offerId", offerId);

    	APIKit.post('messages', data).then(result => {
			if (result.status === 200) {
			  close();
			  setLoading(false);
			}
			//setLoading(false)
		}).catch(e => {})
 	}

  return (
    <React.Fragment>
      <Dialog
        onClose={close}
        open={!!offerId}
        maxWidth={"sm"}
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          	Message Supplier
		  	<IconButton aria-label="close" className={classes.closeButton} onClick={close}>
          		<Close />
        	</IconButton>
          
        </DialogTitle>

        <DialogContent style={{ padding: 0, height: 'auto' }} dividers>

		
			<InputBase
				placeholder="Write your message..."
				value={message}
				classes={{
				root: classes.inputRoot,
				input: classes.inputInput,
				}}
				inputProps={{
					maxLength: 2500,
				}}
				onChange={(event) => setMessage(event.target.value)}
				rows={6}
				multiline
			/>
				
         
        </DialogContent>
		<DialogActions>
          <Button onClick={sendMessage} disabled={loading} variant="contained" disableElevation color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    marginTop: 15,
    width: "100% !important",
    //boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.13)',
    border: "1px solid #e0e0e0",
    background: "#fff",
    [theme.breakpoints.up("sm")]: {
      marginLeft: 0,
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(4),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#80878f",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
    //padding: "2px 0px",
  },
  inputInput: {
    padding: theme.spacing(1, 3, 1, 3),
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "#80878f",
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
