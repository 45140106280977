import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Button } from "@material-ui/core";
import { Beenhere, VerifiedUser, Error } from "@material-ui/icons";
import moment from "moment";
import _get from "lodash/get";

export default function ContractStatus({status, accountType, deliveryDate, onConfirm, openRating, rated}) {
	// const status = 5;
	const statusName = ["In Progress", "Canceled", "Expired", "completed", "ended", "conflict"]
	const statusIcon = ["", "", "", <Beenhere /> , <VerifiedUser />, <Error />]
	const renderAction = (status) => {
		switch(status) {
			case 4:
				return (
					accountType == 2 &&
					<Button color="primary" variant="contained" disableElevation onClick={onConfirm} >
						Confirm
					</Button>
				)
			case 5:
				return (
					!rated &&
					<Button color="inherit" variant="contained" disableElevation onClick={openRating}  >
						Rate Experience
					</Button>
				)
			case 6:
				return (
					<Button color="inherit" variant="contained" disableElevation >
						Open a Dispute
					</Button>
				)
		}
	}
	const getContent = () => {
		switch(status) {
			case 1:
				return "In Progress";
			case 4:
				return accountType == 1 ? (
					<>
						<AlertTitle>You've delivered this shipment! Awaiting client's confirmation.</AlertTitle>
					</>
				) : (
					<>
						<AlertTitle>Your shipment has been delivered! Please confirm before {moment(deliveryDate, "YYYY-MM-DD").add(3, 'days').format("LL")}</AlertTitle>
					</>
				) 
			case 5:
				return <AlertTitle>Contract completed!</AlertTitle>;
			case 6:
				return accountType == 1 ? (
					<>
						<AlertTitle>Client has rejected your delivery notice! Please message the client or open a ticket with us.</AlertTitle>
					</>
				) : (
					<>
						<AlertTitle>You rejected the delivery notice! Let us know if you're unable to resolve it with the supplier.</AlertTitle>
					</>
				)

		}
	}
	return (status == 4 || status == 5 || status == 6) && (
		<Alert icon={statusIcon[[status-1]]} severity="info" className={`contractStatus-bar status-${statusName[status-1]}`}
			action={renderAction(status)}
		>
			{getContent()}
		</Alert>
	)
}



/*

InProgress = 1,
Canceled = 2,
Expired = 3,
Completed - supplier marks it as complete = 4,
client accepts - ended -> 5
decline -> conflict 6




*/