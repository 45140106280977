import { useEffect, useState } from "react";
import APIKit from "../../include/APIKit";
import axios from "axios";

export default function usePartiesSearch(query, pageNumber) {
  const [parties, setParties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setParties([]);
  }, [query]);

  useEffect(() => {
    let cancel;

    setLoading(true);
    setError(false);

    const filters = {
      query: query,
      pageNumber: pageNumber,
      pageSize: 10,
    };
    APIKit.post("parties/search", filters, {
      cancelToken: new axios.CancelToken(c => (cancel = c)),
    })
      .then(result => {
        if (result.status === 200) {
          // console.log(result.data.record);
          setParties(prevParties => {
            return [
              ...prevParties,
              ...result.data.record.items.map(item => item),
            ];
          });

          setHasMore(result.data.record.items.length > 0);
          setLoading(false);
        }
      })
      .catch(e => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => cancel();
  }, [query, pageNumber]);
  return { loading, error, parties, hasMore };
}
