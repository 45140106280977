import React from "react";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";

import {
  FormControl,
  InputLabel,
  Select,
  Chip,
  MenuItem,
  Input,
} from "@material-ui/core";

const BootstrapInput = withStyles(theme => ({
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "7px 26px 7px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    lineHeight: "normal",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

export default function CurrencyFilter(props) {
  const { currency, handleChange } = props;

  const [open, setOpen] = React.useState(false);
  // const [currency, setCurrency] = React.useState("");

  const useStyles = makeStyles(theme => ({
    formControl: {
      minWidth: 120,
    },
  }));
  const classes = useStyles();
  return (
    <FormControl
      variant="outlined"
      className={`${classes.formControl} currency-filter-input`}
    >
      <InputLabel id="demo-simple-select-outlined-label">Currency</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={currency}
        onChange={handleChange}
        label="Currency"
        input={<BootstrapInput />}
      >
        {/* <MenuItem value={null}>All</MenuItem> */}
        <MenuItem value={"EUR"}>Euro</MenuItem>
        <MenuItem value={"USD"}>Dollar</MenuItem>
      </Select>
    </FormControl>
  );
}
