/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { Button, CircularProgress } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { HiPencil } from "react-icons/hi";

import Card from "../../Card";
import APIKit from "../../../include/APIKit";
import UserInfo from "./UserInfo";
import UserInfoForm from "./UserEditForm";

export default function AccountCard(props) {
  const { setOpen, setSeverity, setAlertMessage } = props;

  const [editMode, setEditMode] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [personalInfo, setPersonalInfo] = React.useState({
    firstName: "",
    lastName: "",
    fullName: "",
    email: "",
  });

  const save = () => {
    setLoading(true);
    APIKit.get("fake")
      .then(res => {
        if (res.status === 200) {
          setLoading(false);
          setOpen(true);
          setSeverity("success");
          setAlertMessage("Saved successfully");
          setTimeout(() => {
            setOpen(open => !open);
          }, 5000);
        }
        setEditMode(editMode => !editMode);
      })
      .catch(e => {
        setOpen(open => !open);
        setSeverity("error");
        setEditMode(false);
        setAlertMessage("Something went wrong, please try again");
        setLoading(false);

        setTimeout(() => {
          setOpen(open => !open);
        }, 5000);
      });
  };

  const edit = () => {
    setEditMode(editMode => !editMode);
  };

  const getUserInfo = () => {
    APIKit.get("authentication/details")
      .then(res => {
        if (res.status === 200) {
          setPersonalInfo({
            ...personalInfo,
            fullName: res.data.record.fullName,
            firstName: res.data.record.firstName,
            lastName: res.data.record.lastName,
          });
        }
      })
      .catch(e => {});
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div>
      <Card
        variant="white"
        bodyPadding={false}
        headerTitle="Account"
        // headerSubtitle="Change your default settings"
        headerAction={
          editMode ? (
            <Button
              color="primary"
              disableElevation
              startIcon={
                loading ? (
                  <CircularProgress style={{ width: 20, height: 20 }} />
                ) : (
                  <SaveIcon style={{ fontSize: 20 }} />
                )
              }
              onClick={() => save()}
            >
              {loading ? "Saving changes..." : "Save changes"}
            </Button>
          ) : (
            <Button
              color="primary"
              disableElevation
              startIcon={<HiPencil style={{ fontSize: 20 }} />}
              onClick={() => edit()}
            >
              Edit
            </Button>
          )
        }
      >
        {editMode ? (
          <UserInfoForm
            personalInfo={personalInfo}
            setPersonalInfo={setPersonalInfo}
          />
        ) : (
          <UserInfo
            name={personalInfo.fullName}
            email={"shipper@marketplace.com"}
            // email={personalInfo.email}
          />
        )}
      </Card>
    </div>
  );
}
