import React from "react";
import PersonalInfoForm from "./PersonalInfoForm";
import { Box } from "@material-ui/core";
import AvatarNameContainer from "./AvatarNameContainer";

export default function PersonalInfo(props) {
  const { personalInfo, setPersonalInfo, loading, ImageUrl, setImageUrl } =
    props;

  const style = {
    box: {
      padding: "20px 30px",
      borderBottom: "1px solid rgb(241, 241, 241)",
    },
    title: {
      fontSize: 18,
      fontWeight: "500",
      color: "rgba(0, 0, 0, 50%)",
      paddingBottom: "15px",
    },
  };
  return (
    <Box style={style.box}>
      <div style={style.title}>Personal Information</div>
      <AvatarNameContainer
        defaultUrl={personalInfo?.profilePictureUrl}
        name={personalInfo?.fullName}
        loading={loading}
        ImageUrl={ImageUrl}
        setImageUrl={setImageUrl}
      />
      <PersonalInfoForm
        personalInfo={personalInfo}
        setPersonalInfo={setPersonalInfo}
      />
    </Box>
  );
}
