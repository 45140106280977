import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

let defaultCurrency = localStorage.getItem("default currency");

export default function DefaultCurrency() {
  const [currencies, setCurrencies] = useState(["USD", "EUR"]);
  const [currency, setCurrency] = useState("");

  const handleChange = (e, value) => {
    setCurrency(() => value);
    localStorage.setItem("default currency", value);
  };
  return (
    <Autocomplete
      autoComplete
      autoHighlight
      id="combo-box-demo"
      options={currencies}
      getOptionLabel={option => option}
      style={{ width: 300 }}
      renderInput={params => (
        <TextField {...params} label="Default currency" variant="outlined" />
      )}
      defaultValue={defaultCurrency}
      onChange={handleChange}
    />
  );
}
