import React from "react";
import { Box } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export default function PassInfo() {
  const style = {
    margin: {
      marginRight: "10px",
      color: "#00b437",
    },
    box: {
      padding: "20px 30px",
    },
    title: {
      fontSize: "16px",
    },
    subtext: {
      fontSize: "14px",
      color: "#828282",
    },
  };
  return (
    <Box style={style.box} display="flex">
      <div style={style.margin}>
        <CheckCircleIcon />
      </div>
      <div>
        <div style={style.title}>Password has been set</div>
        <div style={style.subtext}>
          A strong password must be at least 6 character long with symbols,
          numbers, a minimum of 1 capital and lower letter
        </div>
      </div>
    </Box>
  );
}
