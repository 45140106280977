import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useStore } from "../context/StoreContext";

function PrivateRoute({ component: Component, render: Render = null, role, ...rest }) {
  const { authToken, userData, accountType } = useStore();
  if(role) {
    if(role != accountType)
    {
      window.location.href="/";
      return false;
    }
  }
  return (
    <Route
      {...rest}
      render={props =>
        authToken ? (
          Render ? (
            Render(props)
          ) :
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { referer: props.location } }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;





// function PrivateRoute({ component: Component, ...rest }) {
//   const { authToken } = useAuth();

//   return (
//     <Route
//       {...rest}
//       render={props =>
//         authToken ? (
//           <Component {...props} />
//         ) : (
//           <Redirect
//             to={{ pathname: "/login", state: { referer: props.location } }}
//           />
//         )
//       }
//     />
//   );
// }






// function PrivateRoute({ component: Component, ...rest }) {
//   // get authentication token and user type
//   const { authToken } = useAuth();
//   const userType = localStorage.getItem("userType");

//   return (
//     <Route
//       {...rest}
//       render={props => {
//         // check if the user is logged in
//         if(authToken)
//         {
//           // supplier page? user is not a supplier? redirect
//           if((rest.for == "supplier") && userType != 1) {
//             return <Redirect to={{ pathname: "/", state: { referer: props.location } }} />;
//           }
//           // shipper page? user is not a shipper? redirect
//           if((rest.for == "shipper") && userType != 2 ) {
//             return <Redirect to={{ pathname: "/", state: { referer: props.location } }} />;
//           }
//           // all good? display
//           return <Component {...props} />;
//         }
//         else
//         {
//           // redirect to the login page
//           return <Redirect to={{ pathname: "/login", state: { referer: props.location } }} />
//         }
//       }}
//     />
//   );
// }