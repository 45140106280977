import React from 'react';
import PropTypes from 'prop-types'
import { Avatar, Button, IconButton, Paper, Tooltip } from '@material-ui/core';
import { Add, Edit, Error, PersonAdd } from '@material-ui/icons';
import { Alert, AlertTitle, Skeleton } from '@material-ui/lab';
import { useStore } from '../../context/StoreContext';

export default function CarrierBox(props) {
	const {type, title, party, missing, openDialog, loading, edit, status} = props;
	const { accountType } = useStore();

	const canUpdate = (accountType == 1 && status != 5) ? true : false;

	return (
		<Paper className={"white-card "} >
			<div className="card-header wbtn">
				{loading ? <Skeleton variant="text" width="78px" /> : title}
				{canUpdate && 
					<div className="actions-panel">
						<Tooltip title="Assign a Carrier"><IconButton aria-label="delete" onClick={() => openDialog(true)} ><Add fontSize="small" color="primary" /></IconButton></Tooltip>
					</div>
				}
			</div>
			<div className="card-body">
				{missing ? (
					<div>
						{canUpdate ? 
						<Alert severity="warning"
							action={
								canUpdate && 
								<Button color="inherit" size="small" onClick={() => openDialog(true)}>
									Assign
								</Button>
							}
						>
							Not assigned!
						</Alert>
						:
							<div className="party-address">No carrier has been assigned!</div>
						}
                        
					</div>
				) : (
					<div className="party-item">
							{loading ? 
								<Skeleton variant="text" width="80%" />
								: 
								<React.Fragment>
									<div className="supplier-box" style={{ width: "100%" }}>
										<div className="supplier-logo">
											{loading ? (
											<Skeleton
												variant="rect"
												width={45}
												height={45}
												style={{ borderRadius: 35 }}
											/>
											) : (
												<Avatar className="supplier-pic" src={party?.logoUrl}>
													{party?.logoAbbreviation}
												</Avatar>
											)}
										</div>
										<div className="supplier-content" style={{ width: "100%" }}>
											<div className="supplier-name" style={{marginBottom: 0}}>
											{loading ? (
												<Skeleton variant="text" width="40%" />
											) : (
												party?.name
											)}
											</div>
											<div className="supplier-location">
											{loading ? (
												<Skeleton variant="text" width="80%" />
											) : (
												<React.Fragment>
												{party?.description}
												</React.Fragment>
											)}
											</div>
										</div>
									</div>									
								</React.Fragment>
							}
					</div>
				)}
			</div>
		</Paper>
	)
}

CarrierBox.propTypes = {
  title: PropTypes.string,
  party: PropTypes.object,
  type: PropTypes.string,
  missing: PropTypes.bool,
};
