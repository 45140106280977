import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {
	Button,
	IconButton,
	Typography,
	Dialog,
	CircularProgress
} from '@material-ui/core';

import { 
	ToggleButton, 
	ToggleButtonGroup,
	Alert,
} from '@material-ui/lab';

import {
	Close as CloseIcon
} from '@material-ui/icons';



export default function ConfirmDialog({children, content, onConfirm, open, title, onClose, confirmText, cancelText, noElevation, disabledAction, dangerText, onDanger, hideFooter}) {

	return (
		<div>
		<Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth="sm"  className="add-payment-dialog">
			<DialogTitle id="form-dialog-title" onClose={onClose} >{title}</DialogTitle>
			
			<DialogContent dividers style={{borderTop: 0}}>
				{content || children}
			</DialogContent>
			{!hideFooter && 
				<DialogActions>
					{cancelText && 
						<Button onClick={onClose} color="primary">
							{cancelText}
						</Button>
					}

					{dangerText && 
						<Button onClick={onDanger} color="#ef3c3b" disableElevation={noElevation} className="danger-dialog-btn">
							{dangerText}
						</Button>
					}
				
					<Button onClick={onConfirm} style={{minWidth: 68}} disabled={disabledAction} variant="contained" disableElevation={noElevation} color="primary">
						{confirmText || "Confirm"}
					</Button>

				</DialogActions>
			}
		</Dialog>
		</div>
	);
}







const styles = (theme) => ({
	root: {
		margin: 0,
		padding: '16px 24px',
		borderBottom: '1px solid #eee'
	},
	DialogContent: {
		margin: 0
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});


const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: '24px',
		borderColor: '#eee'
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: '12px 24px',
	},
}))(MuiDialogActions);
