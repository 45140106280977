export const taxreg = {
    US: /^[0-9]{9}$/,
    AT: /^[ATU | atu]+([0-9]){8}$/,
    BE:	/^BE[0-9]{10,10}$/,
    BG:	/^BG[0-9]{9,9}$|^BG[0-9]{10,10}$/,
    CY:	/^CY[0-9]{8,8}[A-Z]{1,1}$/,
    CZ:	/^CZ[0-9]{8,10}$/,
    DE:	/^DE[0-9]{9,9}$/,
    EE:	/^EE[0-9]{9,9}$/,
    ES:	/^ES[a-zA-Z]{1,1}[0-9]{7,7}[a-zA-Z]{1,1}$/,
    FI:	/^FI[0-9]{8,8}$/,
    FR:	/^FR[A-Z0-9]{2,2}[0-9]{9,9}$/,
    GB:	/^GB[0-9]{9,9}$|^GB[0-9]{12,12}$|^GBGD[0-9]{3,3}$|^GBHA[0-9]{3,3}$/,
    HU:	/^HU[0-9]{11,11}$/,
    IE:	/^IE[0-9]{1,1}[A-Z0-9]{1,1}[0-9]{5,5}[A-Z]{1,1}$|^IE[0-9]{7,7}[A-W]{1,1}[A-I]{1,1}$/,
    IT:	/^IT[0-9]{11,11}$/,
    LT:	/^LT[0-9]{9,9}$|^LT[0-9]{12,12}$/,
    LU:	/^LU[0-9]{8,8}$/,
    LV:	/^LV[0-9]{11,11}$/,
    MT:	/^MT[0-9]{8,8}$/,
    NL:	/^NL[A-Z0-9]{9,9}B[A-Z0-9]{2,2}$/,
    PL:	/^PL[0-9]{10,10}$/,
    PT:	/^PT[0-9]{9,9}$/,
    SE:	/^SE[0-9]{12,12}$/,
    SI:	/^SI[0-9]{8,8}$/,
    SK:	/^SK[0-9]{10,10}$/,
    RO:	/^RO[1-9]{1,1}[0-9]{1,9}$/,
    EL:	/^EL[0-9]{9,9}$/,
    HR:	/^HR[0-9]{11,11}$/,
    BR:	/^[0-9]{2,2}\.[0-9]{3,3}\.[0-9]{3,3}\/[0-9]{4,4}\-[0-9]{2,2}$ or ^[0-9]{3,3}\.[0-9]{3,3}\.[0-9]{3,3}\-[0-9]{2,2}$/,
    CA:	/^[a-zA-Z0-9]{9,9}$/,
    MX:	/^[a-zA-Z0-9]{12,13}$/,
    AU:	/^[0-9]{11,11}$/,
    AE:	/^[0-9]{10,10}$/,
    IN:	/^[a-zA-Z0-9]{15,15}$/,
    HK:	/^[0-9]{8,8}$/,
    NZ:	/^[0-9]{9,9}$/,
    JP:	/^[0-9]{13,13}$ or ^[0-9]{5,5}$/,
    MY:	/^[0-9]{8,8}$ or ^[a-zA-Z0-9]{12,13}$ or ^[a-zA-Z0-9]{3,3}\-[0-9]{4,4}-[0-9]{8,8}$/,
    CH:	/^[0-9]{6,6}$ or ^CHE\-[0-9]{3,3}\.[0-9]{3,3}\.[0-9]{3,3}MWST$/,
    US: /^[0-9]{9}$/,
    AT: /^[ATU | atu]+([0-9]){8}$/,
    BE:	/^BE[0-9]{10,10}$/,
    BG:	/^BG[0-9]{9,9}$|^BG[0-9]{10,10}$/,
    CY:	/^CY[0-9]{8,8}[A-Z]{1,1}$/,
    CZ:	/^CZ[0-9]{8,10}$/,
    DE:	/^DE[0-9]{9,9}$/,
    EE:	/^EE[0-9]{9,9}$/,
    ES:	/^ES[a-zA-Z]{1,1}[0-9]{7,7}[a-zA-Z]{1,1}$/,
    FI:	/^FI[0-9]{8,8}$/,
    FR:	/^FR[A-Z0-9]{2,2}[0-9]{9,9}$/,
    GB:	/^GB[0-9]{9,9}$|^GB[0-9]{12,12}$|^GBGD[0-9]{3,3}$|^GBHA[0-9]{3,3}$/,
    HU:	/^HU[0-9]{11,11}$/,
    IE:	/^IE[0-9]{1,1}[A-Z0-9]{1,1}[0-9]{5,5}[A-Z]{1,1}$|^IE[0-9]{7,7}[A-W]{1,1}[A-I]{1,1}$/,
    IT:	/^IT[0-9]{11,11}$/,
    LT:	/^LT[0-9]{9,9}$|^LT[0-9]{12,12}$/,
    LU:	/^LU[0-9]{8,8}$/,
    LV:	/^LV[0-9]{11,11}$/,
    MT:	/^MT[0-9]{8,8}$/,
    NL:	/^NL[A-Z0-9]{9,9}B[A-Z0-9]{2,2}$/,
    PL:	/^PL[0-9]{10,10}$/,
    PT:	/^PT[0-9]{9,9}$/,
    SE:	/^SE[0-9]{12,12}$/,
    SI:	/^SI[0-9]{8,8}$/,
    SK:	/^SK[0-9]{10,10}$/,
    RO:	/^RO[1-9]{1,1}[0-9]{1,9}$/,
    EL:	/^EL[0-9]{9,9}$/,
    HR:	/^HR[0-9]{11,11}$/,
    BR:	/^[0-9]{2,2}\.[0-9]{3,3}\.[0-9]{3,3}\/[0-9]{4,4}\-[0-9]{2,2}$ or ^[0-9]{3,3}\.[0-9]{3,3}\.[0-9]{3,3}\-[0-9]{2,2}$/,
    CA:	/^[a-zA-Z0-9]{9,9}$/,
    MX:	/^[a-zA-Z0-9]{12,13}$/,
    AU:	/^[0-9]{11,11}$/,
    AE:	/^[0-9]{10,10}$/,
    IN:	/^[a-zA-Z0-9]{15,15}$/,
    HK:	/^[0-9]{8,8}$/,
    NZ:	/^[0-9]{9,9}$/,
    JP:	/^[0-9]{13,13}$ or ^[0-9]{5,5}$/,
    MY:	/^[0-9]{8,8}$ or ^[a-zA-Z0-9]{12,13}$ or ^[a-zA-Z0-9]{3,3}\-[0-9]{4,4}-[0-9]{8,8}$/,
    CH:	/^[0-9]{6,6}$ or ^CHE\-[0-9]{3,3}\.[0-9]{3,3}\.[0-9]{3,3}MWST$/,
}