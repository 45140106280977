import React from "react";
import {
  FormControlLabel,
  Card,
  CardContent,
  TextField,
  FormGroup,
  Checkbox,
  Button
} from '@material-ui/core';

import {
  DirectionsBoatOutlined as DirectionsBoatOutlinedIcon,
  DirectionsBoat as DirectionsBoatIcon,
  DirectionsRailway as DirectionsRailwayIcon,
  DirectionsRailwayOutlined as DirectionsRailwayOutlinedIcon
} from '@material-ui/icons';

import {
  Autocomplete
} from '@material-ui/lab';

import InputRange from 'react-input-range';

function OfferFilters(props) {
  

  const [priceValue, setPriceValue] = React.useState({min: props.minPrice, max: props.maxPrice});
  const [durationValue, setDurationValue] = React.useState({min: props.minDay, max: props.maxDay});
  const [country, setCountry] = React.useState([]);

  const [transportModes, setTransportModes] = React.useState({
    ocean: false,
    land: false
  });

  const requestFilters = props.requestFilters;

  const requestServices = props.requestServices;

  const [filterTM, setFilterTM] = React.useState([]);


  const handlePriceChange = (value) => {
    setPriceValue(value)
  }
  const handlePriceComplete = (value) => {
    props.handleMinMax('minPrice', 'maxPrice', value)
  }
  const handleDurationChange = (value) => {
    setDurationValue(value);
  }
  const handleDurationComplete = (value) => {
    props.handleMinMax('minDay', 'maxDay', value)
  }
  const handleCountryChange = (event, option) => {
    setCountry(option);
    let tempCountries = [];
    for (var i in option) {
      tempCountries.push(option[i].isoCode)
    }
    props.changeCountry(tempCountries)
  }
  const handleCheckbox = (event) => {
    setTransportModes({ ...transportModes, [event.target.name]: event.target.checked });
    
    let tmArr = filterTM;
    let value = parseFloat(event.target.value);
    let index = tmArr.findIndex(t => t === value);
    if(index > -1) {
      tmArr = [...tmArr.slice(0, index), ...tmArr.slice(index + 1)]
    } else {
      tmArr.push(value);
    }
    setFilterTM(tmArr);
    props.handleCheckbox('freightTypeIds', tmArr);
  }

  const resetFilters = () => {
    setFilterTM([]);
    setTransportModes({ocean: false, land: false});
    setPriceValue({min :props.minPrice, max: props.maxPrice});
    setDurationValue({min: props.minDay, max: props.maxDay});
    setCountry([]);
    props.resetFilters();
  }
  


	return (
		<div>
      <Card elevation={1} className="filter-card">
          <div className="card-header-2">
            Filters
            <Button color="primary" size="small" className="reset-filter-btn" onClick={resetFilters} disabled={
              !(priceValue.min !== props.minPrice || priceValue.max !== props.maxPrice || durationValue.min !== props.minDay || durationValue.max !== props.maxDay || country.length !== 0 || filterTM.length !== 0)
            }>Reset</Button>
          </div>
          <CardContent>
            <p className="filter-label alt-label">Transport Mode:</p>
            <FormGroup className="alt-box">
              <FormControlLabel
                control={<Checkbox icon={<DirectionsBoatOutlinedIcon />} checkedIcon={<DirectionsBoatIcon />} checked={transportModes.ocean} onChange={handleCheckbox} name="ocean" value={2} />}
                label="Ocean Transport"
              />
              <FormControlLabel
                control={<Checkbox icon={<DirectionsRailwayOutlinedIcon />} checkedIcon={<DirectionsRailwayIcon />} checked={transportModes.land} onChange={handleCheckbox} name="land" value={1} />}
                label="Land Transport"
              />
            </FormGroup>
            <div className="dashed-lane"></div>
            <p className="filter-label">Price: <span className="label-value">{`$${priceValue.min} - $${priceValue.max}`}</span></p>
            <div className="slider-box">
              <InputRange
                minValue={(~~((props.minPrice + 99) / 100) * 100)}
                maxValue={(~~((props.maxPrice + 99) / 100) * 100)}
                value={priceValue}
                formatLabel={value => `$${value}`}
                onChange={handlePriceChange}
                onChangeComplete={handlePriceComplete}
                step={100}
              />
            </div>

            <p className="filter-label">Transit Time: <span className="label-value">{`${durationValue.min} - ${durationValue.max} days`}</span></p>
            <div className="slider-box">
              <InputRange
                minValue={props.minDay}
                maxValue={props.maxDay}
                value={durationValue}
                formatLabel={value => `${value} days`}
                onChange={handleDurationChange}
                onChangeComplete={handleDurationComplete}
              />
            </div>

            
            <div className="dashed-lane"></div>
            <p className="filter-label">Supplier Country:</p>
            <Autocomplete
              options={props.countries} 
              multiple
              onChange={handleCountryChange}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params}  variant="outlined" />}  
              disabled={props.countries == null ? true : false}
              // autoHighlight
              openOnFocus={false}
              size="small"
              className="pl-4"
              value={country}
            />

            {/* <p className="filter-label">Rating:</p>
            <RadioGroup aria-label="gender" name="rating" value={0} onChange={null} >
              <FormControlLabel value="0" control={<Radio checked size="small" />} label="Any Rating"/>
              <FormControlLabel value="4" control={<Radio size="small"  />} label="4 stars & up" />
              <FormControlLabel value="5" control={<Radio size="small"  />} label="Top Rated" />
            </RadioGroup>

            <p className="filter-label">Bookings Completed:</p>
            <RadioGroup aria-label="gender" name="rating" value={0} onChange={null} >
              <FormControlLabel value="0" control={<Radio checked size="small" />} label="Any # of bookings"/>
              <FormControlLabel value="4" control={<Radio size="small"  />} label="1+ bookings completed" />
              <FormControlLabel value="5" control={<Radio size="small"  />} label="100+ bookings completed" />
              <FormControlLabel value="5" control={<Radio size="small"  />} label="1000+ bookings completed" />
            </RadioGroup> */}

          </CardContent>
        </Card>

        <Card elevation={1} className="filter-card">
          <div className="card-header-2">
            Included Services
          </div>
          <CardContent>
            {requestFilters && requestFilters.services.map((service) => (
                    
              <div key={service.id}>
                <FormControlLabel control={  <Checkbox checked={ requestServices && requestServices.includes(service.id) ? true : false }  onChange={id => props.handleServicesCheckbox(service.id)} name="services" color="primary" /> } label={service.name}  />
              </div>
              
            ))}
          </CardContent>
        </Card>
		</div>

	);
}

export default OfferFilters;
