import React from "react";

import { Button } from "@material-ui/core";

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Cancel as CancelIcon,
} from "@material-ui/icons";

import { useHistory } from "react-router";
import APIKit from "../../include/APIKit";

export default function Actions(props) {
  const {
    handleDeleteRequest,
    handleEditRequest,
    handleUnlistRequest,
    request,
  } = props;

  const history = useHistory();

  const [show, setShow] = React.useState({
    edit: false,
    delete: false,
    unlist: false,
  });

  React.useEffect(() => {
    switch (request?.status) {
      case 1:
        request.offersCount === 0 &&
          setShow({ ...show, edit: true, delete: true, unlist: true });
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        setShow({ ...show, delete: true, unlist: true });
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request?.status]);

  //   const handleDeleteRequest = () => {
  //       APIKit.post("requests/delete").then().catch(e =>{
  //         setOpenSnackbar(true);
  //       })
  //   };
  //   const handleUnlistRequest = () => {};
  //   const handleEditRequest = () => {
  //     history.push("/requests/create");
  //   };

  return (
    <>
      {request && (
        <>
          {/* {show.delete && (
            <Button
              className="header-actions-btn danger"
              variant="text"
              startIcon={<DeleteIcon />}
              disableElevation
              onClick={handleDeleteRequest}
            >
              Delete
            </Button>
          )}

          {show.unlist && (
            <Button
              className="header-actions-btn blue"
              variant="text"
              startIcon={<CancelIcon />}
              disableElevation
              onClick={handleUnlistRequest}
            >
              Unlist Request
            </Button>
          )} */}
          {show.edit && (
            <Button
              style={{ marginRight: 10 }}
              color="primary"
              variant="text"
              startIcon={<EditIcon />}
              disableElevation
              onClick={handleEditRequest}
              className="header-actions-btn blue"
            >
              Edit Request
            </Button>
          )}
        </>
      )}
    </>
  );
}
