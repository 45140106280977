import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

import CircularProgress from '@material-ui/core/CircularProgress';


import APIKit from '../include/APIKit';
import { AddCircle } from '@material-ui/icons';
import { Button } from '@material-ui/core';


function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function VoyagesAutocomplete(props) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const fetch = React.useMemo(
    () =>
      throttle((query, active) => {
      //console.log(query)
      setLoading(true);
      let url = "voyages/search?query="+query+"&size=10&carrierId="+props.carrierId;
      if(props.type) url+= "&type="+props.type;
        APIKit.get(url).then(result => {
			if (result.status === 200) {
			  //console.log(result.data);
			  if (active) {
				let newOptions = [];
		
				if (value) {
				  newOptions = [value];
				}
		
				if (result.data.record) {
				  newOptions = [...newOptions, ...result.data.record];
				}
		
				setOptions(newOptions);
				
			  }
			} setLoading(false);
		  }).catch(e => {setLoading(false);})
      }, 200),
    [],
  );


 

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    let query = inputValue;

    fetch(query, active);

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const filter = createFilterOptions();

  return (
    <Autocomplete
      style={{ width: '100%' }}
      
      //selectOnFocus
      clearOnBlur={props.canAdd}
      handleHomeEndKeys={props.canAdd}
      freeSolo={props.canAdd}

      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.code;
      }}
      // getOptionLabel={(option) => (typeof option === 'string' ? option : option.code)}
      getOptionSelected={(option, value) => option.id === value.id}
      //filterOptions={(x) => x}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            code: `Add "${params.inputValue}"`,
            isNew: true
          });
        }

        return filtered;
      }}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
	    size={props.size}
      value={props.value || value}
      loading={loading}
      noOptionsText={"Search voyages by code"}
      onChange={(event, newValue) => {
        props.selection(newValue);
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params}         
        InputProps={{
          ...params.InputProps,
          autoComplete: 'new-password',
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={20} className="autocompleteSpinner" /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
        label={props.label ? props.label : null} variant="outlined" fullWidth error={props.error} helperText={props.helperText || null} />
      )}
      renderOption={(option) => {
        return (
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <span key={option.id} style={{ fontWeight: (option.code.toLowerCase() == inputValue.toLowerCase()) ? 600 : 400 }}>
                {option.code}
              </span>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
