import React from "react";
import { Select, MenuItem } from "@material-ui/core";

export default function CompanyType({ data, setData }) {
  return (
    <div>
      <Select
        onChange={event =>
          setData({ ...data, businessType: event.target.value })
        }
        name="freightType"
        value={data?.businessType}
        data-testid="businessType"
      >
        <MenuItem value={1} data-testid="option1">
          Carrier
        </MenuItem>
        <MenuItem value={2} data-testid="option2">
          Freight Forwarder
        </MenuItem>
        <MenuItem value={0} data-testid="option0">
          NVOCC
        </MenuItem>
      </Select>
    </div>
  );
}
