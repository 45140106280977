// IMPORT SYSTEM MODULES
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory
} from "react-router-dom";

import PrivateRoute from "./include/PrivateRoute";

import "./App.css";
import "./assets/fonts/icomoon.min.css";
import "react-input-range/lib/css/index.css";

// IMPORT UI COMPONENTS
import {
  makeStyles,
  MuiThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useSnackbar } from "notistack";

import { Alert, AlertTitle } from "@material-ui/lab";

// IMPORT PAGES
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Requests from "./pages/Client/Requests";
import SupplierRequests from "./pages/Supplier/Supplier-Requests";
import ViewShipment from "./pages/Client/Shipment-View";

import CreateRequest from "./pages/Client/CreateRequest";

import Register from "./pages/Register";
import CreateOffer from "./pages/Supplier/CreateOffer";
import NotFound from "./components/NotFound";
import Contracts from "./pages/Client/Contracts";
import Contract from "./pages/Client/Contract";
import Parties from "./pages/Client/Parties";
import ConfirmEmail from "./pages/ConfirmEmail";
import SupplierApplication from "./pages/SupplierApplication";
import StripeRefresh from "./pages/StripeRefresh";
import Notifications from "./pages/Notifications";
import { StoreContext, useStore } from "./context/StoreContext";
import PaymentSetupForm from "./components/Stripe/PaymentSetupForm";
import CardSetupForm from "./components/Stripe/CardSetupForm";
import Settings from "./pages/settings/Settings";
import { UtilityProvider } from "./context/UtilityContext";

import {
  MonetizationOn as MonetizationOnIcon,
  Notifications as NotificationsIcon,
  NotificationsActive as NotificationsActiveIcon,
} from "@material-ui/icons";
import ResetPassword from "./pages/ResetPassword";
import Messages from "./pages/Messages";
import Offers from "./pages/Supplier/Offers";
import VerificationEmailSent from "./pages/VerificationEmailSent";
import NotificationContainer from "./containers/NotificationContainer";

//this.props.location.pathname.substr(1)

function App(props) {

  const [state, setState] = useState({
    loading: true,
    userDetails: [],
  });





  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <UtilityProvider>
          <Router>
            <NotificationContainer>
            <Switch>
              {/* SHARED ROUTES */}
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/resetpassword" component={ResetPassword} />
              <Route
                path="/verification-sent"
                component={VerificationEmailSent}
              />

              <Route
                path="/confirm-email/:userid/:token"
                component={ConfirmEmail}
              />
              <PrivateRoute path="/requests/create" component={CreateRequest} />
              <PrivateRoute path="/settings" component={Settings} />

              <Route path="/stripe/sepa" component={PaymentSetupForm} />
              <Route path="/stripe/card" component={CardSetupForm} />
              <Route path="/messages/:id" component={Messages} />
              <Route path="/messages" component={Messages} />
              <Route path="/notifications" component={Notifications} />

              {/* SUPLLIER ROUTES */}
              <Route
                role={1}
                path="/create-profile"
                component={SupplierApplication}
              />
              <PrivateRoute
                role={1}
                path="/payment-gateway/stripe-refresh"
                component={StripeRefresh}
              />
              <PrivateRoute
                role={1}
                path="/find-shipments"
                component={SupplierRequests}
              />
              <PrivateRoute role={1} exact path="/offers" component={Offers} />
              <PrivateRoute role={1} path="/offers/:id" component={Offers} />
              <PrivateRoute
                role={1}
                path="/requests/:id"
                component={SupplierRequests}
              />
              <PrivateRoute
                role={1}
                path="/create-offer/:id"
                component={CreateOffer}
              />

              {/* CLIENT ROUTES */}
              <PrivateRoute role={2} path="/requests" component={Requests} />
              <PrivateRoute
                role={2}
                path="/request/:id"
                component={ViewShipment}
              />
              <PrivateRoute path="/edit-request/:id" component={CreateRequest} />
              <PrivateRoute path="/contract/:id/:tab" component={Contract} />
              <PrivateRoute path="/contract/:id/" component={Contract} />
              <PrivateRoute path="/contracts" component={Contracts} />
              <PrivateRoute role={2} path="/parties" component={Parties} />

              <PrivateRoute
                exact
                path="/"
                render={props => (
                  <Dashboard {...props} userDetails={state.userDetails} />
                )}
              />
              <Route component={NotFound} />
            </Switch>
            </NotificationContainer>
          </Router>
        </UtilityProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

// styles and colors

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    // flexGrow: 1,
    marginLeft: "250px",
    //padding: theme.spacing(3),
  },
}));

const theme = createTheme({
  typography: {
    fontFamily: "ReferenzGrotesk, Roboto, sans-serif"
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#1d4ef5",
        },
        //borderColor: '#dbdee5'
      },
    },
    // MuiIconButton: {
    //   root: {
    //     color: '#8a92a2'
    //   }
    // }
  },
  palette: {
    common: {
      black: "#8e9cb2", // 303446
      white: "#ffffff",
    },
    background: {
      paper: "#ffffff",
      default: "#f5f6f8", //f5f6f8
    },
    primary: {
      light: "#e5ebfc",
      main: "#1d4ef5",
      dark: "#0f4fde",
      contrastText: "#ffffff",
    },
    secondary: {
      // "light": "#b39dff",
      // "main": "#4932ff",
      // "dark": "#342ce3",
      // "contrastText": "#ffffff"
      light: "#e6eefc",
      main: "#f44336",
      dark: "#0f4fde",
      contrastText: "#ffffff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "#262626", // 303446
      secondary: "#80878f", // comment it
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "#c7cdd8",
    },
    // "action" : {
    //   "selected": "#e5ebfc",
    //   "active": "#8f9cb2",
    //   "hover": "#e5ebfc",
    // }
  },
});

// primary?: PaletteColorOptions;
//   secondary?: PaletteColorOptions;
//   error?: PaletteColorOptions;
//   warning?: PaletteColorOptions;
//   info?: PaletteColorOptions;
//   success?: PaletteColorOptions;
//   type?: PaletteType;
//   tonalOffset?: number;
//   contrastThreshold?: number;
//   common?: Partial<CommonColors>;
//   grey?: ColorPartial;
//   text?: Partial<TypeText>;
//   divider?: string;
//   action?: Partial<TypeAction>;
//   background?: Partial<TypeBackground>;
//   getContrastText?: (background: string) => string;

export default App;
