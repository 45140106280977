import React, { Component, useEffect } from 'react'
import {useLocation} from 'react-router';

import { Link as RouterLink } from 'react-router-dom';

import { 
  RiHomeLine, 
  RiAccountCircleLine,
  RiChat3Line, 
  RiFileList2Line, 
  RiFileEditLine, 
  RiGroupLine, 
  RiSettingsLine, 
  RiLogoutCircleLine,
  RiNotification4Line,
  RiSearch2Line
} from "react-icons/ri";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";

import PropTypes from "prop-types";
import {
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
  Menu,
  MenuItem,
  Button,
  Avatar,
} from "@material-ui/core";
import { ContactSupportOutlined, KeyboardArrowDown } from "@material-ui/icons";

function ListItemLink(props) {
  const location = useLocation();
  const currentPage = location.pathname;
  const { icon, primary, to, className, selectedIcon, action, isActive } =
    props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  const activeItem = to !== "/" && currentPage.includes(to);

  return (
    <li>
      <ListItem
        button
        className={className}
        component={renderLink}
        selected={activeItem || isActive}
        disableRipple
      >
        {activeItem || isActive ? (
          <ListItemIcon>{selectedIcon}</ListItemIcon>
        ) : (
          <ListItemIcon>{icon}</ListItemIcon>
        )}
        <ListItemText primary={primary} />
        {action && <ListItemSecondaryAction>{action}</ListItemSecondaryAction>}
      </ListItem>
    </li>
  );
}

function Sidebar(props) {
  const location = useLocation();
  const { container, userDetails } = props;
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    document.title = getPageName(location.pathname) + " - Logisoft";
  }, [location.pathname]);

  const getPageName = name => {
    name = name.substr(1);
    name = name.charAt(0).toUpperCase() + name.substr(1);
    return name;
  };

  const logout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  const Label = ({ value, type }) => {
    return <span className={`chip-label ${type}`}>{value}</span>;
  };

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
      elevation={1}
      style={{width: 225}}
    >
      <MenuItem onClick={handleMenuClose}>My Profile</MenuItem>
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
  );

  const drawer = (
    <div className="sidebar">
      <div className="brand">
        <img src="/img/lgsm-logo.svg" className="logo" />
      </div>

      <List className={classes.list}>
        <ListItemLink
          to="/"
          primary="Dashboard"
          selectedIcon={<RiHomeLine />}
          icon={<RiHomeLine />}
          isActive={location.pathname === "/"}
        />

        {userDetails.accountType === 1 ? (
          <>
            <ListItemLink
              to="/find-shipments"
              primary="Find Shipments"
              selectedIcon={<RiSearch2Line />}
              icon={<RiSearch2Line />}
            />
            <ListItemLink
              to="/offers"
              primary="Offers"
              selectedIcon={<LocalOfferOutlinedIcon />}
              icon={<LocalOfferOutlinedIcon />}
            />
            <ListItemLink
              to="/contracts"
              primary="Contracts"
              selectedIcon={<RiFileEditLine />}
              icon={<RiFileEditLine />}
            />
          </>
        ) : (
          <>
            <ListItemLink
              to="/requests"
              primary="Requests"
              selectedIcon={<RiFileList2Line />}
              icon={<RiFileList2Line />}
            />
            <ListItemLink
              to="/contracts"
              primary="Contracts"
              selectedIcon={<RiFileEditLine />}
              icon={<RiFileEditLine />}
            />
            <ListItemLink
              to="/parties"
              primary="Parties"
              selectedIcon={<RiGroupLine />}
              icon={<RiGroupLine />}
            />
          </>
        )}

        <div className="divider" />
        <ListItemLink
          to="/messages"
          primary="Messages"
          selectedIcon={<RiChat3Line />}
          icon={<RiChat3Line />}
          action={<Label value={userDetails.unreadMessagesCount} />}
        />
        <ListItemLink
          to="/profile"
          primary="Public Profile"
          selectedIcon={<RiAccountCircleLine />}
          icon={<RiAccountCircleLine />}
        />
        {/* <ListItemLink
          to="/notifications"
          primary="Notifications"
          selectedIcon={<RiNotification4Line />}
          icon={<RiNotification4Line />}
          action={<Label value="0" type="urgent" />}
        /> */}
      </List>
    </div>
  );

  ListItemLink.propTypes = {
    icon: PropTypes.element,
    primary: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  };

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor="left"
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
          <div className="sidebar sidebarBottom">
            <List className={classes.list}>
              <ListItemLink
                to="/settings"
                primary="Settings"
                selectedIcon={<RiSettingsLine />}
                icon={<RiSettingsLine />}
              />
              <li>
                <ListItem button onClick={logout}>
                  <ListItemIcon>{<RiLogoutCircleLine />}</ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItem>
              </li>
            </List>

            <Button
              aria-controls={menuId}
              aria-haspopup="true"
              //onClick={handleProfileMenuOpen}
              color="inherit"
              className="profileMenu"
              disableRipple
              style={{paddingLeft: 25}}
            >
              <Avatar alt={userDetails.fullName} style={{width: 35, height: 35}} src={userDetails.logoUrl} />
              <div className="profileInfo">
                <span className="profileName">{userDetails.fullName}</span>
                <span className="profileCompany">{userDetails.companyName}</span>
              </div>
              {/* <KeyboardArrowDown style={{ color: '#80878f' }} /> */}
            </Button>
            {renderMenu}
          </div>
        </Drawer>
      </Hidden>
    </nav>
  );
}


export default Sidebar;


const drawerWidth = 225;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    //borderBottom: '1px solid #eeeeee',
    background: 'transparent',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: '#fff'
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    //borderRadius: theme.shape.borderRadius+4,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '60% !important',
    //boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.13)',
    //border: '1px solid #dbdee5',
    background: "#fff",
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
      width: 'auto',
    },
  },
  sectionDesktop: {
    width: '40%',
    textAlign: 'right'
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#80878f'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
    //padding:'2px 0px',
    paddingRight: '18px',
    height: '64px'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    color: '#80878f',
    // [theme.breakpoints.down('md')]: {
    //   width: 200,
    // },
  },
  list: {
    padding: '0px',
    margin: '18px 0px'
  }
}));

