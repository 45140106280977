import React from "react";
import { Box } from "@material-ui/core";

export default function LocationInfo(props) {
  const { timezone, currency, dateFormat } = props;

  const style = {
    box: {
      padding: "10px 30px",
    },
    div: {
      marginBottom: "20px",
      fontSize: "15px",
    },
    title: {
      fontWeight: "500",
      color: "rgb(0 0 0 / 50%)",
    },
  };

  return (
    <Box style={style.box}>
      <div style={style.div}>
        <div style={style.title}>Timezone</div>
        <div>{timezone}</div>
      </div>
      <div style={style.div}>
        <div style={style.title}>Currency</div>
        <div>{currency}</div>
      </div>
      <div style={style.div}>
        <div style={style.title}>Preffered date format</div>
        <div>{dateFormat}</div>
      </div>
    </Box>
  );
}
