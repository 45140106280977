import React from "react";
import { CircularProgress, Drawer } from "@material-ui/core";
import DrawerActions from "./Drawer/DrawerHeader";
import DrawerContent from "./Drawer/DrawerContent";

export default function OfferDrawer(props) {
  const { open, setOpen, offer, offerItems, loading, request } = props;

  const closeDrawer = () => {
    setOpen(false);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={closeDrawer}
      className="drawer-container"
    >
      {loading ? <div className="table-loader"><CircularProgress  /></div> : 
        <>
      <DrawerActions
        closeDrawer={closeDrawer}
        offer={offer}
        loading={loading}
      />
      <DrawerContent
        offer={offer}
        loading={loading}
        offerItems={offerItems}
        request={request}
      />
      </>
      }
      
    </Drawer>
  );
}
