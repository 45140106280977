import { Chip } from "@material-ui/core";
import { Stars } from "@material-ui/icons";
import React from "react";

export default function FeatureNotice({style}) {
	return (
		<Chip
			size="small"
			icon={<Stars style={{color: "#1c4ef5"}} />}
			label="Feature demonstration! to be implemented."
			style={{backgroundColor: "#e5ebfc", color: "#1c4ef5", ...style}}
		/>
	)
}