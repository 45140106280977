import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton } from "@material-ui/core";
import APIKit from "../../../include/APIKit";
import { useUtils } from "../../../context/UtilityContext";


function MethodActionsMenu(props) {
  const { openActionDialog } = useUtils();
  const [loading, setLoading] = useState(false);
  const [loadingDef, setLoadingDef] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);


  const handleProfileMenuOpen = event => {
	event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const confirmDeleteMethod = (id) => {
    openActionDialog({
      title: "Are you sure?",
      content: "Please confirm that you're really going to delete a payment method.",
      confirmAction: () => deleteMethod(id)
    });
  }

  const deleteMethod = (id) => {
    setLoading(true);
    APIKit.delete("PaymentMethods?id="+id ).then(result => {
      setLoading(false);
			if (result.status === 200) {
				if(result.data.isSuccess) {
					props.onDelete();
				}
			}
		}).catch(e => {});
  }

  const setDefault = (id) => {
    setLoadingDef(true);
    APIKit.put("Payments/default-paymentmethod?methodId="+id ).then(result => {
      setLoadingDef(false);
			if (result.status === 200) {
				if(result.data.isSuccess) {
					props.onDelete();
				}
			}
		}).catch(e => {});
  }




  const menuId = props.id;
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId} 
      keepMounted 
      open={isMenuOpen}
      onClose={handleMenuClose}
	  className="actions-menu paymentsmenu"
      elevation={1}
    >
      {!props.isDefault && <MenuItem disabled={loadingDef} onClick={() => setDefault(props.id)}>Set Default</MenuItem>}
     	<MenuItem disabled={loading} onClick={() => confirmDeleteMethod(props.id)} className="delete-menu-item">Delete</MenuItem>
    </Menu>
  );

  

	return (

		<React.Fragment>
			<IconButton
				edge="end"
				aria-label="account of current user"
				aria-controls={menuId}
				aria-haspopup="true"
				onClick={handleProfileMenuOpen}
			>
				<MoreVertIcon />
			</IconButton>
				
			{renderMenu}
		</React.Fragment>

	);
}

export default MethodActionsMenu;
