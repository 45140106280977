import React from "react";
import "../../assets/styles/rating-emojis.css";

export default function RatingStars({rating}) {
	return (
		<div className="feedback" style={{padding:'0px', margin: 0, width: '40%'}} >
			
			<div className="rating disabled" style={{height:'auto'}}>
				<div className="disabled-layer"></div>
				<input type="radio"  checked={rating === 5} />
				<label for="rating-5"></label>
				<input type="radio"  checked={rating === 4} />
				<label for="rating-4"></label>
				<input type="radio"  checked={rating === 3} />
				<label for="rating-3"></label>
				<input type="radio"  checked={rating === 2} />
				<label for="rating-2"></label>
				<input type="radio" checked={rating === 1} />
				<label for="rating-1"></label>
			</div>
		</div>
	)
}