/**
* Use the CSS tab above to style your Element's container.
*/
import React from 'react';
import {IbanElement} from '@stripe/react-stripe-js';
import './IbanFormStyles.css'
import { Alert } from '@material-ui/lab';

// Custom styling can be passed as options when creating an Element.
const IBAN_STYLE = {
  base: {
    color: '#32325d',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    },
    ':-webkit-autofill': {
      color: '#32325d',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a',
    },
  }
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  placeholderCountry: 'DE',
  style: IBAN_STYLE,
};

export default function SepaSetupForm() {
  return (
    <React.Fragment>
      <div className="form-row inline stripe-form">
        <div className="col">
          <label>
            Name
            <input
              name="account_holder_name"
              placeholder="Jenny Rosen"
              required
            />
          </label>
        </div>
        <div className="col">
          <label>
            Email Address
            <input
              name="account_holder_email"
              placeholder="email@address.com"
              type="email"
              required
            />
          </label>
        </div>

        {/* <div className="col">
          <label>
            Email Address
            <input
              name="email"
              type="email"
              placeholder="jenny.rosen@example.com"
              required
            />
          </label>
        </div> */}
      </div>

      <div className="form-row stripe-form">
        <label>
          <span style={{marginBottom: 10, display: 'block'}}>IBAN</span>
          <IbanElement options={IBAN_ELEMENT_OPTIONS} />
        </label>
      </div>

  
      <div className="mandate-acceptance">
      <Alert severity="info">By providing your payment information and confirming this payment,
        you authorise (A) INTRA Logisoft Labs Ltd. and Stripe, our payment service
        provider, to send instructions to your bank to debit your account and
        (B) your bank to debit your account in accordance with those
        instructions. As part of your rights, you are entitled to a refund
        from your bank under the terms and conditions of your agreement with
        your bank. A refund must be claimed within 8 weeks starting from the
        date on which your account was debited. Your rights are explained in
        a statement that you can obtain from your bank. You agree to receive
        notifications for future debits up to 2 days before they occur.</Alert>
        
      </div>
    </React.Fragment>
  );
};