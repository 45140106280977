import React, { forwardRef } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'


const phoneInput = (props, ref) => {

  return (

    <TextField
      {...props}
      // InputProps={{
      //   className: classes.input
      // }}
      inputRef={ref}
      fullWidth
      variant='outlined'
      placeholder="Number"
    />
  )
}
export default forwardRef(phoneInput)