import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import numeral from 'numeral';
import Alert from '@material-ui/lab/Alert';

import APIKit from '../include/APIKit';
import PaymentMethodsDialog from './settings/payments/PaymentMethodsDialog';
import Processing from '../include/custom/Processing';



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '16px 24px'
  },
  DialogContent: {
    margin: 0
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});


const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
	padding: 0,
	borderBottom: '1px solid #e0e0e0'
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: '12px 24px',
  },
}))(MuiDialogActions);

export default class OfferConfirmationDialog extends Component {
	state = {
		open: false,
		offer: null,
		loadingMethods: false,
		method: null,
		processing: false,
		success: false
  	};

	getMethod = (method) => {
		this.setState({method});
	}

	handleClickOpen = (offer, request) => {
		let offerServices = [];
		let acceptedOfferItemIds = [];
		let missing = [];
		for(var i in offer.offerItems) {
			if(!offer.offerItems[i].isRoute) {
				offerServices.push(offer.offerItems[i].logisticService.id);
				if(offer.offerItems[i].isChecked) {
					acceptedOfferItemIds.push(offer.offerItems[i].logisticService.id);
				}
			}
		}
		for(var i in request.services) {
			if(!offerServices.includes(request.services[i].id)){
				missing.push(request.services[i].name)
			}
		}		
    	this.setState({offer, request, missing, acceptedOfferItemIds, open: true}, () => { 
			console.log(this.state)
		});
  	};
	handleClose = () => {
    	this.setState({offer: null, request: null, missing: [], acceptedOfferItemIds: [], open: false, success: false, processing: false});
	};

	acceptOffer = () => {
		this.setState({processing: true});
		const body = {
			acceptedOfferItemIds: this.state.acceptedOfferItemIds,
			offerId: this.state.offer.id,
			requestId: this.state.request.id,
			paymentMethodId: this.state.method
		}
			
			APIKit.post("offers/pay", body ).then(result => {
				if(result.status === 200) {
					if(result.data.isSuccess) {
						this.setState({success: true});
						setTimeout(function () {
							window.location.href = '/contract/'+result.data.record?.id;
						}.bind(this), 3000);
						
					}
				}
				
			}).catch(e => {
				this.setState({processing: false});
			})
		return;
		
		
	}

	mock = () => {
		this.setState({success: !this.state.success})
	}
	

	render () {
		const {offer, processing, success} = this.state;
		const transportModes = ['', 'Land Freight', 'Ocean Freight'];

		return (<div> {offer &&
			<Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.open} fullWidth maxWidth="sm">
			  <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
				Accept & Pay
			  </DialogTitle>
			  <DialogContent dividers>
				{processing ? <div>
					 
					<Processing complete={success} />
				</div> : <React.Fragment>
				<div className="dialog-content-text">
					<div className="label">Payment Method</div>
					<PaymentMethodsDialog getMethod={this.getMethod} offerId={this.state.offer.id} />

					{this.state.missing.length > 0 && 
						<Alert severity="warning" className="offer-warning">Please note that you've selected an offer that doesn't contain the following services you requested: 
							{this.state.missing.map((item, index) => (
								<span key={index}> <strong>{item}</strong>
								{this.state.missing.length-1 === index ? "" : ", " } </span>
							))}
						</Alert>
					}
				</div>
				
				<div className="offer-summary">
					{offer.offerItems.map((item, index) => {
						if(item.isRoute || item.isChecked) {
							return (
								<div className="offer-item" key={index}>
									<span className="offer-item-box">{item.isRoute === true ? 
									"Main Carriage ("+transportModes[item.freightType]+")" : 
									item.logisticService.name}
									<span className="offer-item-price">{item.price ? numeral(item.price).format('$0.00') : ""}</span>
									</span> 
								</div>
							)

						} 
					})}
					{/* <div className="offer-item sys subtotal">
						<span className="offer-item-box">
							<span className="offer-item-label">Offer Price:</span>
							<span className="offer-item-price">{numeral(offer.finalPrice).format('$0.00')}</span>
						</span> 
					</div>
					<div className="offer-item sys service-charge">
						<span className="offer-item-box">
								Service Charge: 
								<Tooltip title="Service Charge helps us run this marketplace and provide you with outstanding services." arrow placement="right">
									<HelpIcon className="helpIcon" />
								</Tooltip>
							<span className="offer-item-price">+{numeral((10 / 100) * offer.finalPrice).format('$0.00')}</span>
						</span> 
					</div> */}
					<div className="offer-item sys total-price">
						<span className="offer-item-box">
							<span className="offer-item-label">Total:</span>
							<span className="offer-item-price">{numeral(offer.finalPrice).format('$0.00')}</span>
						</span> 
					</div>
				</div>
				</React.Fragment>}
			  </DialogContent>
			  <DialogActions>
				<Button autoFocus onClick={this.acceptOffer} disabled={processing}  color="primary" variant="contained" disableElevation >
				  Pay
				</Button>
			  </DialogActions>
			</Dialog>
		}</div>);
	}
  	
}
