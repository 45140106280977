import React, { useEffect, useState } from "react";
import { Button, Checkbox, MenuItem, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Select, Switch, Tooltip } from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";

import fx from 'money'

export default function PricingFields(props) {
	const { request, offer, setOffer, services } = props;

	useEffect(() => {

	}, [])

	const convert = (amount, base, to) => {
		fx.base = base;
		fx.rates = {
			"EUR" : base === "EUR" ? 1 : 0.89,
			"USD" : base === "USD" ? 1 : 1.13, 
		}
		let converted = fx(amount).from(base).to(to);
		return new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: to
		}).format(converted);
	}

	const [flatPrice, setFlatPrice] = useState(false);

	const flatPriceSwitch  = () => {
		const offerItems = [...offer.offerItems];
		for(var i in offerItems) {
		  offerItems[i].price = null;
		}
		setFlatPrice(!flatPrice);
		setOffer({...offer, price: null, totalPrice: 0, offerItems, flatPrice})
	}

	const handleInputChange = (event) => {
		let value = event.target.name;
		if(event.target.name == 'price') {
		  value = parseFloat(event.target.value);
		}
		setOffer({ ...offer, [event.target.name]: value });
	}

	const onPriceChange = (event) => {
		let value = event.target.name;
		value = parseFloat(event.target.value);
		var remainingPrice = deductFees(value).remaining;
		setOffer({ ...offer, [event.target.name]: value, totalPrice: value || 0, remainingPrice });
	}

	const onRemainingChange = (event) => {
		let value = event.target.name;
		value = parseFloat(event.target.value);
		var totalPrice = reversePercentage(value);
		setOffer({ ...offer, totalPrice, price: totalPrice, remainingPrice: value });
	}

	const handleItemAdd = () => {
		const newItem = {
		  logisticService: {
			id: null,
		  },
		  price: ''
		}
		setOffer({ ...offer, offerItems: [ ...offer.offerItems, newItem ] });
	}

	const handleItemInputChange = (event, index) => {
		let value = event.target.value;
		const offerItems = [...offer.offerItems];
		offerItems[index].price = value;
	
		let totalPrice = 0;
		for(var i in offerItems) {
			offerItems[i].price = parseFloat(offerItems[i].price);
			totalPrice += offerItems[i].price || 0;
		}
		var remainingPrice = deductFees(totalPrice).remaining;
		setOffer({ ...offer, offerItems, totalPrice, remainingPrice});
	}

	const handleItemServiceChange = (event, index) => {
		const offerItems = [...offer.offerItems];
		offerItems[index].logisticService.id = event.target.value;
		setOffer({ ...offer, offerItems });
	}

	const handleItemSelectChange = (event, index) => {
		const offerItems = [...offer.offerItems];
		offerItems[index][event.target.name] = event.target.value;
		setOffer({ ...offer, offerItems });
	}

	const handleItemCheckboxChange = (event, index) => {
		const offerItems = [...offer.offerItems];
		offerItems[index].isOptional = !event.target.checked;
		setOffer({ ...offer, offerItems });
	}
	
	const handleItemDelete = (index) => {
		const items = [...offer.offerItems];
		items.splice(index, 1);
		let totalPrice = 0;
		for(var i in items) {
			totalPrice += parseFloat(items[i].price) || 0;
		}
		var remainingPrice = deductFees(totalPrice).remaining;
		setOffer({ ...offer, offerItems: items, totalPrice, remainingPrice }); // => this.calculateTotalPrice();
	}

	

	const getCurrency = (variant) => {
		let currency = request.currency;
		switch(variant) {
			case "code":
				return currency.code;
			case "name":
				return currency.name;
			case "symbol":
				return currency.symbol;
		}
	}

	const getSupplierCurrency = (variant) => {
		let currency = {
			code: "USD",
			name: "United States Dollar",
			symbol: "$"
		};
		switch(variant) {
			case "code":
				return currency.code;
			case "name":
				return currency.name;
			case "symbol":
				return currency.symbol;
		}
	}

	const format = (value) =>
	new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: getCurrency("code")
	}).format(value);

	const supplierAmount = (amount) => {
		
		return "SSS"
	}

	function percentageDec(total, per = 20) {
		return (total - ((per /100) * total));
	}

	function reversePercentage(remaining, per = 20) {
		return(-100*remaining)/(per-100);
		//((finalNum*100)/(per+100));
		
	}
	 
	function percentageInc(total, per = 20) {
		return (total + ((per /100) * total));
	}

	const deductFees = (amount) => {
		var num = parseFloat(amount);
		var deducted = (num * .20);
		var remaining = num - deducted;
		return {
			remaining,
			deducted 
		}
	}


	const transportModes = ['', 'Ocean Freight', 'Land Freight', 'Air Freight'];

	return (
		<>
		<Paper className={"white-card "} >
            <div className="card-header" style={{display: 'flex', alignItems: 'center', height: 60}}>
				<span style={{flexGrow: 1}}>Pricing
				<FormControl size="small" className={`flatPrice-switch ${flatPrice ? 'flatPrice-Active' : ''}`}>
						<FormControlLabel
						value={flatPrice}
						control={<Switch size="small" color="primary" checked={flatPrice} onChange={flatPriceSwitch} />}
						label="Flat Price"
						labelPlacement="end"
						/>
					</FormControl>
				</span>
				
              
			  	<Button onClick={handleItemAdd} color="primary" startIcon={<Add />}>
                    Add a Service
            	</Button>
            </div>
            <div className="card-body">

            {offer.offerItems.map((item, index) => {
              if(item.isRoute) {
                return (
                  <div  className="request-item" key={index}>
                    <Grid container spacing={2}  >
                      {/* <Grid item sm={1}>
                        <Tooltip title="Mandatory" arrow placement="left" >
                          <FormControlLabel control={<Checkbox checked={true} disabled color="primary" />} />
                        </Tooltip>
                      </Grid> */}
                      <Grid item sm={flatPrice ? 11 : 8} >
                        <FormControl variant="outlined" fullWidth size="small" disabled >
                          <InputLabel id="ct-label">Service</InputLabel>
                          <Select labelId="ct-label" label="Service" onChange={(event, ind) => handleItemSelectChange(event, index)} name="containerId" required value={0}>
                              <MenuItem value={0}>Main Carriage ({transportModes[item.freightType]})</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
					  	{!flatPrice && 
							<Grid item sm={3} >
								<FormControl fullWidth variant="outlined" size="small" >
								<InputLabel htmlFor={`price-${index}`}>Price</InputLabel>
								<OutlinedInput
									id={`price-${index}`}
									value={item.price}
									onChange={(event, ind) => handleItemInputChange(event, index)}
									//onBlur={validateInput}
									startAdornment={<InputAdornment position="start">{getCurrency("symbol")}</InputAdornment>}
									labelWidth={40}
									type="number"
									disabled={flatPrice}
								/>
								</FormControl>
							</Grid>
						}
                      <Grid item sm={1} className="text-align-center" >
                        <IconButton aria-label="delete" disabled >
                          <Delete />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                );
              }
              else {
                return (
                  <div  className="request-item" key={index}>
                    <Grid container spacing={2} >
                      {/* <Grid item sm={1}>
                        <Tooltip title="Mandatory" arrow placement="left" >
                          <FormControlLabel control={  <Checkbox checked={item.isOptional == false || false}  onChange={(event, ind) => handleItemCheckboxChange(event, index)} color="primary" /> } label="" />
                        </Tooltip>
                      </Grid> */}
                      <Grid item sm={flatPrice ? 11 : 8} >
                        <FormControl variant="outlined" fullWidth size="small" >
                          <InputLabel id={`ct-label-${index}`}>Service</InputLabel>
                          <Select labelId={`ct-label-${index}`} label="Service" onChange={(event, ind) => handleItemServiceChange(event, index)} name="service" required value={item.logisticService.id} fullWidth  >
                            {services && services.map((service) => (
                              <MenuItem value={service.id} key={service.id}>{service.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
					  	{!flatPrice && 
							<Grid item sm={3} >
								<FormControl fullWidth variant="outlined" size="small" >
								<InputLabel htmlFor={`price-${index}`}>Price</InputLabel>
								<OutlinedInput
									id={`price-${index}`}
									value={item.price}
									onChange={(event, ind) => handleItemInputChange(event, index)}
									//onBlur={validateInput}
									tabIndex={index}
									startAdornment={<InputAdornment position="start">{getCurrency("symbol")}</InputAdornment>}
									labelWidth={40}
									type="number"
									disabled={flatPrice}
								/>
								</FormControl>
							</Grid>
			  			}

                      <Grid item sm={1} className="text-align-center" >
                        <IconButton aria-label="delete" onClick={(ind) => handleItemDelete(index)} disabled={index == 0 ? true : false}>
                          <Delete />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                )
              }
            })}
            
            </div>
            <div className="paper-footer">
        
			  	<Grid container spacing={1} alignItems="center" className="pricing-row">
					<Grid item sm={8}>
						<span>Offer Price</span>
						<span>The amount the client will see on your offer</span>
					</Grid>
					<Grid item sm={4} className="text-align-right">
						<EditablePrice
							name="price"
							flatPrice={flatPrice}
							inputValue={offer.price}
							displayValue={format(offer.totalPrice)}
							currency={getCurrency("symbol")}
							onChange={onPriceChange}
                        />
					</Grid>
				</Grid>
				<Grid container spacing={1} alignItems="center" className="pricing-row">
					<Grid item sm={8}>
						<span>20% Service Fees</span>
					</Grid>
					<Grid item sm={4} className="text-align-right">
						<span className="footer-label-price small"><span>-{format(deductFees(offer.totalPrice || 0).deducted)}</span></span>
					</Grid>
				</Grid>
				<Grid container spacing={1} alignItems="center" className="pricing-row">
					<Grid item sm={8}>
						<span>You'll Receive</span>
						<span>The estimated amount you'll receive after service fees.<br/> 
							{getCurrency("code") !== getSupplierCurrency("code") &&
								<span>
									Approximately 
									<b style={{fontWeight: 500}}> {convert(deductFees(offer.totalPrice).remaining, getCurrency("code"), getSupplierCurrency("code"))} </b> 
									in your currency
								</span>
							}
						</span>
						
					</Grid>
					<Grid item sm={4} className="text-align-right">
						<EditablePrice
							name="remainingPrice"
							flatPrice={flatPrice}
							inputValue={offer.remainingPrice}
							displayValue={format(offer.remainingPrice || 0)}
							currency={getCurrency("symbol")}
							onChange={onRemainingChange}
                        />
						
					</Grid>
				</Grid>
              
            </div>

        </Paper> 

		

		</>
	)
}

const EditablePrice = ({name, inputValue, displayValue, onChange, flatPrice, currency}) => {
	return flatPrice ? (
		<span className="footer-label-price">
		  <FormControl variant="outlined" size="small" >
			<OutlinedInput
			  value={inputValue}
			  onChange={onChange}
			  name={name}
			  startAdornment={<InputAdornment position="start">{currency}</InputAdornment>}
			  type="number"
			  
			  style = {{width: 120}}
			/>
		  </FormControl>
		</span>
		
	  ) : (
		<span className="footer-label-price small"><span>{displayValue}</span></span>
	)
}