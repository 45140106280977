import React, { Component } from 'react'
import OfferConfirmationDialog from "../../components/OfferConfirmationDialog.js";
import OfferFilters from "../../components/Shipper-OffersFilter.js";
import { withSnackbar } from 'notistack';
import InfiniteScroll from "react-infinite-scroll-component";
import containerTypes from "../../include/containerTypes.json";


import moment from 'moment';

import {
  Paper,
  Tabs,
  Tab,
  Chip,
  Avatar,
  Grid,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Button,
  FormControlLabel,
  IconButton,
  Tooltip,
  CircularProgress,
  Checkbox,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table
} from '@material-ui/core';

// IMPORT MATERIAL ICONS
import { 
  ChevronLeft,
  DirectionsBoat, 
  DirectionsRailway, 
  ExpandMore as ExpandMoreIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Cancel as CancelIcon,
  Room as PinIcon,
  ThumbDownOutlined as ThumbDownOutlinedIcon,
  ThumbUpOutlined as ThumbUpOutlinedIcon,
  ThumbUp as ThumbUpIcon,
  DirectionsCar,
  Landscape
} from '@material-ui/icons';

import {
  Rating,
  Skeleton
} from '@material-ui/lab';
import APIKit from '../../include/APIKit';
import Page from '../../containers/Page.js';
import MessageDialog from '../../components/message/MessageDialog.jsx';
import RequestActions from "../../components/shipment-view/Actions.jsx";
import ErrorSnackbar from "../../components/shipment-view/ErrorSnackbar.jsx";
import SortingPanel from '../../components/shipment-view/SortingPanel.jsx';

class ViewShipment extends Component {
  tableRef = React.createRef();
  dialog = React.createRef();

  state = {
    activeTab: 0,
    loadingOffers: true,
    loadingRequest: true,
    pageSize: 10,
    pageNumber: 1,
    totalPages: null,
    totalOffersCount: 0,
    offersFilters: {
      sortingType: 3,
    },
    filterPaneOpen: false,
    countries: null,
    name: "",
    typing: false,
    typingTimeout: 0,
    menuOpen: false,
    anchorEl: null,
    selection: [],
    request: null,
    hasMore: true,
    offersCounts: {
      totalOffers: 0,
    },
    openErrorSnackbar: false,
  };

  UNSAFE_componentWillMount = () => {};

  componentDidMount() {
    document.title = "Request";
    this.getRequestDetails();

    this.getOffersCount(true);
  }

  generateFakeRows = count => {
    let array = [];
    for (let index = 0; index < count; index++) {
      array.push(index);
    }
    return array;
  };

  handleTabChange = (event, activeTab) => {
    this.setState(
      {
        activeTab,
        loadingOffers: true,
        offersFilters: { ...this.state.offersFilters, filterType: activeTab },
        pageNumber: 1,
      },
      () => {
        if (activeTab !== 0) {
          this.getRequestOffers();
          this.getOffersCount();
        }
      }
    );
  };

  getCountries = () => {
    APIKit.get("countries")
      .then(result => {
        if (result.status === 200) {
          this.setState({ countries: result.data.record });
        }
      })
      .catch(e => {});
  };

  getOffersCount = init => {
    APIKit.get("offers/count/" + this.props.match.params.id)
      .then(result => {
        if (result.status === 200) {
          const counts = result.data.record;
          let activeTab = this.state.activeTab;
          if (counts.accepted > 0) {
            activeTab = 2;
          } else if (counts.totalOffers > 0) {
            activeTab = 1;
          }
          this.setState({
            activeTab: init ? activeTab : this.state.activeTab,
            offersCounts: counts,
          });
        }
      })
      .catch(e => {});
  };

  getRequestDetails = () => {
    APIKit.get("requests/WithFilters/" + this.props.match.params.id)
      .then(result => {
        if (result.status === 200) {
          this.setState(
            {
              request: result.data.record.request,
              requestFilters: result.data.record.offerFilters,
            },
            () => {
              const requestServices = result.data.record.request.services;
              let services = [];
              Object.keys(requestServices).forEach(function (key) {
                services.push(requestServices[key].id);
              });
              this.setState({ requestServices: services }, () =>
                this.getRequestOffers()
              );
            }
          );
        }
        this.setState({ loadingRequest: false });
      })
      .catch(e => {
        this.setState({ loadingRequest: false });
      });
  };

  catchErrors = message => {
    this.showAlert("error", message, false);
  };

  // catchErrors = (status, statusText, message, actionText, actionCallback) => {
  //   const action = key => (
  //     <Fragment>
  //         <Button onClick={() => window.location.reload() }>
  //             {actionText ? actionText : ''}
  //         </Button>
  //     </Fragment>
  //   );
  //   this.showAlert('error', message, false, actionText ? action : null);
  // }

  showAlert = (variant, message, persist, action) => {
    this.props.enqueueSnackbar(message, {
      variant: variant,
      persist,
      action,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  getRequestOffers = () => {
    this.setState({ loadingOffers: true, pageNumber: 1 });
    let body = {
      requestId: parseFloat(this.props.match.params.id),
      pageSize: this.state.pageSize,
      pageNumber: 1,
      ...this.state.offersFilters,
    };
    APIKit.post("offers/request", body)
      .then(result => {
        if (result.status === 200) {
          let records = result.data.record.items;
          const requestServices = this.state.request.services;
          let servicesIds = [];

          for (var i in records) {
            let ids = [];
            for (var reqs in requestServices) {
              records[i].offerItems.some(x => {
                if (!x.isRoute) {
                  if (x.logisticService.id === requestServices[reqs].id) {
                    if (!servicesIds.includes(requestServices[reqs].id)) {
                      ids.push(requestServices[reqs].id);
                    }
                  }
                }
              });
              servicesIds = ids;
            }
            records[i].servicesIds = servicesIds;
          }
          this.setState(
            {
              offers: records,
              totalOffersCount: result.data.record.totalItems,
            },
            () => console.log(this.state)
          );
        }
        this.setState({ loadingOffers: false });
      })
      .catch(e => {
        this.catchErrors("Something went wrong. Please refresh this page!");
      });
  };

  fetchMoreData = () => {
    // if(this.state.offers.length >= this.state.totalOffersCount) {
    //   this.setState({hasMore: false});
    //   return;
    // }
    let body = {
      requestId: parseFloat(this.props.match.params.id),
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber + 1,
      ...this.state.offersFilters,
    };
    APIKit.post("offers/request", body)
      .then(result => {
        if (result.status === 200) {
          let records = result.data.record.items;
          const requestServices = this.state.request.services;
          let servicesIds = [];

          for (var i in records) {
            let ids = [];
            for (var reqs in requestServices) {
              records[i].offerItems.some(x => {
                if (!x.isRoute) {
                  if (x.logisticService.id === requestServices[reqs].id) {
                    if (!servicesIds.includes(requestServices[reqs].id)) {
                      ids.push(requestServices[reqs].id);
                    }
                  }
                }
              });
              servicesIds = ids;
            }
            records[i].servicesIds = servicesIds;
          }
          this.setState({
            offers: this.state.offers.concat(records),
            pageNumber: result.data.record.pageIndex,
            totalPages: result.data.record.totalItems,
          });
        }
      })
      .catch(e => {
        this.catchErrors("Something went wrong. Please refresh this page!");
      });
  };

  hideOffer = (event, index, id, action) => {
    event.stopPropagation();
    const offers = [...this.state.offers];
    offers.splice(index, 1);
    this.setState({
      offers,
      totalOffersCount: this.state.totalOffersCount - 1,
    });
    this.showAlert("success", `Offer has been successfully ${action}!`);
    APIKit.post("offers/hiding/" + this.props.match.params.id, [id])
      .then(result => {
        this.getOffersCount();
      })
      .catch(e => {});
  };

  shortlistOffer = (event, index, id) => {
    event.stopPropagation();
    const offers = [...this.state.offers];
    if (this.state.activeTab === 4) {
      offers.splice(index, 1);
    } else {
      offers[index].isShortlisted = !offers[index].isShortlisted;
    }
    this.setState({
      offers,
      offersCounts: {
        ...this.state.offersCounts,
        shortlisted: offers[index].isShortlisted
          ? this.state.offersCounts.shortlisted + 1
          : this.state.offersCounts.shortlisted - 1,
      },
    });
    APIKit.post("offers/shortlisting/" + this.props.match.params.id, [id])
      .then(result => {})
      .catch(e => {});
  };

  handleMinMax = (minName, maxName, minMax) => {
    const offersFilters = this.state.offersFilters;
    offersFilters[minName] = minMax.min;
    offersFilters[maxName] = minMax.max;
    this.setState({ offersFilters }, () => this.getRequestOffers());
  };

  handleCountryChange = countries => {
    const offersFilters = this.state.offersFilters;
    offersFilters.countryIds = countries;
    this.setState({ offersFilters }, () => this.getRequestOffers());
  };

  handleCheckbox = (name, value) => {
    const offersFilters = this.state.offersFilters;
    offersFilters[name] = value;
    this.setState({ offersFilters }, () => this.getRequestOffers());
  };

  handleOpenErrorSnackbar = () => {};

  handleCloseErrorSnackbar = () => {
    this.setState({ openErrorSnackbar: false });
  };

  handleDeleteRequest = () => {
    APIKit.post("requests/delete")
      .then()
      .catch(e => {
        this.setState({ openErrorSnackbar: true });
      });
  };
  handleEditRequest = () => {
    APIKit.post("requests/delete")
      .then()
      .catch(e => {
        this.setState({ openErrorSnackbar: true });
      });
  };
  handleUnlistRequest = () => {
    APIKit.post("requests/delete")
      .then()
      .catch(e => {
        this.setState({ openErrorSnackbar: true });
      });
  };

  resetFilters = () => {
    this.setState(
      { offersFilters: { sortingType: this.state.offersFilters.sortingType } },
      () => this.getRequestOffers()
    );
  };

  sortBy = int => {
    this.setState(
      { offersFilters: { ...this.state.offersFilters, sortingType: int } },
      () => this.getRequestOffers()
    );
  };

  handleServicesCheckbox = id => {
    this.setState(
      state => ({
        requestServices: state.requestServices.includes(id)
          ? state.requestServices.filter(c => c !== id)
          : [...state.requestServices, id],
      }),
      () => {
        this.setState(
          {
            offersFilters: {
              ...this.state.offersFilters,
              serviceIds: this.state.requestServices,
            },
          },
          () => {
            this.getRequestOffers();
          }
        );
      }
    );
  };

  handleLogisticServicesCheckbox = (id, offerIndex, itemIndex, price) => {
    // const offers = [...this.state.offers];
    // offers[offerIndex].servicesIds = offers[offerIndex].servicesIds.includes(id) ? offers[offerIndex].servicesIds.filter(c => c !== id) : [...offers[offerIndex].servicesIds, id];
    // offers[offerIndex].finalPrice = offers[offerIndex].servicesIds.includes(id) ? offers[offerIndex].finalPrice + price : offers[offerIndex].finalPrice - price;
    // this.setState({offers});

    const offers = [...this.state.offers];
    offers[offerIndex].finalPrice = offers[offerIndex].offerItems[itemIndex]
      .isChecked
      ? offers[offerIndex].finalPrice - price
      : offers[offerIndex].finalPrice + price;

    offers[offerIndex].offerItems[itemIndex].isChecked =
      !offers[offerIndex].offerItems[itemIndex].isChecked;
    this.setState({ offers });
  };

  // console.log(id);
  // console.log(offerIndex);
  // this.setState(state => ({
  //   offers1: {
  //     servicesIds :
  //     state.offers1[offerIndex].servicesIds.includes(id)
  //       ? state.offers1[offerIndex].servicesIds.filter(c => c !== id)
  //       : [...state.offers1[offerIndex].servicesIds, id]
  //   }
  //   }), () => {
  //     console.log(this.state.offers1)
  // });

  checkPickupDelivery = (obj, servicesIds) => {
    let arr = "";

    obj.some(x => {
      if (!x.isRoute) {
        if (x.logisticService.id === 5) {
          if (x.isChecked) {
            arr = arr + " pre-active ";
          }
        }
        if (x.logisticService.id === 6) {
          if (x.isChecked) {
            arr = arr + " post-active ";
          }
        }
      }
    });
    return arr;
  };

  // checkPickupDelivery = (obj) => {
  //   let arr = "";
  //   const services = this.state.request.services;
  //   console.log(services);
  //   obj.some(x => {
  //     if(!x.isRoute) {
  //       if(x.logisticService.id == 5) {
  //         if(services.some(y => y.id == 5)){
  //           arr = arr +' pre-active ';
  //         }
  //       }
  //       else if(x.logisticService.id == 6){
  //         if(services.some(y => y.id == 6)){
  //           arr = arr + ' post-active ';
  //         }
  //       }
  //     }
  //   });
  //   return arr;
  // }

  acceptOffer = (event, offer, request) => {
    event.stopPropagation();
    this.dialog.current.handleClickOpen(offer, request);
  };

  messageSupplier = (event, offerId) => {
    event.stopPropagation();
    this.setState({ messageOfferId: offerId });
  };

  getCurrency = (variant) => {
		let currency = this.state.request?.currency;
		switch(variant) {
			case "code":
				return currency.code;
			case "name":
				return currency.name;
			case "symbol":
				return currency.symbol;
		}
	}

  format = (value) => new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: this.getCurrency("code")
	}).format(value);

  render() {
    const transportModes = ["", "Ocean Freight", "Land Freight"];
    const ShipmentStatus = [
      "",
      "Pending",
      "Assigned",
      "Completed",
      "Cancelled",
    ];
    const shipmentTypes = [
      "",
      "RoRo",
      "Less Than Container Load",
      "Full Container Load",
      "Bulk or Break Bulk",
    ];
    const dateOptions = ["", "On", "Before", "After"];

    const packagingTypes = ["","Pallet","Crate","Box","Bag","Bale","Bundle","Can"]
  
    const tabNames = ["", "totalOffers", "shortlisted", "accepted", "archived"];
    const request = this.state.request;
    const offers = this.state.offers;
    const activeTab = this.state.activeTab;
    const loadingOffers = this.state.loadingOffers;
    const loadingRequest = this.state.loadingRequest;
    const requestFilters = this.state.requestFilters;

    const shipmentTypeTitles = ["", "Unit", "Item", "Container", "Item"];

    const typeIcons = [
      "",
      <DirectionsCar className="shipment-icon-big" />,
      <svg
        className="MuiSvgIcon-root shipment-icon-big customIcon"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path d="M21.5,3.5h-19c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h19c1.1,0,2-0.9,2-2v-13C23.5,4.4,22.6,3.5,21.5,3.5z M21.5,18.5h-19v-13h19V18.5zM3.5, 12.5 h17 v5 h-17z"></path>
      </svg>,
      <svg
        className="MuiSvgIcon-root shipment-icon-big customIcon"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path d="M21.5,3.5h-19c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h19c1.1,0,2-0.9,2-2v-13C23.5,4.4,22.6,3.5,21.5,3.5z M21.5,18.5h-19v-13h19V18.5zM3.5, 6.5 h17 v11 h-17z"></path>
      </svg>,
      <Landscape className="shipment-icon-big" />,
    ];

    return (
      <Page
        customTitle={true}
        searchEnabled={false}
        title={
          request && (
            <div className="icon-grid">
              <div className="iconComplex">
                {typeIcons[request.shipmentType]}
                {request.freightType == 1 && (
                  <DirectionsBoat className="shipment-icon-small" />
                )}
                {request.freightType == 2 && (
                  <DirectionsRailway className="shipment-icon-small land" />
                )}
              </div>
              <div>
                <div className="item-title item-ref">
                  {request.reference || "#12009"}
                </div>
                <div className="item-date">
                  {moment(request.createdDate).format("L LT")}
                </div>
              </div>
              <Chip
                size="small"
                label={ShipmentStatus[request.status]}
                className={"shipment-status " + ShipmentStatus[request.status]}
                style={{ marginLeft: 25 }}
              />
            </div>
          )
        }
        subHeader={
          <Tabs
            value={activeTab}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            className="headerTabs-nf"
          >
            <Tab label="Request Details" value={0} />
            <Tab
              label={`All Offers (${this.state.offersCounts?.totalOffers ? this.state.offersCounts.totalOffers : 0})`}
              value={1}
            />
            <Tab
              label={`All Offers (${ this.state.offersCounts?.shortlisted ? this.state.offersCounts.shortlisted : 0})`}
              value={4}
            />
            {/* <Tab label={this.state.offersCounts ? "Messaged (0)" : "Messaged (0)"} value={5} /> */}
            <Tab
              label={`All Offers (${ this.state.offersCounts?.accepted ? this.state.offersCounts.accepted : 0})`}
              value={2}
            />
            <Tab
              label={`All Offers (${ this.state.offersCounts?.archived ? this.state.offersCounts.archived : 0})`}
              value={3}
            />
          </Tabs>
        }
        navigation={true}
        canGoBack={true}
        backAction={() => this.props.history.push("/requests")}
        mainClass={"request-page-container"}
        //canCreateRequest={false}
        actions={
          <>
            <RequestActions
              request={request}
              handleDeleteRequest={this.handleDeleteRequest}
              handleEditRequest={() => this.props.history.push(`/edit-request/${this.props.match.params.id}`)}
              handleUnlistRequest={this.handleUnlistRequest}
            />
          </>
        }
      >

        {activeTab !== 0 && (
          <SortingPanel requestFilters = {requestFilters} totalOffersCount={this.state.totalOffersCount} sortingType={this.state.offersFilters.sortingType} sortBy={this.sortBy}/>
        )}

        <ErrorSnackbar
          open={this.state.openErrorSnackbar}
          handleClose={this.handleCloseErrorSnackbar}
        />

        {!loadingRequest && activeTab === 0 && (
          <div className="page-content">
            <Paper className={"white-card "}>
              {/* <div className="card-header">Request Details</div> */}
              <div className="shipment-header">
                <Grid container spacing={3} alignItems="center">
                  {!loadingRequest ? (
                    <React.Fragment>
                      <Grid item sm={3}>
                        <div className="shipment-info-grid">
                          <Grid container spacing={2}>
                            <Grid item>
                              <span className="grid-text">
                                Pickup {dateOptions[request.pickupDateOption]}:
                              </span>
                            </Grid>
                            <Grid item>
                              <span className="grid-content">
                                {moment(request.pickupDate).format("L")}
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item sm={6}>
                        <div className="place-container">
                          <div className="place">
                            <span className="place-name">
                              <img
                                src={request.routeOrigin.country.flag}
                                className="country-flag"
                                al=""
                              />
                              {request.routeOrigin.isPort
                                ? request.routeOrigin.description
                                : request.routeOrigin.city +
                                  ", " +
                                  request.routeOrigin.country.isoCode}
                            </span>
                          </div>
                          <div className="mode">
                            <span className="route"></span>
                            {request.freightType === 1 && (
                              <DirectionsBoat className="mode-icon" />
                            )}
                            {request.freightType === 2 && (
                              <DirectionsRailway className="mode-icon" />
                            )}
                          </div>
                          <div className="place">
                            <span className="place-name">
                              <img
                                src={request.routeDestination.country.flag}
                                className="country-flag"
                                al=""
                              />
                              {request.routeDestination.isPort
                                ? request.routeDestination.description
                                : request.routeDestination.city +
                                  ", " +
                                  request.routeDestination.country.isoCode}
                            </span>
                          </div>
                        </div>
                      </Grid>
                      <Grid item sm={3}>
                        <div className="shipment-info-grid">
                          <Grid container spacing={2} justifyContent="flex-end">
                            <Grid item>
                              <span className="grid-text">
                                Deliver{" "}
                                {dateOptions[request.deliveryDateOption]}:
                              </span>
                            </Grid>
                            <Grid item>
                              <span className="grid-content">
                                {moment(request.deliveryDate).format("L")}
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <Grid item sm={12}>
                      <Skeleton animation="wave" width="30%"></Skeleton>
                      <Skeleton animation="wave" width="80%"></Skeleton>
                      <Skeleton animation="wave" width="70%"></Skeleton>
                      <Skeleton animation="wave" width="100%"></Skeleton>
                    </Grid>
                  )}
                </Grid>
              </div>
              <div className="card-body">
                <Grid container spacing={3}>
                  <Grid item sm={4}>
                    <p className="review-body-label">Transport Mode:</p>
                    {transportModes[request.freightType]} <br />
                  </Grid>
                  <Grid item sm={4}>
                    <p className="review-body-label">Shipment Type:</p>
                    {shipmentTypes[request.shipmentType]}
                    {/* <p className="review-body-label">Origin:</p>
                      {request.routeOrigin.city + ', ' + request.routeOrigin.country.name}<br/>
                      <p className="review-body-label">Pickup {dateOptions[request.pickupDateOption]}:</p>
                      {moment(request.pickupDate).format('L')} */}
                  </Grid>
                  <Grid item sm={4}>
                    {/* <p className="review-body-label">Destination:</p>
                      {request.routeDestination.city + ', ' + request.routeDestination.country.name}<br/>
                      <p className="review-body-label">Deliver {dateOptions[request.deliveryDateOption]}:</p>
                      {moment(request.deliveryDate).format('L')} */}
                  </Grid>
                </Grid>
                <div className="spacer" />

                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <p className="review-body-label">Cargo Details:</p>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            {request.shipmentType !== 4 && (
                              <TableCell>Qty</TableCell>
                            )}
                            {request.shipmentType === 2 && (
                              <TableCell>Packaging Type</TableCell>
                            )}
                            {request.shipmentType === 3 && (
                              <TableCell>Container Type</TableCell>
                            )}
                            {request.shipmentType !== 3 && (
                              <TableCell>Description</TableCell>
                            )}
                            {request.shipmentType !== 1 && (
                              <TableCell>Category</TableCell>
                            )}
                            {!(
                              request.shipmentType === 3 ||
                              request.shipmentType === 4
                            ) && <TableCell>Length</TableCell>}
                            {!(
                              request.shipmentType === 3 ||
                              request.shipmentType === 4
                            ) && <TableCell>Width</TableCell>}
                            {!(
                              request.shipmentType === 3 ||
                              request.shipmentType === 4
                            ) && <TableCell>Height</TableCell>}
                            <TableCell>Gross Weight</TableCell>
                            {request.shipmentType === 4 && (
                              <TableCell>Volume</TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {request.items.map((item, index) => (
                            <TableRow key={index}>
                              {request.shipmentType !== 4 && (
                                <TableCell>{item.quantity}</TableCell>
                              )}
                              {request.shipmentType === 2 && (
                                <TableCell>
                                  {packagingTypes[item.packagingType]}
                                </TableCell>
                              )}
                              {request.shipmentType === 3 && (
                                <TableCell>
                                  {containerTypes.filter(c => c.Id == item.containerId)[0].Name}
                                </TableCell>
                              )}
                              {request.shipmentType !== 3 && (
                                <TableCell>{item.description || ""}</TableCell>
                              )}
                              {request.shipmentType !== 1 && (
                                <TableCell>
                                  {item.category.name || ""}
                                </TableCell>
                              )}
                              {!(
                                request.shipmentType === 3 ||
                                request.shipmentType === 4
                              ) && (
                                <TableCell>
                                  {item.length + "cm" || ""}
                                </TableCell>
                              )}
                              {!(
                                request.shipmentType === 3 ||
                                request.shipmentType === 4
                              ) && (
                                <TableCell>{item.width + "cm" || ""}</TableCell>
                              )}
                              {!(
                                request.shipmentType === 3 ||
                                request.shipmentType === 4
                              ) && (
                                <TableCell>
                                  {item.height + "cm" || ""}
                                </TableCell>
                              )}
                              <TableCell>
                                {item.grossWeight + "kg" || ""}
                              </TableCell>
                              {request.shipmentType === 4 && (
                                <TableCell>
                                  {item.volume + "cbm" || ""}
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <br />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  {request.description && (
                    <Grid item sm={12}>
                      <p className="review-body-label">Notes:</p>
                      {request.description}
                    </Grid>
                  )}
                  <Grid item sm={12}>
                    <p className="review-body-label">Additional Services:</p>
                    {request.services &&
                      request.services.map(service => (
                        <div className="services-box" key={service.id}>
                                  
                          <div className="checkbox-label">
                            <span className="checbox-title">
                              {service.name}
                            </span>
                            <span className="checbox-info">
                              {service.fields && service.fields.map((field, fieldIndex) => (
                                <span>{field.name}: {field.value}</span>
                              ))}
                            </span>
                          </div>
                            
                          
                        </div>
                      ))}
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </div>
        )}

        {/* {activeTab !== 0 &&
          !loadingRequest &&
          !loadingOffers &&
          !this.state.totalOffersCount && (
            <div className="empty-state small-state">
              <div className="bg img-holder">
                <img src="../img/no-data.svg" al="" />
              </div>
              <div className="title">No offers yet</div>
              <div className="info">
                You did not receive any offers yet. We'll notify you as soon you
                receive offers for this shipment request!
              </div>
            </div>
          )} */}

        <OfferConfirmationDialog ref={this.dialog} />

        <MessageDialog
          offerId={this.state.messageOfferId}
          close={() => this.setState({ messageOfferId: false })}
          //onChange={(type, party) => handlePartyChange(type, party)}
        />

        {activeTab !== 0  && (
          <div className="page-content">
            <Grid container spacing={3}>
              <Grid item sm={3}>
                {requestFilters && (
                  <OfferFilters
                    handleMinMax={(minName, maxName, minMax) =>
                      this.handleMinMax(minName, maxName, minMax)
                    }
                    minPrice={requestFilters.minPrice}
                    maxPrice={requestFilters.maxPrice}
                    minDay={requestFilters.minDay}
                    maxDay={requestFilters.maxDay}
                    countries={requestFilters.countries}
                    changeCountry={this.handleCountryChange}
                    handleCheckbox={this.handleCheckbox}
                    resetFilters={this.resetFilters}
                    request={request}
                    requestFilters={requestFilters}
                    requestServices={this.state.requestServices}
                    handleServicesCheckbox={id =>
                      this.handleServicesCheckbox(id)
                    }
                  />
                )}
              </Grid>
              <Grid item sm={9}>
                {offers && !loadingOffers && !loadingRequest ? (
                  <InfiniteScroll
                    dataLength={offers.length}
                    next={this.fetchMoreData}
                    hasMore={
                      !(this.state.offers.length >= this.state.totalOffersCount)
                    }
                    loader={
                      <div className="loading-pane">
                        <CircularProgress />
                      </div>
                    }
                    //endMessage={activeTab !== 2 && <div className="loading-pane">No more offers to show!</div>}
                  >
                    <React.Fragment>
                      {offers.map((offer, index) => (
                        <ExpansionPanel
                          elevation={1}
                          //expanded={this.state.openedCard == offer.id && this.state.isCardOpen ? true : false}
                          className="offer-card"
                          key={index}
                        >
                          <ExpansionPanelSummary
                            // expandIcon={<ExpandMoreIcon onClick={() => { this.setState({ openedCard: offer.id, isCardOpen: !this.state.isCardOpen }); }} />}
                            // aria-label="Expand"
                            // aria-controls="panel1c-content"
                            // id="panel1c-header"
                            expandIcon={<ExpandMoreIcon />}
                          >
                            <div className="offer-grid">
                              <div className="left">
                                <div className="supplier">
                                  <div className="supplier-logo">
                                    <Avatar
                                      className="supplier-pic"
                                      src={
                                        offer.owner.logoUrl &&
                                        offer.owner.logoUrl
                                      }
                                    >
                                      {!offer.owner.logoUrl &&
                                        offer.owner.logoAbbreviation}
                                    </Avatar>
                                  </div>
                                  <div className="supplier-content">
                                    <div className="supplier-name">
                                      {offer.owner.fullName}
                                      <span className="timestamp">
                                        {offer.createdFrom}
                                      </span>
                                    </div>
                                    <div className="supplier-rating">
                                      <Rating
                                        name="read-only"
                                        value={4}
                                        readOnly
                                        size="small"
                                      />{" "}
                                      <span className="rating-info">
                                        4.0/5 | 1477 reviews
                                      </span>
                                    </div>
                                  </div>
                                  <div className="icon-btn-group">
                                    {activeTab !== 3 && (
                                      <div>
                                        <Tooltip
                                          title="Supplier will not be notified"
                                          placement="left"
                                          arrow
                                        >
                                          <IconButton
                                            aria-label="Eliminate"
                                            size="small"
                                            disabled={request.status !== 1}
                                            disableRipple
                                            onClick={(event, ind, id) =>
                                              this.hideOffer(
                                                event,
                                                index,
                                                offer.id,
                                                "archived"
                                              )
                                            }
                                          >
                                            <ThumbDownOutlinedIcon />
                                          </IconButton>
                                        </Tooltip>

                                        {offer.isShortlisted === true ? (
                                          <IconButton
                                            disabled={request.status !== 1}
                                            aria-label="Shortlist"
                                            className="active"
                                            size="small"
                                            disableRipple
                                            onClick={(event, ind, id) =>
                                              this.shortlistOffer(
                                                event,
                                                index,
                                                offer.id
                                              )
                                            }
                                          >
                                            <ThumbUpIcon />
                                          </IconButton>
                                        ) : (
                                          <IconButton
                                            disabled={request.status !== 1}
                                            aria-label="Shortlist"
                                            size="small"
                                            disableRipple
                                            onClick={(event, ind, id) =>
                                              this.shortlistOffer(
                                                event,
                                                index,
                                                offer.id
                                              )
                                            }
                                          >
                                            <ThumbUpOutlinedIcon />
                                          </IconButton>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="offer-info">
                                  {offer.offerItems.map(item => {
                                    if (item.isRoute === true) {
                                      return (
                                        <div
                                          className="offer-locations"
                                          key={item.id}
                                        >
                                          <div className="loc">
                                            {request &&
                                              request.routeOrigin.city +
                                                ", " +
                                                request.routeOrigin.country
                                                  .isoCode}
                                            
                                          </div>
                                          <div className="loc-date">{moment(offer.pickupDate).format("L")}</div>
                                          <div className="transport-mode">
                                            {item.freightType === 1 && (
                                              <DirectionsBoat />
                                            )}
                                            {item.freightType === 2 && (
                                              <DirectionsRailway />
                                            )}
                                          </div>
                                          <div className="transport-time">
                                            {offer.daysRange} days
                                          </div>
                                          <div
                                            className={
                                              "steps freightType-" +
                                              item.freightType +
                                              this.checkPickupDelivery(
                                                offer.offerItems,
                                                offer.servicesIds
                                              )
                                            }
                                          >
                                            {/* + !item.isRoute && item.logisticService.id == 5 ? " pre-active " : "" + !item.isRoute && item.logisticService.id == 6 ? " post-active " : "" */}
                                            <div className="step ">
                                              <div className="bar"></div>
                                              <PinIcon className="pinIconLeft" />
                                            </div>
                                            {item.freightType === 1 && (
                                              <div className="step complete">
                                                <div className="bar"></div>
                                                <div className="port">
                                                  {item.routeOrigin &&
                                                    item.routeOrigin?.port
                                                      ?.code}
                                                </div>
                                              </div>
                                            )}
                                            {item.freightType === 1 && (
                                              <div className="step active">
                                                <div className="bar"></div>
                                                <div className="port">
                                                  {item.routeDestination &&
                                                    item.routeDestination?.port
                                                      ?.code}
                                                </div>
                                              </div>
                                            )}
                                            <div className="step">
                                              <div className="bar"></div>
                                              <PinIcon className="pinIconRight" />
                                            </div>
                                          </div>
                                          <div className="loc">
                                            {request &&
                                              request.routeDestination.city +
                                                ", " +
                                                request.routeDestination.country
                                                  .isoCode}
                                            
                                          </div>
                                          <div className="loc-date">{moment(offer.deliveryDate).format("L")}</div>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                              <div className="right">
                                <div className="validity">
                                  <span>Valid Until:</span>{" "}
                                  {moment(offer.createdDate).format("L")}
                                </div>
                                <div className="padded">
                                  {/* <div className="icon-btn-group">
                              <IconButton aria-label="Eliminate" size="small">
                                <ThumbDownOutlinedIcon />
                              </IconButton>
                              <IconButton aria-label="Shortlist" size="small">
                                <ThumbUpOutlinedIcon />
                              </IconButton>
                            </div> */}

                                  <div className="price">
                                    {this.format(offer.finalPrice)}
                                  </div>
                                  {/* <div className="btn-group">
                              <Button variant="contained" color="primary" disableElevation size="small" >Book</Button>
                              <IconButton aria-label="Eliminate" size="small">
                                <MessageIcon />
                              </IconButton>
                            </div> */}
                                  {activeTab !== 3 && (
                                    <React.Fragment>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        size="small"
                                        fullWidth
                                        className="mb-btn"
                                        onClick={(event, ofr, req) =>
                                          this.acceptOffer(
                                            event,
                                            offer,
                                            request
                                          )
                                        }
                                        disabled={request.status !== 1}
                                      >
                                        Accept
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        size="small"
                                        fullWidth
                                        disabled={request.status !== 1}
                                        onClick={(event, offerId) =>
                                          this.messageSupplier(event, offer.id)
                                        }
                                      >
                                        Message
                                      </Button>
                                    </React.Fragment>
                                  )}
                                  {activeTab === 3 && (
                                    <React.Fragment>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        size="small"
                                        fullWidth
                                        className="mb-btn"
                                        onClick={(event, ind, id) =>
                                          this.hideOffer(
                                            event,
                                            index,
                                            offer.id,
                                            "restored"
                                          )
                                        }
                                        disabled={request.status !== 1}
                                      >
                                        Unarchive
                                      </Button>
                                      {/* <Button variant="outlined" color="secondary" disableElevation size="small" fullWidth disabled={request.status !== 1}>Decline</Button> */}
                                    </React.Fragment>
                                  )}

                                  {/* <Button color="primary" className="shortlist-btn">Shortlist</Button> */}
                                </div>

                                {/* <Button className="expand-icon" endIcon={this.state.isCardOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />} disableRipple onClick={() => { this.setState({ openedCard: offer.id, isCardOpen: !this.state.isCardOpen }); }} >View Details</Button> */}
                                <span className="expand-text">
                                  View Details
                                </span>
                              </div>
                            </div>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails className="details">
                            <div className="offer-services">
                              {offer.offerItems.map((item, itemIndex) => (
                                <div key={item.id} className="offer-service">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          item.isChecked || !item.isOptional
                                        }
                                        size="small"
                                        disabled={
                                          request.status !== 1 ||
                                          !item.isOptional ||
                                          item.isRoute
                                        }
                                        name="services"
                                        color="primary"
                                      />
                                    }
                                    label={
                                      <span className="offer-item-box">
                                        {item.isRoute === true
                                          ? "Main Carriage (" +
                                            transportModes[item.freightType] +
                                            ")"
                                          : item.logisticService.name}
                                        <span className="offer-item-price">
                                          {item.price ? this.format(item.price) : ""}
                                        </span>
                                      </span>
                                    }
                                    onChange={(id, offerIndex, iIndex, price) =>
                                      this.handleLogisticServicesCheckbox(
                                        item.logisticService.id,
                                        index,
                                        itemIndex,
                                        item.price ? item.price : 0
                                      )
                                    }
                                  />
                                </div>
                              ))}
                            </div>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      ))}
                      {offers.length === 0 &&
                        this.state.offersCounts.totalOffers > 0 && (
                          <div className="empty-state">
                            {/* <LocalOfferTwoToneIcon /> */}
                            <div className="img-holder">
                              <img src="../img/empty.svg" al="" />
                            </div>
                            <div className="title">No offers found</div>
                            <div className="info">
                              Sorry! But we couldn't find any offers matching
                              this criteria.
                            </div>
                          </div>
                        )}
                    </React.Fragment>
                  </InfiniteScroll>
                ) : (
                  this.generateFakeRows(
                    this.state.offersCount
                      ? this.state.offersCount[tabNames[activeTab]]
                      : 4
                  ).map((row, index) => (
                    <ExpansionPanel
                      elevation={1}
                      expanded={false}
                      className="offer-card loading-card"
                      key={index}
                    >
                      <ExpansionPanelSummary>
                        <div className="offer-grid">
                          <div className="left">
                            <div className="supplier">
                              <div className="supplier-logo">
                                <Skeleton
                                  animation="wave"
                                  variant="circle"
                                  height="38px"
                                  width="38px"
                                ></Skeleton>
                              </div>
                              <div className="supplier-content">
                                <div className="supplier-name">
                                  <Skeleton
                                    animation="wave"
                                    width="200px"
                                  ></Skeleton>
                                </div>

                                <div className="supplier-rating">
                                  <Skeleton
                                    animation="wave"
                                    width="300px"
                                  ></Skeleton>
                                </div>
                              </div>
                            </div>
                            <div className="offer-info">
                              <Skeleton animation="wave" width="30%"></Skeleton>
                              <Skeleton
                                animation="wave"
                                width="100%"
                              ></Skeleton>
                              <Skeleton animation="wave" width="80%"></Skeleton>
                            </div>
                          </div>
                        </div>
                      </ExpansionPanelSummary>
                    </ExpansionPanel>
                  ))
                )}
              </Grid>
            </Grid>
          </div>
        )}
      </Page>
    );
  }
}

export default withSnackbar(ViewShipment);