import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import {
  Button,
  IconButton,
  Typography,
  TextField,
  Dialog,
} from "@material-ui/core";

import {
  ToggleButton,
  ToggleButtonGroup,
  Alert,
  Autocomplete,
} from "@material-ui/lab";

import {
  Close as CloseIcon,
  Person as PersonIcon,
  Business as OfficeIcon,
} from "@material-ui/icons";

import APIKit from "../include/APIKit";

const styles = theme => ({
  root: {
    margin: 0,
    padding: "16px 24px",
  },
  DialogContent: {
    margin: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: "24px",
    borderColor: "#eee",
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: "12px 24px",
  },
}))(MuiDialogActions);

export default function PartyFormDialog(props) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [countries, setCountries] = React.useState(null);
  const [party, setParty] = React.useState(
    props.party
      ? props.party
      : {
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          website: "",
          isOrganization: true,
          organizationName: "",
          initialAddress: {
            country: {
              isoCode: "",
            },
            addressLine1: "",
            city: "",
            state: "",
            zipcode: "",
            description: "",
          },
        }
  );
  // if(props.party[0]) {
  // 	setParty({...party, organizationName: props.party[0].organizationName})
  // 	console.log(props.party[0].organizationName)
  // }

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = () => {
    APIKit.get("countries")
      .then(result => {
        if (result.status === 200) {
          setCountries(result.data.record);
        }
      })
      .catch(e => {});
  };

  const saveParty = () => {
    setError(false);
    setLoading(true);
    if (props.party) {
      APIKit.put("parties", party)
        .then(result => {
          if (result.status === 200) {
            if (result.data.isSuccess) {
              props.dialogToggler(false, "updated");
            }
          } else {
            setError("Unable to update party! Please try again");
          }
          setLoading(false)
        })
        .catch(e => {
          setError("Unable to create party! Please try again");
          setLoading(false)
        });
    } else {
      APIKit.post("parties", party)
        .then(result => {
          if (result.status === 200) {
            if (result.data.isSuccess) {
              props.dialogToggler(false, "created");
            }
          } else {
            setError("Unable to create party! Please try again");
          }
          setLoading(false)
        })
        .catch(e => {
          setError("Unable to create party! Please try again");
          setLoading(false)
        });
    }
  };

  const handleClose = () => {
    props.dialogToggler(false);
  };

  const handleToggle = (event, value) => {
    if (value != null) {
      setParty({ ...party, isOrganization: value });
    }
  };

  const handleInputChange = (event, value) => {
    setParty({ ...party, [event.target.name]: event.target.value });
  };

  const handleAddressInputChange = (event, value) => {
    setParty({
      ...party,
      initialAddress: {
        ...party.initialAddress,
        [event.target.name]: event.target.value,
      },
    });
  };

  const handleCountryChange = value => {
    setParty({
      ...party,
      initialAddress: { ...party.initialAddress, country: value },
    });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        className="form-dialog"
      >
        <DialogTitle id="form-dialog-title" onClose={handleClose}>
          Create a Party
        </DialogTitle>
        <DialogContent dividers>
          <ToggleButtonGroup
            value={party.isOrganization}
            className="customToggle"
            exclusive
            onChange={(event, value) => handleToggle(event, value)}
          >
            <ToggleButton value={true}>
              <OfficeIcon /> Organization
            </ToggleButton>
            <ToggleButton value={false}>
              <PersonIcon /> Individual
            </ToggleButton>
          </ToggleButtonGroup>
          <br />
          {party.isOrganization === true ? (
            <TextField
              label="Organization Name"
              name="organizationName"
              value={party.organizationName || ""}
              onChange={handleInputChange}
              variant="outlined"
              type="text"
              autoFocus
              size="small"
              fullWidth
              InputLabelProps={{ required: true }}
            />
          ) : (
            <React.Fragment>
              <TextField
                label="First Name"
                name="firstName"
                value={party.firstName || ""}
                onChange={handleInputChange}
                variant="outlined"
                type="text"
                size="small"
                autoFocus
                fullWidth
                InputLabelProps={{ required: true }}
              />

              <TextField
                label="Last Name"
                name="lastName"
                value={party.lastName || ""}
                onChange={handleInputChange}
                variant="outlined"
                type="text"
                size="small"
                fullWidth
                InputLabelProps={{ required: true }}
              />
            </React.Fragment>
          )}

          <TextField
            label="Phone Number"
            name="phone"
            value={party.phone || ""}
            onChange={handleInputChange}
            variant="outlined"
            type="text"
            size="small"
            fullWidth
            InputLabelProps={{ required: true }}
          />

          <TextField
            label="Email Address"
            name="email"
            value={party.email || ""}
            onChange={handleInputChange}
            variant="outlined"
            type="text"
            size="small"
            fullWidth
            InputLabelProps={{ required: false }}
          />

          <Autocomplete
            options={countries ? countries : []}
            value={party.initialAddress.country}
            onChange={(event, option) =>
              handleCountryChange(option ? option : null)
            }
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
                label="Country"
                variant="outlined"
                size="small"
                InputLabelProps={{ required: false }}
              />
            )}
            disabled={countries === null ? true : false}
            openOnFocus={false}
          />

          <TextField
            label="City"
            name="city"
            value={party.initialAddress.city || ""}
            onChange={handleAddressInputChange}
            variant="outlined"
            type="text"
            size="small"
            fullWidth
            InputLabelProps={{ required: false }}
          />

          <TextField
            label="Address"
            name="addressLine1"
            value={party.initialAddress.addressLine1 || ""}
            onChange={handleAddressInputChange}
            variant="outlined"
            type="text"
            size="small"
            fullWidth
            InputLabelProps={{ required: false }}
          />

          {error != false ? (
            <Alert style={{ marginTop: 15 }} severity="error">
              {error}
            </Alert>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={saveParty} disabled={loading} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
