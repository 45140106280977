import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";

import { countries, vats, continents, supportedCountries } from "../include/countries";
import states from '../include/states.json';

// UI Imports
import { 
  Button, 
  TextField, 
  Grid, 
  CircularProgress, 
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  LinearProgress,
  Box,
  makeStyles, withStyles ,
  Typography,
  Radio,
  RadioGroup,
  InputLabel,
  IconButton,
  Input as MaterialInput
} from '@material-ui/core';

import { 
	ToggleButton, 
	ToggleButtonGroup,
	Alert,
  AlertTitle,
	Autocomplete,
} from '@material-ui/lab';

import Input, { formatPhoneNumber }  from 'react-phone-number-input/input';
// import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input'

import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CustomPhoneNumber from '../components/PhoneNumber'

import {
	Close as CloseIcon,
	Person as PersonIcon,
	Business as OfficeIcon,
  Error as ErrorIcon,
  DirectionsRailway, DirectionsBoat, Flight, DirectionsCar, Landscape,
  LocalShipping,
  ChevronLeft,
  Backup as BackupIcon,
  DescriptionTwoTone as DescriptionTwoToneIcon,
  Delete as DeleteIcon
} from '@material-ui/icons';

import { useStore } from "../context/StoreContext";

import Carousel from 'react-material-ui-carousel'
import APIKit from "../include/APIKit";
import CategoryStep from "../components/SupplierApplication/CategoryStep";
import CoverageStep from "../components/SupplierApplication/CoverageStep";
import { taxreg } from "../include/taxreg";


function SupplierApplication(props) {
  const { authToken, userData, setAuthToken } = useStore();

  const [countryStates, setCountryStates] = useState([]);

  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [stripeLoader, setStripeLoader] = useState(false);

  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Country, setCountry] = useState(null);
  const [company, setCompany] = useState({
    companyLegalName: "",
    vatNumber: "",
    phone: {
      rawNumber: "",
      countryCode: null
    },
    mobile: {
      rawNumber: "",
      countryCode: null
    },
    website: "",
    businessEmail: "",
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      zipCode: "",
      state: "",
      country: {
        isoCode: null,
        name: null
      }
    }
  });
  const [Password, setPassword] = useState("");
  const [AccountRole, setAccountRole] = useState(2);

  const [validate, setValidate] = useState({});
  const [PasswordHelperText, setPasswordHelperText] = useState("");
  const [EmailHelperText, setEmailHelperText] = useState("");

  const [countryInput, setCountryInput] = useState("");
  const [isBusyResend, setIsBusyResend] = useState(false);
  const [validVat, setValidVat] = useState(null);
  const [validNumbers, setValidNumbers] = useState({
    phone: null,
    mobile: null
  });
  const [vatBusy, setVatBusy] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [check, setCheck] = useState({
    terms: false,

  });
  const [selectedFiles, setSelectedFiles] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loadingDetails, setLoadingDetails] = useState(true);

  const [state, setState] = useState({
    businessType: "",
    shipmentTypes: [],
    freightMode: [],
    oceanCoverageFromType: "countries",
    oceanCoverageToType: "countries",
    oceanFromContinents: [],
    oceanFromCountries: [],
    landCoverageFromType: "countries",
    landCoverageToType: "countries",
    landFromContinents: [],
    landFromCountries: []
  })
  const [redirecting, setRedirecting] = useState(false);
  const [isOnboarded, setIsOnboarded] = useState(false);
  const [isStripeActive, setIsStripeActive] = useState(false);
  const [category, setCategory] = useState({
    id: 2,
    businessType: 1,
    isAirSupported: false,
    isLandSupported: false,
    isSeaSupported: true,
    isContainersSupported: true,
    isRoroSupported: false,
    isBulkSupported: false,
    seaCoverageAreas: {
      from: {
        countryIds: ["LB", "DE", "GB"]
      },
      to: {
        countryIds: ["SY", "US", "CN"]
      }
    }
  })
  const [currentStep, setCurrentStep] = useState(1);


  document.title = "Create an Account - Logisoft Marketplace";

  useEffect(() => {
    if(selectedFiles != '')
      uploadFile();
  } , [selectedFiles]);


  useEffect(() => {
    setCompany({...company, 
      companyLegalName: userData?.companyName, 
      country: userData?.country,
      phone: {...company.phone, countryCode: userData?.country.isoCode},
      mobile: {...company.mobile, countryCode: userData?.country.isoCode}
    });
    setCountry(userData?.country);
    setCountryStates(states.filter(a => a.countryCode == userData?.country.isoCode))
    
  } , [userData]);

  useEffect(() => {
    //TODO put it back in the previous useEffect
    getDetails();
  } , []);


  const useStylesFacebook = makeStyles((theme) => ({
    root: {
      position: 'relative',
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
      color: isOnboarded ? '#51af50' : '#1b4ef4',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
  }));
  const classes = useStylesFacebook();

  function sayHi () {
    return "Hi";
  }

  function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
  }
  const flagCDN = (code) => {
    return <img src={"https://flagcdn.com/"+code.toLowerCase()+".svg"} className="dropdown-flag" />;
  }

  const handleItemCheckboxChange = (event, name) => {
    setCheck({...check, [name]: !check[name]})
  }

  //handleFilters = (option, name) => {
    // const filters = this.state.filters;
    // filters[name] = option != null ? option.value : null;
    // this.setState({filters}, () => {console.log(this.state.filters)});
  //}


  function validator(object) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var empty = [];
    for(var key in object) {
      if(object[key] === "" || object[key] == null) {
        empty.push(key);
      }
      if(key == "businessEmail") {
        if(!re.test(String(object.businessEmail).toLowerCase())) {
          setEmailHelperText('Please enter a valid email address.')
          empty.push('businessEmail');
        }
      }
      if(key == "vatNumber") {
        console.log(String(object.vatNumber).toLowerCase())
        console.log(Country?.isoCode)
        console.log(taxreg[Country?.isoCode])
        if(!taxreg[Country?.isoCode].test(String(object.vatNumber))) {
          empty.push('vatNumber');
          setValidVat("Invalid Tax ID for "+Country?.name);
        }
      }
    }
        
    
    return empty;
  }



  function taxValidator (country, taxid) {
    if(!taxreg[country].test(String(taxid).toLowerCase())) {
      return false;
    }
    return true;
  }




  function saveBusinessField() {   
    setIsError(false);
    setIsBusy(true);
    const oceanFromContinents = [];
    const oceanFromCountries = [];
    if(state.oceanCoverageFromType == "continents") {
      for(var i in state.oceanFromContinents) {
        oceanFromContinents.push(state.oceanFromContinents[i].value);
      }
    }
    else {
      for(var i in state.oceanFromCountries) {
        oceanFromCountries.push(state.oceanFromCountries[i].isoCode);
      }
    }

    const oceanToContinents = [];
    const oceanToCountries = [];
    if(state.oceanCoverageToType == "continents") {
      for(var i in state.oceanToContinents) {
        oceanToContinents.push(state.oceanToContinents[i].value);
      }
    }
    else {
      for(var i in state.oceanToCountries) {
        oceanToCountries.push(state.oceanToCountries[i].isoCode);
      }
    }

    const landFromContinents = [];
    const landFromCountries = [];
    if(state.landCoverageFromType == "continents") {
      for(var i in state.landFromContinents) {
        landFromContinents.push(state.landFromContinents[i].value);
      }
    }
    else {
      for(var i in state.landFromCountries) {
        landFromCountries.push(state.landFromCountries[i].isoCode);
      }
    }

    const landToContinents = [];
    const landToCountries = [];
    if(state.landCoverageToType == "continents") {
      for(var i in state.landToContinents) {
        landToContinents.push(state.landToContinents[i].value);
      }
    }
    else {
      for(var i in state.landToCountries) {
        landToCountries.push(state.landToCountries[i].isoCode);
      }
    }

    
    
    const body = {
      businessType: state.businessType,
      isAirSupported: false,
      isLandSupported: state.freightMode.includes("land"),
      isSeaSupported: state.freightMode.includes("ocean"),
      isContainersSupported: state.shipmentTypes.includes("containers"),
      isRoroSupported: state.shipmentTypes.includes("roro"),
      isBulkSupported: state.shipmentTypes.includes("bulk"),
      seaCoverageAreas: {
        from: {
          countryIds: oceanFromCountries,
          continentIds: oceanFromContinents
        },
        to: {
          countryIds: oceanToCountries,
          continentIds: oceanToContinents
        }
      }
    }

    if(body.isLandSupported) {
      body.landCoverageAreas = {
        from: {
          countryIds: landFromCountries,
          continentIds: landFromContinents
        },
        to: {
          countryIds: landToCountries,
          continentIds: landToContinents
        }
      }
    }

    APIKit.put("organizations/businessfield", body).then(result => {
      if(result.data.isSuccess) {
        setIsSuccess(true);
      }
      else {
        setIsError(true);
        setErrorMessage("Whoops! Something went wrong.");
      }
      setIsBusy(false);
    }).catch(e => {
      setIsError(true);
      setErrorMessage(e.response.data?.Errors[0]);
      setIsBusy(false);
    });

    
  }

  function saveBusinessDetails() {   
    setIsError(false);
    setIsBusy(true);
    var body = {...company};
    body.address.country = {
      isoCode: Country.isoCode,
      name: Country.name
    }
    // body.phone.rawNumber = formatPhoneNumber(company.phone.rawNumber.toString());
    // body.mobile.rawNumber = formatPhoneNumber(company.mobile.rawNumber.toString());
    APIKit.put("organizations/businessdetails", body).then(result => {
      if(result.data.isSuccess) {
        setIsSuccess(true);
        setCurrentStep(5);
      }
      setIsBusy(false);
    }).catch(e => {
      setIsError(true);
      setErrorMessage(e.response.data?.Errors[0]);
      setIsBusy(false);
    });

  }



  function configureStripe() {
    setStripeLoader(true);
    APIKit.get("payments/new-account" ).then(result => {
      if (result.status === 200) {
        if(result.data.isSuccess) {
          window.location.href = result.data.record;
        }
      }
    }).catch(e => {
      setStripeLoader(false);
      setIsError(true);
      setErrorMessage(e.response.data?.Message);
    });
  
  }

  function getDetails() {
    APIKit.get("organizations/onboarding-details" ).then(result => {
      if (result.status === 200) {
        if(result.data.isSuccess) {
          const businessField = result.data.record.businessField;
          if(businessField.isSectionCompleted) {
            let freightMode = [],
            oceanFromCountries = [],
            oceanToCountries = [],
            oceanFromContinents = [],
            oceanToContinents = [],
            oceanCoverageFromType = 'countries',
            oceanCoverageToType = 'countries',
            landFromCountries = [],
            landToCountries = [],
            landFromContinents = [],
            landToContinents = [],
            landCoverageFromType = 'countries',
            landCoverageToType = 'countries';

            if(businessField.isSeaSupported) {
              freightMode.push("ocean");
              if(businessField.seaCoverageAreas) {
                oceanFromCountries = businessField.seaCoverageAreas.from.countries || [];
                oceanToCountries = businessField.seaCoverageAreas.to.countries || [];
                oceanFromContinents = Object.entries(continents).filter(x => businessField.seaCoverageAreas.from.continents[x[0]]).map(x => x[1]) || [];
                oceanToContinents = Object.entries(continents).filter(x => businessField.seaCoverageAreas.to.continents[x[0]]).map(x => x[1]) || [];
                oceanCoverageFromType = oceanFromContinents.length > 0 ? 'continents' : 'countries';
                oceanCoverageToType = oceanToContinents.length > 0 ? 'continents' : 'countries';
              } 
            }

            if(businessField.isLandSupported) {
              freightMode.push("land");
              if(businessField.landCoverageAreas) {
                landFromCountries = businessField.landCoverageAreas.from.countries || [];
                landToCountries = businessField.landCoverageAreas.to.countries || [];
                landFromContinents = Object.entries(continents).filter(x => businessField.landCoverageAreas.from.continents[x[0]]).map(x => x[1]) || [];
                landToContinents = Object.entries(continents).filter(x => businessField.landCoverageAreas.to.continents[x[0]]).map(x => x[1]) || [];
                landCoverageFromType = landFromContinents.length > 0 ? 'continents' : 'countries';
                landCoverageToType = landToContinents.length > 0 ? 'continents' : 'countries';
              } 
            }

            const shipmentTypes = [];
            if(businessField.isRoroSupported) shipmentTypes.push("roro");
            if(businessField.isBulkSupported) shipmentTypes.push("bulk");
            if(businessField.isContainersSupported) shipmentTypes.push("containers");
            const businessType = businessField.businessType;
            
            setState({...state, freightMode, shipmentTypes, businessType, oceanFromCountries, oceanToCountries, oceanFromContinents, oceanToContinents, oceanCoverageFromType, oceanCoverageToType, landFromCountries, landToCountries, landFromContinents, landToContinents, landCoverageFromType, landCoverageToType});
            

          }
          const company = result.data.record.businessDetails;
          if(company.isSectionCompleted) {
            // delete company.isSectionCompleted;
            setCompany(company);
            if(company.address.country) {
              setCountry(company.address.country);
            }
          }
          const verificationDocuments = result.data.record.verificationDocuments.verificationDocuments;
          if(verificationDocuments.length > 0) {
            setSelectedFiles({name: verificationDocuments[0].name, size: verificationDocuments[0].size});
          }
          if(result.data.record.paymentProvider.isOnboarded) {
            setCurrentStep(5);
            setIsOnboarded(true);
          }
          else if(result.data.record.businessDetails.isSectionCompleted) {
            setCurrentStep(5);
          }
          else if(result.data.record.businessField.isSectionCompleted) {
            setCurrentStep(3);
          }
          else {
            setCurrentStep(1);
          }
          console.log("Onboarding Details: ", result.data.record)
        }
      }
      setLoadingDetails(false);
    }).catch(e => {

    });
  }

 

  if(redirecting) {
    return <Redirect to="/login" />;
  }


  const handleToggle = (event, value, name) => {
		if(value != null) {
			setState({...state, [name]: value});
		}
	}

  const handleMultiToggle = (event, value, name) => {
    setState({...state, [name]: value})
	}

  






  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: option => option.name,
  });
  

  // if (isLoggedIn) {
  //   return <Redirect to={referer} />;
  // }


  

  const ProgressTitle = (props) => {
    const stepTitle = ["Business Category", "Business Coverage", "Company Details", "Contact Details", "Configure Payouts Method"];
    return (
      <div className="profile-step-info">
        <span>{stepTitle[props.step-1]}</span>
        <span>{props.step == 5 ? "All set! Your profile is almost ready" : `Next: ${stepTitle[props.step]}`}</span>
      </div>
    );
  }

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
      default:
        return <CategoryStep data={state} validation={validate} handleMultiToggle={handleMultiToggle} setState={setState} />;
      case 2:
        return <CoverageStep data={state} setState={setState} />;
      case 3:
        return renderCompanyInfo();
      case 4:
        return renderContactDetails();
      case 5:
        return renderPayout();
    }
  }

  
  

  


  const handleFile = (event) => {
    setFileError(false);
    if(event.target.files[0].size >= 5242880) {
      setFileError('File size must be less than 5MB');
      return false;
    }
    setSelectedFiles(event.target.files[0]);
    console.warn(event.target.files[0])
    
  }

  const getFileInfo = (file) => {
    const name = file.name;
    const lastDot = name.lastIndexOf('.');
    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);
    const size = formatBytes(file.size);
    return {
      name,
      fileName,
      ext,
      size
    }
  }


  const uploadFile = () => {
    const data = new FormData() 
    data.append('files', selectedFiles)

    const config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setUploadProgress(percentCompleted)
      }
    }

    APIKit.post("organizations/verification-documents", data, config ).then(result => {
      if (result.status === 200) {
        if(result.data.isSuccess) {
        }
        else {
          setIsError(true);
          setErrorMessage(result.data.message);
        }
      } else {
        setIsError(true);
        setErrorMessage(result.data.message);
      }
    }).catch(e => {});

  }

  const checkVAT = () => {
    setVatBusy(true);
    APIKit.get(`validation/tax-id?countryCode=${Country?.isoCode}&number=${company.vatNumber}` ).then(result => {
      if (result.status === 200) {
        if(result.data.isSuccess) {
          setValidVat(true);
          setCurrentStep(4);
        }
        else {
          setValidVat(false);
        }
      }
      else {
        setValidVat(false);
      }
      setVatBusy(false);
    }).catch(e => { setValidVat(false); setVatBusy(false); });
  }

  const checkNumber = (number, name) => {
    APIKit.get(`validation/phone-number?countryCode=${number?.countryCode}&number=${number?.rawNumber}` ).then(result => {
      if (result.status === 200) {
        if(result.data.isSuccess) {
          setValidNumbers({...validNumbers, [name]: true});
          if(validNumbers.phone != false && validNumbers.mobile != false) {
            setCurrentStep(5);
            saveBusinessDetails();
          }
        }
        else {
          setValidNumbers({...validNumbers, [name]: false});
        }
      }
      else {
        setValidNumbers({...validNumbers, [name]: false});
      }
    }).catch(e => { setValidNumbers({...validNumbers, [name]: false}); });
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const FileIcon = (props) => {
    return <img src={"/img/filetypes/"+props.type+".svg"} className="filetype-icon" />;
  }

  const renderCompanyInfo = () => {
    return (
      <div className="currentStep">
        <Typography variant="subtitle2" gutterBottom>Registered Business Details</Typography>
        <Autocomplete
          value={Country}
          style={{ width: '100%' }}
          disableClearable
          options={supportedCountries}
          filterOptions={filterOptions}
          autoComplete="new-password"
          getOptionLabel={(option) => option.name}
          onChange={(event, option) => {
            setCountry(option); 
            setCountryStates(states.filter(a => a.countryCode == option.isoCode));
            setCompany({...company, address: {...company.address, state: null}})
          }}
          renderOption={(option) => (
          <React.Fragment>
            <span>{flagCDN(option.isoCode)}</span>
            {option.name}
          </React.Fragment>
          )}
          renderInput={(params) => (
          <TextField
            {...params}
            label="Country"
            error={validate.country}
            name="country"
            variant="outlined"
            autoComplete="new-password"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
          />
          )}
        />
        <TextField label="Legal Business Name" autoComplete="new-password" variant="outlined" fullWidth onChange={e => setCompany({...company, companyLegalName: e.target.value}) } value={company.companyLegalName} name="businessName" error={validate.companyLegalName} />
        <FormHelperText className="sublabel mmt-10" >The name you provide must exactly match the name associated with your tax ID.</FormHelperText>

        <TextField 
          label={vats.find(x => x.country === Country?.isoCode)?.name || "Tax Identification Code"} autoComplete="new-password" variant="outlined" fullWidth 
          onChange={e => {setCompany({...company, vatNumber: e.target.value});} }
          onBlur={() => taxValidator(Country?.isoCode, company.vatNumber) }
          value={company.vatNumber } name="vatNumber" error={validate.vatNumber}  
          placeholder={vats.find(x => x.country === Country?.isoCode)?.sample}
          
        />
        {validate.vatNumber && <FormHelperText style={{marginTop: -5, marginBottom: 10}} error><ErrorIcon className="errorIcon" />{validVat || "Please enter a valid VAT number"}</FormHelperText>}

        <Typography variant="subtitle2" gutterBottom style={{marginBottom: 5}}>License Doucment</Typography>
        <FormHelperText className="sublabel">A license document is any document that can be used to validate a legit shipping & logisitics business.</FormHelperText>
        {selectedFiles == '' ?
        <form>
          <Button  variant="contained" component="label" disableElevation className="dropzone" > 
            <BackupIcon />
            Browse and upload your document (5MB max)
            <input type="file" name="files" hidden onChange={(event) => handleFile(event)} />            
          </Button>
        </form>
        :
        <div className="selectedFile">
          <FileIcon type={getFileInfo(selectedFiles)["ext"]} />
          <div className="fileContent">
            <span>{getFileInfo(selectedFiles)["name"]}</span>
            {uploadProgress == 100 && <span>{getFileInfo(selectedFiles)["size"]}</span>}
            {(uploadProgress > 0 && uploadProgress < 100) && <LinearProgress variant="determinate" value={uploadProgress} style={{marginTop: 5}} />}
          </div>
          <IconButton disableFocusRipple disableRipple disableTouchRipple  onClick={() => setSelectedFiles('')} size="small" style={{height: 30}} ><DeleteIcon style={{color: '#8c92a1'}}  /></IconButton>
        </div>
        }
        {fileError != false && <FormHelperText style={{marginTop: 10}} error><ErrorIcon className="errorIcon" />{fileError}</FormHelperText>}
          
        


        <Typography variant="subtitle2" gutterBottom style={{marginTop: 25}}>Registered Business Address</Typography>
        
        <TextField label="Address line 1" autoComplete="new-password"  variant="outlined" fullWidth onChange={e => setCompany({...company, address: {...company.address, addressLine1: e.target.value}}) } value={company.address.addressLine1} name="addressline1"  error={validate.addressLine1} />

        <TextField label="Address line 2" autoComplete="new-password"  variant="outlined" fullWidth onChange={e => setCompany({...company, address: {...company.address, addressLine2: e.target.value}}) } value={company.address.addressLine2} name="addressline2"  error={validate.addressLine2} />
        <TextField label="City" autoComplete="new-password" variant="outlined"  fullWidth onChange={e => setCompany({...company, address: {...company.address, city: e.target.value}}) } value={company.address.city} name="city"  error={validate.city} />
        
        <Grid container spacing={2}>
          <Grid item sm={8}>
            <Autocomplete
              value={countryStates?.filter(a => a.isoCode == company.address.state)?.isoCode}
              style={{ width: '100%' }}
              disableClearable
              options={countryStates}
              filterOptions={filterOptions}
              autoComplete="new-password"
              getOptionLabel={(option) => option.name}
              onChange={(event, option) => setCompany({...company, address: {...company.address, state: option.isoCode}})}
              renderInput={(params) => (
              <TextField
                {...params}
                label="State"
                error={validate.state}
                name="country"
                variant="outlined"
                autoComplete="new-password"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
              )}
            />
          </Grid>
          <Grid item sm={4}>
            <TextField label="Postal code" autoComplete="new-password" variant="outlined"  fullWidth onChange={e => setCompany({...company, address: {...company.address, zipCode: e.target.value}}) } value={company.address.zipCode} name="zipCode"  error={validate.zipCode} />
          </Grid>
        </Grid>

        

        
      </div>
    )
  }



  const renderContactDetails = () => {
    return (
      <div className="currentStep">
        <Typography variant="subtitle2" gutterBottom>Business Website</Typography>
        <TextField placeholder="https://example.com" autoComplete="new-password" variant="outlined" fullWidth onChange={e => setCompany({...company, website: e.target.value}) } value={company.website} name="website"  error={validate.website} />

        <Typography variant="subtitle2" gutterBottom>Phone Number</Typography>
        <Grid container spacing={1}>
          <Grid item sm={4}>
            <Autocomplete
              value={supportedCountries.find(x => x.isoCode === company.phone?.countryCode)}
              style={{ width: '100%' }}
              disableClearable
              options={supportedCountries}
              filterOptions={filterOptions}
              autoComplete="new-password"
              getOptionLabel={(option) => `${option.isoCode} (+${option.phone})` }
              fullWidth
              onChange={(event, option) => setCompany({...company, phone: {...company.phone, countryCode: option.isoCode}}) }
              renderOption={(option) => (
              <React.Fragment>
                <span>{flagCDN(option.isoCode)}</span>
                {option.isoCode} (+{option.phone})
              </React.Fragment>
              )}
              renderInput={(params) => (
              <TextField
                {...params}
                name="countryCode"
                variant="outlined"
                autoComplete="new-password"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
              )}
            />
          </Grid>
          <Grid item sm={8}>
            <Input
              international={false}
              withCountryCallingCode={false}
              initialValueFormat="national"
              country={company.phone?.countryCode}
              value={company.phone?.rawNumber}
              onChange={(value) => setCompany({...company, phone: {...company.phone, rawNumber: value}}) }
              inputComponent={CustomPhoneNumber}
            />
          </Grid>
        </Grid>
        
        {(validate.phone || validNumbers.phone == false) && <FormHelperText style={{marginTop: -5, marginBottom: 10}} error><ErrorIcon className="errorIcon" />Please enter a valid phone number</FormHelperText>}

        <Typography variant="subtitle2" gutterBottom>Mobile Number</Typography>
        <Grid container spacing={1}>
          <Grid item sm={4}>
            <Autocomplete
              value={supportedCountries.find(x => x.isoCode == company.mobile.countryCode)}
              style={{ width: '100%' }}
              disableClearable
              options={supportedCountries}
              filterOptions={filterOptions}
              autoComplete="new-password"
              getOptionLabel={(option) => `${option.isoCode} (+${option.phone})` }
              fullWidth
              onChange={(event, option) => setCompany({...company, mobile: {...company.mobile, countryCode: option.isoCode}}) }
              renderOption={(option) => (
              <React.Fragment>
                <span>{flagCDN(option.isoCode)}</span>
                {option.isoCode} (+{option.phone})
              </React.Fragment>
              )}
              renderInput={(params) => (
              <TextField
                {...params}
                name="countryCode"
                variant="outlined"
                autoComplete="new-password"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
              )}
            />
          </Grid>
          <Grid item sm={8}>
            <Input
              international={false}
              withCountryCallingCode={false}
              initialValueFormat="national"
              country={company.mobile?.countryCode}
              value={company.mobile?.rawNumber}
              onChange={(value) => setCompany({...company, mobile: {...company.mobile, rawNumber: value}}) }
              inputComponent={CustomPhoneNumber}
            />
          </Grid>
        </Grid>
        {(validate.mobile || validNumbers.mobile == false) && <FormHelperText style={{marginTop: -5, marginBottom: 10}} error><ErrorIcon className="errorIcon" />Please enter a valid mobile number</FormHelperText>}

        <Typography variant="subtitle2" gutterBottom>Business Email Address</Typography>
        <TextField placeholder="info@example.com" autoComplete="new-password" variant="outlined" fullWidth onChange={e => setCompany({...company, businessEmail: e.target.value}) } value={company.businessEmail} name="email" error={validate.businessEmail} helperText={validate.businessEmail && <span><ErrorIcon className="errorIcon" /> {EmailHelperText}</span>} />

       
      </div>
    )
  }
 
  const renderPayout = () => {
    if(isOnboarded){
      return (
        <div className="currentStep">
          <Alert severity="success">
            <AlertTitle>Profile Created Successfully!</AlertTitle>
            Your profile has been created and your application has been submitted for review. Once approved you'll gain full access to our shipping marketplace.
          </Alert>
          <br />
          <Button variant="contained" size="large" fullWidth color="primary" disableElevation
            onClick={()=> {window.location.href = "/";}}
          >
            Go to Dashboard
          </Button>
  
         
        </div>
      )
    }
    else {
      return (
        <div className="currentStep">
          <Alert severity="info">
            <AlertTitle>Configure Payouts Method</AlertTitle>
            To receive your money and accept online payments, you need to configure a stripe account so we can connect it to your marketplace account.<br />
            Kindly note that we do not keep any finanical information you provide to stripe.<br />
            All information that you'll be submitting to stripe will be used by stripe only to validate your legal business and financial credibility.
          </Alert>
          <br />
          <Button variant="contained" size="large" fullWidth color="primary" disableElevation
            onClick={()=> configureStripe()}
            disabled={stripeLoader}
          >
            Save & Configure Stripe
          </Button>
  
         
        </div>
      )
    }
    
  }
  
  const validateBusinessField1 = () => {
    var obj = {
      freightMode: state.freightMode.length > 0 ? state.freightMode[0] : null,
      shipmentTypes: state.shipmentTypes.length > 0 ? state.shipmentTypes[0] : null,
      businessType: state.businessType
    }
    var isValid =  validator(obj);
    if(isValid.length > 0) {
      const vali = [];
      for(var key in isValid){
        vali[isValid[key]] = true;
        setValidate(vali);
      }
      return false;
    }
    setValidate({});
    return true;
  }

  const validateBusinessDetails1 = () => {
    var companyAddress = company.address;
    delete companyAddress.addressLine2;
    var obj = {
      companyLegalName: company.companyLegalName,
      vatNumber: company.vatNumber,
      ...companyAddress,
      country: Country ? Country.isoCode : null

    };
    var isValid =  validator(obj);
    if(isValid.length > 0) {
      const vali = [];
      for(var key in isValid){
        vali[isValid[key]] = true;
        setValidate(vali);
      }
      return false;
    }
    //checkVAT();
    setValidate({});
    
    setCurrentStep(4);
  }

  const validateBusinessDetails2 = () => {
    var obj = {
      website: company.website,
      phone: company.phone?.rawNumber,
      mobile: company.mobile?.rawNumber,
      businessEmail: company.businessEmail
    };
    var isValid =  validator(obj);
    if(isValid.length > 0) {
      const vali = [];
      for(var key in isValid){
        vali[isValid[key]] = true;
        setValidate(vali);
      }
      return false;
    }
    setValidate({});
    saveBusinessDetails();
  }

  const fireNext = () => {
    switch (currentStep){
      case 1:
        if(validateBusinessField1())
          setCurrentStep(2);
        break;
      case 2: 
        saveBusinessField();
        setCurrentStep(3);
        break;
      case 3:
        //setCurrentStep(4);
        validateBusinessDetails1();
        break;
      case 4:
        validateBusinessDetails2();
        break;
      case 5:
        setCurrentStep(6);
        break;
    }
  }

  const renderFooter = () => {
    return (
      <React.Fragment>
        <Button variant="contained" size="large" disableElevation className="back-button" disabled={currentStep == 1}
          onClick={()=> setCurrentStep(currentStep-1)} 
        >
          <ChevronLeft/>
        </Button>
        <Button variant="contained" size="large" color="primary" disableElevation style={{minWidth: 84}}
          onClick={()=> fireNext()}
          disabled={currentStep == 5 || vatBusy || isBusy}
          data-testid="nextButton"
        >
          {vatBusy || isBusy ? <CircularProgress size={25} /> : "Next"}
        </Button>
      </React.Fragment>
    )
  }

  

  return (
    <div className="auth-container overlay createProfile">
      <div className="auth-body">
        <Link to="/"><img src="/img/lgsm-logo.svg" className="auth-logo" /></Link>
        <div className="content-holder" data-testid="content" >
          {(loadingDetails || stripeLoader) && <LinearProgress className="card-loader" />}
          {loadingDetails && <div className="loading-mask"></div>}
          {!isOnboarded && <div className="profile-step-header">
            <Box position="relative" display="inline-flex">
              <div style={{height: 60, overflow: 'hidden'}}>
                <CircularProgress variant="determinate" className={classes.bottom} size={60} thickness={5} value={100} />
                <CircularProgress variant="determinate" className={classes.top} size={60} thickness={5} value={currentStep*20} />
              </div>
              <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center" >
                <span style={{fontWeight: 500, color: "rgb(0 0 0 / 50%)"}}>{currentStep}/5</span>
              </Box>
            </Box>
            <ProgressTitle step={currentStep} />
          </div>}
          <div className="profile-step-inner">
          
            {renderCurrentStep()}
          </div>
          {isError && <Alert severity="error" style={{borderRadius: 0, paddingLeft: 30, paddingRight: 30}}>{errorMessage}</Alert>}
          {!isOnboarded && <div className="profile-step-footer">
            {renderFooter()}
          </div>}
        </div>
      </div>
    </div>
  );
}

export default SupplierApplication;
