import React, { useEffect, useState } from "react";
import { FormControl, Grid, MenuItem, Paper, Select } from "@material-ui/core";
import { DirectionsBoat, DirectionsCar, DirectionsRailway, Flight, Landscape } from "@material-ui/icons";
import { FCL, LCL } from "../../include/custom/CustomIcons";
import PortsAutocomplete from "../PortsAutocomplete";
import { KeyboardDatePicker } from "@material-ui/pickers";

export default function TransportFields(props) {
	const { request, offer, setOffer } = props;
	// const [offer, setInnerOffer] = useState(mainOffer);
	// useEffect(() => {
	// 	setInnerOffer(mainOffer);
	// }, [mainOffer])

	const handleDateChange = (value, name) => {
		try {
			value = value.toISOString();
		}
		catch (e) {}
		setOffer({...offer, [name]: value});
		// this.setState({offer: {...offer, [name]: value}}, ()=> {
		// 	this.validate();
		// });
	}
	
	const handleSelectChange = (event) => {
		const offerItems = [...offer.offerItems];
		offerItems[0][event.target.name] = event.target.value;
		// this.setState({offer: { ...offer, offerItems }}, ()=> {
		// 	this.validate();
		// });
		setOffer({...offer, offerItems})
	}

	const handlePortChange = (value, name) => {
		const offerItems = [...offer.offerItems];
		offerItems[0][name].port = {...value};
		// this.setState({offer: { ...offer, offerItems }}, ()=> {
		//   this.validate();
		// });
		setOffer({...offer, offerItems})
	}
	

	return (
		<Paper className={"white-card "} >
			<p className="card-header">Transport and Route Information</p>
			<div className="card-body">
			<Grid container spacing={3}  >
				<Grid item sm={6}>
					<p className="card-body-label mt-0">Transport Mode</p>
					<FormControl variant="outlined" fullWidth size="small" className="iconSelect">
					<Select
						onChange={handleSelectChange}
						name="freightType"
						value={offer.offerItems[0].freightType}
					>
						<MenuItem value={1}><DirectionsBoat className="iconSelect-mui"/>Ocean Freight</MenuItem>
						<MenuItem value={2}><DirectionsRailway className="iconSelect-mui"/>Land Freight</MenuItem>
						<MenuItem value={3} disabled><Flight className="iconSelect-mui"/>Air Freight</MenuItem>
					</Select>
					</FormControl>
				</Grid>
				<Grid item sm={6}>
					<p className="card-body-label mt-0">Shipment Type</p>
					<FormControl variant="outlined" fullWidth size="small" className="iconSelect" disabled>
					<Select
						value={request.shipmentType}
					>
						<MenuItem value={3}><FCL /> Full Container Load</MenuItem>
						<MenuItem value={2}><LCL /> Less Container Load</MenuItem>
						<MenuItem value={1}><DirectionsCar className="iconSelect-mui"/>RoRo</MenuItem>
						<MenuItem value={4}><Landscape className="iconSelect-mui"/> Bulk/Break Bulk</MenuItem>
					</Select>
					</FormControl>
				</Grid>
			</Grid>
			
			
			<Grid container spacing={3} >
				<Grid item sm={6}>
					<p className="card-body-label">Pickup</p>
						<KeyboardDatePicker
							autoOk
							variant="inline"
							size="small"
							inputVariant="outlined"
							fullWidth
							label="Pickup Date"
							value={offer.pickupDate}
							format="MM/dd/yyyy"
							InputAdornmentProps={{ position: "end" }}
							onChange={(value, name) => handleDateChange(value, "pickupDate")}
							//error={offer.pickupDate == null ? true : false}
							disablePast
							//maxDate={offer.deliveryDate}
							disabled={!(!request.routeOrigin.isPort && offer.offerItems[0].freightType === 1)}
						/>
						<React.Fragment>
							<p className="card-body-label">Port of Loading</p>
							<PortsAutocomplete 
								selection={(data, name) => handlePortChange(data ? data : null, 'routeOrigin')} 
								label="Port of Loading" 
								type="1" 
								value={offer.offerItems[0].routeOrigin?.port}
								disabled={!(!request.routeOrigin.isPort && offer.offerItems[0].freightType === 1)}
							/><br/>
							
						</React.Fragment>
					<KeyboardDatePicker
						autoOk
						variant="inline"
						size="small"
						inputVariant="outlined"
						fullWidth
						label={offer.offerItems[0].freightType === 1 ? "Voyage Origin ETA" : "Pickup Date"}
						value={offer.loadingDate}
						format="MM/dd/yyyy"
						InputAdornmentProps={{ position: "end" }}
						onChange={(value, name) => handleDateChange(value, "loadingDate")}
						//error={offer.pickupDate == null ? true : false}
						disablePast
						maxDate={(!request.routeDestination.isPort && offer.offerItems[0].freightType === 1) ? offer.dischargingDate || offer.deliveryDate : undefined}
						minDate={(!request.routeDestination.isPort && offer.offerItems[0].freightType === 1) ? offer.pickupDate : undefined}
					/>
				</Grid>
				<Grid item sm={6}>
				<p className="card-body-label">Delivery</p> 
					<KeyboardDatePicker
						autoOk
						variant="inline"
						size="small"
						inputVariant="outlined"
						fullWidth
						label="Delivery Date"
						value={offer.deliveryDate}
						format="MM/dd/yyyy"
						InputAdornmentProps={{ position: "end" }}
						onChange={(value, name) => handleDateChange(value, "deliveryDate")}
						//error={offer.pickupDate == null ? true : false}
						disablePast
						minDate={offer.pickupDate}
						disabled={!(!request.routeDestination.isPort && offer.offerItems[0].freightType === 1)}
					/>
				
					<React.Fragment>
						<p className="card-body-label">Port of Discharging</p>
						<PortsAutocomplete 
							selection={(data, name) => handlePortChange(data ? data : null, 'routeDestination')} 
							label="Port of Discharging" 
							type="1" 
							value={offer.offerItems[0].routeDestination?.port}
							disabled={!(!request.routeDestination.isPort && offer.offerItems[0].freightType === 1)}
						/><br/>
						
					</React.Fragment>
					<KeyboardDatePicker
						autoOk
						variant="inline"
						size="small"
						inputVariant="outlined"
						fullWidth
						label={offer.offerItems[0].freightType === 1 ? "Voyage Destination ETA" : "Delivery Date"}
						value={offer.dischargingDate}
						format="MM/dd/yyyy"
						InputAdornmentProps={{ position: "end" }}
						onChange={(value, name) => handleDateChange(value, "dischargingDate")}
						//error={offer.deliveryDate == null && this.state.validationStep == 0 ? true : false}
						disablePast
						minDate={offer.loadingDate || offer.pickupDate}
						maxDate={(!request.routeDestination.isPort && offer.offerItems[0].freightType === 1) ? offer.deliveryDate : undefined}
					/>
				</Grid>
			</Grid>
			</div>
		</Paper>
	)
}