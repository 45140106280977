import React from 'react';
import { List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, CircularProgress, IconButton, Chip, Button } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import moment from 'moment';
import PaymentMethodIcon from './PaymentMethodIcon';
import MethodActionsMenu from './MethodActionsMenu';
import { useUtils } from '../../../context/UtilityContext';

export default function PaymentMethodsList(props) {
	const { verifyBank } = useUtils();
	const cards = {
		visa: {name: 'Visa', icon: ''},
		amex: {name: 'American Express', icon: ''},
		mastercard: {name: 'Master Card', icon: ''},
		jcb: {name: 'JCB', icon: ''},
		diners: {name: 'Diners', icon: ''},
		discover: {name: 'Discover', icon: ''},
		unionpay: {name: 'UnionPay', icon: ''}
	}
	const monthName = (mon) => {
		return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon - 1];
	}

	const verifyCallback = () => {
		props.onDelete();
	}

	return (
		<List style={{padding: 0}} className="payment-methods-list">

			{props.methods && props.methods.map((method, index) => (
				<ListItem key={index}   style={{paddingLeft: 30, paddingRight: 18, borderBottom: '1px solid #f1f1f1'}}
					button={props.onSelect ? true : false}
					onClick={() => props.onSelect ? props.onSelect(method) : null}
				>
					<ListItemAvatar  >
						<PaymentMethodIcon type={method.type == 'card' ? method.cardType : method.type} />
					</ListItemAvatar>
					<ListItemText 
						primary={`${method.type == 'card' ? cards[method.cardType].name : method.typeName} •••• ${method.last4}`} 
						secondary={method.type == 'card' ? `Expires ${ monthName(method.expiryMonth)} ${method.expiryYear}` :
							method.type == 'sepa_debit' ? 'EUR' : 'USD'
						} 
					/>
					
					<ListItemSecondaryAction>
						{method.isDefault && <Chip size="small" label="Default" className="primary-chip-label" />}
						{(method.type == 'bank_account' && !method.isVerified) && <Button size="small" variant="contained" className="white-btn" onClick={(id, callback) => verifyBank(method.id, callback = () => props.onDelete())} >Verify</Button>}
						{!props.readOnly && <MethodActionsMenu id={method.id} isDefault={method.isDefault} onDelete={props.onDelete} />}
 
					</ListItemSecondaryAction>
				</ListItem>
			))}
			
		</List>
	)
}