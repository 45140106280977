// IMPORT SYSTEM MODULES
import React, { useState, useEffect } from "react";
import {useHistory} from "react-router-dom";
import { useSnackbar } from "notistack";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useStore } from "../context/StoreContext";
import { 
  RiMoneyDollarCircleFill,
	RiChat3Fill,
	RiCheckboxCircleFill,
  RiShieldCheckFill,
  RiShieldStarFill,
  RiNavigationFill,
  RiUser3Fill,
  RiTodoFill,
  RiNotification4Fill,
  RiFileUploadFill
} from "react-icons/ri";
import APIKit from "../include/APIKit";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { replaceQuotes } from "../include/helpers";


export default function NotificationContainer({children}) {
  const { connection, getUserDetails } = useStore();
  const history = useHistory();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const updateNotification = (id) => {
    APIKit.put("/notifications/seen", {ids: [id]}).then(result => getUserDetails());
  }

  const showNotification = (id, title, content, target, icon, customClass, checkPath) => {
    if(checkPath && target == history.location.pathname) {
      return;
    }
    enqueueSnackbar("", {
      autoHideDuration: 6000,
      variant: "info",
      //persist: true,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      className: "notifications-container",
      content: key => (
        <Alert
          className={`app-notification compact ${customClass}`}
          icon={icon}
          //onClose={() => closeSnackbar(key)}
          onClick={() => {
            closeSnackbar(key)
            updateNotification(id);
            history.push(target);
          }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={(e) => {e.stopPropagation(); closeSnackbar(key); }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          <div className="notitificationTitle">{title}</div>
          <div className="noti-description" dangerouslySetInnerHTML={{ __html: replaceQuotes(content) || "" }} />
        </Alert>
      ),
    });
  };

  useEffect(() => {
    if (connection) {
      connection.on("Notification", not => {
        // if(not.type !== 10) {
        //   setUserData({...userData, unreadNotificationsCount: userData.unreadNotificationsCount + 1})
        // }
        console.log("NOTIFICATION RECEIVED: ", not);
        
        switch(not.type) {
          case 1: showNotification(not.id, "New Offer Received", not.description, `/request/${not.metaData?.RequestId}`, <RiMoneyDollarCircleFill />); break;
          case 8: showNotification(not.id, "Offer Accepted", not.description, `/contract/${not.objectId}`, <RiCheckboxCircleFill />); break;
          case 10: showNotification(not.id, "New Message Received", not.description, `/messages/${not.objectId}`, <RiChat3Fill />, "", true); break;
          case 11: showNotification(not.id, "Shipment Details Updated", not.description, `/contract/${not.objectId}/shipment`, <RiTodoFill />); break;
          case 12: showNotification(not.id, not?.title, not?.description, `/contract/${not?.objectId}`, <RiNotification4Fill />); break;
          case 14: showNotification(not.id, "Shipment Tracking Updated", not.description, `/contract/${not.objectId}`, <RiNavigationFill />); break;
          case 16: showNotification(not.id, not.title, not.description, `/contract/${not.objectId}/documents`, <RiFileUploadFill />); break;
          case 17: showNotification(not.id, "Shipment Delivered 🎉", not.description, `/contract/${not.objectId}`, <RiCheckboxCircleFill />); break;
          case 18: showNotification(not.id, "Contract Completed", not.description, `/contract/${not.objectId}`, <RiShieldCheckFill />, "noti-green"); break;
          case 19: showNotification(not.id, "You Received A Review", not.description, `/contract/${not.objectId}`, <RiShieldStarFill />); break;
          case 20: showNotification(not.id, not.title, not.description, `/contract/${not.objectId}`, <RiUser3Fill />); break;
          case 21: showNotification(not.id, not.title, not.description, `/contract/${not.objectId}`, <RiNotification4Fill />, "noti-orange"); break;
          case 22: showNotification(not.id, not.title, not.description, `/contract/${not.objectId}`, <RiNotification4Fill />, "noti-orange"); break;
        }
      });
    }
  }, [connection]);


  return children;
}
