import React from "react";
import { Box, Grid, Paper } from "@material-ui/core";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import { FiMapPin } from "react-icons/fi";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import { HiOutlineCurrencyDollar, HiOutlineCurrencyEuro } from "react-icons/hi";

export default function Badges() {
  const style = {
    orange: {
      color: "#ffa312",
    },
    green: {
      color: "#00b437",
    },
    blue: {
      color: "#1c4ef5",
    },
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      style={{ width: "100%" }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={style.blue}
      >
        <FiMapPin /> &nbsp;
        <div style={{ fontSize: "14px" }}>Lebanon</div>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={style.blue}
      >
        <CheckCircleRoundedIcon /> &nbsp; <div>Verified</div>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={style.blue}
      >
        <StarRoundedIcon /> &nbsp; <div>0</div>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={style.blue}
      >
        <HiOutlineCurrencyDollar /> &nbsp; <div>1200</div>
      </Box>
    </Box>
  );
}
