import React, { useEffect } from "react";

import { Button, CircularProgress } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

import APIKit from "../../include/APIKit";
import Card from "../Card";
import AlertComponent from "./generalSettings/Alert";
import PersonalInfo from "./profileCompanyInfo/PersonalInfo";
import CompanyInfo from "./profileCompanyInfo/CompanyInfo";

export default function ProfileCompanyInfo() {
  const [alert, setAlert] = React.useState({
    open: false,
    severity: "",
    message: "",
  });

  const [loading, setLoading] = React.useState(false);

  const [profileUrl, setProfileUrl] = React.useState(null);
  const [companyLogo, setCompanyLogo] = React.useState(null);

  const [personalInfo, setPersonalInfo] = React.useState({
    fullName: "",
    profilePictureUrl: "",
    email: "",
    phone: "",
    password: "",
    confirmPass: "",
  });

  const [companyInfo, setCompanyInfo] = React.useState({
    companyName: "",
    logoUrl: "",
    phone: "",
    address: "",
    vatid: "",
    timezone: "",
    description: "",
  });

  const save = () => {
    setLoading(true);
    APIKit.put("/update")
      .then(res => {
        setLoading(false);
        setAlert({
          ...alert,
          open: true,
          severity: "success",
          message: "Profile updated successfully",
        });

        setTimeout(() => {
          setAlert({
            ...alert,
            open: false,
          });
        }, 5000);
      })
      .catch(err => {
        setLoading(false);

        setAlert({
          ...alert,
          open: true,
          severity: "error",
          message: err.message,
        });

        setTimeout(() => {
          setAlert({
            ...alert,
            open: false,
          });
        }, 5000);
      });
  };

  const getUserInfo = () => {
    APIKit.get("authentication/details")
      .then(res => {
        setLoading(true);
        if (res.status === 200) {
          console.log(res.data.record);
          setPersonalInfo({
            ...personalInfo,
            fullName: res.data.record.fullName,
            profilePictureUrl: res.data.record.profilePictureUrl,
          });
          setCompanyInfo({
            ...companyInfo,
            companyName: res.data.record.companyName,
            logoUrl: res.data.record.logoUrl,
          });
          setLoading(false);
        }
      })
      .catch(e => {
        setLoading(true);
      });
  };

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tab-content">
      <AlertComponent
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
      />
      <br />
      <Card
        variant="white"
        bodyPadding={false}
        headerTitle="Profile and Company information"
        headerSubtitle="Here you can update your personal/company information"
        headerAction={
          <Button
            color="primary"
            disableElevation
            startIcon={
              loading ? (
                <CircularProgress style={{ width: 20, height: 20 }} />
              ) : (
                <SaveIcon style={{ fontSize: 20 }} />
              )
            }
            onClick={() => save()}
          >
            {loading ? "Updating..." : "Update"}
          </Button>
        }
      >
        <PersonalInfo
          personalInfo={personalInfo}
          setPersonalInfo={setPersonalInfo}
          loading={loading}
          imageUrl={profileUrl}
          setImageUrl={setProfileUrl}
        />
        <CompanyInfo
          companyInfo={companyInfo}
          setCompanyInfo={setCompanyInfo}
          loading={loading}
          imageUrl={companyLogo}
          setImageUrl={setCompanyLogo}
        />
      </Card>
    </div>
  );
}
