import React from "react";
import AdjustIcon from "@material-ui/icons/Adjust";
import { Link } from "react-router-dom";

export default function Figure(props) {
  const { value } = props;
  const styles = {
    card: {
      background: "#fff",
      borderRadius: 4,
      border: "1px solid #dedede",
      position: "relative",
      padding: "10px",
      width: "min-content",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "auto",
    },
    headerTitle: {
      fontSize: 14,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "500",
    },
    icon: {
      fontSize: 20,
      color: "#FFCC00",
      background: "rgba(255, 204, 0, 0.1)",
      padding: "10px",
      height: "30px",
      width: "30px",
      borderRadius: "50%",
      marginRight: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  return (
    <div className="card" style={styles.card}>
      <div style={styles.icon}>
        <AdjustIcon />
      </div>

      <Link to="/actions-center" style={{ textDecoration: "none" }}>
        <div className="card-header" style={styles.headerTitle}>
          <span> 7 </span> &nbsp; <span> Actions </span>
        </div>
      </Link>
    </div>
  );
}
