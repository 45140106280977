import React, { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";

import APIKit from "../../include/APIKit";

import Page from "../../containers/Page";
import Figures from "../../components/Dashboard/Figures";
import RequestCard from "../../components/Dashboard/RequestCard";
import InviteCollab from "../../components/Dashboard/InviteCollab";
import ShipmentFigures from "../../components/Dashboard/ShipmentFigures";
import ActiveContracts from "../../components/Dashboard/ActiveContracts";
import Actions from "../../components/Dashboard/Actions";
import { GestureTwoTone } from "@material-ui/icons";

export default function Dashboard() {
  const [contracts, setContracts] = useState([]);
  const [requests, setRequests] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [userName, setUserName] = useState("");

  const [requestsNumber, setRequestsNumber] = useState(0);
  const [pageSize, setPageSize] = useState(42);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const [pendingRequests, setPendingRequests] = useState([]);
  const [ActiveRequests, setActiveRequests] = useState([]);

  const getUserDetails = () => {
    APIKit.get("authentication/details")
      .then(res => {
        console.log(res.data.record.firstName);
        setUserName(res.data.record.firstName);
      })
      .catch(e => console.log(e));
  };

  const getRequests = query => {
    let body = {
      pageSize: pageSize,
      pageNumber: 1,
    };
    APIKit.post("requests/search", body)
      .then(result => {
        if (result.status === 200) {
          setRequestsNumber(result.data.record.totalItems);
          setPendingRequests(
            result.data.record.items.filter(request => {
              return request.requestStatus === 1;
            })
          );
          setActiveRequests(
            result.data.record.items.filter(request => {
              return request.requestStatus === 2;
            })
          );
          console.log("activeTab in parent is " + activeTab);
        }
      })
      .catch(err => {});
  };

  const getContracts = query => {
    return new Promise((resolve, reject) => {
      let body = {
        pageSize: 3,
        pageNumber: query.page + 1,
        status: 1,
      };

      APIKit.post("contracts", body)
        .then(result => {
          if (result.status === 200) {
            setTotalItems(result.data.record.contracts.totalItems);

            resolve({
              data: result.data.record.contracts.items,
              page: query.page,
              totalCount: result.data.record.contracts.totalItems,
            });
          }
        })
        .catch(e => {});
    });
  };

  useEffect(() => {
    // getContracts();
    getRequests();
    getUserDetails();
  }, []);

  useEffect(() => {
    activeTab === 1
      ? setRequests(() => pendingRequests)
      : setRequests(() => ActiveRequests);
  }, [ActiveRequests, activeTab, pendingRequests]);

  return (
    <Page navigation={true} title="Dashboard">
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6} md={6}>
          <h1>Welcome, {userName}</h1>
        </Grid>
        <Grid item xs={6} md={6}>
          <Actions value={4} />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item container spacing={3}>
          <Grid item xs={6} md={6} style={{ paddingBottom: 0 }}>
            <Figures
              requestNumber={requestsNumber}
              requests={requests}
              contracts={contracts}
              figureTitle1="Total Amount"
              figureTitle2="Requests"
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <ShipmentFigures />
          </Grid>
        </Grid>

        <Grid item container spacing={3}>
          <Grid item xs={5} md={5}>
            <ActiveContracts
              contracts={query => getContracts(query)}
              totalItems={totalItems}
            />
          </Grid>
          <Grid item xs={7} md={7}>
            <RequestCard
              data={requests}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
}
