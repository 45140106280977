import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useStore } from "../context/StoreContext";
import { useHistory, useParams } from "react-router-dom";

import {
  Button,
  CircularProgress,
  IconButton,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import queryString from "query-string";

import {
  Visibility,
  VisibilityOff,
  Error as ErrorIcon,
} from "@material-ui/icons";

import APIKit, { setClientToken } from "../include/APIKit";

export default function ResetPassword(props) {
  document.title = "Reset password";
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isBusy, setIsBusy] = useState(false);

  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [validate, setValidate] = useState({
    newPass: false,
    confirmPass: false,
  });

  const [ShowPassword, setShowPassword] = useState({
    newPass: false,
    confirmPass: false,
  });

  const [PasswordHelperText, setPasswordHelperText] = useState(null);
  const [ConfirmPassHelperText, setConfirmPassHelperText] = useState(null);

  const passwordPattern =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/;

  const history = useHistory();

  const token = queryString.parse(window.location.search).token;
  const userid = queryString.parse(window.location.search).user;

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const validateConfirmPass = (newPass, confirmPass) => {
    if (!(newPass === confirmPass)) {
      setValidate({ ...validate, confirmPass: true });
      setConfirmPassHelperText("Passwords don't match");
    } else {
      setValidate({ ...validate, confirmPass: false });
      setConfirmPassHelperText(null);
    }
  };

  const validatePassword = password => {
    if (!passwordPattern.test(password)) {
      setValidate({ ...validate, newPass: true });
      setPasswordHelperText(
        "Password must be 8 characters long, contain at least one small letter, one capital letter, one digit, and one special character."
      );
    } else {
      setValidate({ ...validate, newPass: false });
      setPasswordHelperText(null);
    }
  };

  const resetPassword = e => {
    e.preventDefault();
    validatePassword(newPassword);
    validateConfirmPass(newPassword, confirmedPassword);

    setIsBusy(true);

    let body = {
      userId: userid,
      token: token,
      newPassword: newPassword,
      confirmPassword: confirmedPassword,
    };

    if (!validate.confirmPass && !validate.newPass) {
      APIKit.post("/Authentication/ResetPassword", body)
        .then(res => {
          setIsBusy(false);
          setIsError(false);

          history.push("/login");
        })
        .catch(e => {
          setIsBusy(false);
          setIsError(true);
          setErrorMessage("Something went wrong please try again");
          console.log(e);
        });
    }
  };

  React.useEffect(() => {
    console.log(validate);
  }, [validate]);

  return (
    <div className="auth-container">
      <div className="auth-body">
        <Link to="/">
          <img src="/img/lgsm-logo.svg" className="auth-logo" />
        </Link>
        <div className="content-holder">
          <div>
            <h2 className="page-h2">Choose a new password.</h2>
            <br />
            <form onSubmit={resetPassword}>
              <FormControl
                variant="outlined"
                fullWidth
                error={validate.newPass}
              >
                <InputLabel htmlFor="password">New Password</InputLabel>
                <OutlinedInput
                  id="password"
                  data-testid="passwordInput"
                  type={ShowPassword.newPass ? "text" : "password"}
                  onChange={e => {
                    setNewPassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle Password"
                        onClick={e =>
                          setShowPassword(prevState => {
                            return {
                              ...prevState,
                              newPass: !prevState.newPass,
                            };
                          })
                        }
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {ShowPassword.newPass ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
                {PasswordHelperText && (
                  <FormHelperText error id="accountId-error">
                    <span>
                      <ErrorIcon className="errorIcon" />
                      {PasswordHelperText}
                    </span>
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                variant="outlined"
                fullWidth
                error={validate.confirmPass}
              >
                <InputLabel htmlFor="password">Confirm password</InputLabel>
                <OutlinedInput
                  id="password"
                  data-testid="passwordInput"
                  type={ShowPassword.confirmPass ? "text" : "password"}
                  onChange={e => {
                    setConfirmedPassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle Password"
                        onClick={e =>
                          setShowPassword(prevState => {
                            return {
                              ...prevState,
                              confirmPass: !prevState.confirmPass,
                            };
                          })
                        }
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {ShowPassword.confirmPass ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
                {ConfirmPassHelperText && (
                  <FormHelperText error id="accountId-error">
                    <span>
                      <ErrorIcon className="errorIcon" />
                      {ConfirmPassHelperText}
                    </span>
                  </FormHelperText>
                )}
              </FormControl>

              {isError && <Alert severity="error">{errorMessage}</Alert>}
              <br />
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disableElevation
                fullWidth
                disabled={isBusy}
              >
                {isBusy && <CircularProgress color="inherit" size="26px" />}
                {!isBusy && "Reset Password"}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
