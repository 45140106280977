import React, { useEffect, useState } from "react";
import ConfirmDialog from "../ConfirmDialog";
import { Alert } from "@material-ui/lab";
import RatingEmojis from "./RatingEmojis";
import { Button, TextField } from "@material-ui/core";
import APIKit from "../../include/APIKit";

export default function RatingDialog({open, onClose, onConfirm, setOpen, contractId, updateRating}) {
	const [rating, setRating] = useState(0);
	const [description, setDescription] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const submitReview = () => {
		setError(false);
		if(rating == 0) {
			setError("Rating is required");
			return;
		}
		
		if(description.length < 80) {
			setError("Feedback is too short");
			return;
		}
		
		setLoading(true);
		let body = {
			contractId,
			description,
			rate: rating
		}
		APIKit.post("contracts/review", body).then(result => {
			if (result.status === 200) {
			  if (result.data.isSuccess) {
				updateRating({rate: rating, description})
			  }
			} 
			setLoading(false);
		}).catch(e => {
			setError("Something went wrong!")
			setLoading(false);
		});
	}

	return (
		<ConfirmDialog
			open={open}
			title={"Rate your experience"}
			confirmText="Submit"
			onClose={setOpen}
			noElevation
			//onConfirm={onConfirm}
			hideFooter={true}
			//disabledAction={loading}
		>
			<RatingEmojis rating={rating} setRating={setRating} />
				<div className="textfield-with-count">
					<TextField
						variant="outlined"
						name="description"
						className="rating-field"
						placeholder="Publicly rate your experience with this supplier"
						fullWidth={true}
						multiline={true}
						rows={2}
						inputProps={{ maxLength: 2500 }}
						onChange={e => setDescription(e.target.value)}
						value={description}
					/>
					<span className="charcount" style={{color: description.length > 150 ? '#00b437' : '#a5a5a5'}}>{description.length} characters (minimum 150 characters)</span>
				</div>
				{!!error && <Alert severity="error" style={{marginTop: 25}}>{error}</Alert>}
				<Button
					variant="contained"
					color="primary"
					// className="btn-primary"
					style={{ marginTop: "15px" }}
					disableElevation
					fullWidth
					disabled={loading}
					onClick={submitReview}
				>
					Submit	
				</Button>
			
		</ConfirmDialog>
	)
}