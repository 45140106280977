import React from "react";
import {
  Paper,
  Chip,
  Grid,
  Step,
  Stepper,
  StepLabel,
  StepConnector,
  StepButton,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import {
  ExpandMore as ExpandMoreIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Cancel as CancelIcon,
  Room as PinIcon,
  ThumbDownOutlined as ThumbDownOutlinedIcon,
  ThumbUpOutlined as ThumbUpOutlinedIcon,
  ThumbUp as ThumbUpIcon,
  Settings as SettingsIcon,
  GroupAdd as GroupAddIcon,
  VideoLabel as VideoLabelIcon,
  Home as HomeIcon,
  LocalShipping as LocalShippingIcon,
  CallMade as CallMadeIcon,
  CallReceived as CallReceivedIcon,
  Warning as WarningIcon,
  Room as RoomIcon,
  Search as SearchIcon,
  Add as AddIcon,
  GetApp as DownloadIcon,
  Image as ImageIcon,
  InsertDriveFile as FileIcon,
  Backup as BackupIcon,
  Check,
  VerifiedUser,
  Home,
  CallReceived,
  CallMade,
  LocalShipping,
  Room,
  Error,
} from "@material-ui/icons";
import clsx from "clsx";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useStore } from "../../context/StoreContext";

export default function OverviewStepper(props) {
  const {accountType} = useStore();
  const { steps, trackingDetails, confirmMilestone, contractStatus } = props;
  const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: "#DBDEE4",
      zIndex: 1,
      color: "#7F878F",
      width: 45,
      height: 45,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    active: {
      background: "#1c4ef5",
      color: "#1c4ef5",
      // boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
      background: "#2bb744",
      color: "#fff",
    },
  });
  function ColorlibStepIcon(props, icon) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
    console.log(props);
    const icons = {
      1: <SettingsIcon />,
      2: <GroupAddIcon />,
      3: <VideoLabelIcon />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
          ["active-ms"]: active,
        })}
      >
        {icon}
      </div>
    );
  }

  const ColorlibConnector = withStyles({
    active: {
      "& $line": {
        background: "#ccd6fe",
      },
    },
    completed: {
      "& $line": {
        background: "#2bb744",
      },
    },
    line: {
      flexDirection: "column",
      height: 35,
      width: 4,
      border: 0,
      backgroundColor: "#DBDEE4",
      borderRadius: 0,
    },
    root: {
      marginLeft: 21,
      paddingBottom: 0,
    },
  })(StepConnector);


  const stepName = (type, address, done) => {
    // let location = address.isPort ? `${address.port?.city} ` : address.city
    let location = address.description;
    switch(type) {
      case "MainCarriageOriginDropOff":
        return done ? `Dropped off at ${location}` : `Drop-off ${location}`;
      case "Pickup":
        return done ? "Picked up" : "Pickup"
      case "MainCarriageOriginIn":
        return done ? `Arrived at ${location}` : `Arrival at ${location}`
      case "MainCarriageOriginOut":
        return done ? `Departed from ${location}` : `Departure from ${location}`
      case "MainCarriageDestinationIn":
        return  done ? `Arrived at ${location}` : `Arrival at ${location}`
      case "MainCarriageDestinationOut":
        return  done ? `Departed from ${location}` : `Departure from ${location}`
      case "Delivery":
        return done ? "Delivered" : "Delivery"
    }
  }

  const getIcon = (type) => {
    switch(type) {
      case "MainCarriageOriginDropOff":
        return <Room />
      case "Pickup":
        return <Home />
      case "MainCarriageOriginIn":
        return <CallReceived />
      case "MainCarriageOriginOut":
        return <CallMade />
      case "MainCarriageDestinationIn":
        return <CallReceivedIcon />
      case "MainCarriageDestinationOut":
        return <CallMadeIcon />
      case "Delivery":
        return <LocalShipping />
    }
  }


  return (
    <div>
      <Stepper
        activeStep={trackingDetails?.activeIndex}
        connector={<ColorlibConnector />}
        orientation="vertical"
        style={{background: 'transparent'}}
      >
        {trackingDetails &&
          trackingDetails.milestones.map((ms, index) => (
            <Step key={index} style={{position: 'relative'}}>
              <StepLabel
                StepIconComponent={(props, icon) =>
                  ColorlibStepIcon(props, getIcon(ms.milestoneTypeDescription))
                }
              >
                <div className="tracking-step">
                  <span className="step-title">{stepName(ms.milestoneTypeDescription, ms.address, ms.confirmationStatus)}</span>
                  <span className="step-content">
                    {trackingDetails.activeIndex <= index ? "Estimated on  " : "On "}
                    {moment(ms.confirmationStatus ? ms.completionDate : ms.estimatedDate).format("DD MMM, YYYY HH:mm")}
                  </span>
                  
                </div>
               
              </StepLabel>

              {(accountType == 1) && (
                <>
                  {(trackingDetails.activeIndex == index && ms.milestoneTypeDescription !== "MainCarriageOriginDropOff") && 
                    <Button onClick={() => confirmMilestone({...ms, nextMs: trackingDetails.milestones[index+1] })} className="ms-confirm-btn 1" variant="contained" color="primary" size="small" startIcon={<Check />} >
                      Confirm
                    </Button>
                  }
                  {((index == 0 || index == trackingDetails.milestones.length-1) ) &&
                    <>
                      {ms.confirmationStatus == 5 &&
                        <Tooltip title="Confirmed by client" className="ms-confirm-btn confirmed">
                          <VerifiedUser />
                        </Tooltip>
                      }
                      {ms.confirmationStatus == 6 &&  <Tooltip title="Rejected by client" className="ms-confirm-btn rejected"  ><Error /></Tooltip>}

                    </>
                    
                  }
                </>
              )}

              {}

              {/* {accountType == 1 && (
                trackingDetails.activeIndex > index && ms.confirmationStatus == 1 ? (
                  <IconButton onClick={() => confirmMilestone(ms)} className="ms-confirm-btn 2" ><Check /></IconButton>
                ) : (
                  ((index == 0 || index == trackingDetails.milestones.length-1) && ms.confirmationStatus == 5) &&
                  <Tooltip title="Confirmed by client" className="ms-confirm-btn confirmed"><VerifiedUser /></Tooltip>
                )
              )} */}

              {((accountType == 2) && (index == 0 || index == trackingDetails.milestones.length-1) ) && (
                <>
                  {(
                    (ms.confirmationStatus == 1 && contractStatus != 4 && contractStatus != 5) ||
                    (ms.milestoneTypeDescription == "MainCarriageOriginDropOff" && ms.confirmationStatus == 0)
                  ) && 
                  <Button onClick={() => confirmMilestone(ms)} variant="contained" color="primary" size="small" startIcon={<Check />}  className="ms-confirm-btn client-btn" >Confirm</Button>}
                  {ms.confirmationStatus == 5 &&  <Tooltip title="Confirmed by you" className="ms-confirm-btn confirmed"  ><VerifiedUser /></Tooltip>}
                  {ms.confirmationStatus == 6 &&  <Tooltip title="Rejected by you" className="ms-confirm-btn rejected"  ><Error /></Tooltip>}
                </>) 
              }


              
              
              {/* {(trackingDetails.activeIndex > index && accountType == 2 && ms.confirmationStatus == 1 && (index == 0 || index == trackingDetails.milestones.length-1) ) ? 
                <IconButton onClick={() => confirmMilestone(ms)} className="ms-confirm-btn " ><Check /></IconButton>
                :
                ms.confirmationStatus == 5 && <Tooltip title={accountType == 2 ? "Confirmed by you" : "Confirmed by client"} className="ms-confirm-btn confirmed"  ><VerifiedUser /></Tooltip>
              } */}
            </Step>
          ))}
      </Stepper>
    </div>
  );
}
