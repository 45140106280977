import React from "react";
import Card from "../Card";
import Requests from "./RequestsTabs";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

export default function RequestCard(props) {
  const { data, activeTab, setActiveTab } = props;
  return (
    <div>
      <Card
        variant="white"
        bodyPadding={false}
        headerTitle="Requests"
        headerSubtitle=""
        elevated={false}
        // headerAction={
        //   <FormControl>
        //     <InputLabel htmlFor="grouped-select">Grouping</InputLabel>
        //     <Select defaultValue="" id="grouped-select">
        //       <MenuItem value="">
        //         <em>None</em>
        //       </MenuItem>
        //       <MenuItem value={1}>Option 1</MenuItem>
        //       <MenuItem value={2}>Option 2</MenuItem>
        //       <MenuItem value={3}>Option 3</MenuItem>
        //       <MenuItem value={4}>Option 4</MenuItem>
        //     </Select>
        //   </FormControl>

        //   // <Link to="/requests" style={{ textDecoration: "none" }}>
        //   //   <Button color="primary" disableElevation>
        //   //     ALL requests
        //   //   </Button>
        //   // </Link>
        // }
      >
        <Requests
          data={data}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </Card>
    </div>
  );
}
