import React from "react";
import { TextField, Grid, Box } from "@material-ui/core";

const style = {
  input: {
    width: "60%",
  },
  box: {
    padding: "20px 30px",
  },
  div: {
    marginBottom: "15px",
  },
  title: {
    fontSize: 16,
    fontWeight: "500",
    color: "rgb(0 0 0 / 50%)",
    paddingBottom: "10px",
  },
};
export default function UserEditForm(props) {
  const { personalInfo, setPersonalInfo } = props;

  return (
    <Box style={style.box}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div style={style.div}>
            <div style={style.title}>First Name</div>

            <TextField
              id="firstname"
              label="First Name"
              variant="outlined"
              style={style.input}
              type="text"
              onChange={e =>
                setPersonalInfo({ ...personalInfo, firstname: e.target.value })
              }
              defaultValue={personalInfo.firstname}
              value={personalInfo.firstname}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={style.div}>
            <div style={style.title}>Last Name</div>
            <TextField
              id="lastname"
              label="Last Name"
              variant="outlined"
              style={style.input}
              type="text"
              onChange={e =>
                setPersonalInfo({ ...personalInfo, lastname: e.target.value })
              }
              defaultValue={personalInfo.lastname}
              value={personalInfo.lastname}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <div style={style.title}>Email</div>
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              style={style.input}
              type="email"
              onChange={e =>
                setPersonalInfo({ ...personalInfo, email: e.target.value })
              }
              defaultValue={personalInfo.email}
            />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
