import React, { Component } from 'react'

import { withSnackbar } from "notistack";
import GoogleMaps from "../../components/GoogleMapsAutocomplete";
import RequestDrawer from "../../components/RequestDrawer";
import ToggleButtonsFilter from "../../components/contracts/ToggleButtonsFilter";

import moment from "moment";

import { Paper, Chip, Grid, Checkbox, Box, Tooltip, CircularProgress } from "@material-ui/core";

import {
  DirectionsBoat,
  DirectionsRailway,
  DirectionsCar,
  Landscape,
  WatchLater as WatchLaterIcon,
  LocationOn as LocationOnIcon,
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon,
  ReportOffOutlined,
} from "@material-ui/icons";

import MaterialTable from "material-table";

import APIKit from "../../include/APIKit";
import Page from "../../containers/Page.js";
import Favorite from "../../components/supplier-requests/Favorite";
import CurrencyFilter from "../../components/supplier-requests/CurrencyFilter";
import Report from "../../components/supplier-requests/Report";
import Badges from "../../components/supplier-requests/Badges";
import FilterById from "../../components/supplier-requests/FilterById";
import NoResults from '../../components/NoResults';

let timeout = 0;
let selection = [];

class SupplierRequests extends Component {
  tableRef = React.createRef();
  refDrawer = React.createRef();

  state = {
    loading: true,
    activeTab: 1,
    pageSize: 10,
    filters: {
      shipmentTypes: [],
      freightTypes: [],
      onlyFavorites: false,
      currency: null,
    },
    filterPaneOpen: false,
    countries: null,
    name: "",
    typing: false,
    typingTimeout: 0,
    menuOpen: false,
    anchorEl: null,
    selection: [],
    favoritesCount: 0,
    drawerOpen: false,
  };

  UNSAFE_componentWillMount = () => {
    document.title = "Requests";
  };
  componentDidMount() {
    this.getCountries();
  }

  handleTabChange = (event, activeTab) => {
    this.setState({ activeTab }, () => {
      this.tableRef.current.onChangePage(null, 0);
    });
    console.log(this.state.activeTab);
  };

  handleRowClick = (event, id) => {
    this.setState({ drawerOpen: true });

    this.refDrawer.current.toggleDrawer(id);
    //this.props.history.push('/shipment/'+id);
  };
  handleSelection = data => {
    selection = data;
  };

  handleToggle = (event, value, name) => {
    this.setState({ filters: { ...this.state.filters, [name]: value } }, () => {
      this.tableRef.current.onChangePage(null, 0);
    });
  };

  handleInputChange = event => {
    let name = event.target.name;
    let value = event.target.value;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      this.setState(
        { filters: { ...this.state.filters, [name]: value } },
        () => {
          this.tableRef.current.onChangePage(null, 0);
        }
      );
    }, 1000);
  };

  refreshTable = () => {
    this.tableRef.current.onChangePage(null, 0);
  };

  // handleInputChange = (event) => {
  //   this.setState({filters: {...this.state.filters, [event.target.name]: event.target.value}}, ()=> {
  //     this.tableRef.current.onChangePage(null, 0);
  //   });
  // }

  handleFilters = (obj, name) => {
    const filters = this.state.filters;
    var value;
    if (name == "originCountryCode") {
      filters.originCountry = obj != null ? obj.name : null;
      filters.originCity = null;
      if (obj !== null && typeof obj == "object") {
        for (var i = 0; i < obj.address_components.length; i += 1) {
          var addressObj = obj.address_components[i];
          for (var j = 0; j < addressObj.types.length; j += 1) {
            if (addressObj.types[j] === "country") {
              /*outputs result if it is country*/
              value = addressObj.short_name;
            } else if (addressObj.types[j] === "locality") {
              filters.originCity = addressObj.long_name;
            }
          }
        }
      }
    }
    if (name == "destinationCountryCode") {
      filters.destinationCountry = obj != null ? obj.name : null;
      filters.destinationCity = null;
      if (obj !== null && typeof obj == "object") {
        for (var i = 0; i < obj.address_components.length; i += 1) {
          var addressObj = obj.address_components[i];
          for (var j = 0; j < addressObj.types.length; j += 1) {
            if (addressObj.types[j] === "country") {
              /*outputs result if it is country*/
              value = addressObj.short_name;
            } else if (addressObj.types[j] === "locality") {
              filters.destinationCity = addressObj.long_name;
            }
          }
        }
      }
    }

    filters[name] = obj != null ? value : null;
    this.setState({ filters }, () => {
      this.tableRef.current.onChangePage(null, 0);
    });
  };
  handleDateChange = value => {
    console.log(1);
  };

  handleSearch = event => {
    event.preventDefault();
    let value = event.target['search'].value;
    // if (timeout) clearTimeout(timeout);
    // timeout = setTimeout(() => {
      this.setState(
        { filters: { ...this.state.filters, query: value } },
        () => {
          this.tableRef.current.onChangePage(null, 0);
        }
      );
    // }, 1000);
  };

  renderLabel = date => {
    console.log(date);
    if (date) {
      return date;
    } else {
      return null;
    }
  };

  search = query => {
    return new Promise((resolve, reject) => {
      let body = {
        pageSize: 50,
        pageNumber: query.page + 1,
        // sortingType: query.orderDirection,
        // sortingProperty: query.orderBy ? query.orderBy.title : null,
        ...this.state.filters,
      };
      APIKit.post("requests/supplier", body)
        .then(result => {
          if (result.status === 200) {
            const filteredData = !this.state.filters.currency
              ? result.data.record.items
              : result.data.record.items.filter(
                  item => item.currency.code === this.state.filters.currency
                );
            this.setState({
              totalRecordsCount: filteredData.length,
              lastUpdated: result.data.responseDate,
            });
            if (result.data.record.totalItems < query.pageSize) {
              this.setState({ pageSize: result.data.record.totalItems });
            } else {
              this.setState({ pageSize: 50 });
            }
            this.setState({loading: false})

            resolve({
              data: filteredData,
              page: query.page,
              totalCount: filteredData.length,
            });
          }
        })
        .catch(e => {});
    });
  };

  getFavorites = () => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          onlyFavorites: !this.state.filters.onlyFavorites,
        },
      },
      () => {
        this.tableRef.current.onChangePage(null, 0);
      }
    );
  };

  getCountries = () => {
    APIKit.get("countries")
      .then(result => {
        if (result.status === 200) {
          this.setState({ countries: result.data.records });
        }
      })
      .catch(e => {});
  };

  openMenu = (event, id) => {
    console.log(event.currentTarget);
    this.setState({ anchorEl: event.currentTarget, menuOpen: id });
  };

  closeMenu = event => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  bulkUnlist = (event, data) => {
    let ids = [];
    for (var i in data) {
      if (data[i].requestStatus == 2 || data[i].requestStatus == 3) {
        this.showAlert(
          "warning",
          "You cannot modify assigned or completed requests!"
        );
        return null;
      }
      if (data[i].requestStatus == 4) {
        this.showAlert(
          "warning",
          "You cannot unlist already cancelled requests!"
        );
        return null;
      }
      ids.push(data[i].id);
    }
    APIKit.post("requests/UpdateListing", ids)
      .then(result => {
        if (result.status === 200) {
          this.showAlert("success", "Successfully Updated!");
          this.refreshTable();
        }
      })
      .catch(e => {});
  };

  bulkDelete = (event, data) => {
    let ids = [];
    for (var i in data) {
      if (data[i].requestStatus == 2 || data[i].requestStatus == 3) {
        this.showAlert(
          "warning",
          "You cannot delete assigned or completed requests!"
        );
        return null;
      }
      ids.push(data[i].id);
    }
    APIKit.post("requests/delete", ids)
      .then(result => {
        if (result.status === 200) {
          this.refreshTable();
        }
      })
      .catch(e => {});
  };

  showAlert = (variant, message) => {
    this.props.enqueueSnackbar(message, {
      variant: variant,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  handleDelete = (value, name, type) => {
    if (type == 1) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            [name]: this.state.filters[name].filter(c => c !== value),
          },
        },
        () => {
          this.tableRef.current.onChangePage(null, 0);
        }
      );
    } else {
      const filters = this.state.filters;

      if (name == "originCountryCode") {
        filters.originCountry = null;
        filters.originCity = null;
        value = null;
      }
      if (name == "destinationCountryCode") {
        filters.destinationCountry = null;
        filters.destinationCity = null;
        value = null;
      }

      if (name === "currency") {
        filters.currency = null;
        value = null;
      }

      filters[name] = null;
      this.setState({ filters }, () => {
        this.tableRef.current.onChangePage(null, 0);
      });
    }
  };

  favoriteRequest = (event, id) => {
    event.stopPropagation();
    APIKit.post("requests/favorite/" + id, null)
      .then(result => {
        console.log(id);
      })
      .catch(e => {});
  };
  handleCurrencyFilter = e => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          currency: e.target.value,
        },
      },
      () => {
        this.tableRef.current.onChangePage(null, 0);
      }
    );
  };

  render() {
    const ShipmentStatus = [
      "",
      "Pending",
      "Assigned",
      "Completed",
      "Cancelled",
    ];
    const transportModes = ["", "Ocean Freight", "Land Freight"];

    const shipmentTypes = [
      "",
      "RoRo",
      "Less Than Container Load",
      "Full Container Load",
      "Bulk or Break Bulk",
    ];

    const shipmentTypeTitles = ["", "Unit", "Item", "Container", "Item"];

    const dateOptions = ["", "On", "Before", "After"];

    const typeIcons = [
      "",
      <DirectionsCar className="shipment-icon-big" />,
      <svg
        className="MuiSvgIcon-root shipment-icon-big customIcon"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path d="M21.5,3.5h-19c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h19c1.1,0,2-0.9,2-2v-13C23.5,4.4,22.6,3.5,21.5,3.5z M21.5,18.5h-19v-13h19V18.5zM3.5, 12.5 h17 v5 h-17z"></path>
      </svg>,
      <svg
        className="MuiSvgIcon-root shipment-icon-big customIcon"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path d="M21.5,3.5h-19c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h19c1.1,0,2-0.9,2-2v-13C23.5,4.4,22.6,3.5,21.5,3.5z M21.5,18.5h-19v-13h19V18.5zM3.5, 6.5 h17 v11 h-17z"></path>
      </svg>,
      <Landscape className="shipment-icon-big" />,
    ];

    const style = {
      chip: {
        margin: "3px",
        color: "#1c4ef5",
        border: "none",
        paddingLeft: "4px",
        paddingRight: "4px",
      },
      icon: {
        fontSize: "5px",
        color: "inherit",
      },
    };
    const shipmentColumns = [
      {
        title: "Request",
        sorting: true,
        width: "30%",
        render: rowData => (
          <div className="icon-grid">
            <Tooltip
              title={
                rowData.itemsCount > 1
                ? rowData.itemsCount +
                  " " +
                  shipmentTypeTitles[rowData.shipmentType] +
                  "s"
                : rowData.itemsCount +
                  " " +
                  shipmentTypeTitles[rowData.shipmentType]
              }
            >
            <div>
              <div className="iconComplex">
                {typeIcons[rowData.shipmentType]}
                {rowData.freightType == 1 && (
                  <DirectionsBoat className="shipment-icon-small" />
                )}
                {rowData.freightType == 2 && (
                  <DirectionsRailway className="shipment-icon-small land" />
                )}
                {/* <StarIcon className="shipment-icon-small"/> */}
              </div>
            </div>
            </Tooltip>
            <div>
              <div className="item-title item-ref">
                {rowData.reference}
              </div>
              <div className="item-date">
              {rowData.totalWeight + " kg - " + rowData.totalVolume + " cbm"}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "Origin",
        sorting: false,
        width: "16%",
        render: rowData => (
          <div className="countries-grid">
            <img src={rowData.fromAddress.country.flag} className="grid-country-flag" />
            <div>
              <div className="item-title">
                {/* <LocationOnIcon className="location-icon" />{" "} */}
                {rowData.fromAddress.description}
              </div>
              <div className="item-date">
                {dateOptions[rowData.pickupDateOption] +
                  " " +
                  moment(rowData.pickupDate).format("L")}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "Destination",
        sorting: false,
        width: "16%",
        render: rowData => (
          <div className="countries-grid">
            <img src={rowData.toAddress.country.flag} className="grid-country-flag" />
            <div>
              <div className="item-title">
                {/* <LocationOnIcon className="location-icon" />{" "} */}
                {rowData.toAddress.description}
              </div>
              <div className="item-date">
                {dateOptions[rowData.deliveryDateOption] +
                  " " +
                  moment(rowData.deliveryDate).format("L")}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "Listing Info",
        field: "",
        sorting: true,
        width: "16%",
        render: rowData => (
          <div>
            <div className="item-title item-offers-2">
              {rowData.expiryCountdown.isExpiringSoon && (
                <WatchLaterIcon className="expiring-icon" />
              )}
              {rowData.expiryCountdown.remaining}
            </div>
            {rowData.offersCount > 0 && (
              <div className="item-date">
                $<span className="offerPrice">{rowData.lowestOffer}</span> low,{" "}
                {rowData.offersCount} Offers
              </div>
            )}
            {rowData.offersCount <= 0 && (
              <div
                className={
                  rowData.expiryCountdown.isExpiringSoon
                    ? "item-date highlighted-text alert-text"
                    : "item-date highlighted-text"
                }
              >
                No Offers
              </div>
            )}

            {rowData.sentOffer && (
              <div>
                <div className="item-date highlighted-text">
                  <span className="offerPrice">Sent offer</span>
                </div>
              </div>
            )}
          </div>
        ),
      },
      {
        title: "",
        field: "",
        width: "15%",
        sorting: false,
        render: rowData => (
          <Badges
            //amount="1233"
            paymentVerified={rowData?.owner?.badges?.isPaymentMethodVerified}
            //rating="3"
            location={rowData?.owner?.badges?.country}
            currency={rowData.currency}
            currencyCode={rowData.currency.code}
            currencySymbol={rowData.currency.symbol}
          />
        ),
      },

      {
        title: "",
        width: "10%",
        cellStyle: {
          textAlign: "right",
        },
        render: rowData => (
          <Box display="flex">
            {rowData.isFavorite ? (
              <Checkbox
                className="add-to-favorite-btn"
                icon={<FavoriteIcon />}
                checkedIcon={<FavoriteBorderIcon />}
                name="favorite"
                onClick={(event, id) => this.favoriteRequest(event, rowData.id)}
              />
            ) : (
              <Checkbox
                className="add-to-favorite-btn"
                icon={<FavoriteBorderIcon />}
                checkedIcon={<FavoriteIcon />}
                name="favorite"
                onClick={(event, id) => this.favoriteRequest(event, rowData.id)}
              />
            )}

            {/* <div style={{ width: "10px" }}></div> */}

            {/* <Report /> */}
          </Box>
        ),
      },
    ];

    return (
      <Page
        title="Find Shipments"
        searchPlaceholder="Search by Request ID"
        searchAction={this.handleSearch}
      >
  
        <div className=" supplier-requests-page">
          <div className="filter-pane">
            <Grid container spacing={2}>
              {/* <Grid item xs={6} sm={2}>
                <FilterById />
              </Grid> */}

              <Grid item xs={6} sm={2}>
                <GoogleMaps
                  size="small"
                  selection={data => {}}
                  details={(data, name) => {
                    this.handleFilters(data, "originCountryCode");
                  }}
                  label="Origin"
                />
              </Grid>

              <Grid item xs={6} sm={2}>
                <GoogleMaps
                  size="small"
                  selection={data => {}}
                  details={(data, name) => {
                    this.handleFilters(data, "destinationCountryCode");
                  }}
                  label="Destination"
                />
              </Grid>

              <Grid item xs={6} sm={2}>
                <CurrencyFilter
                  currency={this.state.filters.currency}
                  handleChange={this.handleCurrencyFilter}
                />
              </Grid>

              <Grid item xs={12} sm={5}>
                <ToggleButtonsFilter
                  handleToggle={this.handleToggle}
                  freightTypes={this.state.filters.freightTypes}
                  shipmentTypes={this.state.filters.shipmentTypes}
                />
              </Grid>

              <Grid item xs={2} sm={1} className="text-align-right">
                <Favorite
                  favoritesCount={this.state.favoritesCount}
                  getFavorites={this.getFavorites}
                />
              </Grid>
            </Grid>
          </div>

          <div className="filter-results">
            <div className="left">
              <span className="total-count">
                {!this.state.loading && 
                <>{this.state.totalRecordsCount &&
                this.state.totalRecordsCount > 0
                  ? this.state.totalRecordsCount + " Results"
                  : "No Results"}</>
                }
              </span>

              {this.state.filters.freightTypes &&
                this.state.filters.freightTypes.map(data => {
                  return (
                    <Chip
                      key={data}
                      label={transportModes[data]}
                      size="small"
                      onDelete={(value, name, type) =>
                        this.handleDelete(data, "freightTypes", 1)
                      }
                      color="primary"
                    />
                  );
                })}
              {this.state.filters.shipmentTypes &&
                this.state.filters.shipmentTypes.map(data => {
                  return (
                    <Chip
                      label={shipmentTypes[data]}
                      size="small"
                      onDelete={(value, name, type) =>
                        this.handleDelete(data, "shipmentTypes", 1)
                      }
                      color="primary"
                    />
                  );
                })}
              {this.state.filters.originCountry && (
                <Chip
                  label={this.state.filters.originCountry}
                  size="small"
                  onDelete={(value, name) =>
                    this.handleDelete(
                      this.state.filters.originCountryCode,
                      "originCountryCode"
                    )
                  }
                  color="primary"
                />
              )}
              {this.state.filters.destinationCountry && (
                <Chip
                  label={this.state.filters.destinationCountry}
                  size="small"
                  onDelete={(value, name) =>
                    this.handleDelete(
                      this.state.filters.destinationCountryCode,
                      "destinationCountryCode"
                    )
                  }
                  color="primary"
                />
              )}
              {this.state.filters.currency && (
                <Chip
                  label={this.state.filters.currency}
                  size="small"
                  onDelete={(value, name) =>
                    this.handleDelete(
                      this.state.filters.currency,
                      "currency"
                    )
                  }
                  color="primary"
                />
              )}
            </div>
            <div className="right">
              <span className="last-updated">
                {this.state.lastUpdated &&
                  "Last updated " + moment(this.state.lastUpdated).format("LT")}
              </span>
            </div>
          </div>

          <MaterialTable
            title="My Requests"
            tableRef={this.tableRef}
            options={{
              search: false,
              selection: false,
              toolbar: false,
              pageSize: this.state.pageSize,
              pageSizeOptions: [],
              draggable: false,
              rowStyle: rowData => {
                return {
                  backgroundColor: rowData.sentOffer ? "rgb(223 223 223)" : "",
                };
              },
            }}
            onRowClick={(event, rowData) =>
              this.handleRowClick(event, rowData.id)
            }
            // onSelectionChange={data=>{this.handleSelection(data)}}
            columns={shipmentColumns}
            data={query => this.search(query)}
            components={{
              Container: props => (
                <Paper
                  {...props}
                  elevation={0}
                  className="custom-table-container"
                />
              ),
              OverlayLoading: () => <div className="table-loader"><CircularProgress  /></div>
            }}
            //onChangePage={() => null}
            style={{ boxShadow: "none" }}
            actions={[
              {
                tooltip: "Unlist",
                icon: "cancel",
                position: "toolbarOnSelect",
                onClick: (event, data) => this.bulkUnlist(event, data),
              },
              {
                tooltip: "Delete",
                icon: "delete",
                position: "toolbarOnSelect",
                onClick: (event, data) => this.bulkDelete(event, data),
              },
            ]}
            localization={{
              body: {
                emptyDataSourceMessage: <NoResults
                title="No Requests found!"
                subtitle="There are no matching requests at the moment. Please try again!"
                buttonLabel="Refresh"
                buttonAction={() => this.refreshTable()}
              />
              }
            }}
          />
        </div>
        <RequestDrawer ref={this.refDrawer} />
      </Page>
    );
  }
}

export default withSnackbar(SupplierRequests);