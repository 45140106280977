
export const vats = [
	{country: 'AU', name: 'ABN', sample: '12345678912'},
	{country: 'AT', name: 'VAT Number', sample: 'ATU12345678'},
	{country: 'BE', name: 'VAT Number', sample: 'BE0123456789'},
	{country: 'BR', name: 'CNPJ number', sample: '01.234.456/5432-10'},
	{country: 'BG', name: 'VAT Number', sample: 'BG0123456789'},
	{country: 'CA', name: 'BN', sample: '123456789'},
	{country: 'CL', name: 'TIN', sample: '12.345.678-K'},
	{country: 'HR', name: 'VAT Number', sample: 'HR12345678912'},
	{country: 'CY', name: 'VAT Number', sample: 'CY12345678Z'},
	{country: 'CZ', name: 'VAT Number', sample: 'CZ1234567890'},
	{country: 'DK', name: 'VAT Number', sample: 'DK12345678'},
	{country: 'ES', name: 'VAT Number', sample: 'EE123456789'},
	{country: 'FI', name: 'VAT Number', sample: 'FI12345678'},
	{country: 'FR', name: 'VAT Number', sample: 'FRAB123456789'},
	{country: 'DE', name: 'VAT Number', sample: 'DE123456789'},
	{country: 'GR', name: 'VAT Number', sample: 'EL123456789'},
	{country: 'HK', name: 'BR Number', sample: '12345678'},
	{country: 'HU', name: 'VAT Number', sample: 'HU12345678912'},
	{country: 'IN', name: 'GST Number', sample: '12ABCDE3456FGZH'},
	{country: 'ID', name: 'NPWP Number', sample: '12.345.678.9-012.345'},
	{country: 'IE', name: 'VAT Number', sample: 'IE1234567AB'},
	{country: 'IT', name: 'VAT Number', sample: 'IT12345678912'},
	{country: 'JP', name: 'Japanese Corporate Number', sample: '1234567891234'},
	{country: 'KR', name: 'BRN Number', sample: '123-45-67890'},
	{country: 'LV', name: 'VAT Number', sample: 'LV12345678912'},
	{country: 'LI', name: 'UID Number', sample: 'CHE123456789'},
	{country: 'LT', name: 'VAT Number', sample: 'LT123456789123'},
	{country: 'LU', name: 'VAT Number', sample: 'LU12345678'},
	{country: 'MY', name: 'ITN or FRP Number', sample: 'C 1234567890 or 12345678'},
	{country: 'MT', name: 'VAT Number', sample: 'MT12345678'},
	{country: 'MX', name: 'RFC Number', sample: 'ABC010203AB9'},
	{country: 'NL', name: 'VAT Number', sample: 'NL123456789B12'},
	{country: 'NZ', name: 'GST Number', sample: '123456789'},
	{country: 'NO', name: 'VAT Number', sample: '123456789MVA'},
	{country: 'PL', name: 'VAT Number', sample: 'PL1234567890'},
	{country: 'PT', name: 'VAT Number', sample: 'PT123456789'},
	{country: 'RO', name: 'VAT Number', sample: 'RO1234567891'},
	{country: 'RU', name: 'INN or KPP', sample: '1234567891 or 123456789'},
	{country: 'SA', name: 'VAT Number', sample: '123456789012345'},
	{country: 'SG', name: 'GST or UEN', sample: 'M12345678X or 123456789F'},
	{country: 'SK', name: 'VAT Number', sample: 'SK1234567891'},
	{country: 'SI', name: 'VAT Number', sample: 'SI12345678'},
	{country: 'ZA', name: 'VAT Number', sample: '4123456789'},
	{country: 'es', name: 'CIF or VAT Number', sample: 'A12345678 or ESA1234567Z'},
	{country: 'SE', name: 'VAT Number', sample: 'SE123456789123'},
	{country: 'CH', name: 'VAT Number', sample: 'CHE-123.456.789 MWST'},
	{country: 'TW', name: 'VAT Number', sample: '12345678'},
	{country: 'TH', name: 'VAT Number', sample: '1234567891234'},
	{country: 'AE', name: 'TRN', sample: '1234567890'},
	{country: 'GB', name: 'VAT Number', sample: 'GB123456789'},
	{country: 'US', name: 'EIN', sample: '00-00000000'}
  ];
  
  export const continents = [
	{ value: 1, name: 'Europe' },
	{ value: 2, name: 'Asia' },
	{ value: 3, name: 'North America' },
	{ value: 4, name: 'South America' },
	{ value: 5, name: 'Africa' },
	{ value: 6, name: 'Oceania' },
  ];
  
  
  export const supportedCountries = [
	{ isoCode: 'AT', name: 'Austria', phone: '43' },
	{ isoCode: 'AU', name: 'Australia', phone: '61', suggested: true },
	{ isoCode: 'BE', name: 'Belgium', phone: '32' },
	{ isoCode: 'BR', name: 'Brazil', phone: '55' },
	{ isoCode: 'BG', name: 'Bulgaria', phone: '359' },
	{ isoCode: 'CA', name: 'Canada', phone: '1', suggested: true },
	{ isoCode: 'CY', name: 'Cyprus', phone: '357' },
	{ isoCode: 'CZ', name: 'Czech Republic', phone: '420' },
	{ isoCode: 'DK', name: 'Denmark', phone: '45' },
	{ isoCode: 'EE', name: 'Estonia', phone: '372' },
	{ isoCode: 'FI', name: 'Finland', phone: '358' },
	{ isoCode: 'FR', name: 'France', phone: '33', suggested: true },
	{ isoCode: 'DE', name: 'Germany', phone: '49', suggested: true },
	{ isoCode: 'GR', name: 'Greece', phone: '30' },
	{ isoCode: 'HK', name: 'Hong Kong', phone: '852' },
	{ isoCode: 'HU', name: 'Hungary', phone: '36' },
	{ isoCode: 'IN', name: 'India', phone: '91' },
	{ isoCode: 'IE', name: 'Ireland', phone: '353' },
	{ isoCode: 'IT', name: 'Italy', phone: '39' },
	{ isoCode: 'JP', name: 'Japan', phone: '81', suggested: true },
	{ isoCode: 'LV', name: 'Latvia', phone: '371' },
	{ isoCode: 'LT', name: 'Lithuania', phone: '370' },
	{ isoCode: 'LU', name: 'Luxembourg', phone: '352' },
	{ isoCode: 'MY', name: 'Malaysia', phone: '60' },
	{ isoCode: 'MT', name: 'Malta', phone: '356' },
	{ isoCode: 'MX', name: 'Mexico', phone: '52' },
	{ isoCode: 'NL', name: 'Netherlands', phone: '31' },
	{ isoCode: 'NZ', name: 'New Zealand', phone: '64' },
	{ isoCode: 'NO', name: 'Norway', phone: '47' },
	{ isoCode: 'PL', name: 'Poland', phone: '48' },
	{ isoCode: 'PT', name: 'Portugal', phone: '351' },
	{ isoCode: 'RO', name: 'Romania', phone: '40' },
	{ isoCode: 'SG', name: 'Singapore', phone: '65' },
	{ isoCode: 'SK', name: 'Slovakia', phone: '421' },
	{ isoCode: 'SI', name: 'Slovenia', phone: '386' },
	{ isoCode: 'ES', name: 'Spain', phone: '34' },
	{ isoCode: 'SE', name: 'Sweden', phone: '46' },
	{ isoCode: 'CH', name: 'Switzerland', phone: '41' },
	{ isoCode: 'AE', name: 'United Arab Emirates', phone: '971' },
	{ isoCode: 'GB', name: 'United Kingdom', phone: '44' },
	{ isoCode: 'US', name: 'United States', phone: '1', suggested: true }
  ];
  
  export const countries = [
	{ isoCode: 'AD', name: 'Andorra', phone: '376' },
	{ isoCode: 'AE', name: 'United Arab Emirates', phone: '971' },
	{ isoCode: 'AF', name: 'Afghanistan', phone: '93' },
	{ isoCode: 'AG', name: 'Antigua and Barbuda', phone: '1-268' },
	{ isoCode: 'AI', name: 'Anguilla', phone: '1-264' },
	{ isoCode: 'AL', name: 'Albania', phone: '355' },
	{ isoCode: 'AM', name: 'Armenia', phone: '374' },
	{ isoCode: 'AO', name: 'Angola', phone: '244' },
	{ isoCode: 'AQ', name: 'Antarctica', phone: '672' },
	{ isoCode: 'AR', name: 'Argentina', phone: '54' },
	{ isoCode: 'AS', name: 'American Samoa', phone: '1-684' },
	{ isoCode: 'AT', name: 'Austria', phone: '43' },
	{ isoCode: 'AU', name: 'Australia', phone: '61', suggested: true },
	{ isoCode: 'AW', name: 'Aruba', phone: '297' },
	{ isoCode: 'AX', name: 'Alland Islands', phone: '358' },
	{ isoCode: 'AZ', name: 'Azerbaijan', phone: '994' },
	{ isoCode: 'BA', name: 'Bosnia and Herzegovina', phone: '387' },
	{ isoCode: 'BB', name: 'Barbados', phone: '1-246' },
	{ isoCode: 'BD', name: 'Bangladesh', phone: '880' },
	{ isoCode: 'BE', name: 'Belgium', phone: '32' },
	{ isoCode: 'BF', name: 'Burkina Faso', phone: '226' },
	{ isoCode: 'BG', name: 'Bulgaria', phone: '359' },
	{ isoCode: 'BH', name: 'Bahrain', phone: '973' },
	{ isoCode: 'BI', name: 'Burundi', phone: '257' },
	{ isoCode: 'BJ', name: 'Benin', phone: '229' },
	{ isoCode: 'BL', name: 'Saint Barthelemy', phone: '590' },
	{ isoCode: 'BM', name: 'Bermuda', phone: '1-441' },
	{ isoCode: 'BN', name: 'Brunei Darussalam', phone: '673' },
	{ isoCode: 'BO', name: 'Bolivia', phone: '591' },
	{ isoCode: 'BR', name: 'Brazil', phone: '55' },
	{ isoCode: 'BS', name: 'Bahamas', phone: '1-242' },
	{ isoCode: 'BT', name: 'Bhutan', phone: '975' },
	{ isoCode: 'BV', name: 'Bouvet Island', phone: '47' },
	{ isoCode: 'BW', name: 'Botswana', phone: '267' },
	{ isoCode: 'BY', name: 'Belarus', phone: '375' },
	{ isoCode: 'BZ', name: 'Belize', phone: '501' },
	{ isoCode: 'CA', name: 'Canada', phone: '1', suggested: true },
	{ isoCode: 'CC', name: 'Cocos (Keeling) Islands', phone: '61' },
	{ isoCode: 'CD', name: 'Congo, Democratic Republic of the', phone: '243' },
	{ isoCode: 'CF', name: 'Central African Republic', phone: '236' },
	{ isoCode: 'CG', name: 'Congo, Republic of the', phone: '242' },
	{ isoCode: 'CH', name: 'Switzerland', phone: '41' },
	{ isoCode: 'CI', name: "Cote d'Ivoire", phone: '225' },
	{ isoCode: 'CK', name: 'Cook Islands', phone: '682' },
	{ isoCode: 'CL', name: 'Chile', phone: '56' },
	{ isoCode: 'CM', name: 'Cameroon', phone: '237' },
	{ isoCode: 'CN', name: 'China', phone: '86' },
	{ isoCode: 'CO', name: 'Colombia', phone: '57' },
	{ isoCode: 'CR', name: 'Costa Rica', phone: '506' },
	{ isoCode: 'CU', name: 'Cuba', phone: '53' },
	{ isoCode: 'CV', name: 'Cape Verde', phone: '238' },
	{ isoCode: 'CW', name: 'Curacao', phone: '599' },
	{ isoCode: 'CX', name: 'Christmas Island', phone: '61' },
	{ isoCode: 'CY', name: 'Cyprus', phone: '357' },
	{ isoCode: 'CZ', name: 'Czech Republic', phone: '420' },
	{ isoCode: 'DE', name: 'Germany', phone: '49', suggested: true },
	{ isoCode: 'DJ', name: 'Djibouti', phone: '253' },
	{ isoCode: 'DK', name: 'Denmark', phone: '45' },
	{ isoCode: 'DM', name: 'Dominica', phone: '1-767' },
	{ isoCode: 'DO', name: 'Dominican Republic', phone: '1-809' },
	{ isoCode: 'DZ', name: 'Algeria', phone: '213' },
	{ isoCode: 'EC', name: 'Ecuador', phone: '593' },
	{ isoCode: 'EE', name: 'Estonia', phone: '372' },
	{ isoCode: 'EG', name: 'Egypt', phone: '20' },
	{ isoCode: 'EH', name: 'Western Sahara', phone: '212' },
	{ isoCode: 'ER', name: 'Eritrea', phone: '291' },
	{ isoCode: 'ES', name: 'Spain', phone: '34' },
	{ isoCode: 'ET', name: 'Ethiopia', phone: '251' },
	{ isoCode: 'FI', name: 'Finland', phone: '358' },
	{ isoCode: 'FJ', name: 'Fiji', phone: '679' },
	{ isoCode: 'FK', name: 'Falkland Islands (Malvinas)', phone: '500' },
	{ isoCode: 'FM', name: 'Micronesia, Federated States of', phone: '691' },
	{ isoCode: 'FO', name: 'Faroe Islands', phone: '298' },
	{ isoCode: 'FR', name: 'France', phone: '33', suggested: true },
	{ isoCode: 'GA', name: 'Gabon', phone: '241' },
	{ isoCode: 'GB', name: 'United Kingdom', phone: '44' },
	{ isoCode: 'GD', name: 'Grenada', phone: '1-473' },
	{ isoCode: 'GE', name: 'Georgia', phone: '995' },
	{ isoCode: 'GF', name: 'French Guiana', phone: '594' },
	{ isoCode: 'GG', name: 'Guernsey', phone: '44' },
	{ isoCode: 'GH', name: 'Ghana', phone: '233' },
	{ isoCode: 'GI', name: 'Gibraltar', phone: '350' },
	{ isoCode: 'GL', name: 'Greenland', phone: '299' },
	{ isoCode: 'GM', name: 'Gambia', phone: '220' },
	{ isoCode: 'GN', name: 'Guinea', phone: '224' },
	{ isoCode: 'GP', name: 'Guadeloupe', phone: '590' },
	{ isoCode: 'GQ', name: 'Equatorial Guinea', phone: '240' },
	{ isoCode: 'GR', name: 'Greece', phone: '30' },
	{ isoCode: 'GS', name: 'South Georgia and the South Sandwich Islands', phone: '500' },
	{ isoCode: 'GT', name: 'Guatemala', phone: '502' },
	{ isoCode: 'GU', name: 'Guam', phone: '1-671' },
	{ isoCode: 'GW', name: 'Guinea-Bissau', phone: '245' },
	{ isoCode: 'GY', name: 'Guyana', phone: '592' },
	{ isoCode: 'HK', name: 'Hong Kong', phone: '852' },
	{ isoCode: 'HM', name: 'Heard Island and McDonald Islands', phone: '672' },
	{ isoCode: 'HN', name: 'Honduras', phone: '504' },
	{ isoCode: 'HR', name: 'Croatia', phone: '385' },
	{ isoCode: 'HT', name: 'Haiti', phone: '509' },
	{ isoCode: 'HU', name: 'Hungary', phone: '36' },
	{ isoCode: 'ID', name: 'Indonesia', phone: '62' },
	{ isoCode: 'IE', name: 'Ireland', phone: '353' },
	{ isoCode: 'IM', name: 'Isle of Man', phone: '44' },
	{ isoCode: 'IN', name: 'India', phone: '91' },
	{ isoCode: 'IO', name: 'British Indian Ocean Territory', phone: '246' },
	{ isoCode: 'IQ', name: 'Iraq', phone: '964' },
	{ isoCode: 'IR', name: 'Iran, Islamic Republic of', phone: '98' },
	{ isoCode: 'IS', name: 'Iceland', phone: '354' },
	{ isoCode: 'IT', name: 'Italy', phone: '39' },
	{ isoCode: 'JE', name: 'Jersey', phone: '44' },
	{ isoCode: 'JM', name: 'Jamaica', phone: '1-876' },
	{ isoCode: 'JO', name: 'Jordan', phone: '962' },
	{ isoCode: 'JP', name: 'Japan', phone: '81', suggested: true },
	{ isoCode: 'KE', name: 'Kenya', phone: '254' },
	{ isoCode: 'KG', name: 'Kyrgyzstan', phone: '996' },
	{ isoCode: 'KH', name: 'Cambodia', phone: '855' },
	{ isoCode: 'KI', name: 'Kiribati', phone: '686' },
	{ isoCode: 'KM', name: 'Comoros', phone: '269' },
	{ isoCode: 'KN', name: 'Saint Kitts and Nevis', phone: '1-869' },
	{ isoCode: 'KP', name: "Korea, Democratic People's Republic of", phone: '850' },
	{ isoCode: 'KR', name: 'Korea, Republic of', phone: '82' },
	{ isoCode: 'KW', name: 'Kuwait', phone: '965' },
	{ isoCode: 'KY', name: 'Cayman Islands', phone: '1-345' },
	{ isoCode: 'KZ', name: 'Kazakhstan', phone: '7' },
	{ isoCode: 'LA', name: "Lao People's Democratic Republic", phone: '856' },
	{ isoCode: 'LB', name: 'Lebanon', phone: '961' },
	{ isoCode: 'LC', name: 'Saint Lucia', phone: '1-758' },
	{ isoCode: 'LI', name: 'Liechtenstein', phone: '423' },
	{ isoCode: 'LK', name: 'Sri Lanka', phone: '94' },
	{ isoCode: 'LR', name: 'Liberia', phone: '231' },
	{ isoCode: 'LS', name: 'Lesotho', phone: '266' },
	{ isoCode: 'LT', name: 'Lithuania', phone: '370' },
	{ isoCode: 'LU', name: 'Luxembourg', phone: '352' },
	{ isoCode: 'LV', name: 'Latvia', phone: '371' },
	{ isoCode: 'LY', name: 'Libya', phone: '218' },
	{ isoCode: 'MA', name: 'Morocco', phone: '212' },
	{ isoCode: 'MC', name: 'Monaco', phone: '377' },
	{ isoCode: 'MD', name: 'Moldova, Republic of', phone: '373' },
	{ isoCode: 'ME', name: 'Montenegro', phone: '382' },
	{ isoCode: 'MF', name: 'Saint Martin (French part)', phone: '590' },
	{ isoCode: 'MG', name: 'Madagascar', phone: '261' },
	{ isoCode: 'MH', name: 'Marshall Islands', phone: '692' },
	{ isoCode: 'MK', name: 'Macedonia, the Former Yugoslav Republic of', phone: '389' },
	{ isoCode: 'ML', name: 'Mali', phone: '223' },
	{ isoCode: 'MM', name: 'Myanmar', phone: '95' },
	{ isoCode: 'MN', name: 'Mongolia', phone: '976' },
	{ isoCode: 'MO', name: 'Macao', phone: '853' },
	{ isoCode: 'MP', name: 'Northern Mariana Islands', phone: '1-670' },
	{ isoCode: 'MQ', name: 'Martinique', phone: '596' },
	{ isoCode: 'MR', name: 'Mauritania', phone: '222' },
	{ isoCode: 'MS', name: 'Montserrat', phone: '1-664' },
	{ isoCode: 'MT', name: 'Malta', phone: '356' },
	{ isoCode: 'MU', name: 'Mauritius', phone: '230' },
	{ isoCode: 'MV', name: 'Maldives', phone: '960' },
	{ isoCode: 'MW', name: 'Malawi', phone: '265' },
	{ isoCode: 'MX', name: 'Mexico', phone: '52' },
	{ isoCode: 'MY', name: 'Malaysia', phone: '60' },
	{ isoCode: 'MZ', name: 'Mozambique', phone: '258' },
	{ isoCode: 'NA', name: 'Namibia', phone: '264' },
	{ isoCode: 'NC', name: 'New Caledonia', phone: '687' },
	{ isoCode: 'NE', name: 'Niger', phone: '227' },
	{ isoCode: 'NF', name: 'Norfolk Island', phone: '672' },
	{ isoCode: 'NG', name: 'Nigeria', phone: '234' },
	{ isoCode: 'NI', name: 'Nicaragua', phone: '505' },
	{ isoCode: 'NL', name: 'Netherlands', phone: '31' },
	{ isoCode: 'NO', name: 'Norway', phone: '47' },
	{ isoCode: 'NP', name: 'Nepal', phone: '977' },
	{ isoCode: 'NR', name: 'Nauru', phone: '674' },
	{ isoCode: 'NU', name: 'Niue', phone: '683' },
	{ isoCode: 'NZ', name: 'New Zealand', phone: '64' },
	{ isoCode: 'OM', name: 'Oman', phone: '968' },
	{ isoCode: 'PA', name: 'Panama', phone: '507' },
	{ isoCode: 'PE', name: 'Peru', phone: '51' },
	{ isoCode: 'PF', name: 'French Polynesia', phone: '689' },
	{ isoCode: 'PG', name: 'Papua New Guinea', phone: '675' },
	{ isoCode: 'PH', name: 'Philippines', phone: '63' },
	{ isoCode: 'PK', name: 'Pakistan', phone: '92' },
	{ isoCode: 'PL', name: 'Poland', phone: '48' },
	{ isoCode: 'PM', name: 'Saint Pierre and Miquelon', phone: '508' },
	{ isoCode: 'PN', name: 'Pitcairn', phone: '870' },
	{ isoCode: 'PR', name: 'Puerto Rico', phone: '1' },
	{ isoCode: 'PS', name: 'Palestine, State of', phone: '970' },
	{ isoCode: 'PT', name: 'Portugal', phone: '351' },
	{ isoCode: 'PW', name: 'Palau', phone: '680' },
	{ isoCode: 'PY', name: 'Paraguay', phone: '595' },
	{ isoCode: 'QA', name: 'Qatar', phone: '974' },
	{ isoCode: 'RE', name: 'Reunion', phone: '262' },
	{ isoCode: 'RO', name: 'Romania', phone: '40' },
	{ isoCode: 'RS', name: 'Serbia', phone: '381' },
	{ isoCode: 'RU', name: 'Russian Federation', phone: '7' },
	{ isoCode: 'RW', name: 'Rwanda', phone: '250' },
	{ isoCode: 'SA', name: 'Saudi Arabia', phone: '966' },
	{ isoCode: 'SB', name: 'Solomon Islands', phone: '677' },
	{ isoCode: 'SC', name: 'Seychelles', phone: '248' },
	{ isoCode: 'SD', name: 'Sudan', phone: '249' },
	{ isoCode: 'SE', name: 'Sweden', phone: '46' },
	{ isoCode: 'SG', name: 'Singapore', phone: '65' },
	{ isoCode: 'SH', name: 'Saint Helena', phone: '290' },
	{ isoCode: 'SI', name: 'Slovenia', phone: '386' },
	{ isoCode: 'SJ', name: 'Svalbard and Jan Mayen', phone: '47' },
	{ isoCode: 'SK', name: 'Slovakia', phone: '421' },
	{ isoCode: 'SL', name: 'Sierra Leone', phone: '232' },
	{ isoCode: 'SM', name: 'San Marino', phone: '378' },
	{ isoCode: 'SN', name: 'Senegal', phone: '221' },
	{ isoCode: 'SO', name: 'Somalia', phone: '252' },
	{ isoCode: 'SR', name: 'Suriname', phone: '597' },
	{ isoCode: 'SS', name: 'South Sudan', phone: '211' },
	{ isoCode: 'ST', name: 'Sao Tome and Principe', phone: '239' },
	{ isoCode: 'SV', name: 'El Salvador', phone: '503' },
	{ isoCode: 'SX', name: 'Sint Maarten (Dutch part)', phone: '1-721' },
	{ isoCode: 'SY', name: 'Syrian Arab Republic', phone: '963' },
	{ isoCode: 'SZ', name: 'Swaziland', phone: '268' },
	{ isoCode: 'TC', name: 'Turks and Caicos Islands', phone: '1-649' },
	{ isoCode: 'TD', name: 'Chad', phone: '235' },
	{ isoCode: 'TF', name: 'French Southern Territories', phone: '262' },
	{ isoCode: 'TG', name: 'Togo', phone: '228' },
	{ isoCode: 'TH', name: 'Thailand', phone: '66' },
	{ isoCode: 'TJ', name: 'Tajikistan', phone: '992' },
	{ isoCode: 'TK', name: 'Tokelau', phone: '690' },
	{ isoCode: 'TL', name: 'Timor-Leste', phone: '670' },
	{ isoCode: 'TM', name: 'Turkmenistan', phone: '993' },
	{ isoCode: 'TN', name: 'Tunisia', phone: '216' },
	{ isoCode: 'TO', name: 'Tonga', phone: '676' },
	{ isoCode: 'TR', name: 'Turkey', phone: '90' },
	{ isoCode: 'TT', name: 'Trinidad and Tobago', phone: '1-868' },
	{ isoCode: 'TV', name: 'Tuvalu', phone: '688' },
	{ isoCode: 'TW', name: 'Taiwan, Province of China', phone: '886' },
	{ isoCode: 'TZ', name: 'United Republic of Tanzania', phone: '255' },
	{ isoCode: 'UA', name: 'Ukraine', phone: '380' },
	{ isoCode: 'UG', name: 'Uganda', phone: '256' },
	{ isoCode: 'US', name: 'United States', phone: '1', suggested: true },
	{ isoCode: 'UY', name: 'Uruguay', phone: '598' },
	{ isoCode: 'UZ', name: 'Uzbekistan', phone: '998' },
	{ isoCode: 'VA', name: 'Holy See (Vatican City State)', phone: '379' },
	{ isoCode: 'VC', name: 'Saint Vincent and the Grenadines', phone: '1-784' },
	{ isoCode: 'VE', name: 'Venezuela', phone: '58' },
	{ isoCode: 'VG', name: 'British Virgin Islands', phone: '1-284' },
	{ isoCode: 'VI', name: 'US Virgin Islands', phone: '1-340' },
	{ isoCode: 'VN', name: 'Vietnam', phone: '84' },
	{ isoCode: 'VU', name: 'Vanuatu', phone: '678' },
	{ isoCode: 'WF', name: 'Wallis and Futuna', phone: '681' },
	{ isoCode: 'WS', name: 'Samoa', phone: '685' },
	{ isoCode: 'XK', name: 'Kosovo', phone: '383' },
	{ isoCode: 'YE', name: 'Yemen', phone: '967' },
	{ isoCode: 'YT', name: 'Mayotte', phone: '262' },
	{ isoCode: 'ZA', name: 'South Africa', phone: '27' },
	{ isoCode: 'ZM', name: 'Zambia', phone: '260' },
	{ isoCode: 'ZW', name: 'Zimbabwe', phone: '263' },
  ];
  
  