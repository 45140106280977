import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import PaymentMethodsList from './PaymentMethodsList';
import APIKit from '../../../include/APIKit';
import PaymentMethodIcon from './PaymentMethodIcon';
import { CircularProgress, ListItemSecondaryAction } from '@material-ui/core';
import AddPaymentMethod from './AddPaymentMethod';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function MethodsDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, methods, addAction } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth={"sm"} fullWidth >
      	<DialogTitle id="simple-dialog-title">Select payment method</DialogTitle>
      	<PaymentMethodsList methods={methods} readOnly={true} onSelect={onClose} />
	  	<ListItem autoFocus button onClick={addAction}
		  style={{paddingLeft: 30, paddingRight: 18, borderTop: '1px solid #f1f1f1', height: 70}}
		>
			<ListItemAvatar>
			<Avatar>
				<AddIcon />
			</Avatar>
			</ListItemAvatar>
			<ListItemText primary="Add new payment method" />
		</ListItem>
    </Dialog>
  );
}

MethodsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function PaymentMethodsDialog(props) {
	const [open, setOpen] = React.useState(false);
	const [addOpen, setAddOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [methods, setMethods] = React.useState(null);
	const [defaultMethod, setDefaultMethod] = React.useState(null);
	const [selectedValue, setSelectedValue] = React.useState(null);
	const monthName = (mon) => {
		return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon - 1];
	}
	const cards = {
		visa: {name: 'Visa', icon: ''},
		amex: {name: 'American Express', icon: ''},
		mastercard: {name: 'Master Card', icon: ''},
		jcb: {name: 'JCB', icon: ''},
		diners: {name: 'Diners', icon: ''},
		discover: {name: 'Discover', icon: ''},
		unionpay: {name: 'UnionPay', icon: ''}
	}
	React.useEffect(() => {
		getPaymentMethods();
	}, []);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (value) => {
		setOpen(false);
		setSelectedValue(value);
		props.getMethod(value?.id);
		console.log(value);
	};

	const refresh = () => {
		
	};


	const handleAddClick = () => {
		//setOpen(false);

		setAddOpen(true);
	}

	const onAdd = (id) => {
		getPaymentMethods(id);
	};

	const getPaymentMethods = (selected) => {
		setLoading(true);
		let url = props.offerId ? `Payments/payment-methods/${props.offerId}` : "PaymentMethods";
		APIKit.get(url).then(result => {
			if (result.status === 200) {
				if(result.data.isSuccess) {
					setMethods(result.data.record);
					if(selected) {
						const selectedMethod = result.data.record.filter(obj => obj.id == selected)[0];
						setSelectedValue(selectedMethod)
						props.getMethod(selectedMethod?.id);
						setAddOpen(false);
						setOpen(false);
						return;
					}
					const defMethod = result.data.record.filter(obj => obj.isDefault === true)[0];
					setSelectedValue(defMethod)
					props.getMethod(defMethod?.id);
				}
			}
			setLoading(false);
		}).catch(e => {});
	}

  return (
    <React.Fragment>
		{selectedValue ? <ListItem  style={{border: '1px solid #eaeaea', borderRadius: 4}} button onClick={handleClickOpen} >
			<ListItemAvatar  >
				<PaymentMethodIcon type={selectedValue.type == 'card' ? selectedValue.cardType : selectedValue.type} />
			</ListItemAvatar>
			<ListItemText 
				primary={`${selectedValue.type == 'card' ? cards[selectedValue.cardType].name : selectedValue.typeName} •••• ${selectedValue.last4}`} 
				secondary={selectedValue.type == 'card' ? `Expires ${ monthName(selectedValue.expiryMonth)} ${selectedValue.expiryYear}` :
				selectedValue.type == 'sepa_debit' ? 'EUR' : 'USD'
				} 
			/>
		</ListItem> :
		<ListItem  style={{border: '1px solid #eaeaea', borderRadius: 4}} button onClick={handleClickOpen} >
			<ListItemAvatar  >
				{loading ? <CircularProgress size={30} /> : <PaymentMethodIcon type={'bank_account'} />}
			</ListItemAvatar>
			<ListItemText 
				primary="Select payment method" 
				secondary={loading ? "Loading options..." : `${methods?.length} options` } 
			/>
		</ListItem> }
		{addOpen && <AddPaymentMethod open={addOpen} onClose={() => setAddOpen(false)} refresh={() => refresh()} callback={onAdd} />}
      <MethodsDialog selectedValue={selectedValue} open={open} methods={methods} onClose={handleClose} onSelect={handleClose} addAction={handleAddClick} />
    </React.Fragment>
  );
}