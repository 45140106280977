import { Button, IconButton, TextField, withStyles } from "@material-ui/core";
import React from "react";
import moment from "moment";
import { Delete } from "@material-ui/icons";


export default function MessageFooter({onInputChange, onSubmit, message, setAttachments}) {
  const [selectedFiles, setSelectedFiles] = React.useState("");
  const [fileError, setFileError] = React.useState(false);

  const handleFile = event => {
    const files = event.target.files;
    if (!files) return;

    let newFiles= [];
    Array.from(files).forEach(file => {
      // if (file.size >= 5242880) {
      //   setFileError("File size must be less than 5MB");
      //   return false;
      // }
      const name = file.name;
      const lastDot = name.lastIndexOf(".");
      const fileName = name.substring(0, lastDot);
      const extension = name.substring(lastDot + 1);
      const newFile = {
        name,
        extension,
        size: file.size
      };
      newFiles.push(newFile);
      console.log(file)
    });
    //setFileError(false);
    setAttachments(files);
    
    setSelectedFiles(newFiles);
  };

  const deleteFile = (index) => {
    console.log(formRef.files.files);
    let files = [...selectedFiles];
    let removed = files.splice(index, 1)
    setSelectedFiles(files);

    let atts = Array.from(formRef.files.files);
    let removedAtts = atts.splice(index, 1)
    setAttachments(atts);
    console.log(atts)
  }

  const FileIcon = props => {
    return (
      <img
        src={"/img/filetypes/" + props.type + ".svg"}
        className="message-filetype-icon"
      />
    );
  };

  const handleSubmit = () => {
    onSubmit();
    formRef.reset();  
    setSelectedFiles("");
  }


  let formRef = React.useRef();

	return (
		<div className="message-footer">
      <div style={{marginBottom: selectedFiles ? 25 : 0}}>
        {selectedFiles && selectedFiles.map((file, key) => (
          <div className="file" key={key}>
            <FileIcon type={file.extension} /> {file.name}
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={(index) =>
                deleteFile(key)
              }
            >
              <Delete fontSize="small" />
            </IconButton>
          </div>
        ))}
      </div>
      <form ref={(el) => formRef = el}>
          <TextField
            variant="outlined"
            name="message"
            className="message-field"
            fullWidth
            multiline
            rows={4}
            inputProps={{
              maxLength: 2500,
            }}
            onChange={(event) => onInputChange(event.target.value)}
          />
          <div className="message-inputs">
            <div className="message-left-inputs">
              <span className="charcount" style={message.length === 2500 ? {color: '#f44336'} : {}}>{message.length} / 2500</span>
            </div>
            <div className="message-right-inputs">
              <WhiteButton variant="contained" disableElevation color="default" component="label" >
                Attach Files
                <input
                  type="file"
                  name="files"
                  hidden
                  multiple
                  onChange={event => handleFile(event)}
                />
              </WhiteButton>
              <Button variant="contained" disableElevation color="primary" onClick={handleSubmit}>
                Send
              </Button>
            </div>
          </div>

          
      </form>
    </div>
	)
}

const WhiteButton = withStyles(theme => ({
	root: {
	  color: "#333",
	  backgroundColor: "#fff",
	  marginRight: 10,
	  border: "1px solid #e6e6e6",
	  paddingTop: "5.5px",
	  paddingBottom: "5.5px",
	  "&:hover": {
		backgroundColor: "#fbfbfb",
	  },
	},
}))(Button);