import React from "react";
import Card from "../Card";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";

import { Box, Chip } from "@material-ui/core";

export default function Contracts({ contracts, totalItems }) {
  const history = useHistory();
  const handleRowClick = (event, id) =>
    history.push("/contract/" + id + "/overview");

  const style = {
    title: {
      fontSize: "16px",
      fontWeight: "500",
    },
    subtitle: {
      fontSize: "14px",
      color: "#80878f",
      fontWeight: "400",
    },
    expire: {
      fontSize: "14px",
      color: "#80878f",
    },
  };

  const shipmentColumns = [
    {
      title: "",
      field: "contractNumber",
      headerStyle: {
        display: "none",
      },
      cellStyle: {
        padding: "0 30px",
      },
      sorting: false,
      render: rowData => (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          pt={2}
          pb={2}
        >
          <div>
            <div className="item-title item-ref" style={style.title}>
              {rowData.number}
            </div>
            <div className="item-title" style={style.subtitle}>
              {/* {rowData.currency}  */}$ {rowData.totalAmount}
            </div>
          </div>

          <div style={style.expire}>Expires in 2 days</div>
        </Box>
      ),
    },
  ];
  return (
    <Card
      variant="white"
      bodyPadding={false}
      headerTitle="Active Contracts"
      headerSubtitle=""
      headerAction={
        <Chip
          size="small"
          label={"Total: " + totalItems}
          // label={"Total: " + 3}
          className={"shipment-status Completed"}
        />
      }
      elevated={false}
      style={{ width: "40%" }}
    >
      <MaterialTable
        options={{
          search: false,
          sorting: false,
          draggable: false,
          toolbar: false,
          pageSize: 3,
          pageSizeOptions: [],
        }}
        onRowClick={(event, rowData) => handleRowClick(event, rowData.id)}
        columns={shipmentColumns}
        data={contracts}
        // data={contracts}
        style={{ boxShadow: "none", border: "none" }}
      />
    </Card>
  );
}
