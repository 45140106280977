import React from "react";
import { 
	Box,
	LinearProgress,
	Button,
	CircularProgress
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";


export default function ProgressBar (props) {
	return (
	  <Box display="flex" alignItems="center" className="form-progress">
		<Box width="100%" mr={5}>
			<LinearProgress variant="determinate" value={((props.value-1)/3)*100 } />
		</Box>
		<Box minWidth="250px" maxWidth="40%">
			<Button variant="contained" size="large" color="primary" onClick={props.back} disableElevation disabled={props.value === 1 ? true : false} className="back-button">
			<ChevronLeft/>
			</Button>
			{props.value < 4 &&
			<Button variant="contained" size="large" color="primary" onClick={props.next} disableElevation endIcon={<ChevronRight />} 
			// disabled={props.value >= 80 ? true : false} 
			className="next-button">
			Next
			</Button> }
			{props.value === 4 &&
				<Button
					variant="contained" 
					size="large" 
					color="primary" 
					onClick={props.submit} 
					disableElevation 
					endIcon={!props.loading && <ChevronRight />}  
					className="next-button"
					disabled={props.loading}
				>
					{props.loading ? <CircularProgress color="inherit" size={20} className="button-spinner" /> : "Submit"}
				</Button>
			}
		</Box>
	  </Box>
	);
}