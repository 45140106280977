import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
// import axios from 'axios';
import config from "../include/config.json";
import { useStore } from "../context/StoreContext";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

// UI Imports
import {
  Button,
  TextField,
  Grid,
  CircularProgress,
  IconButton,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { Visibility, VisibilityOff } from "@material-ui/icons";

import APIKit, { setClientToken } from "../include/APIKit";

function Login(props) {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [ShowPassword, setShowPassword] = useState(false);

  const [validate, setValidate] = useState({});

  const { setToken, authToken } = useStore();

  const [ResetMode, setResetMode] = useState(false);
  const [ResetEmail, setResetEmail] = useState("");

  const referer = props.location?.state ? props.location.state.referer : "/";
  document.title = "Login";

  function validator(object) {
    var empty = [];
    for (var key in object) {
      if (object[key] === "") {
        empty.push(key);
      }
    }
    return empty;
  }

  const handleClickShowPassword = () => {
    setShowPassword(!ShowPassword);
  };
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const resetToggle = event => {
    event.preventDefault();
    setResetMode(!ResetMode);
  };

  function postLogin(e) {
    e.preventDefault();
    const body = {
      Email,
      Password,
    };

    var isValid = validator(body);
    if (isValid.length > 0) {
      const vali = [];
      for (var key in isValid) {
        vali[isValid[key]] = true;
        setValidate(vali);
      }
      console.log(validate);
    } else {
      setIsError(false);
      setIsBusy(true);
      APIKit.post("authentication/login", body)
        .then(result => {
          if (result.status === 200) {
            if (result.data.isSuccess) {
              setToken(result.data.record.accessToken);
              console.log(result.data);
              //setIsLoggedIn(true);
              //return <Redirect to={referer} />;
            } else {
              setIsError(true);
              setErrorMessage(result.data?.Message);
            }
          } else {
            setIsError(true);
            setErrorMessage(result.data?.Message);
          }
          setIsBusy(false);
        })
        .catch(e => {
          setIsError(true);
          setErrorMessage(e.response?.data?.Errors ? e.response.data.Errors[0] : "Whoops! Something went wrong.");
          setIsBusy(false);
        });
    }
  }

  const history = useHistory();

  function forgetPassword(e) {
    e.preventDefault();
    setIsBusy(true);

    let body = {
      email: ResetEmail,
    };
    APIKit.post("/Authentication/ForgotPassword", body)
      .then(e => {
        setIsBusy(false);
        history.push("/verification-sent");
      })
      .catch(e => {
        setIsBusy(false);
        setIsError(true);
        setErrorMessage("Something went wrong please try again");
        console.log(e);
      });
  }

  if (authToken) {
    return <Redirect to={referer} />;
  }

  const token = queryString.parse(props.location?.search).token;

  if (token != undefined) {
    setToken(token);
    return <Redirect to="/" />;
  }

  return (
    <div className="auth-container">
      <div className="auth-body">
        <Link to="/">
          <img src="/img/lgsm-logo.svg" className="auth-logo" />
        </Link>
        <div className="content-holder">
          {ResetMode ? (
            <div>
              <h2 className="page-h2">Forgot your password?</h2>
              <p className="page-p">
                Enter your email and we will send you a link to reset your
                password.
              </p>
              <br />
              <form onSubmit={forgetPassword}>
                <TextField
                  label="Email Address"
                  type="email"
                  variant="outlined"
                  fullWidth
                  onChange={e => {
                    setResetEmail(e.target.value);
                  }}
                />

                {isError && <Alert severity="error">{errorMessage}</Alert>}
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disableElevation
                  fullWidth
                  disabled={isBusy}
                >
                  {isBusy && <CircularProgress color="inherit" size="26px" />}
                  {!isBusy && "Reset Password"}
                </Button>
                <p className="page-body2 forgot-password-link">
                  <Link to="#" onClick={resetToggle}>
                    Back to Login
                  </Link>
                </p>
              </form>
            </div>
          ) : (
            <div>
              <h2 className="page-h2">Sign in</h2>
              <p className="page-p">
                Don't have an account? <Link to="/register">Register</Link>
              </p>
              <br />
              <form onSubmit={postLogin}>
                <TextField
                  label="Email Address"
                  type="email"
                  variant="outlined"
                  fullWidth
                  onChange={e => {
                    setEmail(e.target.value);
                  }}
                  error={validate.Email}
                  data-testid="emailInput"
                />

                <FormControl
                  variant="outlined"
                  fullWidth
                  error={validate.Password}
                >
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    id="password"
                    data-testid="passwordInput"
                    type={ShowPassword ? "text" : "password"}
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle Password"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {ShowPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>

                {isError && (
                  <Alert severity="error" data-testid="errorMessage">
                    {errorMessage}
                  </Alert>
                )}
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disableElevation
                  fullWidth
                  disabled={isBusy}
                  data-testid="loginButton"
                >
                  {isBusy && <CircularProgress color="inherit" size="26px" />}
                  {!isBusy && "Login"}
                </Button>

                <p className="page-body2 forgot-password-link">
                  <Link to="#" onClick={resetToggle}>
                    Forgot Password?
                  </Link>
                </p>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
