import React from "react";

import { IconButton, Badge } from "@material-ui/core";
import { Favorite as FavoriteIcon } from "@material-ui/icons";

export default function Favorite(props) {
  const { favoritesCount, getFavorites } = props;
  return (
    <div>
      {favoritesCount > 0 ? (
        <Badge color="secondary" overlap="circle" badgeContent={favoritesCount}>
          <IconButton
            aria-label="Watch"
            size="small"
            className="favorites-btn"
            disableRipple
            onClick={getFavorites}
          >
            <FavoriteIcon />
          </IconButton>
        </Badge>
      ) : (
        <IconButton
          aria-label="Watch"
          size="small"
          className="favorites-btn"
          disableRipple
          onClick={getFavorites}
        >
          <FavoriteIcon />
        </IconButton>
      )}
    </div>
  );
}
