/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import timezones from "../../../assets/json/timezones.json";

let defaultTimezone = localStorage.getItem("Timezone");

export default function TimeZoneSelector() {
  const [timezone, setTimeZone] = React.useState([]);
  const [selectedTimezone, setSelectedTimeZone] = React.useState("");

  const getTimeZones = () => {
    timezones.map(time => {
      time.utc.map(utc => setTimeZone(timezone => [...timezone, utc]));
    });
  };

  const handleChange = (e, value) => {
    setSelectedTimeZone(() => value);
    localStorage.setItem("Timezone", value);
  };
  useEffect(() => {
    getTimeZones();
  }, []);

  return (
    <Autocomplete
      autoComplete
      autoHighlight
      id="combo-box-demo"
      options={timezone}
      getOptionLabel={option => option}
      style={{ width: 300 }}
      renderInput={params => (
        <TextField {...params} label="Choose timezone" variant="outlined" />
      )}
      defaultValue={defaultTimezone}
      onChange={handleChange}
    />
  );
}
