import React from "react";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { Tooltip } from "@material-ui/core";
import {
  DirectionsRailway,
  DirectionsBoat,
  DirectionsCar,
  Landscape,
} from "@material-ui/icons";

export default function ToggleButtonsFilter({
  shipmentTypes,
  handleToggle,
  freightTypes,
}) {
  return (
    <div>
      <ToggleButtonGroup
        value={freightTypes}
        onChange={(event, value, name) =>
          handleToggle(event, value, "freightTypes")
        }
        aria-label="Freight Type"
        size="small"
      >
        <ToggleButton value={1} aria-label="left aligned">
          <Tooltip title="Ocean" placement="bottom">
            <DirectionsBoat />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value={2} aria-label="centered">
          <Tooltip title="Land" placement="bottom">
            <DirectionsRailway />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
      <span className="padder"></span>
      <ToggleButtonGroup
        value={shipmentTypes}
        onChange={(event, value, name) =>
          handleToggle(event, value, "shipmentTypes")
        }
        aria-label="Shipment Type"
        size="small"
      >
        <ToggleButton value={3} aria-label="centered">
          <Tooltip title="FCL" placement="bottom">
            <svg
              className="MuiSvgIcon-root"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M21.5,3.5h-19c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h19c1.1,0,2-0.9,2-2v-13C23.5,4.4,22.6,3.5,21.5,3.5z M21.5,18.5h-19v-13h19V18.5zM3.5, 6.5 h17 v11 h-17z"></path>
            </svg>
          </Tooltip>
        </ToggleButton>
        <ToggleButton value={2} aria-label="right aligned">
          <Tooltip title="LCL" placement="bottom">
            <svg
              className="MuiSvgIcon-root"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M21.5,3.5h-19c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h19c1.1,0,2-0.9,2-2v-13C23.5,4.4,22.6,3.5,21.5,3.5z M21.5,18.5h-19v-13h19V18.5zM3.5, 12.5 h17 v5 h-17z"></path>
            </svg>
          </Tooltip>
        </ToggleButton>
        <ToggleButton value={1} aria-label="RoRo">
          <Tooltip title="RoRo" placement="bottom">
            <DirectionsCar />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value={4} aria-label="centered">
          <Tooltip title="Bulk/Break Bulk" placement="bottom">
            <Landscape />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
