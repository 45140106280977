import React from "react";
import { Box, Grid, Paper } from "@material-ui/core";
import AvatarComponent from "../../settings/profileCompanyInfo/Avatar";
import Badges from "./Badges";
import FeatureNotice from "../../FeatureNotice";

export default function CardBody(props) {
  const {
    name,
    isShipper,
    averageRating,
    currencyCode,
    nbOfBooking,
    url,
    amountToSpend,
    paymentVerified,
    location,
  } = props;

  const style = {
    card: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      padding: "40px",
    },
    title: {
      fontWeight: "500",
      fontSize: "18px",
    },
    subtitle: {
      color: "#80878f",
      fontSize: "14px",
    },
    container: {
      margin: "20px 0",
    },
    value: {
      fontSize: "14px",
      fontWeight: 500,
    },
  };

  return (
    <div className="card-body" style={style.card}>
      <FeatureNotice style={{marginBottom: 25}} />
      <AvatarComponent url={url} badge={true} height="70px" width="70px" />

      <div style={style.container}>
        <div style={style.title}>{name}</div>
        {/* <div style={style.subtitle}>
          {isShipper ? "Shipper" : "Supplier"}
        </div> */}
      </div>

      {/* <Badges
        // amount={amountToSpend}
        // payment={paymentVerified}
        // rating={averageRating}
        // location={location}
        amount={13131}
        payment={paymentVerified}
        rating={averageRating}
        location={"Lebanon"}
      /> */}

      <Badges
        // amount={amountToSpend}
        // payment={paymentVerified}
        // rating={averageRating}
        // location={location}
        amount={13131}
        payment={paymentVerified}
        rating={averageRating}
        location={"Lebanon"}
      />

      <div style={{ width: "100%", marginTop: "20px" }}>
        <Box
          mb={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <div style={style.subtitle}>Number of booking:</div>
          <div style={style.value}>{nbOfBooking}</div>
        </Box>

        <Box
          mb={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <div style={style.subtitle}>Average rating:</div>
          <div style={style.value}>{averageRating}</div>
        </Box>

        <Box
          mb={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <div style={style.subtitle}>Currency Code:</div>
          <div style={style.value}>{currencyCode}</div>
        </Box>

        <Box
          mb={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <div style={style.subtitle}>Payment:</div>
          <div style={style.value}>
            {/* {paymentVerified} */}
            Verified
          </div>
        </Box>

        <Box
          mb={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <div style={style.subtitle}>Amount spent:</div>
          <div style={style.value}>
            1200
            {/* {amountToSpend} */}
          </div>
        </Box>
      </div>
    </div>
  );
}
